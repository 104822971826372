<ng-template #childComponentTemplate>
  <app-tag-wrapper #tagWrapper [element]="element.html">
    <ng-container *ngFor="let el of elements">
      <!-- Display any plain text -->
      <ng-container *ngIf="el.text">
        <div [outerHTML]="el.text | sanitize"></div>
      </ng-container>

      <!-- Display an element-->
      <ng-container *ngIf="el.element">
        <!-- Handle non dynamic elements without children -->
        <div *ngIf="!el.element.dynamic && el.element.html.children.length === 0"
          [outerHTML]="el.element.html.outerHTML | sanitize"></div>

        <!-- Handle non dynamic elements with children -->
        <ng-container *ngIf="!el.element.dynamic && el.element.html.children.length > 0">
          <app-outer-wrapper #wrapperBasic [element]="el.element"></app-outer-wrapper>
          <ng-content *ngTemplateOutlet='wrapperBasic.childComponentTemplate'></ng-content>
        </ng-container>

        <!-- Handle dynamic elements without children -->
        <ng-container *ngIf="el.element.dynamic && el.element.html.children.length === 0">
          <app-dynamic-wrapper #wrapper [element]="el.element.html"></app-dynamic-wrapper>
          <ng-content *ngTemplateOutlet='wrapper.childComponentTemplate'></ng-content>
        </ng-container>

      </ng-container>


    </ng-container>
  </app-tag-wrapper>
</ng-template>