import { Component, OnInit, Input } from '@angular/core';
import { get } from 'lodash';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
@Component({
  selector: 'app-product-detail-card',
  templateUrl: './product-detail-card.component.html',
  styleUrls: ['./product-detail-card.component.scss']
})
export class ProductDetailCardComponent implements OnInit {
  @Input() data;
  img;
  title: string;
  category: string;
  categoryLink: string;
  brand: string;
  brandLink: string;
  description: string;
  aroma: string;
  taste: string;
  finish: string;
  constructor(private imgSafePipe: ImgSafePathPipe) { }

  ngOnInit() {
    this.img = this.imgSafePipe.transform(this.data, `productImage[0].images[0].link.default`);
    this.title = get(this.data, `productSubHeading`);
    this.category = get(this.data, `productCategory[0]categorySubHeadline`);
    this.categoryLink = get(this.data, `productCategory[0]slug`);
    this.brand = get(this.data, `productBrand[0].brandSubHeading`);
    this.brandLink = get(this.data, `productBrand[0].slug`);
    this.description = get(this.data, `productShortDescription.value`);
    this.aroma = get(this.data, `productAroma`);
    this.taste = get(this.data, `productTaste`);
    this.finish = get(this.data, `productFinish`);
  }

}
