import {
  Directive,
  HostBinding,
  Input,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Image, FileLink } from '@proxy/service-proxies';
import { ImgSizes, ImgResizeType } from '@core/enum/enum';
import { ImgResizeObject } from '@shared/interfaces/interfaces';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { get } from 'lodash';
@Directive({
  selector: '[appImgResize]',
})
export class ImgResizeDirective implements AfterViewInit {
  @HostBinding('attr.src') srcAttr = null;
  @HostBinding('style.background-image') background: SafeStyle;
  @HostBinding('style.background-color') color;
  @Input() src: any;
  @Input() resizeInfo: ImgResizeObject;
  @Input() backgroundImg;
  imgSizes = ImgSizes;

  // contains the img sizes from src
  private imageOptions: FileLink;

  constructor(
    private el: ElementRef,
    private imgSafePipe: ImgSafePathPipe,
    public breakpointObserver: BreakpointObserver,
    private sanitizer: DomSanitizer
  ) {}

  ngAfterViewInit() {
    const CustomBreakpointName = {
      extraSmall: '(min-width: 0px) and (max-width: 319px)',
      small: '(min-width: 320px) and (max-width: 575.98px)',
      medium: '(min-width: 576px) and (max-width: 767.99px)',
      large: '(min-width: 768px) and (max-width: 1199.99px)',
      extraLarge: '(min-width: 1200px)',
    };

    if (this.src instanceof Image && this.src['images'][0].link) {
      if (this.resizeInfo.imgType === ImgResizeType.Card) {
        this.imageOptions = this.src['images'][0].link;
        this.breakpointObserver
          .observe([
            CustomBreakpointName.extraSmall,
            CustomBreakpointName.small,
            CustomBreakpointName.medium,
            CustomBreakpointName.large,
            CustomBreakpointName.extraLarge,
          ])
          .subscribe((result) => {
            if (result.breakpoints[CustomBreakpointName.small]) {
              this.srcAttr = this.imgSafePipe.transform(
                this.imageOptions,
                this.resizeInfo.small
              );
            }
            if (result.breakpoints[CustomBreakpointName.medium]) {
              this.srcAttr = this.imgSafePipe.transform(
                this.imageOptions,
                this.resizeInfo.medium
              );
            }
            if (result.breakpoints[CustomBreakpointName.large]) {
              this.srcAttr = this.imgSafePipe.transform(
                this.imageOptions,
                this.resizeInfo.large
              );
            }
            if (result.breakpoints[CustomBreakpointName.extraLarge]) {
              this.srcAttr = this.imgSafePipe.transform(
                this.imageOptions,
                this.resizeInfo.xlarge
              );
            }
          });
      }
    } else if (this.resizeInfo.imgType === ImgResizeType.Hero) {
      this.imageOptions = get(this.backgroundImg, `['images'][0].link`);

      this.breakpointObserver
        .observe([
          CustomBreakpointName.extraSmall,
          CustomBreakpointName.small,
          CustomBreakpointName.medium,
          CustomBreakpointName.large,
          CustomBreakpointName.extraLarge,
        ])
        .subscribe((result) => {
          if (result.breakpoints[CustomBreakpointName.small]) {
            this.background = this.sanitizer.bypassSecurityTrustStyle(
              `url(${this.imgSafePipe.transform(
                this.imageOptions,
                this.resizeInfo.small
              )})`
            );
          }
          if (result.breakpoints[CustomBreakpointName.medium]) {
            this.background = this.sanitizer.bypassSecurityTrustStyle(
              `url(${this.imgSafePipe.transform(
                this.imageOptions,
                this.resizeInfo.medium
              )})`
            );
          }
          if (result.breakpoints[CustomBreakpointName.large]) {
            this.background = this.sanitizer.bypassSecurityTrustStyle(
              `url(${this.imgSafePipe.transform(
                this.imageOptions,
                this.resizeInfo.large
              )})`
            );
          }
          if (result.breakpoints[CustomBreakpointName.extraLarge]) {
            this.background = this.sanitizer.bypassSecurityTrustStyle(
              `url(${this.imgSafePipe.transform(
                this.imageOptions,
                this.resizeInfo.xlarge
              )})`
            );
          }
        });
    } else {
      if (this.src instanceof Image) {
        this.srcAttr = this.imgSafePipe.transform(this.src, `images[0].url`);
      } else {
        this.srcAttr = this.imgSafePipe.transform(this.src, `images[0].url`);
      }
    }
  }
}
