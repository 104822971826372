import { isPlatformBrowser } from '@angular/common';
import { Directive, HostListener, HostBinding, Input, PLATFORM_ID, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@core/auth/auth.service';
import { PasswordComponent } from '@shared/password/password.component';

@Directive({
  selector: '[sso-check]'
})
export class SsoCheckDirective {
  // @HostBinding('attr.href') hrefAttr;
  @HostBinding('attr.target') targetAttr = '_blank';
  @Input() href: string;

  @HostListener('click', ["$event"]) onClick(event) {
    if (isPlatformBrowser(this.platform)) {
      if (!this.auth.hasSSOCookie()) {
        const dialogRef = this.dialog.open(PasswordComponent, {
          width: 'auto',
          maxWidth: '90vw',
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            window.open(this.href, "_blank");
          }
        });
        event.preventDefault();
      }
      else {
        window.open(this.href, "_blank");
      }
    }
  }

  constructor(
    private auth: AuthService,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platform: Object) {
  }


}
