import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sub-navi',
  templateUrl: './sub-navi.component.html',
  styleUrls: ['./sub-navi.component.scss'],
})
export class SubNaviComponent implements OnInit {
  @Input() data;
  constructor() {}

  ngOnInit(): void {}
}
