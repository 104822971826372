import { Component, Input, OnInit } from '@angular/core';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { get } from 'lodash';
@Component({
  selector: 'app-selection-card',
  templateUrl: './selection-card.component.html',
  styleUrls: ['./selection-card.component.scss'],
})
export class SelectionCardComponent implements OnInit {
  @Input() data;
  @Input() reverse;
  public headline: string;
  public category: string;
  public categoryLink;
  public label: string;
  public labelLink;
  public img: string;
  public link;
  public: string;
  constructor(private imgSafePipe: ImgSafePathPipe) {}

  ngOnInit(): void {
    this.headline = get(this.data, 'title');
    this.category = get(this.data, 'brandCategory[0].categoryHeadline');
    this.categoryLink = get(this.data, 'brandCategory[0].slug');
    this.label = get(this.data, 'brand[0].brandRelatedHeadline');
    this.labelLink = get(this.data, 'brand[0].slug');
    this.link = get(this.data, `slug`);
    this.img = this.imgSafePipe.transform(
      this.data,
      `featuredImage[0].images[0]link.default`
    );
  }
}
