import { inject } from '@angular/core';
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

export const BAGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
  const router = inject(Router);
  const allowedNames: string[] = ['a-newport', 'j-buntin', 'r-lucas'];
  const baName:string = route.params['name'];

  if (allowedNames.includes(baName)) {
    return true;
  } else {
    return router.parseUrl('/');
  }
};
