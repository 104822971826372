import { Component, OnInit, ComponentFactoryResolver, Input, ViewContainerRef, ViewChild, TemplateRef } from '@angular/core';
import { LinkComponent } from '../link/link.component';
import { HostDirective } from '@shared/directives/host.directive';

const Components: ComponentType[] = [
  { tag: 'a', component: LinkComponent }
];

interface ComponentType {
  tag: string;
  component: any;
}

@Component({
  selector: 'app-dynamic-wrapper',
  templateUrl: './dynamic-wrapper.component.html',
  styleUrls: ['./dynamic-wrapper.component.scss']
})
export class DynamicWrapperComponent implements OnInit {
  @Input('element') element: HTMLElement;
  @ViewChild('el', { read: ViewContainerRef, static: true }) el: HostDirective;
  @ViewChild('childComponentTemplate', { static: true }) childComponentTemplate: TemplateRef<any>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.loadComponent(this.el, Components.find(c => c.tag === this.element.nodeName.toLocaleLowerCase()),this.element);
  }

  public loadComponent(host, component: ComponentType, element: HTMLElement) {
    if (component) {
      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(component.component);
      let viewContainerRef = host;

      let componentRef = viewContainerRef.createComponent(componentFactory);

      (componentRef.instance)['element'] = element;
      return componentRef.instance;
    }

  }

}
