<div class="tb_image">
  <h2
    appLazyLoad
    class="brandImage"
    [ngStyle]="{ 'background-image': 'url(' + img + ')' }"
    *ngIf="header && title"
    [innerHTML]="title"
    width="378"
    height = "300"
  ></h2>
  <h3
    appLazyLoad
    class="brandImage"
    [ngStyle]="{ 'background-image': 'url(' + img + ')' }"
    *ngIf="!header && title"
    [innerHTML]="title"
    width="528"
    height="300"
  ></h3>
  <div
    appLazyLoad
    class="brandImage"
    [ngStyle]="{ 'background-image': 'url(' + img + ')' }"
    *ngIf="!title"
  ></div>
</div>
