import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegistrationDialogComponent } from '@shared/registration-dialog/registration-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class JoinService {
  constructor(public dialog: MatDialog) {}

  openRegistration(params?): void {
    const dialogRef = this.dialog.open(RegistrationDialogComponent, {
      width: '420px',
      maxWidth: '90vw',
      data: { queryParams: params },
      panelClass: 'registration-dialog',
    });
  }
}
