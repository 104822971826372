<div class="tb_featured-product-carousel-card">
  <a [routerLink]="[link | localize]" class="tb_featured-product-carousel-card__link">
    <img appLazyLoad class="tb_featured-product-carousel-card__image " [src]='img' alt="" width="493" height="740">
    <div class="tb_card__copy-mark">
      <div class="tb_card__category" [innerHTML]="category"></div>
      <div class="h4" [innerHTML]="name"></div>
    </div>
  </a>
</div>


<!-- <div class="tb_card__copy-mark tb_card__copy-mark--reverse">
  <h5 class="tb_card__category">Category</h5>
  <p class="tb_card__brand">Brandname</p>
</div> -->