<div class="tb_media-card">
  <div class="tb_media-card__content-top">
    <div class="tb_media-card__bottom">
      <a class="tb_icon-link tb_media-card__cta tb_media-card__cta--external-link" href="#">View Job</a>
      <span class="tb_media-card__meta">3 hours ago</span>
    </div>
    <div class="tb_media-card__content">
      <h4 class="tb_media-card__title tb_media-card__title--gold">Job Title <span
          class="tb_media-card__title-icon"></span></h4>
      <h5 class="tb_media-card__subtitle">Beam Suntory</h5>
      <p class="tb_media-card__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tincidunt, elit et
        venenatis luctus, leo purus sagittis tortor, sit amet dapibus risus enim in velit. Vestibulum a purus vulputate,
        viverra diam ac, dictum sem. </p>
    </div>

    <a class="tb_media-card__link"></a>
  </div>
</div>