import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { MarketService } from './market.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { RoutingService } from '@routing/services/routing.service';
import { AuthService } from '@core/auth/auth.service';
import { ActivatedRoute, Route, Router, NavigationStart, RoutesRecognized, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { TaxMarket } from '@proxy/service-proxies';
import { PageLoadType, PageStatus } from '@core/interfaces/page-base';
import { IncomingMessage } from 'http';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@env/environment';
import { SeoService } from 'bsi-toolkit';


interface LoadRequest {
  firstLoad: boolean;
  type: PageLoadType;
}

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private firstLoad = false;
  private reload: BehaviorSubject<LoadRequest> = new BehaviorSubject<LoadRequest>({ firstLoad: false, type: PageLoadType.Initial });
  private markets: TaxMarket[];
  public pageReload: Subject<PageStatus> = new Subject<PageStatus>();
  private routeSnapshot: ActivatedRouteSnapshot;
  private response: Response;

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private auth: AuthService,
    private routeSvc: RoutingService,
    private localizeSvc: LocalizeRouterService,
    private marketSvc: MarketService,
    private seoSvc: SeoService,
    @Inject(PLATFORM_ID) private platform: Object,
    @Optional() @Inject(REQUEST) private request: IncomingMessage
  ) {
    // Handle page loading logic
    this.reload.subscribe(status => {
      this.firstLoad = status.firstLoad ? true : false;
      if (status.firstLoad) {
        this.auth.isAuthenticated().subscribe(auth => {
          this.pageReload.next(new PageStatus(auth, status.type, this.routeSvc, this.markets, this.routeSnapshot));
        });
      }
    });

    // // Handle parameter changes
    // this.router.params.subscribe(params => {
    //   console.log('route changed');
    //   this.reload.next({ firstLoad: this.firstLoad, type: PageLoadType.ParamChange });
    // });
    route.events.forEach((event) => {
      // if(event instanceof NavigationStart) {
      // this.reload.next({ firstLoad: this.firstLoad, type: PageLoadType.ParamChange });
      // }

      // Changed to later stage of router so we can get route data
      // if (event instanceof RoutesRecognized) {
      if (event instanceof NavigationEnd) {
        // this.routeData = event.state.root.firstChild.data;
        // this.routeSnapshot = event.state.root;
        this.routeSnapshot = this.router.snapshot;

        // Set the canonical URL
        if (isPlatformBrowser(this.platform)) {
          this.seoSvc.setCanonicalURL(`${environment.baseUrl}${event.url}`);
        } else {
          this.seoSvc.setCanonicalURL(`${environment.baseUrl}${this.request.url}`);
        }


        this.reload.next({ firstLoad: this.firstLoad, type: PageLoadType.ParamChange });
      }

    });

    // Handle user sign in
    this.auth.subjectUserSign.subscribe(user => {
      this.reload.next({ firstLoad: this.firstLoad, type: PageLoadType.Authentication });
    });

    // Handle user sign out
    this.auth.subjectUserSignOut.subscribe(authenticated => {
      this.reload.next({ firstLoad: this.firstLoad, type: PageLoadType.Authentication });
    });

    // Handle language change
    this.localizeSvc.routerEvents.subscribe(language => {
      this.marketSvc.getMarkets(false).subscribe(markets => {
        this.markets = markets;
        this.reload.next({ firstLoad: this.firstLoad, type: PageLoadType.Language });
      });
    });
  }

  public init() {
    // Handle initial page load
    this.marketSvc.getMarkets(false).subscribe(markets => {
      // this.unSub(this.auth.isAuthenticated()).subßscribe(markets => {
      this.markets = markets;
      this.reload.next({ firstLoad: true, type: PageLoadType.Initial });
    });
  }
}
