import { Component, OnInit, Input } from '@angular/core';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { PageBase, PageStatus } from '@core/interfaces/page-base';
import { PageComponentBase } from '@core/classes/page-component-base';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AuthService } from '@core/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '@routing/services/routing.service';
import { PodcastSeries } from '@proxy/service-proxies';
import { PageService } from '@shared/services/page.service';

@Component({
  selector: 'app-podcast-series',
  templateUrl: './podcast-series.component.html',
  styleUrls: ['./podcast-series.component.scss']
})
export class PodcastSeriesComponent extends PageComponentBase implements PageBase {
  @Input() data: PodcastSeries;
  public bgImg;
  public title;
  public slug;
  public authorName;

  constructor(
    protected routingService: RoutingService,
    protected router: ActivatedRoute,
    public imgSafePipe: ImgSafePathPipe,
    protected auth: AuthService,
    protected localizeSvc: LocalizeRouterService,
    protected pageSvc: PageService
  ) {
    super(pageSvc);
  }

  onPageLoad(event: PageStatus) {
    const cardData = this.data;
    this.title = cardData.heading.value;
    this.bgImg = this.imgSafePipe.transform(cardData, `image.images[0].link.default`);
    this.slug = cardData.slug;
  }

}
