import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, ElementRef } from '@angular/core';
import { Observable, combineLatest, concat, defer, of, fromEvent } from 'rxjs';
import { map, distinctUntilChanged, mergeMap } from 'rxjs/operators';

@Injectable()

// This service is built in conjunction with the content-lazy-load directive to lazily load components on pages
export class VisibilityService {
  private pageVisible$: Observable<boolean>;

  constructor(@Inject(DOCUMENT) document: any) {
    this.pageVisible$ = concat(
      defer(() => of(!document.hidden)),
      fromEvent(document, 'visibilitychange').pipe(map((e) => !document.hidden))
    );
  }

  elementInSight(element: ElementRef): Observable<boolean> {
    const el = element.nativeElement;
    const elementVisible$ = new Observable((observer) => {
      const intersectionObserver = new IntersectionObserver((entries) => {
        observer.next(entries);
      });

      intersectionObserver.observe(el);

      return () => {
        intersectionObserver.disconnect();
      };
    }).pipe(
      mergeMap((entries: IntersectionObserverEntry[]) => entries),
      map((entry) => entry.isIntersecting),
      distinctUntilChanged()
    );

    const elementInSight$ = combineLatest(
      this.pageVisible$,
      elementVisible$,
      (pageVisible, elementVisible: boolean) => pageVisible && elementVisible
    ).pipe(distinctUntilChanged());

    return elementInSight$;
  }
}
