import { Component, OnInit, Input } from '@angular/core';
import { CenteredTextInterface } from '@shared/interfaces/interfaces';

@Component({
  selector: 'app-centered-text',
  templateUrl: './centered-text.component.html',
  styleUrls: ['./centered-text.component.scss']
})
export class CenteredTextComponent implements OnInit {
  @Input() data: CenteredTextInterface;
  @Input() reverse: string;
  @Input() header: boolean;
  @Input() isCenter: boolean = false;

  constructor() { }

  ngOnInit() { }

}
