<div class="tb_expansion-panel">
  <div class="tb_expansion-panel__top">
    <!-- <span class="tb_expansion-panel__toggle" (click)="expandPanel()" *ngIf="!expanded">
      <img src="../assets/images/icons/plus-circle--blue.svg" alt="">
    </span>
    <span class="tb_expansion-panel__toggle" (click)="shrinkPanel()" *ngIf="expanded">
      <img src="../assets/images/icons/minus-circle--blue.svg" alt="">
    </span> -->
    <h2 class="h2" [innerHTML]="data.heading"></h2>
    <div class="row">
      <div class="imgContainer col-sm-12 col-md-6 col-lg-3" *ngFor="let item of initialFinalists">
        <div class="tb_link-card">
          <a class="tb_link-card__link" [routerLink]="item.slug">
            <img class="img-fluid" [src]="imgSafePipe.transform(item, 'image.images[0].url')" alt="" />
            <div class="tb_card__overlay">
              <h3 class="h4" [innerHTML]="item.heading.value"></h3>
              <span class="tb_card__cta"><img src="../assets/images/icons/arrow-right-circle-gold.svg" /></span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <app-template-section [paddingVal]="'2rem'" [center]="true" *ngIf="moreThanFourFinalists && !expanded">
      <button class="tb_button tb_button--outline-gold" [innerHTML]="showBtnText" (click)="togglePanel()"></button>
    </app-template-section>
  </div>
  <div class="tb_expansion-panel__bottom" #expansionPanel>
    <div class="row">
      <div class="imgContainer col-sm-12 col-md-6 col-lg-3" *ngFor="let item of finalists">
        <div class="tb_link-card">
          <a class="tb_link-card__link" [routerLink]="item.slug">
            <img class="img-fluid" [src]="imgSafePipe.transform(item, 'image.images[0].url')" alt="" />
            <div class="tb_card__overlay">
              <h3 class="h4" [innerHTML]="item.heading.value"></h3>
              <span class="tb_card__cta"><img src="../assets/images/icons/arrow-right-circle-gold.svg" /></span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <app-template-section [center]="true" *ngIf="moreThanFourFinalists && expanded">
      <button class="button-type" [innerHTML]="showBtnText" (click)="togglePanel()"></button>
    </app-template-section>
  </div>
</div>