import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of, pipe } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RoutingService, LANG_EXCEPTIONS, ROUTE_EXCEPTIONS } from './services/routing.service';
import { switchMap } from 'rxjs/operators';
import { merge } from 'lodash';
import { makeStateKey, TransferState } from '@angular/platform-browser';

import fs from 'fs';

export class JSONModuleLoader implements TranslateLoader {

    constructor(private transferState: TransferState) { }

    getTranslation(lang: string): Observable<any> {
        const locale = RoutingService.parseLanguage(lang);
        if (LANG_EXCEPTIONS.hasOwnProperty(lang)) {
            // console.log(this.getTranslationJson(LANG_EXCEPTIONS[lang]));
            return of(this.getTranslationJson(LANG_EXCEPTIONS[lang], lang));
        }
        // Only use the default english translation file
        else if (locale.language === 'en') {
            // console.log(this.getTranslationJson('en-00'));
            return of(this.getTranslationJson('en-00', lang));
        }
        else {
            // console.log(this.getTranslationJson(lang));
            return of(this.getTranslationJson(lang, lang));
        }
    }

    private getTranslationJson(lang: string, originalLang: string): Object {
        const locale = RoutingService.parseLanguage(lang);
        // let data = require(`../../assets/i18n/${lang}.json`);
        // let defaultRoutes = require(`../../assets/i18n/routes/en-00.json`);
        let data = this.loadJsonFile(`./dist/browser/assets/i18n/${lang}.json`);
        let defaultRoutes = this.loadJsonFile(`./dist/browser/assets/i18n/routes/en-00.json`);

        // this.setCache(`/assets/i18n/${lang}.json`, data);
        // this.setCache(`/assets/i18n/routes/en-00.json`, defaultRoutes);

        // Check if there is a language specific override
        if (ROUTE_EXCEPTIONS.hasOwnProperty(originalLang)) {
            // const routes = require(`../../assets/i18n/routes/${ROUTE_EXCEPTIONS[originalLang]}.json`);
            const routes = this.loadJsonFile(`./dist/browser/assets/i18n/routes/${ROUTE_EXCEPTIONS[originalLang]}.json`);
            // this.setCache(`/assets/i18n/routes/${ROUTE_EXCEPTIONS[originalLang]}.json`, routes);

            // const routes = this.getFile(`/assets/i18n/routes/${ROUTE_EXCEPTIONS[originalLang]}.json`);
            const mergedRoutes = merge(defaultRoutes, routes);
            return Object.assign(data, mergedRoutes);
            // return this.http.get(`${this.prefix}routes/${ROUTE_EXCEPTIONS[originalLang]}${this.suffix}`).pipe(
            //     switchMap(routes => {
            //         const mergedRoutes = merge(defaultRoutes, routes);
            //         return of(Object.assign(data, mergedRoutes));
            //     })
            // );
        }
        else if (locale.language === 'en') {
            return Object.assign(data, defaultRoutes);
        }
        else {
            // const routes = require(`../../assets/i18n/routes/${lang}.json`);
            const routes = this.loadJsonFile(`./dist/browser/assets/i18n/routes/${lang}.json`);
            // this.setCache(`/assets/i18n/routes/${lang}.json`, routes);

            const mergedRoutes = merge(defaultRoutes, routes);
            return Object.assign(data, mergedRoutes);
        }
    }

    private loadJsonFile(path: string){
        let json = JSON.parse(fs.readFileSync(path, 'utf-8'));
        // console.log('loaded',json);
        return json;
    }

    private setCache(path: string, data: any): void {
        // const storedResponse = this.transferState.get(makeStateKey(path), null);

        // if (!storedResponse) {
        //     this.transferState.set(makeStateKey(path), data);
        // }

    }
}

export class TranslateHttpJSONLoader implements TranslateLoader {
    constructor(private http: HttpClient, private transferState: TransferState, public prefix: string = '/assets/i18n/', public suffix: string = '.json') { }

    /**
     * Gets the translations from the server
     */
    public getTranslation(lang: string): Observable<Object> {

        const locale = RoutingService.parseLanguage(lang);
        if (LANG_EXCEPTIONS.hasOwnProperty(lang)) {
            return this.getTranslationJson(LANG_EXCEPTIONS[lang], lang);
        }
        // Only use the default english translation file
        else if (locale.language === 'en') {
            // return this.http.get(`${this.prefix}en-00${this.suffix}`);
            return this.getTranslationJson('en-00', lang);
        } else if (lang === 'es-us') {
            // mexico spanish logic
            return this.http.get(`${this.prefix}es-mx${this.suffix}`);
        }
        else {
            // return this.http.get(`${this.prefix}${lang}${this.suffix}`);
            return this.getTranslationJson(lang, lang);
        }
    }

    /**
     * Get and concat translation file and translation file for routing.
     * Routing translation file is separate because it isn't automated with extract and agencies won't translate routes
     * @param lang 
     */
    private getTranslationJson(lang: string, originalLang: string): Observable<Object> {
        const locale = RoutingService.parseLanguage(lang);

        // testing
        // console.log('state', this.transferState);
        // this.transferState.set(makeStateKey(`/assets/i18n/routes/en-00.json`), usroute);

        // return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
        return this.getFile(`${this.prefix}${lang}${this.suffix}`).pipe(
            switchMap(data => {
                // Get default routes
                // return this.http.get(`${this.prefix}routes/en-00${this.suffix}`).pipe(
                return this.getFile(`${this.prefix}routes/en-00${this.suffix}`).pipe(
                    switchMap(defaultRoutes => {
                        // Check if there is a language specific override
                        if (ROUTE_EXCEPTIONS.hasOwnProperty(originalLang)) {
                            // return this.http.get(`${this.prefix}routes/${ROUTE_EXCEPTIONS[originalLang]}${this.suffix}`).pipe(
                            return this.getFile(`${this.prefix}routes/${ROUTE_EXCEPTIONS[originalLang]}${this.suffix}`).pipe(
                                switchMap(routes => {
                                    const mergedRoutes = merge(defaultRoutes, routes);
                                    return of(Object.assign(data, mergedRoutes));
                                })
                            );
                        }
                        // Only need default routes
                        else if (locale.language === 'en') {
                            return of(Object.assign(data, defaultRoutes));
                        }
                        // otherwise get language specific routes
                        // return this.http.get(`${this.prefix}routes/${lang}${this.suffix}`).pipe(
                        return this.getFile(`${this.prefix}routes/${lang}${this.suffix}`).pipe(
                            switchMap(routes => {
                                const mergedRoutes = merge(defaultRoutes, routes);
                                return of(Object.assign(data, mergedRoutes));
                            })
                        );
                    })
                );

            })
        );
    }

    private getFile(url): Observable<any> {
        // console.log(url);

        // const storedResponse = this.transferState.get(makeStateKey(url), null);
        // // console.log(storedResponse);
        // if (storedResponse) {
        //     return of(storedResponse);
        // } else {
        //     return this.http.get(url);
        // }
        return this.http.get(url);

    }

}

export function JSONModuleLoaderFactory(http: HttpClient, platform, transferState: TransferState) {
    if (isPlatformBrowser(platform)) {
        return new TranslateHttpJSONLoader(http, transferState);
        // return new TranslateHttpLoader(http);
    } else {
        return new JSONModuleLoader(transferState);
    }
}