import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { DialogData } from '@modules/sharing-dialog/sharing-dialog.component';
import { TaxMicroCommunity } from '@proxy/service-proxies';
import { RoutingService } from '@routing/services/routing.service';

@Component({
  selector: 'app-finished-dialog',
  templateUrl: './finished-dialog.component.html',
  styleUrls: ['./finished-dialog.component.scss'],
})
export class FinishedDialogComponent implements OnInit {
  redirectUrl: string;
  activeMarkets;
  isFragment: boolean = false;
  isAffiliate: boolean = false;
  affiliateMessage;
  microCommuniytData: TaxMicroCommunity;
  microCommunityLogo: string;
  constructor(
    public dialogRef: MatDialogRef<FinishedDialogComponent>,
    private router: Router,
    private localize: LocalizeRouterService,
    public dialog: MatDialog,
    private _route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private routingService: RoutingService,
    private imgSafePipe: ImgSafePathPipe
  ) {}

  ngOnInit() {
    this.routingService.getActiveMarkets();
    this.routingService.activeMarketsRecieved.subscribe((data) => {
      this.activeMarkets = data;
    });
    // check for url fragment
    this._route.fragment.subscribe((fragment: string) => {
      if (fragment) {
        this.isFragment = true;
      }
    });

    if (this.data.isAffiliate && this.data.isAffiliateSuccess) {
      this.isAffiliate = true;
      this.microCommuniytData = this.data.microCommunity;
      this.microCommunityLogo = this.imgSafePipe.transform(
        this.data.microCommunity,
        `logo.images[0].url`
      );
      this.affiliateMessage = this.data.microCommunity.description.value;
    } else if (this.data.isAffiliate && !this.data.isAffiliateSuccess) {
      this.isAffiliate = true;
      this.affiliateMessage =
        `You have successfully registered, but the code you used was invalid`;
    }
  }

  routeTo() {
    const activeMarket = this.activeMarkets.items.find((market) => {
      return market.country === this.data['market'].alpha2;
    });

    // this logic built in for tpb active page to reroute back there if directed to join page
    if (this.isFragment) {
      this.router.navigate([
        this.localize.translateRoute('/perfect-blend'),
        'active',
      ]);
    } else {
      if (this.data['queryParams'] && this.data['queryParams'].return) {
        this.router.navigate([
          this.localize.translateRoute(this.data['queryParams'].return),
        ]);
      } else {
        if (activeMarket) {
          this.routingService.changeMarket(activeMarket);
        } else {
          this.router.navigate(['/']);
        }
        // this.router.navigate([this.localize.translateRoute('/')]);
      }
    }

    this.dialog.closeAll();
  }

  closeDialog(){
    this.dialog.closeAll();
  }
}
