import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PhoneCountryCodes, Country } from '@modules/join/phone-country-codes';
import { RoutingService } from '@routing/services/routing.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationCountriesService {

  constructor(
    private translate: TranslateService,
    private routeSvc: RoutingService
  ) {
    this.translate.instant([
      'Countries.AC',
      'Countries.AD',
      'Countries.AE',
      'Countries.AF',
      'Countries.AG',
      'Countries.AI',
      'Countries.AL',
      'Countries.AM',
      'Countries.AO',
      'Countries.AQ',
      'Countries.AR',
      'Countries.AS',
      'Countries.AT',
      'Countries.AU',
      'Countries.AW',
      'Countries.AX',
      'Countries.AZ',
      'Countries.BA',
      'Countries.BB',
      'Countries.BD',
      'Countries.BE',
      'Countries.BF',
      'Countries.BG',
      'Countries.BH',
      'Countries.BI',
      'Countries.BJ',
      'Countries.BL',
      'Countries.BM',
      'Countries.BN',
      'Countries.BO',
      'Countries.BQ',
      'Countries.BR',
      'Countries.BS',
      'Countries.BT',
      'Countries.BV',
      'Countries.BW',
      'Countries.BY',
      'Countries.BZ',
      'Countries.CA',
      'Countries.CC',
      'Countries.CD',
      'Countries.CF',
      'Countries.CG',
      'Countries.CH',
      'Countries.CI',
      'Countries.CK',
      'Countries.CL',
      'Countries.CM',
      'Countries.CN',
      'Countries.CO',
      'Countries.CP',
      'Countries.CR',
      'Countries.CU',
      'Countries.CV',
      'Countries.CW',
      'Countries.CX',
      'Countries.CY',
      'Countries.CZ',
      'Countries.DE',
      'Countries.DG',
      'Countries.DJ',
      'Countries.DK',
      'Countries.DM',
      'Countries.DO',
      'Countries.DZ',
      'Countries.EA',
      'Countries.EC',
      'Countries.EE',
      'Countries.EG',
      'Countries.EH',
      'Countries.ER',
      'Countries.ES',
      'Countries.ET',
      'Countries.EU',
      'Countries.FI',
      'Countries.FJ',
      'Countries.FK',
      'Countries.FM',
      'Countries.FO',
      'Countries.FR',
      'Countries.FX',
      'Countries.GA',
      'Countries.GB',
      'Countries.GD',
      'Countries.GE',
      'Countries.GF',
      'Countries.GG',
      'Countries.GH',
      'Countries.GI',
      'Countries.GL',
      'Countries.GM',
      'Countries.GN',
      'Countries.GP',
      'Countries.GQ',
      'Countries.GR',
      'Countries.GS',
      'Countries.GT',
      'Countries.GU',
      'Countries.GW',
      'Countries.GY',
      'Countries.HK',
      'Countries.HM',
      'Countries.HN',
      'Countries.HR',
      'Countries.HT',
      'Countries.HU',
      'Countries.IC',
      'Countries.ID',
      'Countries.IE',
      'Countries.IL',
      'Countries.IM',
      'Countries.IN',
      'Countries.IO',
      'Countries.IQ',
      'Countries.IR',
      'Countries.IS',
      'Countries.IT',
      'Countries.JE',
      'Countries.JM',
      'Countries.JO',
      'Countries.JP',
      'Countries.KE',
      'Countries.KG',
      'Countries.KH',
      'Countries.KI',
      'Countries.KM',
      'Countries.KN',
      'Countries.KP',
      'Countries.KR',
      'Countries.KW',
      'Countries.KY',
      'Countries.KZ',
      'Countries.LA',
      'Countries.LB',
      'Countries.LC',
      'Countries.LI',
      'Countries.LK',
      'Countries.LR',
      'Countries.LS',
      'Countries.LT',
      'Countries.LU',
      'Countries.LV',
      'Countries.LY',
      'Countries.MA',
      'Countries.MC',
      'Countries.MD',
      'Countries.ME',
      'Countries.MF',
      'Countries.MG',
      'Countries.MH',
      'Countries.MK',
      'Countries.ML',
      'Countries.MM',
      'Countries.MN',
      'Countries.MO',
      'Countries.MP',
      'Countries.MQ',
      'Countries.MR',
      'Countries.MS',
      'Countries.MT',
      'Countries.MU',
      'Countries.MV',
      'Countries.MW',
      'Countries.MX',
      'Countries.MY',
      'Countries.MZ',
      'Countries.NA',
      'Countries.NC',
      'Countries.NE',
      'Countries.NF',
      'Countries.NG',
      'Countries.NI',
      'Countries.NL',
      'Countries.NO',
      'Countries.NP',
      'Countries.NR',
      'Countries.NU',
      'Countries.NZ',
      'Countries.OM',
      'Countries.PA',
      'Countries.PE',
      'Countries.PF',
      'Countries.PG',
      'Countries.PH',
      'Countries.PK',
      'Countries.PL',
      'Countries.PM',
      'Countries.PN',
      'Countries.PR',
      'Countries.PS',
      'Countries.PT',
      'Countries.PW',
      'Countries.PY',
      'Countries.QA',
      'Countries.RE',
      'Countries.RO',
      'Countries.RS',
      'Countries.RU',
      'Countries.RW',
      'Countries.SA',
      'Countries.SB',
      'Countries.SC',
      'Countries.SD',
      'Countries.SE',
      'Countries.SG',
      'Countries.SH',
      'Countries.SI',
      'Countries.SJ',
      'Countries.SK',
      'Countries.SL',
      'Countries.SM',
      'Countries.SN',
      'Countries.SO',
      'Countries.SR',
      'Countries.SS',
      'Countries.ST',
      'Countries.SU',
      'Countries.SV',
      'Countries.SX',
      'Countries.SY',
      'Countries.SZ',
      'Countries.TA',
      'Countries.TC',
      'Countries.TD',
      'Countries.TF',
      'Countries.TG',
      'Countries.TH',
      'Countries.TJ',
      'Countries.TK',
      'Countries.TL',
      'Countries.TM',
      'Countries.TN',
      'Countries.TO',
      'Countries.TR',
      'Countries.TT',
      'Countries.TV',
      'Countries.TW',
      'Countries.TZ',
      'Countries.UA',
      'Countries.UG',
      'Countries.UK',
      'Countries.UM',
      'Countries.US',
      'Countries.UY',
      'Countries.UZ',
      'Countries.VA',
      'Countries.VC',
      'Countries.VE',
      'Countries.VG',
      'Countries.VI',
      'Countries.VN',
      'Countries.VU',
      'Countries.WF',
      'Countries.WS',
      'Countries.XK',
      'Countries.YE',
      'Countries.YT',
      'Countries.ZA',
      'Countries.ZM',
      'Countries.ZW'
    ]);
  }

  /**
   * Returns translated countries if language is not english
   */
  public getCountries(lang?: string): Country[] {
    // If another language we have to translate country names
    const countriesPath = 'Countries';
    const countries = this.translate.instant(countriesPath);
    return PhoneCountryCodes.countries.map(country => {
      country.name = countries[country.alpha2] ? countries[country.alpha2] : country.name;
      return country;
    }).sort((a, b) => a.name.localeCompare(b.name));
  }
}


