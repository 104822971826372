import { NgModule, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routes';

/**  uncomment to enable localization **/
import {
  LocalizeRouterModule,
  LocalizeParser,
  LocalizeRouterSettings,
  ManualParserLoader,
} from '@gilsdav/ngx-translate-router';
import {
  TranslateModule,
  TranslateService,
  TranslateLoader,
} from '@ngx-translate/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { JSONModuleLoaderFactory } from './loader';
// import { NewsModule } from '@modules/news/news.module';
import { TransferState } from '@angular/platform-browser';
import { AuthGuard } from '@core/auth/auth.guard';

const markets = [
  'en-00', // English Default market
  'en-de',
  'en-in',
  'en-us',
  'es-us',
  'en-au',
  'en-nz',
  'en-at',
  'en-es',
  'es-es',
  'es-us',
];

@NgModule({
  declarations: [],
  imports: [
    // Uncomment for no static translation content
    // TranslateModule.forRoot(),
    // Uncomment for universal ssr translation loading
    // NewsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: JSONModuleLoaderFactory,
        deps: [HttpClient, PLATFORM_ID, TransferState],
      },
    }),
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
}),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate, location, settings) =>
          new ManualParserLoader(translate, location, settings, markets),
        deps: [TranslateService, Location, LocalizeRouterSettings],
      },
      alwaysSetPrefix: false,
      useCachedLang: false,
    }),
  ],
  exports: [TranslateModule, LocalizeRouterModule, RouterModule],
  providers: [AuthGuard]
})
export class RoutingModule {
  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en-00');
  }
}
