import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'deletedCountryFilter'
})
export class DeletedCountryFilter implements PipeTransform {
    transform(value: any[]): any[] {
        return value.filter(country =>
            country['status'] !== 'deleted' && country['countryCallingCodes'].length > 0);
    }
}
