export enum SurfaceColors {
  Gray200 = 'gray200',
  Gray400 = 'gray400',
  Gray700 = 'gray700',
  Gray800 = 'gray800',
}

export enum TwoUpPageTypes {
  Tpb = 'the-perfect-blend',
  Botw = 'the-blends-of-the-world',
  BotwSub = 'the-blends-of-the-world-subpage',
  PerfectBlend = 'the-perfect-blend',
  PerfectBlendSub = 'the-perfect-blend-sub',
  Category = 'category',
  Brand = 'brand',
  Community = 'community',
  LMS = 'lms',
  News = 'news',
  ServiceWell = 'service-well',
}

export enum TwoUpPageColors {
  Gray700 = 'tb_two-up-section--gray700',
  Gray800 = 'tb_two-up-section--gray800',
}

export enum IngredientType {
  BeamProduct = 'beamProduct',
  OtherIngrident = 'otherIngrident',
  Mixer = 'mixer',
}

export enum ImgSizes {
  OneOne = 'card_1_1',
  ThreeFour = 'card_3_4',
  FourThree = 'card_4_3',
  SixteenNine = 'card_16_9',
  HeroSixteenNine = 'hero_16_9',
  HeroNineSixteen = 'hero_9_16',
}

export enum SlideTypes {
  Image = 'img',
  Video = 'video',
}

export enum ImgResizeType {
  Card = 'card',
  Hero = 'hero',
}

// this represents the differnet state the join fields can have
export enum FieldState {
  Required,
  Optional,
  Hidden,
}

// This is for the differnet join page categories
export enum MarketCategories {
  PersonalInfo = 'personalInfo',
  locationLangauge = 'locationLanguage',
  Work = 'work',
  AccountInfo = 'accountInfo',
}

// Enum for Markets
export enum Markets {
  Australia = 'AU',
  Austria = 'AT',
  Germany = 'DE',
  Global = '00',
  India = 'IN',
  New_Zealand = 'NZ',
  USA = 'US',
}

export enum BtnColors {
  Blue = '#4fc6db',
}

export enum BVIColors {
  Blue = 'blue',
  Green = 'green',
  Purple = 'purple',
  Orange = 'orange',
  Red = 'red',
}
