import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { VisibilityService } from '@shared/services/visibility.service';
import { take, filter } from 'rxjs/operators';
@Directive({
  selector: '[visibleWith]',
})
export class ContentLazyLoadDirective implements AfterViewInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private visibilityService: VisibilityService
  ) {}
  // This directive is built for lazy loading components on pages
  @Input()
  set visibleWith(element) {
    this.visibilityService
      .elementInSight(new ElementRef(element))
      .pipe(
        filter((visible) => visible),
        take(1)
      )
      .subscribe(() => {
        this.viewContainer.createEmbeddedView(this.templateRef);
      });
  }

  ngAfterViewInit() {}
}
