import { Component, OnInit, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { RoutingService } from '@routing/services/routing.service';
import { locale } from 'moment';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {

  oneTrustID = environment.oneTrustID;

  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document, @Inject(PLATFORM_ID) private platform: Object, private routingService: RoutingService) { }

  ngOnInit() {

    if (isPlatformBrowser(this.platform)) {
      let cookieScript = this._renderer2.createElement('script');
      cookieScript.type = `text/javascript`;
      cookieScript.src = `https://cdn.cookielaw.org/consent/` + this.oneTrustID + `.js`;
      this._renderer2.setAttribute(cookieScript, 'data-language', this.routingService.getLocale().market);
      this._renderer2.appendChild(this._document.head, cookieScript);
      

      let cookiePopup = this._renderer2.createElement('script');
      cookiePopup.type = `text/javascript`;
      cookiePopup.text = `function OptanonWrapper() { }`;
      this._renderer2.appendChild(this._document.head, cookiePopup);
    }
  }
}
