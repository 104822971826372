<div *ngIf="!community && geekOut">
  <a [routerLink]="['/geek-out', link | translate]">
    <div class="tb-card tb-card__spotlight">
      <img appLazyLoad [src]="img" class="tb-card__thumb" alt="" width="502" height="282">
      <div class="tb-card__overlay">
        <div class="tb-card__content">
          <ng-container *ngIf="linkIsExternal">
            <a [href]="link" target="_blank"
              class="tb-card__btn button-type text-uppercase text-center">{{ 'Shared.CategoryCard.LearnMore' | translate }}</a>
          </ng-container>
          <ng-container *ngIf="linkIsInternal"> <a [routerLink]="link"
              class="tb-card__btn button-type text-uppercase text-center">{{ 'Shared.CategoryCard.LearnMore' | translate }}</a>
          </ng-container>

          <p class="tb-card__description" [innerHTML]="body" *ngIf="body"></p>
          <div class="tb-card__meta  text-uppercase" [ngClass]="{ 'noBorder': !body }">
            <span class="tb-card__post-author mini" [innerHTML]="heading"></span>
          </div>
        </div>
      </div>
    </div>
  </a>
</div>


<div *ngIf="!community && !geekOut">
  <div class="tb-card tb-card__spotlight">
    <img appLazyLoad [src]="img" class="tb-card__thumb" alt="" width="529" height="297">
    <div class="tb-card__overlay">
      <div class="tb-card__content">
        <ng-container *ngIf="linkIsExternal">
          <a [href]="link" target="_blank"
            class="tb-card__btn button-type text-uppercase text-center">{{ 'Shared.CategoryCard.LearnMore' | translate }}</a>
        </ng-container>
        <ng-container *ngIf="linkIsInternal"> <a [routerLink]="link"
            class="tb-card__btn button-type text-uppercase text-center">{{ 'Shared.CategoryCard.LearnMore' | translate }}</a>
        </ng-container>

        <p class="tb-card__description" [innerHTML]="body" *ngIf="body"></p>
        <div class="tb-card__meta  text-uppercase" [ngClass]="{ 'noBorder': !body }">
          <span class="tb-card__post-author mini" [innerHTML]="heading"></span>
        </div>
      </div>
    </div>
  </div>
</div>



<div *ngIf="community">
  <a [routerLink]="link" class="">
    <div class="tb-card tb-card__spotlight">
      <img appLazyLoad [src]="img" class="tb-card__thumb" alt="" width="529" height="297">
      <div class="tb-card__overlay">
        <div class="tb-card__content">
          <a [routerLink]="link" class="tb-card__btn button-type text-uppercase text-center"
            *ngIf="link && showLink">{{ 'Shared.CategoryCard.LearnMore' | translate }}</a>
          <p class="tb-card__description" [innerHTML]="body" *ngIf="body">
          </p>
          <div class="tb-card__meta  text-uppercase">
            <span class="tb-card__post-author mini" [innerHTML]="heading"></span>
          </div>
        </div>
      </div>
    </div>
  </a>
</div>


<!-- <div *ngIf="!community && !newsSpotLight">
  <a class="tb_spotlight-card__cta tb_icon-link" [ngClass]="{ 'tb_icon-link--reverse': reverse }"
    [routerLink]="[sectionRoute | localize]" style="display: block" *ngIf="!geekOut && !linkItem">
    <div class="tb_bordered-image tb_bordered-image--gray">
      <img appLazyLoad class="tb_bordered-image__image" [src]="img" alt="" />
    </div>
  </a>
  <a class="tb_spotlight-card__cta tb_icon-link" [ngClass]="{ 'tb_icon-link--reverse': reverse }"
    [routerLink]="showLink ? link : []" style="display: block" *ngIf="!geekOut && linkItem">
    <div class="tb_bordered-image tb_bordered-image--gray">
      <img appLazyLoad class="tb_bordered-image__image" [src]="img" alt="" />
    </div>
  </a>
  <a class="tb_spotlight-card__cta tb_icon-link" [ngClass]="{ 'tb_icon-link--reverse': reverse }"
    [routerLink]="['/learn/geek-out' | localize, link]" style="display: block" *ngIf="geekOut">
    <div class="tb_bordered-image tb_bordered-image--gray">
      <img appLazyLoad class="tb_bordered-image__image" [src]="img" alt="" />
    </div>
  </a>
  <a class="tb_spotlight-card__cta tb_icon-link" [ngClass]="{ 'tb_icon-link--reverse': reverse }" [routerLink]=""
    style="display: block" *ngIf="community">
    <div class="tb_bordered-image tb_bordered-image--gray">
      <img appLazyLoad class="tb_bordered-image__image" [src]="img" alt="" />
    </div>
  </a>

  <div class="tb_card__copy-default tb_card__copy-default--reverse" [ngClass]="{ community: community }">
    <h3 class="tb_card__heading" [innerHTML]="heading"></h3>
    <p class="tb_card__text" [innerHTML]="body"></p>
    <div class="tb_card__catlabel tb_catlabel" *ngIf="brandsPage">
      <a class="tb_catlabel__link tb_link"
        [routerLink]="['/learn/categories' | localize, categorySlug]">{{ category }}</a>
      <ng-container *ngIf="category && brand">//</ng-container>
      <a *ngIf="brand" class="tb_catlabel__link tb_link"
        [routerLink]="['/learn/brands' | localize, brandSlug]">{{ brand }}</a>
    </div>
    <a class="tb_spotlight-card__cta tb_icon-link" [ngClass]="{ 'tb_icon-link--reverse': reverse }"
      [routerLink]="['/learn/geek-out' | localize, link]" style="display: block" *ngIf="geekOut && link">
      {{ "Shared.Spotlight.LearnMore" | translate }}
      <span class="tb_icon-link__icon tb_icon-link__icon--right">
        <img appLazyLoad src="../../../assets/images/icons/arrow-right-circle.svg" />
      </span>
    </a>
    <a class="tb_spotlight-card__cta tb_icon-link" [ngClass]="{ 'tb_icon-link--reverse': reverse }"
      [routerLink]="[sectionRoute | localize]" style="display: block" *ngIf="!geekOut && sectionRoute && !linkItem">
      {{ "Shared.Spotlight.LearnMore" | translate }}
      <span class="tb_icon-link__icon tb_icon-link__icon--right">
        <img appLazyLoad src="../../../assets/images/icons/arrow-right-circle.svg" />
      </span>
    </a>
    <a class="tb_spotlight-card__cta tb_icon-link" [ngClass]="{ 'tb_icon-link--reverse': reverse }" [routerLink]="link"
      style="display: block" *ngIf="!geekOut && link && showLink">
      {{ "Shared.Spotlight.LearnMore" | translate }}
      <span class="tb_icon-link__icon tb_icon-link__icon--right">
        <img appLazyLoad src="../../../assets/images/icons/arrow-right-circle.svg" />
      </span>
    </a>
  </div>
</div> -->

<!-- Community layout -->
<!-- <div [ngClass]="{ community: community }" *ngIf="community">
  <div class="tb_bordered-image tb_bordered-image--gray" *ngIf="!showLink">
    <img appLazyLoad class="tb_bordered-image__image" [src]="img" alt="" />
  </div>
  <a class="tb_spotlight-card__cta tb_icon-link" [ngClass]="{ 'tb_icon-link--reverse': reverse }" [routerLink]="link"
    style="display: block" *ngIf="showLink">
    <div class="tb_bordered-image tb_bordered-image--gray">
      <img appLazyLoad class="tb_bordered-image__image" [src]="img" alt="" />
    </div>
  </a>
  <div class="tb_card__copy-default tb_card__copy-default--reverse" [ngClass]="{ community: community }"
    style="justify-content: space-between">
    <div>
      <h3 class="tb_card__heading" [innerHTML]="heading"></h3>
      <p class="tb_card__text" [innerHTML]="body"></p>
    </div>
    <a class="tb_spotlight-card__cta tb_icon-link" [ngClass]="{ 'tb_icon-link--reverse': reverse }" [routerLink]="link"
      style="display: block" *ngIf="link && showLink">
      {{ linkText }}
      <span class="tb_icon-link__icon tb_icon-link__icon--right">
        <img appLazyLoad src="../../../assets/images/icons/arrow-right-circle.svg" />
      </span>
    </a>
  </div>
</div> -->

<!-- <div *ngIf="newsSpotLight">
  <a class="tb_spotlight-card__cta tb_icon-link" [ngClass]="{ 'tb_icon-link--reverse': reverse }" [routerLink]="link"
    style="display: block">
    <div class="tb_bordered-image tb_bordered-image--gray asdg">
      <img appLazyLoad class="tb_bordered-image__image" [src]="img" alt="" />
    </div>
  </a>
  <div class="tb_card__copy-default" style="justify-content: space-between">
    <div>
      <h3 class="tb_card__heading" [innerHTML]="heading"></h3>
      <p class="tb_card__text" [innerHTML]="body"></p>
    </div>
    <ng-container *ngIf="linkIsExternal">
      <a class="tb_spotlight-card__cta tb_icon-link" [href]="link" target="_blank" style="display: block"
        *ngIf="link && showLink">
        {{ linkText }}
        <span class="tb_icon-link__icon tb_icon-link__icon--right">
          <img appLazyLoad src="../../../assets/images/icons/arrow-right-circle.svg" />
        </span>
      </a>
    </ng-container>
    <ng-container *ngIf="linkIsInternal">
      <a class="tb_spotlight-card__cta tb_icon-link" [routerLink]="link" style="display: block"
        *ngIf="link && showLink">
        {{ linkText }}
        <span class="tb_icon-link__icon tb_icon-link__icon--right">
          <img appLazyLoad src="../../../assets/images/icons/arrow-right-circle.svg" />
        </span>
      </a>
    </ng-container>

  </div>
</div> -->