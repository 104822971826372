import { Injectable } from '@angular/core';
import {
  Botw,
  GeekOut,
  Barfly,
  News,
  PodcastPage,
  ServiceDirectoryPage,
  BookPage,
  EducationPage,
  CategoryPage,
  BlendCategory,
  BrandsPage,
  Brands,
  ProductPage,
  Product,
  GeekOutPage,
  LmsPage,
  CocktailPage,
  Cocktail,
  EventPage,
  Event,
  TPBPage,
  TPB,
  TPBSubPage,
  CommunityPage,
  NewsPage,
  BotwPage,
  HiveMindPage,
  PodcastSeries,
  CategorySubPage,
  BrandSubPage,
  ServiceDirectory,
  BarflyPage,
  BotwSub,
  Home,
  Contact,
  ServiceWellPage,
  ServiceWell,
  Base,
  BartenderBasics,
  BartenderBasicsPage,
  BrandAmbassadorsSubPage,
  BrandAmbassadorsPage,
  BrandAmbassadors,
} from '@proxy/service-proxies';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { environment } from '@env/environment';

export class LinkItem {
  public link: string | any[];
  public privateLink: boolean;

  constructor(link: string | any[], privateLink: boolean) {
    this.link = link;
    this.privateLink = privateLink;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  constructor(private localize: LocalizeRouterService) {}

  /**
   * Pass in an object and returns a generate link
   * Example: Pass in a Botw (campaign) and get /botw/<slug> returned
   * @param data - any listed type
   */

  //
  public getLink(
    data:
      | Barfly
      | BartenderBasics
      | BlendCategory
      | Brands
      | BookPage
      | Botw
      | BotwPage
      | BotwSub
      | BrandsPage
      | BrandSubPage
      | Contact
      | CategoryPage
      | CategorySubPage
      | Cocktail
      | CocktailPage
      | CommunityPage
      | EducationPage
      | Event
      | EventPage
      | GeekOut
      | GeekOutPage
      | Home
      | HiveMindPage
      | LmsPage
      | News
      | NewsPage
      | PodcastPage
      | PodcastSeries
      | ProductPage
      | Product
      | ServiceDirectory
      | ServiceDirectoryPage
      | ServiceWell
      | ServiceWellPage
      | TPB
      | TPBPage
      | TPBSubPage
      | Base
  ): LinkItem {
    switch (true) {
      case data instanceof Barfly: {
        return new LinkItem(
          this.localize.translateRoute(`/news/barfly`),
          false
        );
      }
      case data instanceof BartenderBasics: {
        return new LinkItem(
          this.localize.translateRoute('/community-resources/bar-essentials'),
          false
        );
      }
      case data instanceof BartenderBasicsPage: {
        return new LinkItem(
          this.localize.translateRoute('/community-resources/bar-essentials'),
          false
        );
      }
      case data instanceof BarflyPage: {
        return new LinkItem(
          this.localize.translateRoute(`/news/barfly`),
          false
        );
      }
      case data instanceof BlendCategory: {
        return new LinkItem(
          this.localize.translateRoute(`/learn/categories/${data['slug']}`),
          false
        );
      }
      case data instanceof BookPage: {
        return new LinkItem(
          this.localize.translateRoute('/community/books-library'),
          true
        );
      }
      case data instanceof Botw: {
        return new LinkItem(
          this.localize.translateRoute(`/botw/${data['slug']}`),
          true
        );
      }
      case data instanceof BotwPage: {
        return new LinkItem(this.localize.translateRoute(`/botw`), true);
      }
      case data instanceof Brands: {
        return new LinkItem(
          this.localize.translateRoute(`/learn/brands/${data['slug']}`),
          false
        );
      }

      case data instanceof BrandAmbassadors: {
        return new LinkItem(
          this.localize.translateRoute(
            `/brand-ambassadors-profile/${data['slug']}`
          ),
          false
        );
      }
      case data instanceof BrandAmbassadorsPage: {
      }
      case data instanceof BrandAmbassadorsSubPage: {
        return new LinkItem(
          this.localize.translateRoute(`/brand-ambassadors/${data['slug']}`),
          false
        );
      }
      case data instanceof BrandsPage: {
        return new LinkItem(
          this.localize.translateRoute(`/learn/brands`),
          false
        );
      }
      case data instanceof BrandSubPage: {
        return new LinkItem(
          this.localize.translateRoute(
            `/learn/brands/${data['brand'][0]['slug']}/${data['slug']}`
          ),
          false
        );
      }
      case data instanceof CategoryPage: {
        return new LinkItem(
          this.localize.translateRoute(`/learn/categories`),
          false
        );
      }
      case data instanceof CategorySubPage: {
        return new LinkItem(
          this.localize.translateRoute(
            `/learn/categories/${data['categoryNode'][0]['slug']}/${data['slug']}`
          ),
          false
        );
      }
      case data instanceof Cocktail: {
        return new LinkItem(
          this.localize.translateRoute(`/cocktails/${data['slug']}`),
          false
        );
      }
      case data instanceof CocktailPage: {
        return new LinkItem(this.localize.translateRoute('/cocktails'), false);
      }
      case data instanceof Contact: {
        return new LinkItem(this.localize.translateRoute('/contact-us'), false);
      }
      case data instanceof CommunityPage: {
        return new LinkItem(
          this.localize.translateRoute(`/community-resources`),
          false
        );
      }
      case data instanceof EducationPage: {
        return new LinkItem(this.localize.translateRoute(`/learn`), false);
      }
      case data instanceof Event: {
        return new LinkItem(
          this.localize.translateRoute(`/whats-on/${data['slug']}`),
          false
        );
      }
      case data instanceof EventPage: {
        return new LinkItem(this.localize.translateRoute('/whats-on'), false);
      }
      case data instanceof GeekOut: {
        return new LinkItem(
          this.localize.translateRoute(`/learn/geek-out/${data['slug']}`),
          false
        );
      }
      case data instanceof GeekOutPage: {
        return new LinkItem(
          this.localize.translateRoute(`/learn/geek-out`),
          false
        );
      }
      case data instanceof Home: {
        return new LinkItem(this.localize.translateRoute(`/`), false);
      }
      case data instanceof HiveMindPage: {
        return new LinkItem(
          this.localize.translateRoute('/community/hivemind'),
          true
        );
      }
      case data instanceof LmsPage: {
        return new LinkItem(
          this.localize.translateRoute(`/learn/still-learning`),
          false
        );
      }
      case data instanceof News: {
        return new LinkItem(
          this.localize.translateRoute(`/news/${data['slug']}`),
          false
        );
      }
      case data instanceof NewsPage: {
        return new LinkItem(this.localize.translateRoute(`/news`), false);
      }
      case data instanceof PodcastPage: {
        return new LinkItem(
          this.localize.translateRoute(`/community/podcasts`),
          true
        );
      }
      case data instanceof PodcastSeries: {
        return new LinkItem(
          this.localize.translateRoute(`/community/podcasts/${data['slug']}`),
          true
        );
      }
      case data instanceof Product: {
        return new LinkItem(
          this.localize.translateRoute(`/learn/products/${data['slug']}`),
          false
        );
      }
      case data instanceof ProductPage: {
        return new LinkItem(
          this.localize.translateRoute(`/learn/products`),
          false
        );
      }
      case data instanceof ServiceDirectoryPage: {
        return new LinkItem(
          this.localize.translateRoute('/community/service-directory'),
          true
        );
      }
      case data instanceof ServiceDirectory: {
        return new LinkItem(
          this.localize.translateRoute(
            `/community/service-directory/${data['slug']}`
          ),
          true
        );
      }
      case data instanceof ServiceWell: {
        return new LinkItem(
          this.localize.translateRoute(`/news/articles/${data['slug']}`),
          true
        );
      }
      case data instanceof ServiceWellPage: {
        return new LinkItem(
          this.localize.translateRoute(`/news/articles`),
          false
        );
      }
      case data instanceof TPB: {
        return new LinkItem(
          this.localize.translateRoute(`/perfect-blend/${data['slug']}`),
          false
        );
      }
      case data instanceof TPBPage: {
        return new LinkItem(
          this.localize.translateRoute(`/perfect-blend`),
          false
        );
      }
      case data instanceof TPBSubPage: {
        return new LinkItem(
          this.localize.translateRoute(
            `/perfect-blend/${data['parent']['slug']}/${data['slug']}`
          ),
          false
        );
      }
      // default: { return new LinkItem(this.localize.translateRoute(`/`), false); }
    }
    //

    return null;
  }

  /**
   * Pass in a uri and it will join with environment url and remove duplicated slashes
   * @param uri - string
   * @returns 'https://www.theblend.world/news
   */
  public getEnvironmentUrl(uri: string): string {
    const link = uri.replace(/\/{2,}/g, '/');
    return `${environment.baseUrl}${link}`;
  }
}

export interface IBreadcrumbs {
  title: string | undefined;
  linkItem: LinkItem | undefined;
}
