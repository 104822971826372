import { ProfileServiceProxy, CurrentUserProfileEditDto } from '@proxy/service-proxies';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ProfileService implements Resolve<any> {

    private profile: CurrentUserProfileEditDto | null;

    constructor(private profileProxy: ProfileServiceProxy) {
    }

    /**
 * Resolver
 *
 * @param {ActivatedRouteSnapshot} route
 * @param {RouterStateSnapshot} state
 * @returns {Observable<any> | Promise<any> | any}
 */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
        return this.getProfile();
    }

    getProfile(): Observable<CurrentUserProfileEditDto> {
        return this.profileProxy.getCurrentUserProfileForEdit()
    }
}