import { Injectable } from '@angular/core';
// import { AmplifyService } from 'aws-amplify-angular';
import Auth from '@aws-amplify/auth';
import * as Rx from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CognitoService {
    signedInSubject = new Rx.Subject();
    signedIn: boolean;
    user;
    greeting;

    constructor(
        // public amplifyService: AmplifyService
    ) {
        // this.amplifyService = amplifyService;

        // this.amplifyService.authStateChange$.subscribe(authState => {
        //   this.signedIn = authState.state === 'signedIn';
        //   if (!authState.user) {
        //     this.user = null;
        //     console.log('signed out');
        //   } else {
        //     this.user = authState.user;
        //     this.greeting = 'Hello ' + this.user.username;
        //     // console.log(this.user);
        //     this.signedInSubject.next(this.user);
        //   }
        // });
    }

    authenticateUser() {
        Auth.currentAuthenticatedUser({
            bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
            .then(user => console.log(user))
            .catch(err => console.log(err));
    }

    retrieveCurrentSession() {
        Auth.currentSession()
            .then(data => console.log(data))
            .catch(err => console.log(err));
    }

    signOut() {
        Auth.signOut()
            .then(data => console.log(data))
            .catch(err => console.log(err));
    }
    setEmail(email: string) {
        localStorage.setItem('email', email);
    }

    getEmail() {
        return localStorage.email;
    }

    clearLocalStorageEmail() {
        localStorage.removeItem('email');
    }
}
