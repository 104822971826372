import { Component, OnInit, Input } from "@angular/core";
import { ImgSafePathPipe } from "@core/pipes/img.pipe";
import { PageType } from "@shared/interfaces/interfaces";

@Component({
  selector: "app-two-up",
  templateUrl: "./two-up.component.html",
  styleUrls: ["./two-up.component.scss"],
})
export class TwoUpComponent implements OnInit {
  @Input() color: string;
  @Input() data;
  @Input() goldBorder;
  @Input() index: number;
  @Input() linkColor: string;
  @Input() trackingNumber: number;

  // states whether the image will be first in the ping pong or not
  public imgFirst: boolean;

  // testing
  @Input() page: PageType;
  public pageType: PageType;
  public linkData: boolean;

  constructor(public imgSafePipe: ImgSafePathPipe) {}

  ngOnInit() {
    this.pageType = this.page;
    if (this.data.link || this.data.subPage || this.data.files) {
      this.linkData = true;
    }
    // Logic for layout
    if (this.trackingNumber % 2 === 0) {
      this.imgFirst = true;
    } else {
      this.imgFirst = false;
    }
  }
}
