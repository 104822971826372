import { Injectable } from '@angular/core';
import {
  Barfly,
  BarflyPage,
  BaseInput,
  BasicPage,
  BartenderBasicsPage,
  BlendCategory,
  BookPage,
  Botw,
  BotwPage,
  BotwServiceProxy,
  BotwSub,
  Brands,
  BrandsPage,
  BrandsServiceProxy,
  BrandSubPage,
  CategoryPage,
  CategoryServiceProxy,
  CategorySubPage,
  Cocktail,
  CocktailPage,
  CommunityPage,
  Contact,
  EducationPage,
  Event,
  EventPage,
  GeekOut,
  GeekOutPage,
  HiveMindPage,
  Home,
  LmsPage,
  News,
  NewsPage,
  PageServiceProxy,
  PodcastPage,
  PodcastSeries,
  Product,
  ProductPage,
  RootsPage,
  ServiceDirectory,
  ServiceDirectoryPage,
  ServiceWell,
  ServiceWellPage,
  SlugInput,
  TPB,
  TPBActive,
  TPBFinalist,
  TPBPage,
  TPBServiceProxy,
  TPBSubPage,
  BartenderBasics,
  BartenderBasicsServiceProxy,
  NewsItemInput,
  BartenderBasicsItemInput,
} from '@proxy/service-proxies';
import { TranslateService } from '@ngx-translate/core';
import { RoutingService } from '@routing/services/routing.service';
import { IBreadcrumbs, LinkService } from './link.service';
import { PageService } from './page.service';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  pageData: any;
  params: Params;
  request: BaseInput;
  breadcrumbs: IBreadcrumbs[] = [];

  constructor(
    private linkSvc: LinkService,
    private translate: TranslateService,
    protected pageSvc: PageService,
    private pageApi: PageServiceProxy,
    private categoryApi: CategoryServiceProxy,
    private routingService: RoutingService,
    private brandApi: BrandsServiceProxy,
    private botwApi: BotwServiceProxy,
    private tpbApi: TPBServiceProxy,
    private bartendingApi: BartenderBasicsServiceProxy
  ) {
    const locale = this.routingService.getLocale();
    this.request = new BaseInput({
      languageCode: locale.language,
      market: locale.market,
      publishedStatus: '1',
    });
  }

  clean() {
    this.breadcrumbs = [
      {
        title: this.translate.instant('Misc.Home'),
        linkItem: this.linkSvc.getLink(new Home()),
      },
    ];
  }

  breadcrumbLink(data, heading, link = undefined) {
    let title = data.breadcrumb || heading;
    this.breadcrumbs.push({
      title: title,
      linkItem: link ? this.linkSvc.getLink(link) : undefined,
    });
  }

  getBreadcrumbs(
    data:
      | BartenderBasicsPage
      | BartenderBasics
      | BasicPage
      | Barfly
      | BlendCategory
      | Brands
      | BookPage
      | Botw
      | BotwPage
      | BotwSub
      | BrandsPage
      | BrandSubPage
      | CategoryPage
      | CategorySubPage
      | Cocktail
      | CocktailPage
      | Contact
      | CommunityPage
      | EducationPage
      | Event
      | EventPage
      | GeekOut
      | GeekOutPage
      | Home
      | HiveMindPage
      | LmsPage
      | News
      | NewsPage
      | PodcastPage
      | PodcastSeries
      | ProductPage
      | Product
      | RootsPage
      | ServiceDirectory
      | ServiceDirectoryPage
      | ServiceWell
      | ServiceWellPage
      | TPB
      | TPBActive
      | TPBPage
      | TPBSubPage
      | TPBFinalist,
    params: Params
  ) {
    this.pageData = data;
    this.params = params;
    switch (true) {
      case data instanceof BartenderBasicsPage: {
        return this.bartendingPage();
      }
      case data instanceof BartenderBasics: {
        return this.bartendingBasicsPage();
      }
      case data instanceof BasicPage: {
        return this.basicPage();
      }
      case data instanceof BarflyPage: {
        return this.barflyPage();
      }
      case data instanceof BlendCategory: {
        return this.blendCategory();
      }
      case data instanceof BookPage: {
        return this.bookPage();
      }
      case data instanceof Botw: {
        return this.botw();
      }
      case data instanceof BotwSub: {
        return this.botwSub();
      }
      case data instanceof BotwPage: {
        return this.botwPage();
      }
      case data instanceof Brands: {
        return this.brands();
      }
      case data instanceof BrandsPage: {
        return this.educationSub();
      }
      case data instanceof BrandSubPage: {
        return this.brandsSub();
      }
      case data instanceof CategoryPage: {
        return this.educationSub();
      }
      case data instanceof CategorySubPage: {
        return this.categorySubPage();
      }
      case data instanceof Contact: {
        return this.contact();
      }
      case data instanceof Cocktail: {
        return this.cocktail();
      }
      case data instanceof CocktailPage: {
        return this.cocktailPage();
      }
      case data instanceof CommunityPage: {
        return this.communityPage();
      }
      case data instanceof EducationPage: {
        return this.educationPage();
      }
      case data instanceof Event: {
        return this.event();
      }
      case data instanceof EventPage: {
        return this.eventPage();
      }
      case data instanceof GeekOut: {
        return this.geekOut();
      }
      case data instanceof GeekOutPage: {
        return this.educationSub();
      }
      case data instanceof HiveMindPage: {
        return this.hiveMindPage();
      }
      case data instanceof LmsPage: {
        return this.lmsPage();
      }
      case data instanceof News: {
        return this.news();
      }
      case data instanceof NewsPage: {
        return this.newsPage();
      }
      case data instanceof PodcastPage: {
        return this.podcastPage();
      }
      case data instanceof PodcastSeries: {
        return this.podcastSeries();
      }
      case data instanceof Product: {
        return this.product();
      }
      case data instanceof ProductPage: {
        return this.educationSub();
      }
      case data instanceof RootsPage: {
        return this.rootsPage();
      }
      case data instanceof ServiceDirectoryPage: {
        return this.serviceDirectoyPage();
      }
      case data instanceof ServiceDirectory: {
        return this.serviceDirectory();
      }
      case data instanceof ServiceWell: {
        return this.serviceWell();
      }
      case data instanceof ServiceWellPage: {
        return this.serviceWellPage();
      }
      case data instanceof TPB: {
        return this.tPB();
      }
      case data instanceof TPBActive: {
        return this.tPBActive();
      }
      case data instanceof TPBFinalist: {
        return this.tPBFinalist();
      }
      case data instanceof TPBSubPage: {
        return this.tPBSubPage();
      }
      case data instanceof TPBPage: {
        return this.tPBPage();
      }
    }
  }

  educationPage() {
    this.headingLink(this.pageData);
  }

  botwPage() {
    this.headingLink(this.pageData);
  }

  cocktailPage() {
    this.headingLink(this.pageData);
  }
  bartendingPage() {
    this.pageApi.community(this.request).subscribe((data) => {
      this.communityPageLink(data);
      this.breadcrumbLink(this.pageData, this.pageData.heading1.value);
    });
  }
  bartendingBasicsPage() {
    this.pageApi.community(this.request).subscribe((data) => {
      this.communityPageLink(data);
      this.pageApi.bartenderBasics(this.request).subscribe((data) => {
        this.headingLink(data.items[0], new BartenderBasics());
        this.breadcrumbLink(this.pageData, this.pageData.heading);
      });
    });
  }

  eventPage() {
    this.headlineLink(this.pageData);
  }

  communityPage() {
    this.headlineLink(this.pageData);
  }

  tPBPage() {
    this.breadcrumbLink(this.pageData, this.pageData.heading1.value);
  }

  newsPage() {
    this.headlineLink(this.pageData);
  }

  communityPageLink(data) {
    this.headlineLink(data.items[0], new CommunityPage());
  }

  educationPageLink(data) {
    this.headingLink(data.items[0], new EducationPage());
  }

  tPBPageLink(data) {
    this.breadcrumbLink(
      data.items[0],
      data.items[0].heading1.value,
      new TPBPage()
    );
  }

  headingLink(data, link = undefined) {
    this.breadcrumbLink(data, this.headingValue(data), link);
  }

  headingValue(data) {
    return data.heading === null
      ? null
      : typeof data.heading === 'object' && 'value' in data.heading
      ? data.heading.value
      : data.heading;
  }

  headlineLink(data, link = undefined) {
    this.breadcrumbLink(
      data,
      data.headline === null
        ? null
        : typeof data.headline === 'object' && 'value' in data.headline
        ? data.headline.value
        : data.headline,
      link
    );
  }

  fieldHeading(data, link = undefined) {
    this.breadcrumbLink(
      data,
      data.fieldHeading1 === null
        ? null
        : typeof data.fieldHeading1 === 'object' &&
          'value' in data.fieldHeading1
        ? data.fieldHeading1.value
        : data.fieldHeading1,
      link
    );
  }

  hiveMindPage() {
    this.pageApi.community(this.request).subscribe((data) => {
      this.communityPageLink(data);
      this.headingLink(this.pageData);
    });
  }

  podcastSeries() {
    this.pageApi.community(this.request).subscribe((data) => {
      this.communityPageLink(data);
      this.pageApi.podcast(this.request).subscribe((data) => {
        this.headingLink(data.items[0], new PodcastPage());
        this.headingLink(this.pageData);
      });
    });
  }

  serviceDirectory() {
    this.pageApi.community(this.request).subscribe((data) => {
      this.communityPageLink(data);
      this.pageApi.serviceDirectory(this.request).subscribe((data) => {
        this.headingLink(data.items[0], new ServiceDirectoryPage());
        this.headingLink(this.pageData);
      });
    });
  }

  geekOut() {
    this.pageApi.education(this.request).subscribe((data) => {
      this.educationPageLink(data);
      this.pageApi.geekOut(this.request).subscribe((data) => {
        this.headingLink(data.items[0], new GeekOutPage());
        this.breadcrumbLink(this.pageData, this.pageData.geekingOutHeading);
      });
    });
  }

  product() {
    this.pageApi.education(this.request).subscribe((data) => {
      this.educationPageLink(data);
      this.pageApi.product(this.request).subscribe((data) => {
        this.headingLink(data.items[0], new ProductPage());
        this.breadcrumbLink(this.pageData, this.pageData.productSubHeading);
      });
    });
  }

  brands() {
    this.pageApi.education(this.request).subscribe((data) => {
      this.educationPageLink(data);
      this.pageApi.brands(this.request).subscribe((data) => {
        this.headingLink(data.items[0], new BrandsPage());
        this.breadcrumbLink(this.pageData, this.pageData.brandSubHeading);
      });
    });
  }

  brandsSub() {
    this.pageApi.education(this.request).subscribe((data) => {
      this.educationPageLink(data);
      this.pageApi.brands(this.request).subscribe((data) => {
        this.headingLink(data.items[0], new BrandsPage());
        this.brandApi
          .item(new SlugInput({ ...this.request, slug: this.params.brand }))
          .subscribe((data) => {
            this.breadcrumbLink(
              data.items[0],
              data.items[0].brandSubHeading,
              new Brands(data.items[0])
            );
            // this.headingLink(this.pageData);

            let currentBreadcrumb = this.headingValue(this.pageData);
            if (currentBreadcrumb && currentBreadcrumb.length > 0) {
              this.headingLink(this.pageData);
            } else {
              data.items[0].fieldContent.forEach((item) => {
                if (item['subPage']) {
                  if (item['subPage'][0].slug === this.pageData.slug) {
                    this.headingLink({ heading: item['subPageLinkName'] });
                  }
                }
              });
            }
          });
      });
    });
  }

  botwSub() {
    this.pageApi.botw(this.request).subscribe((data) => {
      this.headingLink(data.items[0], new BotwPage());
      this.botwApi
        .item(new SlugInput({ ...this.request, slug: this.params.route }))
        .subscribe((data) => {
          this.headingLink(data.items[0], new Botw(data.items[0]));

          let currentBreadcrumb = this.headingValue(this.pageData);
          if (currentBreadcrumb && currentBreadcrumb.length > 0) {
            this.headingLink(this.pageData);
          } else {
            data.items[0].content.forEach((item) => {
              if (item['subPage']) {
                if (item['subPage'].slug === this.pageData.slug) {
                  this.headingLink(item);
                }
              }
            });
          }
        });
    });
  }

  botw() {
    this.pageApi.botw(this.request).subscribe((data) => {
      this.headingLink(data.items[0], new BotwPage());
      this.headingLink(this.pageData);
    });
  }

  event() {
    this.pageApi.event(this.request).subscribe((data) => {
      this.headlineLink(data.items[0], new EventPage());
      this.headingLink(this.pageData);
    });
  }

  cocktail() {
    this.pageApi.cocktail(this.request).subscribe((data) => {
      this.headingLink(data.items[0], new CocktailPage());
      this.headingLink(this.pageData);
    });
  }

  educationSub() {
    this.pageApi.education(this.request).subscribe((data) => {
      this.educationPageLink(data);
      this.headingLink(this.pageData);
    });
  }

  lmsPage() {
    this.pageApi.education(this.request).subscribe((data) => {
      this.educationPageLink(data);
      this.headlineLink(this.pageData);
    });
  }

  blendCategory() {
    this.pageApi.education(this.request).subscribe((data) => {
      this.educationPageLink(data);
      this.pageApi.category(this.request).subscribe((data) => {
        this.headingLink(data.items[0], new CategoryPage());
        this.breadcrumbLink(this.pageData, this.pageData.categorySubHeadline);
      });
    });
  }

  tPBSubPage() {
    this.pageApi.tPB(this.request).subscribe((data) => {
      this.tPBPageLink(data);
      this.tpbApi
        .item(new SlugInput({ ...this.request, slug: this.params.archive }))
        .subscribe((data) => {
          this.headingLink(data.items[0], new TPB(data.items[0]));
          this.headingLink(this.pageData);
        });
    });
  }

  tPBFinalist() {
    this.pageApi.tPB(this.request).subscribe((data) => {
      this.tPBPageLink(data);
      const params = this.params;
      this.tpbApi
        .item(new SlugInput({ ...this.request, slug: params.archive }))
        .subscribe((data) => {
          this.headingLink(data.items[0], new TPB(data.items[0]));
          this.tpbApi
            .subPageItem(new SlugInput({ ...this.request, slug: params.id }))
            .subscribe((data) => {
              this.headingLink(data.items[0], new TPBSubPage(data.items[0]));
              this.headingLink(this.pageData);
            });
        });
    });
  }

  tPB() {
    this.pageApi.tPB(this.request).subscribe((data) => {
      this.tPBPageLink(data);
      this.headingLink(this.pageData);
    });
  }

  tPBActive() {
    this.pageApi.tPB(this.request).subscribe((data) => {
      this.tPBPageLink(data);
      this.fieldHeading(this.pageData);
    });
  }

  podcastPage() {
    this.pageApi.community(this.request).subscribe((data) => {
      this.communityPageLink(data);
      this.headingLink(this.pageData);
    });
  }

  bookPage() {
    this.pageApi.community(this.request).subscribe((data) => {
      this.communityPageLink(data);
      this.headingLink(this.pageData);
    });
  }

  serviceDirectoyPage() {
    this.pageApi.community(this.request).subscribe((data) => {
      this.communityPageLink(data);
      this.headingLink(this.pageData);
    });
  }

  categorySubPage() {
    this.pageApi.education(this.request).subscribe((data) => {
      this.educationPageLink(data);
      this.pageApi.category(this.request).subscribe((data) => {
        this.headingLink(data.items[0], new CategoryPage());
        const params = this.params;
        this.categoryApi
          .item(new SlugInput({ ...this.request, slug: params.brand }))
          .subscribe((data) => {
            this.breadcrumbLink(
              data.items[0],
              data.items[0].categorySubHeadline,
              new BlendCategory(data.items[0])
            );
            this.headingLink(this.pageData);
          });
      });
    });
  }

  news() {
    this.pageApi.news(this.request).subscribe((data) => {
      this.headlineLink(data.items[0], new NewsPage());
      this.headingLink(this.pageData);
    });
  }

  serviceWell() {
    this.pageApi.news(this.request).subscribe((data) => {
      this.headlineLink(data.items[0], new NewsPage());
      this.pageApi.serviceWell(this.request).subscribe((result) => {
        this.breadcrumbLink(
          result.items[0],
          result.items[0].heading1.value,
          new ServiceWellPage()
        );
        this.headingLink(this.pageData);
        // this.breadcrumbLink(this.pageData, this.pageData.heading1.value);
      });
    });
  }

  serviceWellPage() {
    this.pageApi.news(this.request).subscribe((data) => {
      this.headlineLink(data.items[0], new NewsPage());
      this.pageApi.serviceWell(this.request).subscribe((result) => {
        // this.headingLink(data.items[0], new ServiceWellPage());
        this.breadcrumbLink(this.pageData, this.pageData.heading1.value);
      });
    });
  }

  // brandsSub() {
  //   this.pageApi.education(this.request).subscribe(data => {
  //     this.educationPageLink(data);
  //     this.pageApi.brands(this.request).subscribe(data => {
  //       this.headingLink(data.items[0], new BrandsPage());
  //       this.brandApi.item(
  //         new SlugInput({ ...this.request, slug: this.params.brand })
  //       ).subscribe(data => {
  //         this.breadcrumbLink(data.items[0],
  //           data.items[0].brandSubHeading,
  //           new Brands(data.items[0])
  //         );
  //         this.headingLink(this.pageData);
  //       });
  //     });
  //   });
  // }

  barflyPage() {
    this.pageApi.news(this.request).subscribe((data) => {
      this.headlineLink(data.items[0], new NewsPage());
      this.headingLink(this.pageData);
    });
  }
  rootsPage() {
    this.pageApi.news(this.request).subscribe((data) => {
      this.headlineLink(data.items[0], new NewsPage());
      this.headingLink(this.pageData);
    });
  }

  basicPage() {
    this.headingLink(this.pageData);
  }

  contact() {
    this.headingLink(this.pageData);
  }
}
