import { Component, OnInit, Input } from '@angular/core';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { get } from 'lodash';

@Component({
  selector: 'app-books-card',
  templateUrl: './books-card.component.html',
  styleUrls: ['./books-card.component.scss']
})
export class BooksCardComponent implements OnInit {
  @Input() data;
  title: string;
  content: string;
  link: string;
  linkText: string;
  img: string;
  constructor(public imgSafePipe: ImgSafePathPipe) { }

  ngOnInit() {
    this.title = get(this.data, `bookTitle`);
    this.content = get(this.data, `authorName`);
    this.link = get(this.data, `link.uri`);
    this.linkText = get(this.data, `link.title`);
    this.img = this.imgSafePipe.transform(this.data, `image.images[0].link.default`);
  }

}
