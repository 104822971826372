<div class="tb_two-up-section" *ngIf="imgFirst">
  <div class="tb_two-up-section__left">
    <app-two-up-media
      [media]="data"
      [page]="pageType"
      [goldBorder]="goldBorder"
    ></app-two-up-media>
  </div>
  <div class="tb_two-up-section__right copy">
    <app-two-up-content
      [contentData]="data"
      [page]="pageType"
    ></app-two-up-content>
    <div
      class="tb_two-up-section__text tb_type-group tb_type-group--reverse tb_type-group--center"
    ></div>
    <app-two-up-link
      [linkData]="data"
      [linkColor]="linkColor"
      *ngIf="linkData"
      [page]="pageType"
    ></app-two-up-link>
  </div>
</div>

<div class="tb_two-up-section" *ngIf="!imgFirst">
  <div class="tb_two-up-section__left copy">
    <app-two-up-content
      [contentData]="data"
      [page]="pageType"
    ></app-two-up-content>
    <div
      class="tb_two-up-section__text tb_type-group tb_type-group--reverse tb_type-group--center"
    ></div>
    <app-two-up-link
      [linkData]="data"
      [linkColor]="linkColor"
      *ngIf="linkData"
      [page]="pageType"
    ></app-two-up-link>
  </div>
  <div class="tb_two-up-section__right">
    <app-two-up-media
      [media]="data"
      [page]="pageType"
      [goldBorder]="goldBorder"
    ></app-two-up-media>
  </div>
</div>
