import { Component, OnInit, Input } from '@angular/core';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { get } from 'lodash';
@Component({
  selector: 'app-explore-products-card',
  templateUrl: './explore-products-card.component.html',
  styleUrls: ['./explore-products-card.component.scss']
})
export class ExploreProductsCardComponent implements OnInit {
  @Input() data;
  link: string;
  img: string;
  title: string;
  constructor(private imgSafePipe: ImgSafePathPipe) { }

  ngOnInit() {
    this.img = this.imgSafePipe.transform(this.data, `productImage[0].images[0].link.default`, true);
    this.link = get(this.data, `slug`);
    this.title = get(this.data, `productSubHeading`);
  }

}
