import { Country } from '@modules/join/phone-country-codes';
import {
  Product,
  TaxCocktailGlasses,
  TaxTPBCategory,
  TaxTPBJourney,
  TaxTPBMethods,
  TaxTPBRegions,
} from '@proxy/service-proxies';
import { Moment } from 'moment';
import {
  ImgSizes,
  ImgResizeType,
  SlideTypes,
  FieldState,
} from '@core/enum/enum';

export interface CategoryCardInterface {
  label: string;
  title: string;
  heading: string;
  text: string;
  link: string;
}

export interface CenteredTextInterface {
  heading: string;
  copy?: string;
}

interface Copy {
  copy: string;
}
export interface SubHeadInterface {
  heading: string;
  paragraphs: Copy[];
}

export interface HeroInterface {
  bgImg: string;
  heading?: string;
  text?: string;
  link?: string;
  linkText?: string;
}

export interface IconCardInterface {
  heading: string;
  text: string;
  icon: string;
}

export interface NewsCardInterface {
  category: string;
  title: string;
  img: string;
  teaser?: string;
  byline?: string;
  location: string;
  link: string;
}

export interface SpotlightCardInterface {
  heading: string;
  text: string;
  product: string;
  link: string;
}

export interface SubSectionInterface {
  imgSrc: string;
  copy: {
    heading: string;
    text: string;
  };
  link?: string;
}

export interface SubSectionMenuInterface {
  name: string;
  link: string;
  active?: boolean;
}

export interface GenericHeroInterface {
  bgImg: string;
  fileType: 'Image' | 'Video';
  text?: string;
  src?: string;
  route?: string;
  heading?: string;
}

export enum PageType {
  BarflyLanding = 'barfly-landing',
  BartendingEssentials = 'bartending-essentials',
  Botw = 'botw',
  Category = 'category',
  CategoryLanding = 'categories',
  Cocktail = 'cocktail',
  Event = 'event',
  EventsLanding = 'events-landing',
  Home = 'home',
  News = 'news',
  NewsLanding = 'news-landing',
  PerfectBlend = 'perfect-blend',
  PerfectBlendFinalist = 'perfect-blend-finalist',
  RootsLanding = 'roots-landing',
}

export interface EnrollForm {
  preliminary?: {
    experienceLevel: TaxTPBCategory;
    journey: TaxTPBJourney;
    region: TaxTPBRegions;
  };
  aboutYou?: {
    firstName: string;
    lastName: string;
    birthday: Moment;
    gender: string;
    countryCode: Country;
    phoneNumber: string;
    streetName: string;
    streetNumber: string;
    city: string;
    stateProvince: string;
    postcode: string;
    country: string;
    venueName: string;
    venueNumber: string;
    venueStreetName: string;
    venueCity: string;
    venueStateProv: string;
    venuePostalCode: string;
    venueCountry: string;
    role: string;
    yearsInIndustry: string;
  };
  heroSpirit?: {
    heroSpirit: Product;
  };
  yourCocktail?: {
    name: string;
    method: TaxTPBMethods;
    glass: TaxCocktailGlasses;
    garnish: string;
    heroSpirit: Product;
    quantity: number;
    ingredients: (Product | string)[];
    photos?: string[];
    video?: string;
  };

  story?: {
    toast: string;
    terms: boolean;
    promoting: boolean;
    travel: boolean;
    attendance: boolean;
  };
  currentStep?: {
    step: number;
  };
}

interface Ingredients {
  type: string;
  ingredient_bsp: string;
  quantity_bsp: string;
  ingredient_other: string;
  quantity_other: string;
  ingredient_mixer: string;
  quantity_mixer: string;
}

export interface ImgResizeObject {
  small: ImgSizes;
  medium: ImgSizes;
  large: ImgSizes;
  xlarge: ImgSizes;
  imgType: ImgResizeType;
}

export interface MarketPersonalInfo {
  firstName: FieldState;
  lastName: FieldState;
  gender: FieldState;
  birthday: FieldState;
  countryCode: FieldState;
  phoneNumber: FieldState;
}

export interface MarketLocationLanguage {
  country: FieldState;
  preferredLang: FieldState;
  address: FieldState;
}

export interface MarketWork {
  role: FieldState;
  yearsInIndustry: FieldState;
  businessName: FieldState;
  businessAddress: FieldState;
}

export interface MarketAccountInfo {
  email: FieldState;
  password: FieldState;
  emailOptIn: FieldState;
  textOptIn: FieldState;
}
