import { Component, OnInit, TemplateRef, ViewChild, Input, ElementRef, AfterContentChecked, AfterContentInit } from '@angular/core';

import { DynamicElement, BasicPageService } from '@shared/dynamic/services/basic-page.service';

@Component({
  selector: 'app-outer-wrapper',
  templateUrl: './outer-wrapper.component.html',
  styleUrls: ['./outer-wrapper.component.scss']
})
export class OuterWrapperComponent implements OnInit {
  @Input('element') element: DynamicElement;
  @ViewChild('childComponentTemplate', { static: true }) childComponentTemplate: TemplateRef<any>;
  // @ViewChild('tag', { static: true }) tag: HTMLElement;

  public tags: CustomWrapper[] = [];
  public elements: CustomWrapper[] = [];

  constructor(private basicPageSvc: BasicPageService) { }

  ngOnInit() {
    // Get array of children items
    const parsedElements = this.basicPageSvc.parseChildren(this.element.html);

    if (this.element.html.nodeName.toLocaleLowerCase() === 'table') {
      // Just output table html
      this.elements.push({ text: this.element.html.outerHTML, element: null });
    }
    else {
      // Handle basic element
      let working: string = this.element.html.innerHTML;
      parsedElements.forEach((el: DynamicElement) => {
        // Get text up until current element
        const first = working.split(el.html.outerHTML)[0];
        // Remove first text from working string
        working = working.replace(first, '');
        // Remove the element
        working = working.replace(el.html.outerHTML, '');
        // Add the text and element to be shown
        this.elements.push({
          text: first,
          element: el
        });
      });
      this.elements.push({ text: working, element: null });
    }

  }


}


interface CustomWrapper {
  text: string;
  element: DynamicElement;
}
