<!-- <div class="col-sm-12 tb_breadcrumbs " [ngClass]="{
    'tb_breadcrumbs--header': paddingTop, 
    'tb_surface--gray800': darkBackground,
    'tb_surface--gray700': grayBackground
}">
    <span class="tb_breadcrumbs__section" *ngFor="let breadcrumb of breadcrumbs">
        <a class="tb_breadcrumbs_item tb_breadcrumbs_item__link" *ngIf="breadcrumb.linkItem"
            [routerLink]="[breadcrumb.linkItem.link]">
            <span class="tb_breadcrumbs_item__cutter">{{breadcrumb.title}}</span>
            <span class="tb_breadcrumbs_item__icon"> &gt; </span>
        </a>

        <span class="tb_breadcrumbs_item tb_breadcrumbs_item__active tb_breadcrumbs_item__cutter"
            *ngIf="!breadcrumb.linkItem">{{breadcrumb.title}}</span>
    </span>
</div> -->
<div class="container">
    <div class="row">
        <div class="tb_bvi_breadcrumbs" [ngClass]="{'tb_bvi_breadcrumbs--header': paddingTop }">
            <span class="tb_bvi_breadcrumbs__section" *ngFor="let breadcrumb of breadcrumbs">
                <a class="tb_bvi_breadcrumbs_item__link" *ngIf="breadcrumb.linkItem"
                    [routerLink]="[breadcrumb.linkItem.link]">
                    <span class="tb_bvi_breadcrumbs_item">{{breadcrumb.title}}</span>
                    <span class="tb_bvi_breadcrumbs_item--icon"> &ndash; </span>
                </a>

                <!-- <a class="tb_bvi_breadcrumbs_item__link" href="/">
                <span class="tb_bvi_breadcrumbs_item">Section</span>
                <span class="tb_bvi_breadcrumbs_item--icon"> &ndash; </span>
            </a> -->

                <span class="tb_bvi_breadcrumbs_item tb_bvi_breadcrumbs_item--active"
                    *ngIf="!breadcrumb.linkItem">{{breadcrumb.title}}</span>
            </span>
        </div>
    </div>
</div>