import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  PFeaturedContent,
  PGallery,
  TPBSubPage,
  PTPBSection,
  PTPBFinalistSection,
  PPDFSection,
  PResourcesPodcasts,
  PCommunitySection,
  PSpotlightGrid,
  PFeaturedContentLink,
  PCommunitySpotlightGrid,
  PLmsPingPong,
  PLmsSpotlightGrid,
  PText,
  PMedia,
  PHeading2,
  PHeading3,
  PNewsSpotlightGrid,
  PSWSpotlightGrid,
  PNewsPingPong,
  PSWPingPong,
  PSpreadsheetsSection,
  PBBPingPong,
  PBBSpotlightGrid,
  PBotwSection,
  PJoinButton,
} from '@proxy/service-proxies';
import { ComponentBase } from '@core/classes/component-base';
import { get } from 'lodash';
import { PageService } from '@shared/services/page.service';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { ContentService } from '@shared/services/content.service';
import { JoinService } from '@shared/user-registration/services/join.service';

enum ContentTypes {
  Community = 'community',
  FeaturedContent = 'featuredContent',
  Finalist = 'finalist',
  Gallery = 'gallery',
  Heading2 = 'heading2',
  Heading3 = 'heading3',
  JoinBtn = 'JoinBtn',
  LMS = 'lms',
  PDF = 'pdf',
  PLmsSpotlightGrid = 'PLmsSpotlightGrid',
  Podcasts = 'podcasts',
  PBOTW = 'pBotwSection',
  PtpbSection = 'ptpbSection',
  Spotlight = 'spotlight',
  TPBSubPage = 'tpbSubPage',
  PText = 'text',
  PMedia = 'media',
  PNewsSpotlight = 'PNewsSpotlight',
  PnewsPingPong = 'PNewsPingPong',
  PSpreadsheetsSection = 'PSpreadSheetSection',
}

enum DisplayTypes {
  Both = 'Both',
  Private = 'Private',
  Public = 'Public',
}

class Content {
  public type;
  public content;
  public display = true;
  private authenticated: boolean;
  pingPongTrackingNumber: number;
  constructor(
    data:
      | PFeaturedContent
      | PBBSpotlightGrid
      | PTPBFinalistSection
      | PGallery
      | PHeading2
      | PHeading3
      | TPBSubPage
      | PTPBSection
      | PPDFSection
      | PResourcesPodcasts
      | PCommunitySection
      | PCommunitySpotlightGrid
      | PSpotlightGrid
      | PFeaturedContentLink
      | PJoinButton
      | PLmsPingPong
      | PLmsSpotlightGrid
      | PText
      | PNewsSpotlightGrid
      | PSWSpotlightGrid
      | PNewsPingPong
      | PSWPingPong
      | PSpreadsheetsSection,
    authenticated: boolean,
    public contentSvc: ContentService
  ) {
    this.authenticated = authenticated;

    if (
      data instanceof PFeaturedContent ||
      data instanceof PFeaturedContentLink
    ) {
      this.setFeatured(data, this.authenticated);
    } else if (data instanceof PCommunitySection) {
      this.setCommunity(data);
    } else if (data instanceof PCommunitySpotlightGrid) {
      this.setSpotlight(data);
    } else if (data instanceof PBBSpotlightGrid) {
      this.setBarEssentialSpotlight(data);
    } else if (data instanceof PBotwSection) {
      this.setPBotw(data);
    } else if (data instanceof PGallery) {
      this.setGallery(data);
    } else if (data instanceof PSWPingPong || data instanceof PBBPingPong) {
      this.setNewsPingPong(data);
    } else if (data instanceof PHeading2) {
      this.setHeading2(data);
    } else if (data instanceof PHeading3) {
      this.setHeading3(data);
    } else if (data instanceof PJoinButton) {
      this.setJoinBtn(data);
    } else if (data instanceof PLmsPingPong) {
      this.setLms(data);
    } else if (data instanceof PLmsSpotlightGrid) {
      this.setPLmsSpotlightGrid(data);
    } else if (data instanceof PSWSpotlightGrid) {
      this.setNewsSpotlightGrid(data);
    } else if (data instanceof PMedia) {
      this.setPMedia(data);
    } else if (data instanceof PNewsPingPong) {
      this.setNewsPingPong(data);
    } else if (data instanceof PNewsSpotlightGrid) {
      this.setNewsSpotlightGrid(data);
    } else if (data instanceof PPDFSection) {
      this.setPDF(data);
    } else if (data instanceof PResourcesPodcasts) {
      this.setPodCasts(data);
    } else if (data instanceof PTPBFinalistSection) {
      this.setFinalist(data);
    } else if (data instanceof PTPBSection) {
      this.setPtpbSection(data);
    } else if (data instanceof PText) {
      this.setPText(data);
    } else if (data instanceof TPBSubPage) {
      this.setTpbSubPage(data);
    } else if (data instanceof PSpreadsheetsSection) {
      this.setSpreadSheet(data);
    }
  }

  private setCommunity(content: PCommunitySection) {
    this.type = ContentTypes.Community;
    this.content = content;
    this.setDisplay(content['display']);
    this.pingPongTrackingNumber = this.contentSvc.getPingPongTrackingNumber();
    this.contentSvc.increasePingPongTrack();
  }

  private setFeatured(
    content: PFeaturedContent | PFeaturedContentLink,
    authenticated
  ) {
    if (content['_discriminator'] === 'PFeaturedContent') {
      this.type = ContentTypes.FeaturedContent;
      this.content = content;
    } else if (content['_discriminator'] === 'PFeaturedContentLink') {
      this.type = ContentTypes.FeaturedContent;
      this.content = content;
      this.setDisplay(content['display']);
    }
  }
  private setFinalist(content: PTPBFinalistSection) {
    this.type = ContentTypes.Finalist;
    this.content = content;
  }
  private setGallery(content: PGallery) {
    this.type = ContentTypes.Gallery;
    this.content = content;
  }

  private setHeading2(content: PHeading2) {
    this.type = ContentTypes.Heading2;
    this.content = content;
  }

  private setHeading3(content: PHeading3) {
    this.type = ContentTypes.Heading3;
    this.content = content;
  }
  private setLms(content: PLmsPingPong) {
    this.type = ContentTypes.LMS;
    this.content = content;
    this.pingPongTrackingNumber = this.contentSvc.getPingPongTrackingNumber();
    this.contentSvc.increasePingPongTrack();
  }

  private setPMedia(content: PMedia) {
    this.type = ContentTypes.PMedia;
    this.content = content;
  }
  private setNewsPingPong(content: PNewsPingPong) {
    this.type = ContentTypes.PnewsPingPong;
    this.content = content;
    this.pingPongTrackingNumber = this.contentSvc.getPingPongTrackingNumber();
    this.contentSvc.increasePingPongTrack();
  }

  private setNewsSpotlightGrid(content: PNewsSpotlightGrid) {
    this.type = ContentTypes.PNewsSpotlight;
    this.content = content;
  }

  private setPDF(content: PPDFSection) {
    this.type = ContentTypes.PDF;
    this.content = content;
    this.pingPongTrackingNumber = this.contentSvc.getPingPongTrackingNumber();
    this.contentSvc.increasePingPongTrack();
  }
  private setPLmsSpotlightGrid(content: PLmsSpotlightGrid) {
    this.type = ContentTypes.Spotlight;
    this.content = content;
    this.display = true;
  }
  private setPText(content: PText) {
    this.type = ContentTypes.PText;
    this.content = content;
  }
  private setPodCasts(content: PResourcesPodcasts) {
    this.type = ContentTypes.Podcasts;
    this.content = content;
    this.setDisplay(content['display']);
  }
  private setPtpbSection(content: PTPBSection) {
    this.type = ContentTypes.PtpbSection;
    this.content = content;
    this.pingPongTrackingNumber = this.contentSvc.getPingPongTrackingNumber();
    this.contentSvc.increasePingPongTrack();
  }

  private setPBotw(content: PBotwSection) {
    this.type = ContentTypes.PBOTW;
    this.content = content;
    this.pingPongTrackingNumber = this.contentSvc.getPingPongTrackingNumber();
    this.contentSvc.increasePingPongTrack();
  }

  private setSpotlight(content: PCommunitySpotlightGrid) {
    this.type = ContentTypes.Spotlight;
    this.content = content;
    this.setDisplay(content['display']);
  }

  private setBarEssentialSpotlight(content: PBBSpotlightGrid) {
    this.type = ContentTypes.Spotlight;
    this.content = content;
    this.display = true;
  }
  private setTpbSubPage(content: TPBSubPage) {
    this.type = ContentTypes.TPBSubPage;
    this.content = content;
    this.pingPongTrackingNumber = this.contentSvc.getPingPongTrackingNumber();
    this.contentSvc.increasePingPongTrack();
  }
  private setSpreadSheet(content: PSpreadsheetsSection) {
    this.type = ContentTypes.PDF;
    this.content = content;
  }

  private setJoinBtn(content: PJoinButton) {
    this.type = ContentTypes.JoinBtn;
    this.content = content;
    this.setDisplay(DisplayTypes.Public);
  }

  private setDisplay(display: string) {
    if (display === DisplayTypes.Both) {
      this.display = true;
    } else if (display === DisplayTypes.Private && this.authenticated) {
      this.display = true;
    } else if (display === DisplayTypes.Public && !this.authenticated) {
      this.display = true;
    } else {
      this.display = false;
    }
    // if both true
    // if private && auth true;
    // if public true && !auth true
  }
}

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent
  extends ComponentBase
  implements OnInit, OnChanges
{
  @Input() content: (
    | PFeaturedContent
    | PTPBFinalistSection
    | PGallery
    | PHeading2
    | PHeading3
    | TPBSubPage
    | PTPBSection
    | PPDFSection
    | PResourcesPodcasts
    | PLmsPingPong
    | PLmsSpotlightGrid
    | PText
    | PMedia
    | PSpreadsheetsSection
    | PBotwSection
  )[];
  @Input() pageType;
  @Input() goldBorder;
  @Input() linkColor;
  @Input() authenticated;
  shownContent: Content[] = [];
  types = ContentTypes;
  displayTypes = DisplayTypes;
  public pingPongIndex: number = 0;

  constructor(
    protected pageSvc: PageService,
    public imgSafePipe: ImgSafePathPipe,
    public contentSvc: ContentService,
    public joinSvc: JoinService
  ) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const content = get(changes, `content.currentValue`);
    if (content) {
      this.contentSvc.resetTrackingNumber();
      this.transformContent(content);
    }
  }

  transformContent(content) {
    this.shownContent = [];
    content.forEach((item) => {
      this.shownContent.push(
        new Content(item, this.authenticated, this.contentSvc)
      );
    });
  }

  public userJoin() {
    this.joinSvc.openRegistration();
  }
}
