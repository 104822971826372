import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ElementRef,
  Input,
} from '@angular/core';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { Image } from '@proxy/service-proxies';

@Component({
  selector: 'app-img-carousel',
  templateUrl: './img-carousel.component.html',
  styleUrls: ['./img-carousel.component.scss'],
})
export class ImgCarouselComponent implements OnInit, AfterViewInit {
  @Input() slides: Image[];
  @Input() heading: string;
  @Input() copy;
  @ViewChildren('divs') divs: QueryList<ElementRef>;

  public galleryItems = [];

  public galleryCaptions: Array<string> = [];
  public captionShown: number = 0;

  // the slide that will be in the front of the gallery
  public firstSlide: number = 0;

  // the number of slides that will be visible
  private slidesToShow: number = 0;

  private largeGallery: boolean = false;
  private smallGallery: boolean = false;

  public copyValue: string = undefined;

  constructor(private imgSafePipe: ImgSafePathPipe) {}

  ngOnInit(): void {
    if (this.copy?.value) {
      this.copyValue = this.copy.value;
    }
    this.slides.forEach((slide) => {
      if (slide.images) {
        this.galleryItems.push(
          this.imgSafePipe.transform(slide, `images[0].link.card_3_2`)
        );
      } else {
        this.galleryItems.push(slide);
      }

      if (slide.description) {
        this.galleryCaptions.push(slide.description.value);
      } else {
        this.galleryCaptions.push('');
      }
    });
  }

  ngAfterViewInit(): void {
    // large gallery
    if (this.divs.length > 4) {
      this.largeGallery = true;
      this.slidesToShow = 5;
      // first slide gets set to the second to last slide that way the order shown is second to last last first second third from the data
      this.firstSlide = this.divs.length - 2;
      // small gallery
    } else if (this.divs.length >= 3 && this.divs.length < 5) {
      this.smallGallery = true;
      this.slidesToShow = 3;
      this.firstSlide = this.divs.length - 1;
    }

    // this.setInitialGalleryClasses(this.divs);
    this.updateClass(this.getSlides(this.divs));
  }

  // change class names on slides depending on direction
  updateClass(items: ElementRef[]): void {
    this.divs.toArray().forEach((item: ElementRef) => {
      item.nativeElement.classList = [];
    });
    if (this.smallGallery) {
      items.forEach((item: ElementRef, index: number) => {
        if (index === 0) {
          item.nativeElement.classList.add('slide');
          item.nativeElement.classList.add('previous-item');
        } else if (index === 1) {
          item.nativeElement.classList.add('slide');
          item.nativeElement.classList.add('selected-item');
        } else if (index === 2) {
          item.nativeElement.classList.add('slide');
          item.nativeElement.classList.add('next-item');
        }
      });
    } else if (this.largeGallery) {
      items.forEach((item: ElementRef, index: number) => {
        if (index === 0) {
          item.nativeElement.classList.add('slide');
          item.nativeElement.classList.add('first-item');
        } else if (index === 1) {
          item.nativeElement.classList.add('slide');
          item.nativeElement.classList.add('previous-item');
        } else if (index === 2) {
          item.nativeElement.classList.add('slide');
          item.nativeElement.classList.add('selected-item');
        } else if (index === 3) {
          item.nativeElement.classList.add('slide');
          item.nativeElement.classList.add('next-item');
        } else if (index === 4) {
          item.nativeElement.classList.add('slide');
          item.nativeElement.classList.add('last-item');
        }
      });
    }
  }

  // slice the slides from initial array to get new selection
  getSlides(array, direction?: string): Array<ElementRef> {
    const arrayToSlice = Array(...array._results);
    const arr = [];

    const slidesLoop = () => {
      for (
        let i = this.firstSlide;
        i < this.firstSlide + this.slidesToShow;
        i++
      ) {
        arr.push(arrayToSlice[i % arrayToSlice.length]);
      }
    };

    // condition for initial slide selection
    if (!direction) {
      slidesLoop();
      return arr;
    } else {
      // if carousel goes forward
      if (direction === 'next') {
        // check for looping
        if (this.firstSlide === arrayToSlice.length - 1) {
          this.firstSlide = 0;
        } else {
          this.firstSlide++;
        }
        slidesLoop();
        return arr;
      }

      // if carousel goes backwards
      if (direction === 'previous') {
        // check for looping
        if (this.firstSlide === 0) {
          this.firstSlide = arrayToSlice.length - 1;
        } else {
          this.firstSlide--;
        }
        slidesLoop();
        return arr;
      }
    }
  }

  updateCaptionShown(direction) {
    if (direction === 'next') {
      this.captionShown++;
      if (this.captionShown === this.slides.length) {
        this.captionShown = 0;
      }
    } else if (direction === 'previous') {
      this.captionShown--;
      if (this.captionShown < 0) {
        this.captionShown = this.slides.length - 1;
      }
    }
  }

  // move the gallery to the right
  goPrevious(): void {
    this.updateClass(this.getSlides(this.divs, 'previous'));
    this.updateCaptionShown('previous');
  }
  // move the gallery to the left
  goNext(): void {
    this.updateClass(this.getSlides(this.divs, 'next'));
    this.updateCaptionShown('next');
  }

  onSwipe(evt) {
    const x =
      Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    const y = Math.abs(evt.deltaY) > 40 ? (evt.deltaY > 0 ? 'down' : 'up') : '';
    // this.eventText += `${x} ${y}<br/>`;
    if (x === 'left') {
      this.goNext();
    } else if (x === 'right') {
      this.goPrevious();
    }
  }
}
