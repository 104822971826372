import { Component, OnInit, Input, ChangeDetectorRef, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tag-wrapper',
  templateUrl: './tag-wrapper.component.html',
  styleUrls: ['./tag-wrapper.component.scss']
})
export class TagWrapperComponent implements OnInit {
  @Input('element') element: HTMLElement;
  @ViewChild('childComponentTemplate', { static: true }) childComponentTemplate: TemplateRef<any>;
  public name: string;
  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.name = this.element.localName;
    this.ref.detectChanges();
  }

}
