<div class="tb_media-card tb_media-card--image">
  <a target="_blank" [href]="link">
    <div class="tb_media-card__content-top">
      <img appLazyLoad class="tb_media-card__image" [src]="img" alt="" width="416" height="463">
      <div class="tb_media-card__bg-overlay"></div>
      <div class="tb_media-card__bottom">
        <a class="tb_icon-link tb_media-card__cta tb_media-card__cta--external-link" target="_blank" [href]="link"
          [innerHTML]="linkText"></a>
        <span class="tb_media-card__meta"></span>
      </div>
      <div class="tb_media-card__content">
        <h2 class="tb_media-card__title" [innerHTML]="title"></h2>
        <h3 class="tb_media-card__subtitle" [innerHTML]="content"></h3>
      </div>
    </div>
  </a>
</div>