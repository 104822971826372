<!-- <a [routerLink]="['/community/podcasts' | localize, slug]">
  <div class="tb_media-card tb_media-card--image">
    <div class="tb_media-card__content-top">
      <img class="tb_media-card__image" [src]="bgImg" alt="">
      <div class="tb_media-card__bg-overlay"></div>
      <div class="tb_media-card__bottom">
        <a class="tb_icon-link tb_media-card__cta tb_media-card__cta--internal-link"
          [routerLink]="['/community/podcasts' | localize, slug]">{{ 'Podcasts.Series.View' | translate }}</a>
        <span class="tb_media-card__meta"></span>
      </div>
      <div class="tb_media-card__content">
        <h4 class="tb_media-card__title" [innerHTML]="title"></h4>
        <h5 class="tb_media-card__subtitle" *ngIf="authorName">{{ 'Podcasts.Series.AuthorName' | translate }}</h5>
      </div>
    </div>
  </div>
</a> -->

<a [routerLink]="['/community/podcasts' | localize, slug]">
  <div class="tb_podcast-series-card">
    <p class="h5" [innerHTML]="title"></p>
    <div class="tb_podcast-series-card__bottom">
      <p>{{ 'Podcasts.Series.View' | translate }}</p>
    </div>
  </div>
</a>