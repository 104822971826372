import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Subject, from, Observable, of } from 'rxjs';
import Auth from '@aws-amplify/auth';
import { switchMap, catchError } from 'rxjs/operators';
import {
  UserServiceProxy,
  AuthServiceProxy,
  SSOInput,
  UtilServiceProxy,
  LogInput,
} from '@proxy/service-proxies';
import { AgeGateSubmissionService } from 'bsi-toolkit-agegate';
import { GtmService } from 'bsi-toolkit';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie';
import { TranslateService } from '@ngx-translate/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { isPlatformServer } from '@angular/common';
// import { Auth } from 'aws-amplify';

export enum AuthLogEvent {
  SignIn = 'SignIn',
  SignOut = 'SignOut',
  ResetPassword = 'ResetPassword',
  ChangePassword = 'ChangePassword',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private cognitoCookieName = 'cognito';
  private cognitoLogId = 'Cognito';

  // Fires when user is signed in or signed out
  public subjectAuthenticated: Subject<boolean> = new Subject<boolean>();

  public subjectUserInfo: Subject<any> = new Subject<any>();

  public subjectUserSign: Subject<any> = new Subject<any>();
  // Fires when user is signed out
  public subjectUserSignOut: Subject<any> = new Subject<any>();

  constructor(
    private userApi: UserServiceProxy,
    private ageGate: AgeGateSubmissionService,
    private gtm: GtmService,
    private _snackBar: MatSnackBar,
    private authService: AuthServiceProxy,
    private cookieSvc: CookieService,
    private translate: TranslateService,
    private utilSvc: UtilServiceProxy,
    @Inject(PLATFORM_ID) private platform: Object
  ) {}

  /**
   *
   * @param event
   * @param message
   */
  public logAuthAction(event: AuthLogEvent, message: any) {
    this.utilSvc
      .log(
        new LogInput({
          serviceName: this.cognitoLogId,
          methodname: event,
          customData: JSON.stringify(message),
        })
      )
      .subscribe((val) => {});
  }

  public isAuthenticated(): Observable<boolean> {
    // Always return false server side since we don't have users here
    if (isPlatformServer(this.platform)) {
      return of(false);
    }
    return from(Auth.currentAuthenticatedUser()).pipe(
      switchMap((user) => {
        return of(true);
      }),
      catchError(() => of(undefined))
    );
  }

  /**
   * Checks if a user is authenticated and first authentication subject
   */
  public checkAuthStatus() {
    // Always return false server side since we don't have users here
    if (isPlatformServer(this.platform)) {
      this.subjectAuthenticated.next(false);
    } else {
      from(Auth.currentAuthenticatedUser()).subscribe(
        (user) => {
          // console.log('user', user);
          this.subjectAuthenticated.next(true);
        },
        (err) => {
          this.subjectAuthenticated.next(false);
          // console.log('error', err);
        }
      );
    }
  }
  /**
   * Sign out of AWS
   */
  public signOut() {
    document.cookie = `${this.cognitoCookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${environment.oauthDomain};`;
    from(Auth.signOut()).subscribe(
      (data) => {
        this.subjectAuthenticated.next(false);
        this.subjectUserSignOut.next(false);
      },
      (err) => {
        // console.log('sign out err', err);
      }
    );
  }

  /**
   * Checks and return true if SSO cognito cookie is set
   */
  public hasSSOCookie(): boolean {
    return this.cookieSvc.get(this.cognitoCookieName) ? true : false;
  }

  /**
   * Set the AWS SSO Cookie to bypass hosted UI
   * @param password
   */
  // public authSSO(password: string) {
  //   // Authenitcate to SSO
  //   const input: SSOInput = new SSOInput({password:password});
  //   this.authService.sSOAuthenticate(input).subscribe(response =>{
  //     const cookie = response.find(cookie => cookie.name === this.cognitoCookieName);
  //     const expires = new Date(cookie['expires']).toUTCString();
  //     const set = `${this.cognitoCookieName}=${cookie.value};expires=${expires};path=/;domain=${environment.oauthDomain};"`;
  //     document.cookie = set;
  //   });
  // }
  public authSSO(password: string): Observable<boolean> {
    // Authenitcate to SSO
    const input: SSOInput = new SSOInput({ password: password });
    return this.authService.sSOAuthenticate(input).pipe(
      switchMap((response) => {
        const cookie = response.find(
          (cookie) => cookie.name === this.cognitoCookieName
        );
        if (cookie) {
          // const expires = new Date(cookie['expires']).toUTCString();
          const value = cookie.value.replaceAll('"', ``);

          const set = `${this.cognitoCookieName}="${value}";path=/;domain=${environment.oauthDomain}`;
          // const set = `${this.cognitoCookieName}=\"${value}\";expires=${expires};path=/;domain=${environment.oauthDomain};`;
          document.cookie = set;
          return of(this.hasSSOCookie());
        } else {
          return of(false);
        }
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }

  public signIn(
    username: string,
    password: string,
    location: string = ''
  ): Observable<SignInResponse> {
    username = username ? username.toLowerCase() : username;
    return from(
      Auth.signIn({
        username: username, // Required, the username
        password: password, // Optional, the password
      })
    ).pipe(
      switchMap((user) => {
        this.subjectAuthenticated.next(true);
        this.subjectUserSign.next(user);
        this.ageGate.setVerified(true);

        // dataLayer Push
        this.gtm.pushEvent({
          event: 'e_accountLogin',
          loginMethod: 'username and password',
          loginLocation: location,
        });

        return of({
          success: true,
          message: this.translate.instant('Auth.Service.LoggedIn'), //''
        } as SignInResponse);
      }),
      catchError((err) => {
        this.logAuthAction(AuthLogEvent.SignIn, {
          username: username,
          message: err,
        });

        this.subjectAuthenticated.next(false);
        this.subjectUserSign.next(err);
        return of({
          success: false,
          message: this.translate.instant('Auth.Service.IncorrectCredential'),
        } as SignInResponse);
      })
    );
  }

  public signUp(username: string, password: string, attributes: string[]) {
    return Auth.signUp({
      username, // Required, the username
      password,
      attributes,
    }).then((data) => {
      // console.log('signUp ', data);

      this.subjectUserInfo.next(data);
    });
  }

  public confirmSignUp(username: string, code: string) {
    from(Auth.confirmSignUp(username, code)).subscribe(
      (user) => {
        // console.log('confirmSignUp', user);

        this.subjectUserSign.next(user);
      },
      (err) => {
        // console.log('ERROR confirmSignUp', err);

        this.subjectUserSign.next(err);
      }
    );
  }

  getUserBearerTokenHeaderValue(): Observable<string | null> {
    return from(Auth.currentSession()).pipe(
      catchError((error) => {
        return of(null);
      }),
      switchMap((session: CognitoUserSession) => {
        let token;
        try {
          token = session.getIdToken().getJwtToken();
        } catch (error) {}

        if (token) return of(`Bearer ${token}`);
        else return of(null);
      })
    );
  }

  getUser(): Observable<any> {
    return this.userApi.getCurrent();
  }
}

export interface SignInResponse {
  success: boolean;
  message: string;
}
