import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loadingSubject = new Subject<boolean>();
  private loading = false;
  constructor() { }

  showLoader() {
    if (!this.loading) {
      this.loading = true;
      this.loadingSubject.next(true);
    }

  }
  hideLoader() {
    if (this.loading) {
      this.loading = false;
      this.loadingSubject.next(false);
    }
  }
}
