<!-- <div class="tb_2up-cta-section">
  <div class="tb_2up-cta-section__left col-md-offset-2">
    <div class="tb_2up-cta-section__text tb_type-group tb_type-group--reverse">
      <div class="tb_type-group__subheading">{{ 'Shared.CTA.JoinTheBlend' | translate }}</div>
      <p class="tb_type-group__copy">{{ 'Shared.CTA.JoinMessage' | translate }}</p>
    </div>
  </div>
  <div class="tb_2up-cta-section__right">
    <a class="tb_2up-cta-section__button tb_button" [routerLink]="['/join' | localize]">{{ 'Shared.CTA.Join' | translate }}</a>
  </div>
</div> -->

<div class="tb_cta-banner row">
  <div class="col-12 col-md-6 tb_cta-banner__left">
    <p class="tb_cta-banner__headline h1">{{ 'Shared.CTA.JoinTheBlend' | translate }}</p>
    <p class="tb_cta-copy">{{ 'Shared.CTA.JoinMessage' | translate }} </p>
  </div>
  <div class="col-12 col-md-6 tb_cta-banner__right">
    <button class="button-type" (click)="routeJoin()">
      {{'Header.Join' | translate}}</button>
  </div>
</div>