import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RoutingService } from '@routing/services/routing.service';
import { RoutingLocale } from '@routing/interfaces/routing';
@Component({
  selector: 'app-ie-check',
  templateUrl: './ie-check.component.html',
  styleUrls: ['./ie-check.component.scss']
})
export class IeCheckComponent implements OnInit {
  locale: RoutingLocale;
  outdateLink: string;
  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    private routingService: RoutingService
  ) { }
  isIe: boolean = false;
  ngOnInit() {
    this.locale = this.routingService.getLocale();
    if (isPlatformBrowser(this.platform)) {
      this.isIe = window.navigator.userAgent.toLowerCase().indexOf('trident') > -1;
    }

    if (this.locale.language === 'es' || this.locale.language === 'es-mx') {
      this.outdateLink = 'https://bestvpn.org/outdatedbrowser/es';
    } else {
      this.outdateLink = 'https://bestvpn.org/outdatedbrowser/en';
    }
  }
}
