<!-- <ng-template #childComponentTemplate> -->
  <p *ngIf="name === 'p'">
    <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
  </p>
  <ul *ngIf="name === 'ul'">
    <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
  </ul>
  <li *ngIf="name === 'li'">
    <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
  </li>
  <table *ngIf="name === 'table'">
    <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
  </table>
  <tbody *ngIf="name === 'tbody'">
    <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
  </tbody>
  <tr *ngIf="name === 'tr'">
    <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
  </tr>
  <td *ngIf="name === 'td'">
    <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
  </td>

  <ng-template #tempOutlet>
    <ng-content></ng-content>
  </ng-template>
<!-- </ng-template> -->