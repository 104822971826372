import { RoutingLocale, Routing } from '@routing/interfaces/routing';
import { RoutingService } from '@routing/services/routing.service';
import { TaxMarket } from '@proxy/service-proxies';
import { MarketService } from '@shared/services/market.service';
import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { get } from 'lodash';

export interface PageBase {
    onPageLoad(event: PageStatus): void;
}

export class PageStatus implements IPageStatus {
    public authenticated: boolean;
    public type: PageLoadType;
    public locale: RoutingLocale;
    public markets: TaxMarket[];
    public routeData: any;
    public queryParams: any;

    constructor(authenticated: boolean, type: PageLoadType, routeSvc: RoutingService, markets: TaxMarket[], routeSnapshot: ActivatedRouteSnapshot) {
        this.authenticated = authenticated;
        this.type = type;
        this.markets = markets;
        this.locale = routeSvc.getLocale();
        this.queryParams = get(routeSnapshot, `queryParams`);

        // We need to recrusively traverse the route object to find data
        if (routeSnapshot && routeSnapshot.children) {
            this.routeData = this.extractChildrenData(routeSnapshot.children);
        } else {
            this.routeData = {};
        }
    }

    /**
     * @todo never: change function to data objects that may be different instead of just taking the first one to contain a non empty object
     * @param snapshots
     */
    private extractChildrenData(snapshots: ActivatedRouteSnapshot[]): any {
        for (const snapshot of snapshots) {
            if (snapshot.data && Object.keys(snapshot.data).length > 0) {
                return snapshot.data;
            }
        };

        for (const snapshot of snapshots) {
            if (snapshot.children) {
                return this.extractChildrenData(snapshot.children);
            }
        };
    }
}

export interface IPageStatus {
    authenticated: boolean;
    locale: RoutingLocale;
    markets: TaxMarket[];
    type: PageLoadType;
    routeData: any;
}

export enum PageLoadType {
    Initial = 'initial',
    ParamChange = 'param-change',
    Authentication = 'authentication',
    Language = 'language'
}
