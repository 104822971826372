import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { AuthLogEvent, AuthService } from '@core/auth/auth.service';
import { CognitoService } from '@core/auth/cognito.service';
import { PageStatus } from '@core/interfaces/page-base';
import { ProfileService } from '@core/services/profile.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { RoutingService } from '@routing/services/routing.service';
import { MarketService } from '@shared/services/market.service';

@Component({
  selector: 'app-single-login',
  templateUrl: './single-login.component.html',
  styleUrls: ['./single-login.component.scss']
})
export class SingleLoginComponent implements OnInit {
  @Input('event') event: PageStatus;
  @ViewChild('loginTabGroup') loginTabGroup: MatTabGroup;

  loginForm: any;
  resetForm: any;
  emailSent = false;
  msgError = false;
  message = '';

  constructor(
    private routingService: RoutingService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private translate: TranslateService,
    private cognito: CognitoService,
    private _snackBar: MatSnackBar,
    private profileService: ProfileService,
    private marketSvc: MarketService,
    @Inject(PLATFORM_ID) private platform: Object
    ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      usercode: ['', Validators.nullValidator],
    });

    this.resetForm = this.formBuilder.group({
      forgottenEmail: ['', [Validators.required, Validators.email]]
    });
  }
  
  showForgotPasswordForm() {
    this.loginTabGroup.selectedIndex = 1;
  }

  showLoginForm() {
    this.loginTabGroup.selectedIndex = 0;
    this.emailSent = false;
  }

  
  onPasswordReset(form: NgForm) {
    const email = form.value.forgottenEmail ? form.value.forgottenEmail.toLowerCase() : form.value.forgottenEmail;
    Auth.forgotPassword(email)
      .then(data => {
        this.emailSent = true;
        // this._snackBar.open('Password reset email has been sent.', '', {
        this._snackBar.open(this.translate.instant('Auth.Reset.Success'), '', {
          duration: 5000,
          panelClass: 'tb-snackbar-info'
        });
        this.cognito.setEmail(email);
      })
      .catch(err => {
        this.emailSent = true;
        this.auth.logAuthAction(AuthLogEvent.ResetPassword,{
          username: email,
          message: err
        });
      });
  }

  onLogin(form: FormGroup) {
    this.auth.signIn(form.value.email, form.value.password).subscribe(response => {
      if (response.success) {
        // this.auth.authSSO(form.value.password);
        this.auth.authSSO(form.value.password).subscribe(set => { });
        // get current user to update last active status
        this.auth.getUser().subscribe(user =>{});

        this._snackBar.open(response.message, '', {
          duration: 5000
        });

        // set country based on profile
        if (isPlatformBrowser(this.platform)) {
          // if(this._route.snapshot.params.return) {
          //   this.routingService.handleLogInRedirect(this.localize.translateRoute(this._route.snapshot.params.return));
          // } 
          if(this.event && this.event.queryParams.return) {
            this.routingService.handleLogInRedirect(this.event.queryParams.return);
          } 
          else {
            this.marketSvc.getMarkets(true).subscribe(markets => {
              this.profileService.getProfile().subscribe(profile => {
                // this.routingService.setLanguageFromAgeGate(profile.country.toLowerCase(), 'en', markets);
                // If user's country is different from current change market
                  this.routingService.handleSignInMarket(profile.country, markets);
              });
            });
          }
        }
      } else {
        this.msgError = true;
        this.message = response.message;
      }
    });
  }

}
