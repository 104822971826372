import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgeGateService {
  public AgeGateShow: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  public showAgeGate(show: boolean): void {
    this.AgeGateShow.next(show);
  }
}
