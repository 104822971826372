import { Component, OnInit, Input } from '@angular/core';
import {
  PPDFSection,
  PBotwSection,
  File,
  PBrandParagraph,
  PCategoryParagraph,
  PTPBSection,
  PCommunitySection,
  PNewsPingPong,
  PSWPingPong,
  PSpreadsheetsSection,
  PBBPingPong,
} from '@proxy/service-proxies';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { TwoUpPageTypes } from '@core/enum/enum';
import { LinkService } from '@shared/services/link.service';

enum LinkTypes {
  Internal = 'internal',
  External = 'external',
  PDF = 'pdf',
}

class Link {
  // public linkType: MediaTypes;
  public linkImg: string;
  public linkText?: string;
  public linkRoute: string | any;
  public linkType: LinkTypes;
  // newlink is for links used with linkservice
  // need to update all links to linkSvc
  public newLink: boolean = false;

  // private Types = MediaTypes;

  constructor(
    data:
      | PBBPingPong
      | PPDFSection
      | PBotwSection
      | File
      | PBrandParagraph
      | PCategoryParagraph
      | PTPBSection
      | PCommunitySection
      | PNewsPingPong,
    private route: ActivatedRoute,
    private imgSafePipe: ImgSafePathPipe,
    private page: TwoUpPageTypes,
    private linkSvc: LinkService
  ) {
    if (data instanceof File) {
      this.setPdf(data);
    } else if (data instanceof PBBPingPong) {
      this.setNewsPingPong(data);
    } else if (data instanceof PBotwSection) {
      this.setBotw(data);
    } else if (data instanceof PBrandParagraph) {
      this.setBrand(data);
    } else if (data instanceof PCategoryParagraph) {
      this.setCategory(data);
    } else if (data instanceof PTPBSection) {
      this.setPerfectBlend(data);
    } else if (data instanceof PCommunitySection) {
      this.setCommunity(data);
    } else if (data instanceof PNewsPingPong) {
      this.setNewsPingPong(data);
    } else if (data instanceof PSWPingPong) {
      this.setNewsPingPong(data);
    } else {
      this.setDefault(data);
    }
  }

  private setNewsPingPong(data) {
    this.newLink = true;
    if (data.subPage) {
      this.linkText = 'Learn More';
      const localLink = this.linkSvc.getLink(data.subPage);
      this.linkType = LinkTypes.Internal;
      this.linkRoute = localLink.link;
    } else if (data.link.uri) {
      this.linkText = data.link.title;
      this.linkRoute = data.link.uri;
      this.linkType = LinkTypes.External;
    }
  }


  private setPdf(data) {
    this.linkText = data.meta.description;
    this.linkType = LinkTypes.PDF;
    this.linkRoute = this.imgSafePipe.transform(data, `url`);
  }

  private setBotw(data) {
    if (data.subPage) {
      switch (data.subPage._discriminator) {
        case 'Cocktail':
          this.linkType = LinkTypes.Internal;
          this.linkRoute =
            `/botw/${this.route.snapshot.params.id}/cocktails/` +
            get(data, `subPage.slug`);
          break;
        case 'PodcastSeries':
          this.linkType = LinkTypes.Internal;
          this.linkRoute = `/community/podcasts/` + get(data, `subPage.slug`);
          break;
        case 'BotwSub':
          this.linkType = LinkTypes.Internal;
          this.linkRoute =
            `/botw/${this.route.snapshot.params.id}/` +
            get(data, `subPage.slug`);
          break;
      }
    } else if (data.link.uri) {
      this.linkType = LinkTypes.External;
      this.linkRoute = data.link.uri;
      this.linkText = data.link.title;
    }
  }

  private setBrand(data) {
    if (data.subPage) {
      this.linkType = LinkTypes.Internal;
      this.linkRoute =
        `/learn/brands/${this.route.snapshot.params.id}/` +
        get(data, `subPage[0].slug`);
    }
  }

  private setCategory(data) {
    if (data.subPage) {
      this.linkType = LinkTypes.Internal;
      this.linkRoute =
        `/learn/categories/${this.route.snapshot.params.id}/` +
        get(data, `subPage[0].slug`);
    }
  }

  private setPerfectBlend(data) {
    if (this.page === TwoUpPageTypes.PerfectBlendSub) {
      if (data.link.uri) {
        this.linkType = LinkTypes.External;
        this.linkRoute = data.link.uri;
        this.linkText = data.link.title;
      } else if (data.subPage) {
        switch (data.subPage._discriminator) {
          case 'GeekOut':
            this.linkType = LinkTypes.Internal;
            this.linkRoute = `/learn/geek-out/` + get(data, `subPage.slug`);
            break;
          case 'BarflyPage':
            this.linkType = LinkTypes.Internal;
            this.linkRoute = `/news/barfly`;
            break;
          case 'News':
            this.linkType = LinkTypes.Internal;
            this.linkRoute = `/news/` + get(data, `subPage.slug`);
            break;
          case 'TPBSubPage':
            this.linkType = LinkTypes.Internal;
            if (this.page === TwoUpPageTypes.PerfectBlendSub) {
              this.linkRoute =
                `/perfect-blend/${this.route.snapshot.params.archive}/` +
                get(data, `subPage.slug`);
            }
            break;
        }
      }
    }
    if (this.page === TwoUpPageTypes.PerfectBlend) {
      if (data.subPage) {
        this.linkType = LinkTypes.Internal;
        // using linkSvc error because tbpSubPage parent is undefined
        // console.log(this.linkSvc.getLink(data.subPage));
        switch (data.subPage._discriminator) {
          case 'GeekOut':
            this.linkType = LinkTypes.Internal;
            this.linkRoute = `/learn/geek-out/` + get(data, `subPage.slug`);
            break;
          case 'BarflyPage':
            this.linkType = LinkTypes.Internal;
            this.linkRoute = `/news/barfly`;
            break;
          case 'News':
            this.linkType = LinkTypes.Internal;
            this.linkRoute = `/news/` + get(data, `subPage.slug`);
            break;
          case 'TPBSubPage':
            this.linkType = LinkTypes.Internal;
            this.linkRoute =
              `/perfect-blend/${this.route.snapshot.params.id}/` +
              get(data, `subPage.slug`);
            break;
        }
      } else if (data.link.uri) {
        this.linkType = LinkTypes.External;
        this.linkRoute = data.link.uri;
        this.linkText = data.link.title;
      }
    }
  }

  private setCommunity(data) {
    if (data.link.uri) {
      this.linkType = LinkTypes.External;
      this.linkRoute = data.link.uri;
      this.linkText = data.link.title;
    } else if (data.subPage) {
      switch (data.subPage._discriminator) {
        case 'GeekOut':
          this.linkType = LinkTypes.Internal;
          this.linkRoute = `/learn/geek-out/` + get(data, `subPage.slug`);
          break;
        case 'BarflyPage':
          this.linkType = LinkTypes.Internal;
          this.linkRoute = `/news/barfly`;
          break;
        case 'News':
          this.linkType = LinkTypes.Internal;
          this.linkRoute = `/news/` + get(data, `subPage.slug`);
          break;
        case 'TPBSubPage':
          this.linkType = LinkTypes.Internal;
          if (this.page === TwoUpPageTypes.PerfectBlendSub) {
            this.linkRoute =
              `/perfect-blend/${this.route.snapshot.params.archive}/` +
              get(data, `subPage.slug`);
          }
          break;
        case 'ResourcesPage':
          this.linkType = LinkTypes.Internal;
          this.linkRoute = 'resources';
          break;
      }
    }
  }

  private setDefault(data) {
    this.linkType = LinkTypes.Internal;
    this.linkRoute = data.link;
  }
}

@Component({
  selector: 'app-two-up-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class TwoUpLinkComponent implements OnInit {
  @Input() linkData;
  @Input() page;
  @Input() linkColor;
  link: string;
  linkDisplay;
  multipleLinks: boolean;
  newLink: boolean = false;
  isSpreadSheet: boolean = false;
  constructor(
    private route: ActivatedRoute,
    public imgSafePipe: ImgSafePathPipe,
    private linkSvc: LinkService
  ) {}

  ngOnInit() {
    if (this.linkData) {
      if (
        this.linkData instanceof PPDFSection ||
        this.linkData instanceof PSpreadsheetsSection
      ) {
        if (this.linkData instanceof PSpreadsheetsSection) {
          this.isSpreadSheet = true;
        }
        this.multipleLinks = true;
        this.linkDisplay = { links: [] };
        this.linkData.files.forEach((file) => {
          this.linkDisplay.links.push(
            new Link(
              file,
              this.route,
              this.imgSafePipe,
              this.page,
              this.linkSvc
            )
          );
        });
      } else {
        this.linkDisplay = new Link(
          this.linkData,
          this.route,
          this.imgSafePipe,
          this.page,
          this.linkSvc
        );
      }
    }
  }
}
