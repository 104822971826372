<!-- <div class="tb_hive-card" [ngClass]="{'tb_hive-card--image': textColor === textColorOptions.Light}" #card
  (click)="toggleActive(card)">
  <div class="tb_hive-card__content-top"
    [ngStyle]="{ 'background-image': 'url(' + this.imgPipe.transform(data, 'image.images[0].url')  + ')' }">
    <div class="tb_hive-card__bg-overlay" *ngIf="isOverlay"></div>

    <div class="tb_hive-card__bottom">
      <div class="tb_icon-link tb_hive-card__cta" [innerHTML]="likesCount | thousandTramsform : 1"
        (click)="toggleLike($event)" #like></div>
      <span class="tb_hive-card__meta" [innerHTML]="timeSinceCreation | timeSince" *ngIf="timeSinceCreation"></span>
    </div>
    <div class="tb_hive-card__content">
      <h2 class="tb_hive-card__title" [innerHTML]="heading"></h2>
    </div>
    <div class="tb_hive-card__top">
      <span class="tb_hive-card__category" [innerHTML]="category"></span>
      <span class="tb_hive-card__top-icon"></span>
    </div>
  </div>
  <div class="tb_hive-card__article" [innerHTML]="copy">
  </div>
</div> -->

<div class="tb-card tb-card__hive" #card (click)="toggleActive(card)">
  <div class="tb-card__wrapper">
    <div class="tb-card__hive-label text-uppercase fw-bold" * ngIf="category">
      <span [innerHTML]="category"></span>
    </div>
    <h3 class="tb-card__title text-uppercase" [innerHTML]="heading">
    </h3>
    <div class="tb-card__meta text-uppercase" (click)="toggleLike($event)">
      <div class="likes"> &hearts;
        <span class="tb_card__post-like" [innerHTML]="likesCount | thousandTramsform : 1" #like>
        </span></div>

      <span class="tb_card__post-time" [innerHTML]="timeSinceCreation | timeSince" *ngIf="timeSinceCreation">

      </span>
    </div>
  </div>
  <div class="tb-card__description">
    <div class="tb-card__content" [innerHTML]="copy">
    </div>
    <div class="tb-card__toggle-btn">
      <i class="icon"></i>
    </div>
  </div>

</div>