<ng-container *ngIf="!categoryPage">
  <p class="h3" [innerHTML]="heading"></p>
  <div class="tb-card tb-card__spotlight">
    <a [routerLink]="[linkMain | localize]" *ngIf="linkMain">
      <img appLazyLoad [src]="img" class="tb-card__thumb" alt="" width="529" height="297">
    </a>
    <a [routerLink]="[link | localize]" *ngIf="!linkMain">
      <img appLazyLoad [src]="img" class="tb-card__thumb" alt="" width="529" height="297">
    </a>
    <div class="tb-card__overlay">
      <div class="tb-card__content">
        <a [routerLink]="[linkMain | localize]" class="tb-card__btn button-type text-uppercase text-center"
          *ngIf="linkMain && learnMore">
          {{ 'Shared.CategoryCard.LearnMore' | translate }}
        </a>
        <a [routerLink]="[link | localize]" class="tb-card__btn button-type text-uppercase text-center"
          *ngIf="!linkMain && learnMore">
          {{ 'Shared.CategoryCard.LearnMore' | translate }}
        </a>
        <div class="tb-card__meta  text-uppercase">
          <a [routerLink]="[linkMain | localize, link]" *ngIf="linkMain">
            <span class="h4" [innerHTML]="title">
            </span>
          </a>
          <a [routerLink]="[link | localize]" *ngIf="!linkMain">
            <span class="h4" [innerHTML]="title">
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <p class="tb-card__description" [innerHTML]="text"></p>
</ng-container>

<ng-container *ngIf="categoryPage">
  <p class="h3" [innerHTML]="heading"></p>
  <div class="tb-card tb-card__spotlight">
    <a [routerLink]="[linkMain | localize]">
      <img appLazyLoad [src]="img" class="tb-card__thumb" alt=""  width="529" height="297">
    </a>
    <div class="tb-card__overlay">
      <div class="tb-card__content">
        <a [routerLink]="[linkMain | localize]" class="tb-card__btn button-type text-uppercase text-center">
          {{ 'Shared.CategoryCard.LearnMore' | translate }}
        </a>
        <div class="tb-card__meta  text-uppercase">
          <a [routerLink]="[linkMain | localize]">
            <span class="h4" [innerHTML]="title">
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <p class="tb-card__description" [innerHTML]="text"></p>
</ng-container>



<!-- 
<div class="wrapper-category-card" [ngClass]='{inverted: invertedBoolean, perfectBlend: perfectBlend}'>
  <div class="tb_link-card">
    <div class="tb_card__copy-category" style="padding-top:0;" *ngIf="heading">
      <h2 class="tb_card__heading" [innerHTML]="heading"></h2>
    </div>
    <a class="tb_link-card__link" [routerLink]="[linkMain | localize, link]" *ngIf="linkMain">
      <img appLazyLoad class="tb_image-card__image tb_image" [src]="img" alt="">
      <div class="tb_card__overlay">
        <h3 class="tb_card__title" [innerHTML]="title" style="margin-bottom: 1rem !important"></h3>
        <p class="tb_card__label" [innerHTML]="label" *ngIf="label">
        </p>
        <span class="tb_card__cta"><img src="../assets/images/icons/arrow-right-circle.svg" *ngIf="blueArrow"></span>
        <span class="tb_card__cta"><img src="../assets/images/icons/arrow-right-circle-gold.svg"
            *ngIf="!blueArrow"></span>
      </div>
    </a>
    <a [routerLink]="[link | localize]" class="tb_category-card__content-top" *ngIf="!linkMain">
      <img appLazyLoad class="tb_image-card__image tb_image" [src]="img" alt="">
      <div class="tb_card__overlay">
        <h2 *ngIf="header" class="tb_card__title" [innerHTML]="title" style="margin-bottom: 0 !important"></h2>
        <h3 *ngIf="!header" class="tb_card__title" [innerHTML]="title" style="margin-bottom: 0 !important"></h3>
        <p class="tb_card__label" [innerHTML]="label" *ngIf="label">
        </p>
        <span class="tb_card__cta"><img src="../assets/images/icons/arrow-right-circle.svg" *ngIf="blueArrow"></span>
        <span class="tb_card__cta"><img src="../assets/images/icons/arrow-right-circle-gold.svg"
            *ngIf="!blueArrow"></span>
      </div>
    </a>
  </div>
  <div class="tb_card__copy-category" *ngIf="linkMain && learnMore">
    <p class="tb_card__text" [innerHTML]="text"></p>
    <a class="tb_two-up-section__cta tb_icon-link"
      [routerLink]="[linkMain | localize]">{{ 'Shared.CategoryCard.LearnMore' | translate }}
      <span class="tb_icon-link__icon tb_icon-link__icon--right"><img
          src="../assets/images/icons/arrow-right-circle.svg"></span></a>
  </div>
  <div class="tb_card__copy-category" *ngIf="!linkMain && learnMore">
    <p class="tb_card__text" [innerHTML]="text"></p>
    <a class="tb_two-up-section__cta tb_icon-link"
      [routerLink]="[link | localize]">{{ 'Shared.CategoryCard.LearnMore' | translate }}
      <span class="tb_icon-link__icon tb_icon-link__icon--right"><img
          src="../assets/images/icons/arrow-right-circle.svg"></span></a>
  </div>
</div> -->