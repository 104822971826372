<div class="row uploadContainer previousUploads">
  <div class="uploads" *ngIf="previouslyUploaded">
    <div class="inputs row" *ngFor="let upload of previouslyUploaded  let i = index">
      <span class="fileLabel tb_type-group__copy">
        {{'Upload.File' | translate}}: <span id="file-upload-value">{{upload.fileName}}</span>
      </span>
    </div>
  </div>
  <div class="buttons">
    <div class="button" *ngFor="let upload of previouslyUploaded; let i = index">
      <!-- <button class="tb_button hide" (click)="destroyComponent(i)" *ngIf="uploads[i+1]">Remove</button> -->
      <svg class="icon icon-cross" (click)="removePreviousUpload(i)">
        <use xlink:href="#icon-cross">
          <symbol id="icon-cross" viewBox="0 0 32 32">
            <title>cross</title>
            <path
              d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z">
            </path>
          </symbol>
        </use>
      </svg>
    </div>
  </div>
</div>
<div class="row uploadContainer currentUploads">
  <div class="uploads">
    <ng-template #uploadContainer>
    </ng-template>
  </div>
  <div class="buttons">
    <div class="button" *ngFor="let upload of uploads; let i = index">
      <!-- <button class="tb_button hide" (click)="destroyComponent(i)" *ngIf="uploads[i+1]">Remove</button> -->
      <svg class="icon icon-cross" (click)="destroyComponent(i)">
        <use xlink:href="#icon-cross">
          <symbol id="icon-cross" viewBox="0 0 32 32">
            <title>cross</title>
            <path
              d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z">
            </path>
          </symbol>
        </use>
      </svg>
    </div>
  </div>
</div>