<div>
    <a class="tb_spotlight-card__cta tb_icon-link tb_icon-link--reverse" style="display: block;" [routerLink]="link">
        <div class="tb_bordered-image tb_bordered-image--gray">
            <img alt="" class="tb_bordered-image__image img-fluid" appLazyLoad [src]="img"></div>
    </a>

    <div class="tb_card__copy-default tb_card__copy-default--reverse">
        <h3 class="h3" [innerHTML]="heading"></h3>
        <p class="tb_card__text" [innerHTML]="body">
        </p>
        <div class="tb_card__catlabel tb_catlabel"><a class="tb_catlabel__link tb_link"
                href="/en-us/learn/categories/undefined"></a>

        </div>
        <a class="text-link" style="display: block;" [routerLink]="link"> {{ 'Shared.TwoUp.LearnMore' | translate }}</a>

    </div>
</div>