import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textTruncate'
})

export class TextTruncatePipe implements PipeTransform {

  transform(value: string, args: string, thing: string): string {
    // const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const limit = parseInt(args, 10);
    // const trail = args.length > 1 ? args[1] : '...';
    const trail = thing ? thing : '...';
    //console.log(value);
    //console.log(value.substring(0, limit));
    return value.length > limit ? value.substring(3, limit) + trail : value;
  }
}

