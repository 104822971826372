import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'imgUrl'
})
export class ImgUrlPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return value && value.startsWith(environment.assetUrl) ? value : `${environment.assetUrl}${value}`;
  }

}
