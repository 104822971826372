import { Component, Input } from '@angular/core';
import { Podcast } from '@proxy/service-proxies';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import * as moment from 'moment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@routing/services/routing.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { PageBase, PageStatus } from '@core/interfaces/page-base';
import { PageComponentBase } from '@core/classes/page-component-base';
import { PageService } from '@shared/services/page.service';

@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.scss'],
})
export class PodcastComponent extends PageComponentBase implements PageBase {
  @Input() data: Podcast;
  podcastContent: Podcast;
  podcastImg: string;
  isPaused: boolean;
  dateFormat = 'M-D-YYYY';
  hideCard = false;
  closeResult: string;

  constructor(
    protected routingService: RoutingService,
    protected router: ActivatedRoute,
    public imgSafePipe: ImgSafePathPipe,
    protected auth: AuthService,
    protected localizeSvc: LocalizeRouterService,
    private modalService: NgbModal,
    protected pageSvc: PageService
  ) {
    super(pageSvc);
  }

  onPageLoad(event: PageStatus) {
    this.isPaused = true;
    this.podcastContent = this.data;
    this.podcastImg = this.imgSafePipe.transform(
      this.podcastContent,
      `image['images'][0].link.default`
    );
  }

  formatDate(): string {
    return moment(this.podcastContent.date).format('MMM Do, YYYY');
  }

  open(vidName) {
    this.modalService
      .open(vidName, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        centered: true,
        windowClass: 'mainVid',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
