import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
} from '@angular/core';
import {
  MediaFileTypes,
  UploadComponent,
} from '@shared/upload/upload.component';

@Component({
  selector: 'app-multi-upload',
  templateUrl: './multi-upload.component.html',
  styleUrls: ['./multi-upload.component.scss'],
})
export class MultiUploadComponent implements OnInit, AfterViewInit {
  @Input() timeLimit;
  @Input() uploadsAllowed: number;
  @Input() previouslyUploaded;
  @Input() fileType: MediaFileTypes;
  @Output() filesUploadedOutput = new EventEmitter<
    { drupalFileName: string; fileName: any }[]
  >();
  @ViewChild('uploadContainer', { read: ViewContainerRef })
  private entry: ViewContainerRef;

  public uploads: ComponentRef<UploadComponent>[] = [];
  public filesUploaded: { drupalFileName: string; fileName: any }[] = [];

  constructor(private resolver: ComponentFactoryResolver) {}

  ngOnInit() {
    // created to prevent issue with createComponent if uploadAllowed is undefined
    if (!this.uploadsAllowed) {
      this.uploadsAllowed = 100;
    }
  }

  ngAfterViewInit() {
    this.createComponent();
    if (this.previouslyUploaded) {
      this.uploadsAllowed -= this.previouslyUploaded.length;
      this.filesUploaded = [...this.previouslyUploaded];
      this.filesUploadedOutput.emit(this.filesUploaded);
    }
  }

  createComponent() {
    if (this.uploads.length >= 1) {
      this.mapFilesUploaded();
    }
    const factory = this.resolver.resolveComponentFactory(UploadComponent);
    const component = this.entry.createComponent(factory);
    component.instance.multiUpload = 'true';
    component.instance.maxLength = this.timeLimit;
    component.instance.fileType = this.fileType;
    component.instance.fileUploaded.subscribe((data) => {
      this.uploads.push(component);
      // prevents upload componenets from being created if uploads array length reaches uploads allowed
      if (this.uploadsAllowed > this.uploads.length) {
        this.createComponent();
      } else {
        this.mapFilesUploaded();
      }
    });
  }

  destroyComponent(index) {
    this.uploads[index].destroy();
    this.uploads = this.uploads.filter(
      (x) => this.uploads.indexOf(x) !== index
    );
    this.mapFilesUploaded();
    // is there is an uploadsAllowed and the last of the uploads is deleted create a new upload component
    if (this.uploads.length === this.uploadsAllowed - 1) {
      this.createComponent();
    }

    // once the limit is reached 3
    // delete happens uploads becomes less than three create another component
  }

  removePreviousUpload(index) {
    this.previouslyUploaded[index] = { drupalFileName: '', fileName: '' };
    this.previouslyUploaded = this.previouslyUploaded.filter(
      (x) => x.drupalFileName !== ''
    );
    this.filesUploaded = [...this.previouslyUploaded];
    this.filesUploadedOutput.emit(this.filesUploaded);
    this.uploadsAllowed += 1;
    if (this.uploads.length === this.uploadsAllowed - 1) {
      this.createComponent();
    }
  }

  // need to edit so map and filter don't need to be used
  mapFilesUploaded() {
    this.filesUploaded = [];
    this.filesUploaded = this.uploads
      .map((upload) => {
        if (upload.instance.drupalFileName) {
          return {
            drupalFileName: upload.instance.drupalFileName,
            fileName: upload.instance.upload.file.name,
          };
        }
      })
      .filter((x) => {
        return x !== undefined;
      });
    if (this.previouslyUploaded) {
      this.filesUploaded = [...this.filesUploaded, ...this.previouslyUploaded];
    } else {
      this.filesUploaded = [...this.filesUploaded];
    }
    this.filesUploadedOutput.emit(this.filesUploaded);
  }

  reset() {
    this.previouslyUploaded = [];
    this.uploads = [];
    this.entry.clear();
    this.createComponent();
  }
}
