<app-surface-section [color]="SurfaceColors.Gray800" [goldBorder]="border">
  <app-template-section [center]="true" [paddingVal]="'1rem'">
    <div class="row" style="display: flex; justify-content: center; align-items:center; margin: 1rem 2rem"
      *ngIf="heading">
      <h6 class="tb_type-group__subheading" style="color: white" *ngIf="heading" [innerHTML]="heading">
      </h6>
      <div *ngIf="copy">
        <p class="tb_type-group__copy text-center" style="color: white" *ngFor="let paragraph of copy"
          [innerHTML]="paragraph.copy">
        </p>
      </div>
    </div>
    <div class="row highlightedRow hide">
      <img class='img' [src]="highlightedItem.src" alt="" *ngIf="highlightedItem.type === 'img'">
      <div class="" style="display:flex; justify-content: center; background: rgba(23, 28, 28, 0.8); width: 100%"
        *ngIf="highlightedItem.type === 'video'">
        <div style="position:relative;padding-top:50.25%; width:90%; height: 90%">
          <iframe [src]="highlightedItem.src | safe: 'resourceUrl'" frameborder="0" allowfullscreen
            style="position:absolute;top:5%;left:5%;width:90%;height:90%;"></iframe>
        </div>
      </div>
    </div>
    <ng-container *ngIf="data.length >= 2">
      <div class="carousel" *ngIf="carouselItems">
        <ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="carouselItems">
          <div *nguCarouselDef="let item;" class="item" style="text-align: center">
            <div *ngIf="item.type === 'img'" [ngStyle]="{ 'background-image': 'url(' + item.src + ')' }"
              (click)="changeHighlightedSlide(item)"></div>
            <!-- <img [src]="item.src" alt="" *ngIf="item.type === 'img'" (click)="changeHighlightedSlide(item)"> -->

            <div class="videoCover" (click)="changeHighlightedSlide(item)">
              <div class="iframeContainer"
                style="display:flex; justify-content: center; background: rgba(23, 28, 28, 0.8); width:100%"
                *ngIf="item.type === 'video'">
                <div style="position:relative;padding-top:50.25%; width:90%; height: 90%">
                  <iframe [src]="item.src | safe: 'resourceUrl'" frameborder="0" allowfullscreen
                    style="position:absolute;top:5%;left:5%;width:90%;height:90%;"></iframe>
                </div>
              </div>
            </div>
          </div>
          <button NguCarouselPrev class="leftRs tb_carousel-card__prev" [style.opacity]="myCarousel.isFirst ? 0.5:1"
            *ngIf="isArrows"></button>
          <button NguCarouselNext class="rightRs tb_carousel-card__next" [style.opacity]="myCarousel.isLast ? 0.5:1"
            *ngIf="isArrows"></button>
        </ngu-carousel>
      </div>
    </ng-container>
  </app-template-section>
</app-surface-section>