import { Component, OnInit, Input } from '@angular/core';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { get } from 'lodash';
@Component({
  selector: 'app-service-directory-card',
  templateUrl: './service-directory-card.component.html',
  styleUrls: ['./service-directory-card.component.scss']
})
export class ServiceDirectoryCardComponent implements OnInit {
  @Input() data;
  title: string;
  content: string;
  link: string;
  constructor(public imgSafePipe: ImgSafePathPipe) { }

  ngOnInit() {
    this.title = get(this.data, `heading.value`);
    this.content = get(this.data, `teaser.value`);
    this.link = get(this.data, `slug`);
  }

}
