import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef, PLATFORM_ID, Inject } from '@angular/core';
import { get } from 'lodash';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { HiveMindLikesListingResponse, HiveMindServiceProxy, HiveMindLikeInput } from '@proxy/service-proxies';
import { ComponentBase } from '@core/classes/component-base';
import { ActiveCardService } from '@modules/hive-mind/services/active-card.service';
import { isPlatformBrowser } from '@angular/common';

export enum TextColor {
  Dark = 'Dark',
  Light = 'Light'
}
@Component({
  selector: 'app-hive-card',
  templateUrl: './hive-card.component.html',
  styleUrls: ['./hive-card.component.scss']
})
export class HiveCardComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() data;
  @Input() index;
  @Input() likes;
  @ViewChild('card') card: ElementRef;
  @ViewChild('like') like: ElementRef;
  public img: string;
  public heading: string;
  public copy: string;
  public category: string;
  public timeSinceCreation: string;
  private likesData: HiveMindLikesListingResponse;
  public likesCount: number;
  public textColor: TextColor;
  public textColorOptions = TextColor;
  public isOverlay: boolean;
  private likeId: string;
  private userHasLiked: boolean;
  private cardId: string;
  constructor(public imgPipe: ImgSafePathPipe, private hiveMindApi: HiveMindServiceProxy, private cardService: ActiveCardService, @Inject(PLATFORM_ID) private platform: Object) {
    super();
  }

  ngOnInit() {
    const cardData = this.data;

    this.img = get(cardData, `image.images[0].link.default`);
    this.isOverlay = get(cardData, `image.images[0].link.default`) ? true : false;
    this.heading = get(cardData, `heading`);
    this.copy = get(cardData, `description`);
    this.category = get(cardData, `category.name`);
    // const creationTime = get(cardData, `published`);
    // this.timeSinceCreation = creationTime.fromNow();
    // this.timeSinceCreation = moment.duration(currentMoment.diff(creationTime));
    this.timeSinceCreation = get(cardData, `published`);

    this.textColor = get(cardData, `textColor`);

    this.cardId = get(cardData, `id`);

    this.cardService.cardSelected.subscribe(id => {
      if (this.cardId === id) {
        this.card.nativeElement.classList.contains('active') ? this.card.nativeElement.classList.remove('active') : this.card.nativeElement.classList.add('active');
        // this.card.nativeElement.classList.add('active');
        this.scrollIntoView(this.card.nativeElement);
      } else {
        if (this.card.nativeElement.classList.contains('active')) {
          this.card.nativeElement.classList.remove('active');
        }
      }
    });
  }

  ngOnChanges(changes) {
    if (changes.likes) {
      const likes = this.likes;
      if (likes) {
        this.likesData = this.likes[this.index];
        this.likesCount = get(this.likesData, `count`);
        // this.likesCount = 9849651968416;
        this.likeId = get(this.likesData, `id`);
        this.userHasLiked = get(this.likesData, `liked`);
        if (this.like && this.userHasLiked) {
          this.like.nativeElement.classList.add('liked');
        }
      }
    }
  }

  toggleLike(event) {
    event.stopPropagation();
    if (this.likeId) {
      if (this.userHasLiked) {
        this.unSub(this.hiveMindApi.like(new HiveMindLikeInput({ id: this.likeId, like: false }))).subscribe(() => { });
        this.likesCount--;
      } else {
        this.unSub(this.hiveMindApi.like(new HiveMindLikeInput({ id: this.likeId, like: true }))).subscribe(() => { });
        this.likesCount++;
      }
      this.userHasLiked = !this.userHasLiked;
      this.like.nativeElement.classList.toggle('liked');
    }
  }

  scrollIntoView(el: HTMLElement) {
    if (isPlatformBrowser(this.platform)) {
      setTimeout(() => {
        const yOffset = -134;
        const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo(0, y);
      }, 0);
    }
  }

  toggleActive(target) {
    target.classList.toggle('tb-card__is-open');
  }



}
