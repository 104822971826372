<a class="text-link" [routerLink]="linkDisplay.linkRoute"
  *ngIf="linkDisplay.linkType === 'internal' && linkDisplay.newLink" [ngClass]="{gold: linkColor === 'gold'}">
  <ng-container *ngIf="!linkDisplay.linkText">
    {{ 'Shared.TwoUp.LearnMore' | translate }}
  </ng-container>
  <ng-container *ngIf="linkDisplay.linkText">
    <span [innerHTML]="linkDisplay.linkText"></span>
  </ng-container>
  <span class="text-icon" *ngIf="linkColor !== 'gold'">
    <img src="./assets/images/icons/arrow-right-circle.svg">
  </span>
  <span class="text-icon" *ngIf="linkColor === 'gold'">
    <img src="./assets/images/icons/arrow-right-circle-gold.svg">
  </span>
</a>

<a class="text-link" [routerLink]="[ linkDisplay.linkRoute | localize]"
  *ngIf="linkDisplay.linkType === 'internal' && !linkDisplay.newLink" [ngClass]="{gold: linkColor === 'gold'}">
  <ng-container *ngIf="!linkDisplay.linkText">
    {{ 'Shared.TwoUp.LearnMore' | translate }}
  </ng-container>
  <ng-container *ngIf="linkDisplay.linkText">
    <span [innerHTML]="linkDisplay.linkText"></span>
  </ng-container>
  <span class="text-icon" *ngIf="linkColor !== 'gold'">
    <img src="./assets/images/icons/arrow-right-circle.svg" width="26" height="26">
  </span>
  <span class="text-icon" *ngIf="linkColor === 'gold'">
    <img src="./assets/images/icons/arrow-right-circle-gold.svg">
  </span>
</a>

<a class="text-link" [href]="linkDisplay.linkRoute" *ngIf="linkDisplay.linkType === 'external'" target="_blank"
  [ngClass]="{gold: linkColor === 'gold'}">
  <ng-container *ngIf="!linkDisplay.linkText">
    {{ 'Shared.TwoUp.LearnMore' | translate }}
  </ng-container>
  <ng-container *ngIf="linkDisplay.linkText">
    <span [innerHTML]="linkDisplay.linkText"></span>
  </ng-container>
  <span class="text-icon" *ngIf="linkColor !== 'gold'">
    <img src="./assets/images/icons/arrow-right-circle.svg">
  </span>
  <span class="text-icon" *ngIf="linkColor === 'gold'">
    <img src="./assets/images/icons/arrow-right-circle-gold.svg">
  </span>
</a>

<ng-container *ngIf="multipleLinks">
  <div class="multipleLinks">
  <a class="text-link" [href]="link.linkRoute" *ngFor="let link of linkDisplay.links" target="_blank"
    [ngClass]="{gold: linkColor === 'gold'}">
    <ng-container *ngIf="!link.linkText">
      {{ 'Shared.TwoUp.LearnMore' | translate }}
    </ng-container>
    <ng-container *ngIf="link.linkText">
      <span [innerHTML]="link.linkText"></span>
    </ng-container>
    <ng-container *ngIf="!isSpreadSheet">
      <span class="pdf-link" *ngIf="linkColor !== 'gold'">
        <img src="./assets/images/icons/pdf-blue.svg">
      </span>
      <span class="pdf-link" *ngIf="linkColor === 'gold'">
        <img src="./assets/images/icons/pdf-gold.svg">
      </span>
    </ng-container>
    <ng-container *ngIf="isSpreadSheet">
      <svg class="icon icon-file-text" [ngClass]="{goldIcon: linkColor === 'gold'}">
        <use xlink:href="#icon-file-text">
          <symbol id="icon-file-text" viewBox="0 0 32 32">
            <path
              d="M27 0h-24c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h24c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3zM26 28h-22v-24h22v24zM8 14h14v2h-14zM8 18h14v2h-14zM8 22h14v2h-14zM8 10h14v2h-14z">
            </path>
          </symbol>
        </use>
      </svg>
    </ng-container>
  </a>
</div>
</ng-container>