import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { SurfaceColors, TwoUpPageTypes, TwoUpPageColors } from '@core/enum/enum';
import { PageBase, PageStatus, PageLoadType } from '@core/interfaces/page-base';
import { PageService } from '@shared/services/page.service';

interface LoadRequest {
    firstLoad: boolean;
    type: PageLoadType;
}

@Component({
    template: ``
})
export class PageComponentBase implements OnInit, OnDestroy, PageBase {
    public ngUnsubscribe = new Subject();
    public SurfaceColors = SurfaceColors;
    public TwoUpPageTypes = TwoUpPageTypes;
    public TwoUpPageColors = TwoUpPageColors;

    private initialized: boolean = false;

    constructor(
        protected pageSvc: PageService
    ) { }

    public unSub(call: Observable<any>): Observable<any> {
        return call.pipe(takeUntil(this.ngUnsubscribe));
    }

    public ngOnInit() {
        this.unSub(this.pageSvc.pageReload).subscribe((event: PageStatus) => {
            if (event.type === PageLoadType.Initial && !this.initialized) {
                this.initialized = true;
                this.onPageLoad(event);
            } else if (event.type !== PageLoadType.Initial) {
                this.onPageLoad(event);
            }
        });

        this.pageSvc.init();
    }

    onPageLoad(event) {

    }

    unSubscribeAll() {
        this.ngUnsubscribe.next({});
        this.ngUnsubscribe.complete();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next({});
        this.ngUnsubscribe.complete();
    }
}
