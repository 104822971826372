import { DOCUMENT } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { PageComponentBase } from '@core/classes/page-component-base';
import { PageBase, PageStatus } from '@core/interfaces/page-base';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UtilServiceProxy } from '@proxy/service-proxies';
import { RoutingService } from '@routing/services/routing.service';
import { ConfigKeys, ConfigService } from '@shared/services/config.service';
import { MarketService } from '@shared/services/market.service';
import { PageService } from '@shared/services/page.service';
import { TranslationExtService } from '@shared/services/translation-ext.service';
import { AgeGateSubmissionService } from 'bsi-toolkit-agegate';
import { SingleAgeGateService } from './single-age-gate.service';

@Component({
  selector: 'app-single-age-gate',
  templateUrl: './single-age-gate.component.html',
  styleUrls: ['./single-age-gate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SingleAgeGateComponent extends PageComponentBase implements PageBase {
  public show = false;
  public international = false;
  public preselected = ['US', 'AT', 'AU', 'DE','IN','NZ'];
  private currentCountry = 'us';
  private ageGatePassed: boolean = false;

  public termLink: string;
  public privacyLink: string;

  constructor(
    private agegate: AgeGateSubmissionService,
    @Inject(DOCUMENT) private _document,
    protected auth: AuthService,
    protected routingService: RoutingService,
    private utilApi: UtilServiceProxy,
    private singleAGSvc: SingleAgeGateService,
    private translateSvc: TranslationExtService,
    protected router: ActivatedRoute,
    protected marketSvc: MarketService,
    protected localizeSvc: LocalizeRouterService,
    protected pageSvc: PageService,
    private configSvc: ConfigService
  ) {
    super(pageSvc);

    this.ageGatePassed = !this.agegate.enabled();
  }

  onPageLoad(event: PageStatus) {
    if (!this.ageGatePassed) {
      // Only US should have correct date format
      this.international = this.isInternational(this.routingService.getLocale().market);
      
      this.initSingleAgeGate();
    }
  }

  close() {
    this.singleAGSvc.showAgeGate(false);
  }

  private initSingleAgeGate() {
    const locale = this.routingService.getLocale();

    // Handles display of age gate component
    this.singleAGSvc.AgeGateShow.subscribe(show => {
      this.show = show;
      if (show) {
        this._document.body.classList.add('noScroll');
        this._document.body.classList.remove('scrollImportant');
      } else {
        this._document.body.classList.remove('noScroll');
        this._document.body.classList.add('scrollImportant');
      }
    });

    // Init country change listener
    this.initCountryChange();

    // Handle events with age gate
    this.agegate.show.subscribe(res => {

      // Translate countries if need be
      if (locale.language !== 'en') {
        this.agegate.setCountryNamesTranslation(this.translateSvc.getCountriesAgeGate());
      }
      // Set the country code
      this.agegate.setCountryByCode(this.currentCountry);

      // Handle actions by age gate
      if (res.submitUnderage) {
        // Go to drink smart youngin
        this._document.location.href = 'https://www.drinksmart.com';
      } else if (!res.show) {
        this.ageGatePassed = true;
        this.singleAGSvc.showAgeGate(false);
      }
    });

    // // Get user's country from API call
    this.utilApi.countryCode().subscribe(code => {
      if (this.routingService.isGlobal()) {
        // If global use IP based
        this.currentCountry = code;
      } else {
        // Else use the market from url
        this.currentCountry = this.routingService.getLocale().market;
      }
      this.agegate.setCountryByCode(this.currentCountry);
      this.handleCountry(this.currentCountry);
    });
  }

  private isInternational(country: string){
    return country.toLowerCase() !== 'us';
  }

  private initCountryChange() {
    // handle country switching and dates
    this.agegate.countryChanged.subscribe(country => {
      // If country changed
      if (this.currentCountry.toLowerCase() !== country.country.code.toLowerCase()) {
        this.currentCountry = country.country.code;

        // If country is changed to an active market route
        this.handleCountry(country.country.code);
      }
    });
  }

  private handleCountry(code: string) {
    // Check for international
    this.international = this.isInternational(code);
    this.handleComplianceLinks();
  }

  private handleComplianceLinks(){
    this.configSvc.getConfigurations([ConfigKeys.TermsLink, ConfigKeys.PrivacyLink]).subscribe(links =>{
      this.termLink = links[ConfigKeys.TermsLink];
      this.privacyLink = links[ConfigKeys.PrivacyLink];
    });
  }

}

