import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthServiceProxy, SSOInput } from '@proxy/service-proxies';
import { AuthService } from '@core/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  public password;
  errorMessage: string;

  constructor(
    public dialogRef: MatDialogRef<PasswordComponent>,
    public authSvc: AuthService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  public submit() {
    this.errorMessage = '';
    this.authSvc.authSSO(this.password).subscribe(set => {
      if (set) {
        this.dialogRef.close(true);
      } else {
        // show bad password message
        this.errorMessage = this.translate.instant('Shared.Password.IncorrectPassword');//'Incorrect Password';
      }
    });
  }

}
