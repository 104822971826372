<div class="user-registration container">
  <p class="label headline">{{ 'Account.Join.JoinCommunity' | translate }}</p>
  <img
    src="/assets/images/the-blend-black.webp"
    class="img-fluid hide-mobile"
    alt=""
  />
  <!-- Us Market Form -->
  <!-- First Name US -->
  <ng-container *ngIf="isUS">
    <form
      class="row US"
      [formGroup]="userForm"
      *ngIf="userForm"
      (ngSubmit)="onSubmit()"
    >
      <!-- Country -->
      <div class="tb_input--select form-field-container col-12">
        <label class="label" for="country"
          >{{ 'Account.Form.Country' | translate }}*</label
        >
        <select
          id="country"
          [(ngModel)]="userCountry"
          (ngModelChange)="onCountryChange($event)"
          [ngModelOptions]="{ standalone: true }"
          tabindex="1"
        >
          <option [value]="0" disabled selected>
            {{ 'Account.Form.CountrySelect' | translate }}
          </option>
          <option *ngFor="let country of countries" [ngValue]="country">
            {{ country.name }}
          </option>
        </select>
        <div class="user-registration__error label">
          <div *ngIf="userForm.controls.country.errors?.required">
            {{ 'Account.Form.CountryRequired' | translate }}
          </div>
        </div>
      </div>
      <div class="tb_input form-field-container col-6">
        <label class="label" for="first-name"
          >{{ 'Account.Form.FirstName' | translate }}*</label
        >
        <input
          type="text"
          id="first-name"
          placeholder="{{ 'Account.Form.FirstName' | translate }}"
          formControlName="firstName"
          required
          tabindex="2"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.firstName.errors &&
            userForm.controls.firstName.dirty
          "
        >
          <div *ngIf="userForm.controls.firstName.errors.required">
            {{ 'Account.Form.FirstNameRequired' | translate }}
          </div>
        </div>
      </div>
      <!-- Last Name US -->
      <div class="tb_input form-field-container col-6">
        <label class="label" for="last-name"
          >{{ 'Account.Form.LastName' | translate }}*</label
        >
        <input
          type="text"
          id="last-name"
          placeholder="{{ 'Account.Form.LastName' | translate }}"
          formControlName="lastName"
          required
          tabindex="3"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.lastName.errors &&
            userForm.controls.lastName.dirty
          "
        >
          <div *ngIf="userForm.controls.lastName.errors.required">
            {{ 'Account.Form.LastNameRequired' | translate }}
          </div>
        </div>
      </div>
      <!-- Email US -->
      <div class="tb_input form-field-container col-12">
        <label class="label" for="email"
          >{{ 'Account.Form.Email' | translate }}*</label
        >
        <input
          type="email"
          id="email"
          placeholder="{{ 'Account.Form.Email' | translate }}"
          formControlName="email"
          required
          tabindex="4"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.email.errors && userForm.controls.email.dirty
          "
        >
          <div *ngIf="userForm.controls.email.errors.required">
            {{ 'Account.Form.EmailRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.get('email').hasError('email') &&
              userForm.get('email').touched
            "
          >
            {{ 'Account.Form.ValidEmail' | translate }}
          </div>
        </div>
      </div>
      <!-- Password -->
      <div class="tb_input form-field-container col-sm-12 col-md-6">
        <label class="label" for="password"
          >{{ 'Account.Form.Password' | translate }}*</label
        >
        <input
          type="password"
          id="password"
          placeholder="{{ 'Account.Form.Password' | translate }}"
          formControlName="password"
          required
          tabindex="5"
        />
        <div
          class="user-registration__error--password label show-tablet"
          *ngIf="
            userForm.controls.password.errors &&
            userForm.controls.password.dirty
          "
        >
          <div
            *ngIf="
              userForm.get('password').hasError('required') &&
              userForm.get('password').touched
            "
          >
            {{ 'Account.Form.PasswordRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.get('password').hasError('pattern') &&
              userForm.get('password').touched
            "
          >
            {{ 'Account.Form.PasswordComplexity' | translate }}
          </div>
        </div>
      </div>
      <!-- Password Confirm -->
      <div class="tb_input form-field-container col-sm-12 col-md-6">
        <label class="label" for="confirmPassword"
          >{{ 'Account.Form.PasswordConfirm' | translate }}*</label
        >
        <input
          type="password"
          id="confirmPassword"
          placeholder="{{ 'Account.Form.PasswordConfirm' | translate }}"
          formControlName="confirmPassword"
          required
          tabindex="6"
        />
        <div
          class="user-registration__error--password label show-tablet"
          *ngIf="
            userForm.controls.confirmPassword.errors &&
            userForm.controls.confirmPassword.dirty
          "
        >
          <div
            *ngIf="
              userForm.get('confirmPassword').hasError('required') &&
              userForm.get('confirmPassword').touched
            "
          >
            {{ 'Account.Form.ConfirmPasswordRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.get('confirmPassword').hasError('passwordMismatch') &&
              userForm.get('confirmPassword').touched
            "
          >
            {{ 'Account.Form.PasswordMatch' | translate }}
          </div>
        </div>
      </div>
      <div
        class="user-registration__error--password label hide-tablet col-12"
        *ngIf="
          userForm.controls.password.errors && userForm.controls.password.dirty
        "
      >
        <div
          *ngIf="
            userForm.get('password').hasError('required') &&
            userForm.get('password').touched
          "
        >
          {{ 'Account.Form.PasswordRequired' | translate }}
        </div>
        <div
          *ngIf="
            userForm.get('password').hasError('pattern') &&
            userForm.get('password').touched
          "
        >
          {{ 'Account.Form.PasswordComplexity' | translate }}
        </div>
      </div>
      <div
        class="user-registration__error--password label col-12 hide-tablet"
        *ngIf="
          userForm.controls.confirmPassword.errors &&
          userForm.controls.confirmPassword.dirty
        "
      >
        <div
          *ngIf="
            userForm.get('confirmPassword').hasError('required') &&
            userForm.get('confirmPassword').touched
          "
        >
          {{ 'Account.Form.ConfirmPasswordRequired' | translate }}
        </div>
        <div
          *ngIf="
            userForm.get('confirmPassword').hasError('passwordMismatch') &&
            userForm.get('confirmPassword').touched
          "
        >
          {{ 'Account.Form.PasswordMatch' | translate }}
        </div>
      </div>
      <!-- End Pasword -->

      <!-- State -->
      <div class="tb_input form-field-container col-6">
        <label class="label" for="state"
          >{{ 'Account.Form.StateProvince' | translate }}*</label
        >
        <input
          type="text"
          id="state"
          placeholder="{{ 'Account.Form.StateProvince' | translate }}"
          formControlName="state"
          required
          tabindex="7"
        />
      </div>
      <!-- Postcode -->
      <div class="tb_input form-field-container col-6">
        <label class="label" for="zipcode"
          >{{ 'Account.Form.PostCode' | translate }}*</label
        >
        <input
          type="text"
          id="zipcode"
          placeholder="{{ 'Account.Form.PostCode' | translate }}"
          formControlName="zipcode"
          required
          tabindex="8"
        />
      </div>
      <!-- End Postcode -->

      <div
        class="user-registration__error--password label col-12"
        *ngIf="
          userForm.controls.state.errors?.required &&
          userForm.controls.state.dirty
        "
      >
        {{ 'Account.Form.StateProvinceRequired' | translate }}
      </div>
      <div
        class="user-registration__error--password label col-12"
        *ngIf="
          userForm.controls.zipcode.errors?.required &&
          userForm.controls.zipcode.dirty
        "
      >
        {{ 'Account.Form.PostCodeRequired' | translate }}
      </div>

      <!-- DOB -->

      <div class="col-12">
        <label class="label dob" for="day"
          >{{ 'Account.Form.Birthday' | translate }}*</label
        >
      </div>
      <div class="tb_input form-field-container col-4">
        <input
          type="number"
          min="1"
          max="12"
          id="month"
          placeholder="{{ 'Account.Form.Month' | translate }}"
          formControlName="dobMonth"
          required
          tabindex="9"
        />
      </div>
      <div class="tb_input form-field-container col-4">
        <input
          type="number"
          id="day"
          min="1"
          max="31"
          placeholder="{{ 'Account.Form.Day' | translate }}"
          formControlName="dobDay"
          required
          tabindex="10"
        />
      </div>
      <div class="tb_input form-field-container col-4">
        <input
          type="number"
          id="year"
          min="1900"
          placeholder="{{ 'Account.Form.Year' | translate }}"
          formControlName="dobYear"
          required
          tabindex="11"
        />
      </div>
      <div class="col-12">
        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobMonth.errors &&
            userForm.controls.dobMonth.dirty
          "
        >
          <div *ngIf="userForm.controls.dobMonth.errors.required">
            {{ 'Account.Form.MonthRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.controls.dobMonth.errors.min ||
              userForm.controls.dobMonth.errors.max
            "
          >
            {{ 'Account.Join.ValidMonth' | translate }}
          </div>
        </div>
        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobDay.errors && userForm.controls.dobDay.dirty
          "
        >
          <div *ngIf="userForm.controls.dobDay.errors.required">
            {{ 'Account.Form.DayRequired' | translate }}
          </div>
        </div>
        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobDay.errors && userForm.controls.dobDay.dirty
          "
        >
          <div
            *ngIf="
              userForm.controls.dobDay.errors.min ||
              userForm.controls.dobDay.errors.max
            "
          >
            {{ 'Account.Join.ValidDay' | translate }}
          </div>
        </div>
        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobYear.errors && userForm.controls.dobYear.dirty
          "
        >
          <div *ngIf="userForm.controls.dobYear.errors.required">
            {{ 'Account.Form.YearRequired' | translate }}
          </div>
          <div *ngIf="userForm.controls.dobYear.errors.min">
            {{ 'Account.Join.ValidYear' | translate }}
          </div>
        </div>
      </div>
      <!-- End DOB -->
      <!-- Affiliate -->
      <div class="tb_input form-field-container col-12">
        <label class="label" for="last-name">Affiliate Code (Optional)</label>
        <input
          type="text"
          id="last-name"
          placeholder="Affiliate Code"
          formControlName="affiliate"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.affiliate.errors &&
            userForm.controls.affiliate.dirty
          "
        ></div>
      </div>
      <!-- End Affiliate -->
      <p class="requireMessage">
        {{ 'Account.Join.RequiredFields' | translate }}
      </p>
      <!-- Checkboxes -->
      <div class="col-12 tb_checkbox">
        <input
          type="checkbox"
          id="community"
          formControlName="community"
          tabindex="12"
        />
        <!-- <label for="community">
          Yes, I want to be a part of The Blend’s global community.*</label> -->
        <label for="community">
          {{ 'Account.Join.OptInPrivacy' | translate
          }}<a href="{{ privacyLink }}" target="_blank">{{
            'Footer.Privacy' | translate
          }}</a
          >*
        </label>
      </div>
      <div class="col-12 tb_checkbox">
        <input type="checkbox" formControlName="communication" tabindex="13" />
        <label for="communication">
          {{ 'Account.Join.OptInCommunication' | translate }}🤗</label
        >
      </div>
      <div class="col-12 tb_checkbox">
        <input type="checkbox" formControlName="legal" tabindex="14" />
        <div>
          {{ 'Account.Join.OptInTermsOne' | translate
          }}<a href="{{ termLink }}" target="_blank">{{
            'Footer.Terms' | translate
          }}</a>
          {{ 'Account.Join.OptInTermsTwo' | translate
          }}<a href="{{ privacyLink }}" target="_blank">{{
            'Footer.Privacy' | translate
          }}</a>
          {{ 'AgeGate.AgreeTwo' | translate }}
          <a href="{{ cookieLink }}" target="_blank">
            {{ 'Footer.Cookie' | translate }}</a
          >.*
        </div>
      </div>
      <!-- End Checkboxes -->

      <button
        class="button-type"
        type="submit"
        [disabled]="userForm.invalid"
        tabindex="15"
      >
        {{ 'Auth.SignIn.SignUp' | translate }}
      </button>
    </form>
  </ng-container>
  <!-- End US Market Form -->

  <!-- AU Market Form -->
  <ng-container *ngIf="isAU">
    <form
      class="row"
      [formGroup]="userForm"
      *ngIf="userForm"
      (ngSubmit)="onSubmit()"
    >
      <!-- Country -->
      <div class="tb_input--select form-field-container col-12">
        <label class="label" for="country"
          >{{ 'Account.Form.Country' | translate }}*</label
        >
        <select
          id="country"
          [(ngModel)]="userCountry"
          (ngModelChange)="onCountryChange($event)"
          [ngModelOptions]="{ standalone: true }"
          tabindex="1"
        >
          <option [value]="0" disabled selected>
            {{ 'Account.Form.CountrySelect' | translate }}
          </option>
          <option *ngFor="let country of countries" [ngValue]="country">
            {{ country.name }}
          </option>
        </select>
        <div class="user-registration__error label">
          <div *ngIf="userForm.controls.country.errors?.required">
            {{ 'Account.Form.CountryRequired' | translate }}
          </div>
        </div>
      </div>
      <div class="tb_input form-field-container col-6">
        <label class="label" for="first-name"
          >{{ 'Account.Form.FirstName' | translate }}*</label
        >
        <input
          type="text"
          id="first-name"
          placeholder="{{ 'Account.Form.FirstName' | translate }}"
          formControlName="firstName"
          required
          tabindex="2"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.firstName.errors &&
            userForm.controls.firstName.dirty
          "
        >
          <div *ngIf="userForm.controls.firstName.errors.required">
            {{ 'Account.Form.FirstNameRequired' | translate }}
          </div>
        </div>
      </div>
      <!-- Last Name -->
      <div class="tb_input form-field-container col-6">
        <label class="label" for="last-name"
          >{{ 'Account.Form.LastName' | translate }}*</label
        >
        <input
          type="text"
          id="last-name"
          placeholder="{{ 'Account.Form.LastName' | translate }}"
          formControlName="lastName"
          required
          tabindex="3"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.lastName.errors &&
            userForm.controls.lastName.dirty
          "
        >
          <div *ngIf="userForm.controls.lastName.errors.required">
            {{ 'Account.Form.LastNameRequired' | translate }}
          </div>
        </div>
      </div>
      <!-- Email -->
      <div class="tb_input form-field-container col-12">
        <label class="label" for="email"
          >{{ 'Account.Form.Email' | translate }}*</label
        >
        <input
          type="email"
          id="email"
          placeholder="{{ 'Account.Form.Email' | translate }}"
          formControlName="email"
          required
          tabindex="4"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.email.errors && userForm.controls.email.dirty
          "
        >
          <div *ngIf="userForm.controls.email.errors.required">
            {{ 'Account.Form.EmailRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.get('email').hasError('email') &&
              userForm.get('email').touched
            "
          >
            {{ 'Account.Form.ValidEmail' | translate }}
          </div>
        </div>
      </div>
      <!-- Password -->
      <div class="tb_input form-field-container col-sm-12 col-md-6">
        <label class="label" for="password"
          >{{ 'Account.Form.Password' | translate }}*</label
        >
        <input
          type="password"
          id="password"
          placeholder="{{ 'Account.Form.Password' | translate }}"
          formControlName="password"
          required
          tabindex="5"
        />
        <div
          class="user-registration__error--password label show-tablet"
          *ngIf="
            userForm.controls.password.errors &&
            userForm.controls.password.dirty
          "
        >
          <div
            *ngIf="
              userForm.get('password').hasError('required') &&
              userForm.get('password').touched
            "
          >
            {{ 'Account.Form.PasswordRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.get('password').hasError('pattern') &&
              userForm.get('password').touched
            "
          >
            {{ 'Account.Form.PasswordComplexity' | translate }}
          </div>
        </div>
      </div>
      <!-- Password Confirm -->
      <div class="tb_input form-field-container col-sm-12 col-md-6">
        <label class="label" for="confirmPassword"
          >{{ 'Account.Form.PasswordConfirm' | translate }}*</label
        >
        <input
          type="password"
          id="confirmPassword"
          placeholder="{{ 'Account.Form.PasswordConfirm' | translate }}"
          formControlName="confirmPassword"
          required
          tabindex="6"
        />
        <div
          class="user-registration__error--password label show-tablet"
          *ngIf="
            userForm.controls.confirmPassword.errors &&
            userForm.controls.confirmPassword.dirty
          "
        >
          <div
            *ngIf="
              userForm.get('confirmPassword').hasError('required') &&
              userForm.get('confirmPassword').touched
            "
          >
            {{ 'Account.Form.ConfirmPasswordRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.get('confirmPassword').hasError('passwordMismatch') &&
              userForm.get('confirmPassword').touched
            "
          >
            {{ 'Account.Form.PasswordMatch' | translate }}
          </div>
        </div>
      </div>
      <div
        class="user-registration__error--password label hide-tablet col-12"
        *ngIf="
          userForm.controls.password.errors && userForm.controls.password.dirty
        "
      >
        <div
          *ngIf="
            userForm.get('password').hasError('required') &&
            userForm.get('password').touched
          "
        >
          {{ 'Account.Form.PasswordRequired' | translate }}
        </div>
        <div
          *ngIf="
            userForm.get('password').hasError('pattern') &&
            userForm.get('password').touched
          "
        >
          {{ 'Account.Form.PasswordComplexity' | translate }}
        </div>
      </div>
      <div
        class="user-registration__error--password label hide-tablet col-12"
        *ngIf="
          userForm.controls.confirmPassword.errors &&
          userForm.controls.confirmPassword.dirty
        "
      >
        <div
          *ngIf="
            userForm.get('confirmPassword').hasError('required') &&
            userForm.get('confirmPassword').touched
          "
        >
          {{ 'Account.Form.ConfirmPasswordRequired' | translate }}
        </div>
        <div
          *ngIf="
            userForm.get('confirmPassword').hasError('passwordMismatch') &&
            userForm.get('confirmPassword').touched
          "
        >
          {{ 'Account.Form.PasswordMatch' | translate }}
        </div>
      </div>
      <!-- Address -->
      <div class="tb_input form-field-container col-sm-12 col-md-6">
        <label class="label" for="address1"
          >{{ 'Account.Form.Address1' | translate }}*</label
        >
        <input
          class="join__form-field tb_input"
          id="address1"
          formControlName="addressOne"
          appRequired
          placeholder="{{ 'Account.Form.Address1' | translate }}"
          tabindex="7"
          required
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.addressOne.errors?.required &&
            userForm.controls.addressOne.dirty
          "
        >
          {{ 'Account.Form.AddressRequired' | translate }}
        </div>
      </div>
      <div class="tb_input form-field-container col-sm-12 col-md-6">
        <label class="label" for="address2">{{
          'Account.Form.Address2' | translate
        }}</label>
        <input
          placeholder="{{ 'Account.Form.Address2' | translate }}"
          id="address2"
          class="join__form-field tb_input"
          formControlName="addressTwo"
          appRequired
          tabindex="8"
        />
      </div>
      <!-- City -->
      <div class="tb_input form-field-container col-12">
        <label class="label" for="city"
          >{{ 'Account.Form.City' | translate }}*</label
        >
        <input
          placeholder="{{ 'Account.Form.City' | translate }}"
          id="city"
          class="join__form-field tb_input"
          formControlName="city"
          appRequired
          #autoFillCity
          tabindex="9"
          required
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.city.errors?.required &&
            userForm.controls.city.dirty
          "
        >
          {{ 'Account.Form.CityRequired' | translate }}
        </div>
      </div>
      <!-- State -->
      <div class="tb_input form-field-container col-6">
        <label class="label" for="state"
          >{{ 'Account.Form.StateProvince' | translate }}*</label
        >
        <input
          placeholder="{{ 'Account.Form.StateProvince' | translate }}"
          id="state"
          class="join__form-field tb_input"
          formControlName="state"
          appRequired
          #autoFillState
          tabindex="10"
          required
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.state.errors?.required &&
            userForm.controls.state.dirty
          "
        >
          {{ 'Account.Form.StateProvinceRequired' | translate }}
        </div>
      </div>
      <!-- Zip/Postalcode -->
      <div class="tb_input form-field-container col-6">
        <label class="label" for="zipcode"
          >{{ 'Account.Form.PostCode' | translate }}*</label
        >
        <input
          placeholder="{{ 'Account.Form.PostCode' | translate }}"
          id="zipcode"
          class="join__form-field tb_input"
          formControlName="zipcode"
          appRequired
          #autoFillZip
          tabindex="11"
          required
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.zipcode.errors?.required &&
            userForm.controls.zipcode.touched
          "
        >
          {{ 'Account.Form.PostCodeRequired' | translate }}
        </div>
      </div>

      <!-- End Address -->
      <!-- DOB -->
      <div class="col-12">
        <label class="label dob" for="month"
          >{{ 'Account.Form.Birthday' | translate }}*</label
        >
      </div>
      <div class="tb_input form-field-container col-4">
        <input
          type="number"
          id="day"
          min="1"
          max="31"
          placeholder="{{ 'Account.Form.Day' | translate }}"
          formControlName="dobDay"
          required
          tabindex="12"
        />
      </div>
      <div class="tb_input form-field-container col-4">
        <input
          type="number"
          id="month"
          min="1"
          max="12"
          placeholder="{{ 'Account.Form.Month' | translate }}"
          formControlName="dobMonth"
          required
          tabindex="13"
        />
      </div>
      <div class="tb_input form-field-container col-4">
        <input
          type="number"
          id="year"
          min="1900"
          placeholder="{{ 'Account.Form.Year' | translate }}"
          formControlName="dobYear"
          required
          tabindex="14"
        />
      </div>
      <div class="col-12">
        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobDay.errors && userForm.controls.dobDay.dirty
          "
        >
          <div *ngIf="userForm.controls.dobDay.errors.required">
            {{ 'Account.Form.DayRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.controls.dobDay.errors.min ||
              userForm.controls.dobDay.errors.max
            "
          >
            {{ 'Account.Join.ValidDay' | translate
            }}{{ 'Account.Join.ValidDay' | translate }}
          </div>
        </div>
        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobMonth.errors &&
            userForm.controls.dobMonth.dirty
          "
        >
          <div *ngIf="userForm.controls.dobMonth.errors.required">
            {{ 'Account.Form.MonthRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.controls.dobMonth.errors.min ||
              userForm.controls.dobMonth.errors.max
            "
          >
            {{ 'Account.Join.ValidMonth' | translate }}
          </div>
        </div>
        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobYear.errors && userForm.controls.dobYear.dirty
          "
        >
          <div *ngIf="userForm.controls.dobYear.errors.required">
            {{ 'Account.Form.YearRequired' | translate }}
          </div>
          <div *ngIf="userForm.controls.dobYear.errors.min">
            {{ 'Account.Join.ValidYear' | translate }}
          </div>
        </div>
      </div>
      <!-- End DOB -->
      <!-- Affiliate -->
      <div class="tb_input form-field-container col-12">
        <label class="label" for="last-name">Affiliate Code (Optional)</label>
        <input
          type="text"
          id="last-name"
          placeholder="Affiliate Code"
          formControlName="affiliate"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.affiliate.errors &&
            userForm.controls.affiliate.dirty
          "
        ></div>
      </div>
      <!-- End Affiliate -->
      <p class="requireMessage">
        {{ 'Account.Join.RequiredFields' | translate }}
      </p>
      <!-- Checkboxes -->
      <div class="col-12 tb_checkbox">
        <input
          type="checkbox"
          id="community"
          formControlName="community"
          tabindex="15"
        />
        <!-- <label for="community">
          Yes, I want to be a part of The Blend’s global community.*</label> -->
        <label for="community">
          {{ 'Account.Join.OptInPrivacy' | translate
          }}<a href="{{ privacyLink }}" target="_blank">{{
            'Footer.Privacy' | translate
          }}</a
          >*
        </label>
      </div>
      <div class="col-12 tb_checkbox">
        <input type="checkbox" formControlName="communication" tabindex="16" />
        <label for="communication">
          {{ 'Account.Join.OptInCommunication' | translate }}🤗</label
        >
      </div>
      <div class="col-12 tb_checkbox">
        <input type="checkbox" formControlName="legal" tabindex="17" />
        <div>
          {{ 'Account.Join.OptInTermsOne' | translate
          }}<a href="{{ termLink }}" target="_blank">{{
            'Footer.Terms' | translate
          }}</a>
          {{ 'Account.Join.OptInTermsTwo' | translate
          }}<a href="{{ privacyLink }}" target="_blank">{{
            'Footer.Privacy' | translate
          }}</a>
          {{ 'AgeGate.AgreeTwo' | translate }}
          <a href="{{ cookieLink }}" target="_blank">
            {{ 'Footer.Cookie' | translate }}</a
          >.*
        </div>
      </div>
      <!-- End Checkboxes -->
      <button
        class="button-type"
        type="submit"
        [disabled]="userForm.invalid"
        tabindex="18"
      >
        {{ 'Auth.SignIn.SignUp' | translate }}
      </button>
    </form>
  </ng-container>

  <!-- End AU Market Form -->

  <!-- IN Market Form -->
  <ng-container *ngIf="isIN">
    <form
      class="row"
      [formGroup]="userForm"
      *ngIf="userForm"
      (ngSubmit)="onSubmit()"
    >
      <!-- Country -->
      <div class="tb_input--select form-field-container col-12">
        <label class="label" for="country"
          >{{ 'Account.Form.Country' | translate }}*</label
        >
        <select
          id="country"
          [(ngModel)]="userCountry"
          (ngModelChange)="onCountryChange($event)"
          [ngModelOptions]="{ standalone: true }"
          tabindex="1"
        >
          <option [value]="0" disabled selected>
            {{ 'Account.Form.CountrySelect' | translate }}
          </option>
          <option *ngFor="let country of countries" [ngValue]="country">
            {{ country.name }}
          </option>
        </select>
        <div class="user-registration__error label">
          <div *ngIf="userForm.controls.country.errors?.required">
            {{ 'Account.Form.CountryRequired' | translate }}
          </div>
        </div>
      </div>
      <!-- End Country -->
      <div class="tb_input form-field-container col-6">
        <label class="label" for="first-name"
          >{{ 'Account.Form.FirstName' | translate }}*</label
        >
        <input
          type="text"
          id="first-name"
          placeholder="{{ 'Account.Form.FirstName' | translate }}"
          formControlName="firstName"
          required
          tabindex="2"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.firstName.errors &&
            userForm.controls.firstName.dirty
          "
        >
          <div *ngIf="userForm.controls.firstName.errors.required">
            {{ 'Account.Form.FirstNameRequired' | translate }}
          </div>
        </div>
      </div>
      <!-- Last Name -->
      <div class="tb_input form-field-container col-6">
        <label class="label" for="last-name"
          >{{ 'Account.Form.LastName' | translate }}*</label
        >
        <input
          type="text"
          id="last-name"
          placeholder="{{ 'Account.Form.LastName' | translate }}"
          formControlName="lastName"
          required
          tabindex="3"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.lastName.errors &&
            userForm.controls.lastName.dirty
          "
        >
          <div *ngIf="userForm.controls.lastName.errors.required">
            {{ 'Account.Form.LastNameRequired' | translate }}
          </div>
        </div>
      </div>
      <!-- Email -->
      <div class="tb_input form-field-container col-12">
        <label class="label" for="email"
          >{{ 'Account.Form.Email' | translate }}*</label
        >
        <input
          type="email"
          id="email"
          placeholder="{{ 'Account.Form.Email' | translate }}"
          formControlName="email"
          required
          tabindex="4"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.email.errors && userForm.controls.email.dirty
          "
        >
          <div *ngIf="userForm.controls.email.errors.required">
            {{ 'Account.Form.EmailRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.get('email').hasError('email') &&
              userForm.get('email').touched
            "
          >
            {{ 'Account.Form.ValidEmail' | translate }}
          </div>
        </div>
      </div>
      <!-- Password -->
      <div class="tb_input form-field-container col-sm-12 col-md-6">
        <label class="label" for="password"
          >{{ 'Account.Form.Password' | translate }}*</label
        >
        <input
          type="password"
          id="password"
          placeholder="{{ 'Account.Form.Password' | translate }}"
          formControlName="password"
          required
          tabindex="5"
        />
        <div
          class="user-registration__error--password label show-tablet"
          *ngIf="
            userForm.controls.password.errors &&
            userForm.controls.password.dirty
          "
        >
          <div
            *ngIf="
              userForm.get('password').hasError('required') &&
              userForm.get('password').touched
            "
          >
            {{ 'Account.Form.PasswordRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.get('password').hasError('pattern') &&
              userForm.get('password').touched
            "
          >
            {{ 'Account.Form.PasswordComplexity' | translate }}
          </div>
        </div>
      </div>
      <!-- Password Confirm -->
      <div class="tb_input form-field-container col-sm-12 col-md-6">
        <label class="label" for="confirmPassword"
          >{{ 'Account.Form.PasswordConfirm' | translate }}*</label
        >
        <input
          type="password"
          id="confirmPassword"
          placeholder="{{ 'Account.Form.PasswordConfirm' | translate }}"
          formControlName="confirmPassword"
          required
          tabindex="6"
        />
        <div
          class="user-registration__error--password label show-tablet"
          *ngIf="
            userForm.controls.confirmPassword.errors &&
            userForm.controls.confirmPassword.dirty
          "
        >
          <div
            *ngIf="
              userForm.get('confirmPassword').hasError('required') &&
              userForm.get('confirmPassword').touched
            "
          >
            {{ 'Account.Form.ConfirmPasswordRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.get('confirmPassword').hasError('passwordMismatch') &&
              userForm.get('confirmPassword').touched
            "
          >
            {{ 'Account.Form.PasswordMatch' | translate }}
          </div>
        </div>
      </div>
      <div
        class="user-registration__error--password label hide-tablet col-12"
        *ngIf="
          userForm.controls.password.errors && userForm.controls.password.dirty
        "
      >
        <div
          *ngIf="
            userForm.get('password').hasError('required') &&
            userForm.get('password').touched
          "
        >
          {{ 'Account.Form.PasswordRequired' | translate }}
        </div>
        <div
          *ngIf="
            userForm.get('password').hasError('pattern') &&
            userForm.get('password').touched
          "
        >
          {{ 'Account.Form.PasswordComplexity' | translate }}
        </div>
      </div>
      <div
        class="user-registration__error--password label hide-tablet col-12"
        *ngIf="
          userForm.controls.confirmPassword.errors &&
          userForm.controls.confirmPassword.dirty
        "
      >
        <div
          *ngIf="
            userForm.get('confirmPassword').hasError('required') &&
            userForm.get('confirmPassword').touched
          "
        >
          {{ 'Account.Form.ConfirmPasswordRequired' | translate }}
        </div>
        <div
          *ngIf="
            userForm.get('confirmPassword').hasError('passwordMismatch') &&
            userForm.get('confirmPassword').touched
          "
        >
          {{ 'Account.Form.PasswordMatch' | translate }}
        </div>
      </div>
      <!-- End Pasword -->

      <!-- State -->
      <div class="tb_input form-field-container col-12">
        <label class="label" for="state"
          >{{ 'Account.Form.StateProvince' | translate }}*</label
        >
        <input
          type="text"
          id="state"
          placeholder="{{ 'Account.Form.StateProvince' | translate }}"
          formControlName="state"
          required
          tabindex="7"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.state.errors?.required &&
            userForm.controls.state.dirty
          "
        >
          {{ 'Account.Form.StateProvinceRequired' | translate }}
        </div>
      </div>
      <!-- End state -->

      <!-- DOB -->
      <div class="col-12">
        <label class="label dob" for="month"
          >{{ 'Account.Form.Birthday' | translate }}*</label
        >
      </div>
      <div class="tb_input form-field-container col-4">
        <input
          type="text"
          id="day"
          min="1"
          max="31"
          placeholder="{{ 'Account.Form.Day' | translate }}"
          formControlName="dobDay"
          required
          tabindex="8"
        />
      </div>
      <div class="tb_input form-field-container col-4">
        <input
          type="text"
          id="month"
          min="1"
          max="12"
          placeholder="{{ 'Account.Form.Month' | translate }}"
          formControlName="dobMonth"
          required
          tabindex="9"
        />
      </div>
      <div class="tb_input form-field-container col-4">
        <input
          type="text"
          id="year"
          min="1900"
          placeholder="{{ 'Account.Form.Year' | translate }}"
          formControlName="dobYear"
          required
          tabindex="10"
        />
      </div>
      <div class="col-12">
        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobDay.errors && userForm.controls.dobDay.dirty
          "
        >
          <div *ngIf="userForm.controls.dobDay.errors.required">
            {{ 'Account.Form.DayRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.controls.dobDay.errors.min ||
              userForm.controls.dobDay.errors.max
            "
          >
            {{ 'Account.Join.ValidDay' | translate }}
          </div>
        </div>
        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobMonth.errors &&
            userForm.controls.dobMonth.dirty
          "
        >
          <div *ngIf="userForm.controls.dobMonth.errors.required">
            {{ 'Account.Form.MonthRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.controls.dobMonth.errors.min ||
              userForm.controls.dobMonth.errors.max
            "
          >
            {{ 'Account.Join.ValidMonth' | translate }}
          </div>
        </div>
        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobYear.errors && userForm.controls.dobYear.dirty
          "
        >
          <div *ngIf="userForm.controls.dobYear.errors.required">
            {{ 'Account.Form.YearRequired' | translate }}
          </div>
          <div *ngIf="userForm.controls.dobYear.errors.min">
            {{ 'Account.Join.ValidYear' | translate }}
          </div>
        </div>
      </div>
      <!-- End DOB -->
      <!-- Affiliate -->
      <div class="tb_input form-field-container col-12">
        <label class="label" for="last-name">Affiliate Code (Optional)</label>
        <input
          type="text"
          id="last-name"
          placeholder="Affiliate Code"
          formControlName="affiliate"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.affiliate.errors &&
            userForm.controls.affiliate.dirty
          "
        ></div>
      </div>
      <!-- End Affiliate -->
      <p class="requireMessage">
        {{ 'Account.Join.RequiredFields' | translate }}
      </p>
      <!-- Checkboxes -->
      <div class="col-12 tb_checkbox">
        <input
          type="checkbox"
          id="community"
          formControlName="community"
          tabindex="11"
        />
        <!-- <label for="community">
          Yes, I want to be a part of The Blend’s global community.*</label> -->
        <label for="community">
          {{ 'Account.Join.OptInPrivacy' | translate
          }}<a href="{{ privacyLink }}" target="_blank">{{
            'Footer.Privacy' | translate
          }}</a
          >*
        </label>
      </div>
      <div class="col-12 tb_checkbox">
        <input type="checkbox" formControlName="communication" tabindex="12" />
        <label for="communication">
          {{ 'Account.Join.OptInCommunication' | translate }}🤗</label
        >
      </div>
      <div class="col-12 tb_checkbox">
        <input type="checkbox" formControlName="legal" tabindex="13" />
        <div>
          {{ 'Account.Join.OptInTermsOne' | translate
          }}<a href="{{ termLink }}" target="_blank">{{
            'Footer.Terms' | translate
          }}</a>
          {{ 'Account.Join.OptInTermsTwo' | translate
          }}<a href="{{ privacyLink }}" target="_blank">{{
            'Footer.Privacy' | translate
          }}</a>
          {{ 'AgeGate.AgreeTwo' | translate }}
          <a href="{{ cookieLink }}" target="_blank">
            {{ 'Footer.Cookie' | translate }}</a
          >.*
        </div>
      </div>
      <!-- End Checkboxes -->

      <button
        class="button-type"
        type="submit"
        [disabled]="userForm.invalid"
        tabindex="14"
      >
        {{ 'Auth.SignIn.SignUp' | translate }}
      </button>
    </form>
  </ng-container>
  <!-- End IN Market Form -->

  <!-- Global Market -->
  <ng-container *ngIf="isGlobal">
    <form
      class="row global"
      [formGroup]="userForm"
      *ngIf="userForm"
      (ngSubmit)="onSubmit()"
    >
      <!-- Country -->
      <div class="tb_input--select form-field-container col-12">
        <label class="label" for="country"
          >{{ 'Account.Form.Country' | translate }}*</label
        >
        <select
          id="country"
          [(ngModel)]="userCountry"
          (ngModelChange)="onCountryChange($event)"
          [ngModelOptions]="{ standalone: true }"
          tabindex="1"
        >
          <option [value]="undefined" disabled selected>
            {{ 'Account.Form.CountrySelect' | translate }}
          </option>
          <option *ngFor="let country of countries" [ngValue]="country">
            {{ country.name }}
          </option>
        </select>
        <div class="user-registration__error label">
          <div *ngIf="userForm.controls.country.errors?.required">
            {{ 'Account.Form.CountryRequired' | translate }}
          </div>
        </div>
      </div>
      <div class="tb_input form-field-container col-6">
        <label class="label" for="first-name"
          >{{ 'Account.Form.FirstName' | translate }}*</label
        >
        <input
          type="text"
          id="first-name"
          placeholder="{{ 'Account.Form.FirstName' | translate }}"
          formControlName="firstName"
          required
          tabindex="2"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.firstName.errors &&
            userForm.controls.firstName.dirty
          "
        >
          <div *ngIf="userForm.controls.firstName.errors.required">
            {{ 'Account.Form.FirstNameRequired' | translate }}
          </div>
        </div>
      </div>
      <!-- Last Name -->
      <div class="tb_input form-field-container col-6">
        <label class="label" for="last-name"
          >{{ 'Account.Form.LastName' | translate }}*</label
        >
        <input
          type="text"
          id="last-name"
          placeholder="{{ 'Account.Form.LastName' | translate }}"
          formControlName="lastName"
          required
          tabindex="3"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.lastName.errors &&
            userForm.controls.lastName.dirty
          "
        >
          <div *ngIf="userForm.controls.lastName.errors.required">
            {{ 'Account.Form.LastNameRequired' | translate }}
          </div>
        </div>
      </div>
      <!-- Email -->
      <div class="tb_input form-field-container col-12">
        <label class="label" for="email"
          >{{ 'Account.Form.Email' | translate }}*</label
        >
        <input
          type="email"
          id="email"
          placeholder="{{ 'Account.Form.Email' | translate }}"
          formControlName="email"
          required
          tabindex="4"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.email.errors && userForm.controls.email.dirty
          "
        >
          <div *ngIf="userForm.controls.email.errors.required">
            {{ 'Account.Form.EmailRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.get('email').hasError('email') &&
              userForm.get('email').touched
            "
          >
            {{ 'Account.Form.ValidEmail' | translate }}
          </div>
        </div>
      </div>
      <!-- Password -->
      <div class="tb_input form-field-container col-sm-12 col-md-6">
        <label class="label" for="password"
          >{{ 'Account.Form.Password' | translate }}*</label
        >
        <input
          type="password"
          id="password"
          placeholder="{{ 'Account.Form.Password' | translate }}"
          formControlName="password"
          required
          tabindex="5"
        />
        <div
          class="user-registration__error--password label show-tablet"
          *ngIf="
            userForm.controls.password.errors &&
            userForm.controls.password.dirty
          "
        >
          <div
            *ngIf="
              userForm.get('password').hasError('required') &&
              userForm.get('password').touched
            "
          >
            {{ 'Account.Form.PasswordRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.get('password').hasError('pattern') &&
              userForm.get('password').touched
            "
          >
            {{ 'Account.Form.PasswordComplexity' | translate }}
          </div>
        </div>
      </div>
      <!-- Password Confirm -->
      <div class="tb_input form-field-container col-sm-12 col-md-6">
        <label class="label" for="confirmPassword"
          >{{ 'Account.Form.PasswordConfirm' | translate }}*</label
        >
        <input
          type="password"
          id="confirmPassword"
          placeholder="{{ 'Account.Form.PasswordConfirm' | translate }}"
          formControlName="confirmPassword"
          required
          tabindex="6"
        />
        <div
          class="user-registration__error--password label show-tablet"
          *ngIf="
            userForm.controls.confirmPassword.errors &&
            userForm.controls.confirmPassword.dirty
          "
        >
          <div
            *ngIf="
              userForm.get('confirmPassword').hasError('required') &&
              userForm.get('confirmPassword').touched
            "
          >
            {{ 'Account.Form.ConfirmPasswordRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.get('confirmPassword').hasError('passwordMismatch') &&
              userForm.get('confirmPassword').touched
            "
          >
            {{ 'Account.Form.PasswordMatch' | translate }}
          </div>
        </div>
      </div>
      <div
        class="user-registration__error--password label hide-tablet col-12"
        *ngIf="
          userForm.controls.password.errors && userForm.controls.password.dirty
        "
      >
        <div
          *ngIf="
            userForm.get('password').hasError('required') &&
            userForm.get('password').touched
          "
        >
          {{ 'Account.Form.PasswordRequired' | translate }}
        </div>
        <div
          *ngIf="
            userForm.get('password').hasError('pattern') &&
            userForm.get('password').touched
          "
        >
          {{ 'Account.Form.PasswordComplexity' | translate }}
        </div>
      </div>
      <div
        class="user-registration__error--password label hide-tablet col-12"
        *ngIf="
          userForm.controls.confirmPassword.errors &&
          userForm.controls.confirmPassword.dirty
        "
      >
        <div
          *ngIf="
            userForm.get('confirmPassword').hasError('required') &&
            userForm.get('confirmPassword').touched
          "
        >
          {{ 'Account.Form.ConfirmPasswordRequired' | translate }}
        </div>
        <div
          *ngIf="
            userForm.get('confirmPassword').hasError('passwordMismatch') &&
            userForm.get('confirmPassword').touched
          "
        >
          {{ 'Account.Form.PasswordMatch' | translate }}
        </div>
      </div>
      <!-- End Pasword -->

      <!-- City -->
      <div class="tb_input form-field-container col-12">
        <label class="label" for="city"
          >{{ 'Account.Form.City' | translate }}*</label
        >
        <input
          type="text"
          id="city"
          placeholder="{{ 'Account.Form.City' | translate }}"
          formControlName="city"
          required
          tabindex="7"
        />
        <div
          class="user-registration__error label"
          *ngIf="userForm.controls.city.errors && userForm.controls.city.dirty"
        >
          <div *ngIf="userForm.controls.city.errors.required">
            {{ 'Account.Form.CityRequired' | translate }}
          </div>
        </div>
      </div>
      <!-- DOB -->

      <div class="col-12">
        <label class="label dob" for="month"
          >{{ 'Account.Form.Birthday' | translate }}*</label
        >
      </div>
      <div class="tb_input form-field-container col-4">
        <input
          type="number"
          id="day"
          min="1"
          max="31"
          placeholder="{{ 'Account.Form.Day' | translate }}"
          formControlName="dobDay"
          required
          tabindex="8"
        />
      </div>
      <div class="tb_input form-field-container col-4">
        <input
          type="number"
          id="month"
          min="1"
          max="12"
          placeholder="{{ 'Account.Form.Month' | translate }}"
          formControlName="dobMonth"
          required
          tabindex="9"
        />
      </div>
      <div class="tb_input form-field-container col-4">
        <input
          type="number"
          id="year"
          min="1900"
          placeholder="{{ 'Account.Form.Year' | translate }}"
          formControlName="dobYear"
          required
          tabindex="10"
        />
      </div>
      <div class="col-12">
        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobDay.errors && userForm.controls.dobDay.dirty
          "
        >
          <div *ngIf="userForm.controls.dobDay.errors.required">
            {{ 'Account.Form.DayRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.controls.dobDay.errors.min ||
              userForm.controls.dobDay.errors.max
            "
          >
            {{ 'Account.Join.ValidDay' | translate }}
          </div>
        </div>
        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobMonth.errors &&
            userForm.controls.dobMonth.dirty
          "
        >
          <div *ngIf="userForm.controls.dobMonth.errors.required">
            {{ 'Account.Form.MonthRequired' | translate }}
          </div>
          <div
            *ngIf="
              userForm.controls.dobMonth.errors.min ||
              userForm.controls.dobMonth.errors.max
            "
          >
            {{ 'Account.Join.ValidMonth' | translate }}
          </div>
        </div>

        <div
          class="user-registration__error--password label"
          *ngIf="
            userForm.controls.dobYear.errors && userForm.controls.dobYear.dirty
          "
        >
          <div *ngIf="userForm.controls.dobYear.errors.required">
            {{ 'Account.Form.YearRequired' | translate }}
          </div>
          <div *ngIf="userForm.controls.dobYear.errors.min">
            {{ 'Account.Join.ValidYear' | translate }}
          </div>
        </div>
      </div>

      <!-- Affiliate -->
      <div class="tb_input form-field-container col-12">
        <label class="label" for="last-name">Affiliate Code (Optional)</label>
        <input
          type="text"
          id="last-name"
          placeholder="Affiliate Code"
          formControlName="affiliate"
        />
        <div
          class="user-registration__error label"
          *ngIf="
            userForm.controls.affiliate.errors &&
            userForm.controls.affiliate.dirty
          "
        ></div>
      </div>
      <!-- End Affiliate -->
      <p class="requireMessage">
        {{ 'Account.Join.RequiredFields' | translate }}
      </p>
      <!-- Checkboxes -->
      <div class="col-12 tb_checkbox">
        <input
          type="checkbox"
          id="community"
          formControlName="community"
          tabindex="11"
        />
        <!-- <label for="community">
          Yes, I want to be a part of The Blend’s global community.*</label> -->
        <label for="community">
          {{ 'Account.Join.OptInPrivacy' | translate
          }}<a href="{{ privacyLink }}" target="_blank">{{
            'Footer.Privacy' | translate
          }}</a
          >*
        </label>
      </div>
      <div class="col-12 tb_checkbox">
        <input type="checkbox" formControlName="communication" tabindex="12" />
        <label for="communication">
          {{ 'Account.Join.OptInCommunication' | translate }}🤗</label
        >
      </div>
      <div class="col-12 tb_checkbox">
        <input type="checkbox" formControlName="legal" tabindex="13" />
        <div>
          {{ 'Account.Join.OptInTermsOne' | translate
          }}<a href="{{ termLink }}" target="_blank">{{
            'Footer.Terms' | translate
          }}</a>
          {{ 'Account.Join.OptInTermsTwo' | translate
          }}<a href="{{ privacyLink }}" target="_blank">{{
            'Footer.Privacy' | translate
          }}</a>
          {{ 'AgeGate.AgreeTwo' | translate }}
          <a href="{{ cookieLink }}" target="_blank">
            {{ 'Footer.Cookie' | translate }}</a
          >.*
        </div>
      </div>
      <!-- End Checkboxes -->

      <button
        class="button-type"
        type="submit"
        [disabled]="userForm.invalid"
        tabindex="14"
      >
        {{ 'Auth.SignIn.SignUp' | translate }}
      </button>
    </form>
  </ng-container>
  <!-- End Global Market -->
  <p class="ageInvalid label" *ngIf="ageValidMesasge">{{ ageValidMesasge }}</p>
  <p class="signIn">
    {{ 'Account.Misc.AlreadyMember' | translate
    }}<a class="text-link" href="#" (click)="openLoginDialog($event)">{{
      'Account.Misc.SignIn' | translate
    }}</a>
  </p>
</div>
