<form class="join__form" [formGroup]="form">
  <div class="autocomplete">
    <!-- <button class="tb_button tb_button--outline" style="align-self:flex-end" (click)="toggleManualInput()">Manual
      Input</button> -->
    <div class="tb_input">
      <p class="inputSwitch" (click)="toggleManualInput()" *ngIf="!isManualInput && showToggle">
        {{cantFind}}
        <!-- <ng-container *ngIf="!placeSearchAttr">{{cantFindAddressPlaceholder}}</ng-container>
        <ng-container *ngIf="placeSearchAttr">{{cantFindVenuePlaceholder}}</ng-container> -->
      </p>
      <!-- <input [placeholder]="placeholder" [formControlName]="formControlInput" type="text"
        class="form-control tb_input autocomplete_auto-fill" (focus)="onFocus()" (keyup)="startCheck()" #search
        [tabIndex]="tabIndex"> -->
      <input [placeholder]="placeholder" type="text" class="autocomplete_auto-fill" #search [tabIndex]="tabIndex"
        *ngIf="!placeSearchAttr">
      <input [placeholder]="placeholder" type="text" class=" autocomplete_auto-fill" #placeSearch [tabIndex]="tabIndex"
        *ngIf="placeSearchAttr">
      <div #msg id="msg" (click)="toggleManualInput()"></div>
      <div class="autocomplete-results">
        <div class=" tb_input result" *ngFor="let result of autoCompleteOptions" [innerHTML]="result.address.label"
          (click)="selectLocation(result)"></div>
      </div>
    </div>

  </div>
</form>