<div class="tb_timeline">
  <div class="container">
    <div class="row d-none d-sm-block">
      <div class="tb_timeline-card" *ngFor="let card of cards.data; let i = index">
        <ng-container *ngIf="
        i % 2 === 0">
          <div class=" tb_timeline-card__left">
            <h4 class="tb_timeline-card__date" [innerHTML]="card.date"></h4>
            <p class="tb_timeline-card__text" [innerHTML]="card.text"></p>
          </div>
          <div class="tb_timeline-card__right">
            <div class="tb_timeline-card__image tb_img-container--gold">
              <img appLazyLoad class="tb_bordered-image__image" [src]="card.img" alt="">
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="
        i % 2 !== 0">
          <div class="tb_timeline-card__left">
            <div class="tb_timeline-card__image tb_img-container--gold">
              <img appLazyLoad class="tb_bordered-image__image" [src]="card.img" alt="">
            </div>

          </div>
          <div class="tb_timeline-card__right">
            <h4 class="tb_timeline-card__date" [innerHTML]="card.date"></h4>
            <p class="tb_timeline-card__text" [innerHTML]="card.text"></p>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row  tb_timeline__carousel">
      <div class="col-12  d-sm-none">
        <ngu-carousel style="max-width:98vw;" #myCarousel [inputs]="carouselConfig" [dataSource]="cards.data">
          <div *nguCarouselDef="let item" class="item">
            <div class="tb_timeline__carousel__item">
              <div class="tb_img-container--gold">
                <img appLazyLoad class="tb_bordered-image__image" [src]="item.img" alt="">
              </div>
              <div class="tb_timeline__carousel__item-copy-container">
                <h4 class="tb_timeline__carousel__date">
                  <span [innerHTML]="item.date" class="tb_timeline__carousel__title"></span>
                </h4>
                <p class="tb_timeline__carousel__text" [innerHTML]="item.text"></p>
              </div>

            </div>
          </div>
          <ul class="tb_timeline__carousel__dots-row myPoint row justify-content-center" NguCarouselPoint>
            <li class="tb_timeline__carousel__dots" *ngFor="let a of myCarousel?.pointNumbers; let j = index"
              [class.active]="j == myCarousel.activePoint" (click)="myCarousel.moveTo(j)"></li>
          </ul>
        </ngu-carousel>
      </div>

    </div>
  </div>
</div>

<!-- <div class="tb_timeline-section">
  <div class="tb_timeline-card" *ngFor="let card of cards.data">
    <div class="tb_timeline-card__left">
      <h2 class="tb_timeline-card__date" [innerHTML]="card.date"></h2>
      <p class="tb_timeline-card__text" [innerHTML]="card.text"></p>
    </div>
    <div class="tb_timeline-card__right">
      <div class="tb_timeline-card__image tb_bordered-image tb_bordered-image--gold">
        <img class="tb_bordered-image__image" [src]="card.img" alt="">
      </div>
    </div>
  </div>
</div> -->