import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import * as tus from 'tus-js-client';
import { AuthService } from '@core/auth/auth.service';
import { environment } from '@env/environment';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

export enum MediaFileTypes {
  Video = 'video',
  Image = 'image',
  Media = 'media',
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  @Input() multiUpload;
  @Input() maxLength;
  // need to create logic for specifying what type the component uses by default
  @Input() fileType: MediaFileTypes;

  @ViewChild('file', { static: true }) file;
  @ViewChild('fileVal', { static: true }) fileVal;
  @ViewChild('img', { static: true }) img;
  videoList: FileList;
  private uploadProgress: number;
  public upload;
  public drupalFileName: string;

  // progress bar variables
  public barProgress = 0;
  public color = 'primary';
  public mode = 'determinate';
  public value = 0;
  public bufferValue = 75;
  public isLoaded: boolean;

  // button showing variables
  public isUploadDisabled = true;
  public isUploadInProgress = false;
  public isUploadComplete = false;

  videoLength: number;
  videoLengthErrorMsg: string;

  incorrectFileType: string = '';
  @Output() fileUploaded = new EventEmitter<string>();

  constructor(
    private authSvc: AuthService,
    @Inject(PLATFORM_ID) private platform: Object,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.maxLength = +this.maxLength;
    this.videoLengthErrorMsg = this.translate.instant('Upload.VideoDuration', {
      time: this.secondsToHms(this.maxLength),
    });
  }

  public fileChangeEvent(fileInput: any) {
    //
    const uploadLogic = () => {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.fileVal.nativeElement.innerText =
          fileInput.srcElement.files[0].name;
        this.isUploadDisabled = false;
        // this.img.nativeElement.setAttribute('src', e.target.result);
        // $('#preview').attr('src', e.target.result);
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    };

    if (isPlatformBrowser(this.platform)) {
      // check if file type desired is video and if a video is submitted
      if (
        this.fileType === MediaFileTypes.Video &&
        (fileInput.target.files[0].type === 'video/mp4' ||
          fileInput.target.files[0].type === 'video/mov' ||
          fileInput.target.files[0].type === 'video/wmv' ||
          fileInput.target.files[0].type === 'video/avi' ||
          fileInput.target.files[0].type === 'video/avchd')
      ) {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;
          this.videoLength = duration;
        };
        video.src = URL.createObjectURL(fileInput.target.files[0]);
        uploadLogic();

        this.incorrectFileType = '';
        // check if file type desired is image and if a image is submitted
      } else if (
        this.fileType === MediaFileTypes.Image &&
        (fileInput.target.files[0].type === 'image/png' ||
          fileInput.target.files[0].type === 'image/jpeg' ||
          fileInput.target.files[0].type === 'image/gif' ||
          fileInput.target.files[0].type === 'image/jpg' ||
          fileInput.target.files[0].type === 'image/jpeg' ||
          fileInput.target.files[0].type === 'image/tiff')
      ) {
        if (fileInput.target.files && fileInput.target.files[0]) {
          uploadLogic();
        }
        this.incorrectFileType = '';
      } else {
        if (this.fileType === MediaFileTypes.Video) {
          this.incorrectFileType =
            'Please use file type: .mp4, .mov, .wmv, .avi, or .avchd';
        } else if (this.fileType === MediaFileTypes.Image) {
          this.incorrectFileType =
            'Please use file type: .png, .jpg, .jpeg, .gif, or .tiff';
        }
      }
    }

    // if (
    //   this.fileType === MediaFileTypes.Image &&
    //   fileInput.target.files[0].type === "png"
    // ) {
    //   if (fileInput.target.files && fileInput.target.files[0]) {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       this.fileVal.nativeElement.innerText =
    //         fileInput.srcElement.files[0].name;
    //       this.isUploadDisabled = false;
    //       // this.img.nativeElement.setAttribute('src', e.target.result);
    //       // $('#preview').attr('src', e.target.result);
    //     };
    //     reader.readAsDataURL(fileInput.target.files[0]);
    //   }
    // }
  }

  private uploadFile(file: File) {
    this.uploadProgress = 0;
    // console.log('uploading', file);
    this.isUploadInProgress = true;
    // const url = 'https://blend-dev-api.azurewebsites.net/files/';//'http://localhost:5000/files/';
    // const url = 'http://localhost:5000/files/';
    const url = environment.apiFileUrl;

    this.authSvc.getUserBearerTokenHeaderValue().subscribe((token) => {
      // console.log('here', token);
      this.upload = new tus.Upload(file, {
        endpoint: url,
        uploadUrl: url,
        retryDelays: [0, 3000, 6000, 12000, 24000],
        chunkSize: 200000,
        // chunkSize: 10000000,
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        headers: {
          Authorization: token,
        },
        withCredentials: true,
        onError: async (error) => {},
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          // console.log(
          //   'progress: ',
          //   bytesUploaded,
          //   bytesTotal,
          //   percentage + '%'
          // );
          this.barProgress = +percentage;
          // console.log(this.barProgress, 'Bar Progress');
        },
        // onChunkComplete: (chunkSize, bytesAccepted, bytesTotal) => {
        //   this.uploadProgress = Math.floor(bytesAccepted / bytesTotal * 100);
        //   // this.changeDetectionRef.detectChanges();
        // },
        onSuccess: async () => {
          this.uploadProgress = 100;
          this.isUploadComplete = true;
          this.isUploadInProgress = false;
          // this.changeDetectionRef.detectChanges();
          // console.log('upload success', this.upload.url);
          const drupalFilename = this.upload['_xhr'].getResponseHeader(
            'tus-drupal-filename'
          );
          this.drupalFileName = drupalFilename;
          // console.log(drupalFilename);
          this.fileUploaded.emit(drupalFilename);
          // .getAllResponseHeaders();
        },
      });

      // Start the upload
      this.upload.start();
    });
  }

  public start(files: FileList) {
    this.videoList = files;
    // console.log(this.videoList);
    this.uploadFile(files[0]);

    // const recursion = this.getLink(this.videoList[0], 0, this.videoList).pipe(expand(res => {
    //   return res.index > res.arr.length - 1 ?
    //     EMPTY : this.getLink(this.videoList[res.index], res.index, this.videoList);
    // }));
    // recursion.subscribe(x => {
    //   if (x.index > x.arr.length - 1) {
    //     console.log('Links generated, Starting upload');
    //     // All links have been generated now you can start the upload process here
    //   }
    // });
  }

  public cancelUpload(file) {
    file.value = '';
    this.barProgress = 0;
    this.upload.abort();
    this.isUploadDisabled = true;
    this.isUploadInProgress = false;
    this.isUploadComplete = false;
    this.fileVal.nativeElement.innerText = '';
  }

  public restartUpload(file) {
    file.value = '';
    this.barProgress = 0;
    this.isUploadDisabled = true;
    this.isUploadInProgress = false;
    this.isUploadComplete = false;
    this.fileVal.nativeElement.innerText = '';
  }

  private secondsToHms(d) {
    d = Number(d);

    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
    const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
    return mDisplay + sDisplay;
  }
}

export class uploadFiles {
  constructor(
    public video: File,
    public path: string,
    public uploadURI: string
  ) {
    this.video = video;
    this.path = path;
    this.uploadURI = uploadURI;
  }
}
