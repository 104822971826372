import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  userLocation;
  userCountry;
  public userLocationRecieved: Subject<boolean> = new Subject<boolean>();
  public userCountryRecieved: Subject<boolean> = new Subject<boolean>();
  constructor() { }

  getUserLocation(location) {
    this.userLocation = location;
    this.userLocationRecieved.next(true);
  }

  setUserLocation() {
    return this.userLocation;
  }

  setUserCountry(country) {
    this.userCountry = country;
    this.userCountryRecieved.next(true);
  }

  getUserCountry() {
    return this.userCountry;
  }
}
