// import { Pipe, PipeTransform } from '@angular/core';
import { Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';

@Pipe({
  name: 'timeSince'
})
export class TimeSincePipe implements PipeTransform, OnDestroy {

  // transform(value: any, args?: any): any {
  //   const currentDate = new Date();
  //   console.log(value);
  //   return null;
  // }

  private timer: number;
  constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone, private translate: TranslateService, @Inject(PLATFORM_ID) private platform: Object) { }
  transform(value: string) {
    if (isPlatformBrowser(this.platform)) {
      this.removeTimer();
      const d = new Date(value);
      const now = new Date();
      const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
      const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
      this.timer = this.ngZone.runOutsideAngular(() => {
        if (typeof window !== 'undefined') {
          return window.setTimeout(() => {
            this.ngZone.run(() => this.changeDetectorRef.markForCheck());
          }, timeToUpdate);
        }
        return null;
      });
      const minutes = Math.round(Math.abs(seconds / 60));
      const hours = Math.round(Math.abs(minutes / 60));
      const days = Math.round(Math.abs(hours / 24));
      const weeks = Math.round(Math.abs(days / 7));
      const months = Math.round(Math.abs(days / 30.416));
      const years = Math.round(Math.abs(days / 365));

      if (Number.isNaN(seconds)) {
        return '';
      } else if (hours <= 1) {
        return this.translate.instant('TimeSince.JustNow');
      } else if (minutes <= 90) {
        return this.translate.instant('TimeSince.HourAgo');
      } else if (hours <= 22) {
        return this.translate.instant('TimeSince.HoursAgo', { time: hours });
      } else if (hours <= 36) {
        return this.translate.instant('TimeSince.DayAgo');
      } else if (hours > 36 && days < 7) {
        return this.translate.instant('TimeSince.DaysAgo', { time: days });
      } else if (hours > 144 && days >= 7 && days <= 13) {
        return this.translate.instant('TimeSince.WeekAgo');
      } else if (days < 44 && days >= 14) {
        return this.translate.instant('TimeSince.WeeksAgo', { time: weeks });
      } else if (days <= 45) {
        return this.translate.instant('TimeSince.MonthAgo');
      } else if (days <= 345) {
        return this.translate.instant('TimeSince.MonthsAgo', { time: months });
      } else if (days <= 545) {
        return this.translate.instant('TimeSince.YearAgo');
      } else { // (days > 545)
        return this.translate.instant('TimeSince.YearsAgo', { time: years });
      }
    }
  }
  ngOnDestroy(): void {
    this.removeTimer();
  }
  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }
  private getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) { // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) { // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) { // less then a day, update every 5 mins
      return 300;
    } else { // update every hour
      return 3600;
    }
  }

}
