import { Injectable, Inject, PLATFORM_ID } from '@angular/core';

const DYNAMIC_TAGS = [
  'a'
] 

@Injectable({
  providedIn: 'root'
})
export class BasicPageService {

  constructor(@Inject(PLATFORM_ID) private platform: Object) { }

  public parseDocument(data: string): Document {
    return new DOMParser().parseFromString(data, 'text/html');
  }

  /**
   * Returns an array of children elements denoting if they are dynamic components
   *  ex: an A tag is dynamic for potentially being a routedpath
   * @param element 
   */
  public parseChildren(element: HTMLElement) {
    let elements: DynamicElement[] = [];
    Array.from(element.children).forEach((el: HTMLElement, index) => {
      const dynamic = DYNAMIC_TAGS.includes(el.nodeName.toLocaleLowerCase());
      elements.push({ dynamic: dynamic, html: el });
    });
    return elements;
  }

}

export interface DynamicElement {
  dynamic: boolean;
  html: HTMLElement;
}