  <mat-tab-group class="tb_single-login header-less-tabs" #loginTabGroup>
    <mat-tab>
      <form [formGroup]="loginForm" (ngSubmit)="onLogin(loginForm)">
        <ng-container>
            <div class="row">
                <div class="col-12">
                    <div class="tb_type-group">
                        <h3 class="tb_type-group__subtext text-center">
                            {{ 'Shared.Login.AlreadyRegisteredQuestion' | translate }}
                        </h3>
                    </div>
                </div>
            </div>
            <p *ngIf="msgError" class="tb_type-group__subtext" [innerHTML]="message"></p>
            <div class="tb_login">
                <mat-form-field appearance="outline">
                    <input autocapitalize="off" autocorrect="off" 
                        autocapitalize="none" tabindex="1" matInput required
                        class="tb_input-group__input tb_input" type="text"
                        placeholder="{{ 'Shared.Login.Email' | translate }}" formControlName="email">
                    <mat-error *ngIf="loginForm.get('email').errors?.required">
                        {{ 'Shared.Login.EmailRequired' | translate }}
                    </mat-error>
                    <mat-error *ngIf="loginForm.get('email').errors?.email">
                        {{ 'Shared.Login.ValidEmail' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <input autocapitalize="off" autocorrect="off" tabindex="2"
                        matInput class="tb_input-group__password tb_input" required type="password" maxlength="50"
                        placeholder="{{ 'Shared.Login.Password' | translate }}" formControlName="password">
                    <mat-error>{{ 'Shared.Login.EnterPassword' | translate }}
                    </mat-error>
                </mat-form-field>
                <div class="justify-content-center">
                    <button class="tb_button"
                        [disabled]=loginForm.invalid>{{ 'Shared.Login.Submit' | translate }}</button>
                </div>
                <div class="tb_link-forgot-password">
                    <p><a (click)="showForgotPasswordForm()" class="tb_link" href="javascript:;">
                        {{ 'Shared.Login.ForgotPasswordQuestion' | translate }}
                    </a></p>
                </div>
            </div>
          </ng-container>
      </form>
    </mat-tab>
    <mat-tab>
      <form [formGroup]="resetForm" (ngSubmit)="onPasswordReset(resetForm)">
            <h5 class="tb_heading--h5">{{ 'Shared.Login.ForgotPassword' | translate }}</h5>
            <div *ngIf="!emailSent" class="tb_type-group">
                <h3 class="tb_type-group__subtext text-center">
                    {{ 'Shared.Login.EnterEmail' | translate }}
                </h3>
                <div class="tb_reset-password">
                    <mat-form-field appearance="outline">
                        <input autocapitalize="off" autocorrect="off" tabindex="1"
                            matInput required class="tb_input-group__password tb_input" type="text"
                            placeholder="{{ 'Shared.Login.Email' | translate }}" formControlName="forgottenEmail">
                        <mat-error *ngIf="resetForm.get('forgottenEmail').errors?.required">
                            {{ 'Shared.Login.EmailRequired' | translate }}
                        </mat-error>
                        <mat-error *ngIf="resetForm.get('forgottenEmail').errors?.email">
                            {{ 'Shared.Login.ValidEmail' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <div class="justify-content-center">
                        <button class="tb_button" [disabled]="resetForm.invalid">
                            {{ 'Shared.Login.SendEmail' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="tb_type-group" *ngIf="emailSent">
                <h3 class="tb_type-group__subtext text-center">
                    {{ 'Shared.Login.ResetNotification' | translate }}
                </h3>
            </div>
            <div class="tb_link-forgot-password">
                <p>
                    <a (click)="showLoginForm()" class="tb_link" href="javascript:;">
                        {{ 'Shared.Login.BackToLogin' | translate }}
                    </a>
                </p>
            </div>
        </form>
    </mat-tab>
  </mat-tab-group>