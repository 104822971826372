<!-- <div class="videoContainer" [ngClass]="{'tb_image--gold-border': goldBorder}" *ngIf="data.imgSrc?.video">
  <iframe width="560" height="349" [src]="data.imgSrc.src | safe: 'resourceUrl'" frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
<img class="tb_two-up-section__image tb_image" [ngClass]="{'tb_image--gold-border': goldBorder}" [src]="data.imgSrc"
  alt="" *ngIf="!data.imgSrc.video"> -->

<div class="videoContainer" [ngClass]="{'tb_image--gold-border': goldBorder}"
  *ngIf="displayedMedia.fileType === 'video' && displayedMedia">
  <iframe width="560" height="349" [src]="displayedMedia.video | safe: 'resourceUrl'" frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
<div class="tb_img-container--gold" [ngClass]="{'gold': goldBorder}">
  <img appLazyLoad class="tb_two-up-section__image tb_image" [src]="displayedMedia.img" alt="" width="781" height="439"
    *ngIf="displayedMedia.fileType === 'img' && displayedMedia">
</div>