<div class="uploadContainer row justify-content-center">
  <div class="inputs row">
    <label class="custom-file-upload tb_button tb_button--outline" [hidden]="isUploadInProgress || isUploadComplete">
      <input class="tb_input" type="file" #file name="video" id="video" accept="image/*,video/*"
        (change)="fileChangeEvent($event)">
      {{'Upload.Browse' | translate}}
    </label>
    <span class="fileLabel tb_type-group__copy">
      {{'Upload.File' | translate}}: <span #fileVal id="file-upload-value">---</span>
    </span>
    <input class="tb_button" type="button" value="{{'Upload.Upload' | translate}}" (click)="start(file.files);"
      [disabled]="isUploadDisabled || videoLength > maxLength" [hidden]="isUploadInProgress || isUploadComplete">
    <!-- <button class="tb_button" [hidden]="!isUploadPaused && !isUploadInProgress" (click)="resumeUpload(pause)">Resume</button>-->
    <button class="tb_button cancel" [hidden]="!isUploadInProgress" #pause
      (click)="cancelUpload(file)">{{'Upload.Cancel' | translate}}</button>
    <!-- <button class="tb_button remove" [hidden]="!isUploadComplete" (click)="restartUpload(file)"
      *ngIf="!multiUpload">Remove</button> -->
    <div [hidden]="!isUploadComplete" (click)="restartUpload(file)" *ngIf="!multiUpload">
      <svg class="icon icon-cross">
        <use xlink:href="#icon-cross">
          <symbol id="icon-cross" viewBox="0 0 32 32">
            <title>cross</title>
            <path
              d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z">
            </path>
          </symbol>
        </use>
      </svg>
    </div>

  </div>
  <div class="row" *ngIf="videoLength > maxLength">
    {{videoLengthErrorMsg}}
  </div>
  <div class="progress-bar row" [hidden]="isUploadDisabled || isUploadComplete">
    <mat-progress-bar class="example-margin" [color]="color" [mode]="mode" [value]="barProgress"
      [bufferValue]="bufferValue">
    </mat-progress-bar>
    <span class="barProgressNum" [innerHTML]="barProgress" *ngIf="!isUploadDisabled && !isUploadComplete"></span>
    <span class="completedUpload" *ngIf="isUploadComplete">{{'Upload.UploadComplete' | translate}}</span>
  </div>
</div>
<div class="row justify-content-center">
  <p class="incorrectFileType"> {{incorrectFileType}}</p>

</div>