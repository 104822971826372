import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import {
  NguCarousel,
  NguCarouselConfig,
  NguCarouselStore,
} from '@ngu/carousel';
import {
  Podcast,
  PResourcesPodcasts,
  Product,
  News,
  Event,
  Hero,
  Brands,
  Cocktail,
  PodcastSeries,
  PLmsSpotlightGridItem,
  ServiceWell,
  PBBSpotlightGrid,
  PBBSpotlightGridItem,
} from '@proxy/service-proxies';
import { ComponentBase } from '@core/classes/component-base';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BtnColors } from '@core/enum/enum';
import { Console } from 'console';

enum SlideTypes {
  Brands = 'brands',
  Cocktail = 'cocktail',
  Event = 'event',
  Hero = 'hero',
  News = 'news',
  Podcast = 'podcast',
  PodcastResource = 'podcastResource',
  PodcastSeries = 'pocastSeries',
  Product = 'product',
  Spotlight = 'spotlight',
}

class CarouselSlide {
  public type:
    | SlideTypes.Brands
    | SlideTypes.Cocktail
    | SlideTypes.Event
    | SlideTypes.Hero
    | SlideTypes.News
    | SlideTypes.Podcast
    | SlideTypes.PodcastResource
    | SlideTypes.PodcastSeries
    | SlideTypes.Product
    | SlideTypes.Spotlight;

  public content:
    | Brands
    | Cocktail
    | Event
    | Hero
    | News
    | Podcast
    | PResourcesPodcasts
    | PodcastSeries
    | Product
    | PLmsSpotlightGridItem
    | ServiceWell;

  constructor(
    slide:
      | Brands
      | Cocktail
      | Event
      | Hero
      | News
      | Podcast
      | PBBSpotlightGridItem
      | PResourcesPodcasts
      | PodcastSeries
      | Product
      | PLmsSpotlightGridItem
      | ServiceWell
  ) {
    if (slide instanceof Brands) {
      this.setBrands(slide);
    } else if (slide instanceof Cocktail) {
      this.setCocktail(slide);
    } else if (slide instanceof Event) {
      this.setEvent(slide);
    } else if (slide instanceof Hero) {
      this.setHero(slide);
    } else if (slide instanceof News) {
      this.setNews(slide);
    } else if (slide instanceof Podcast) {
      this.setPodcast(slide);
    } else if (slide instanceof PResourcesPodcasts) {
      this.setPodcastResource(slide);
    } else if (slide instanceof PodcastSeries) {
      this.setPodcastSeries(slide);
    } else if (slide instanceof Product) {
      this.setProduct(slide);
    } else if (
      slide instanceof PLmsSpotlightGridItem ||
      slide instanceof PBBSpotlightGridItem
    ) {
      this.setSpotlight(slide);
    } else if (slide instanceof ServiceWell) {
      this.setNews(slide);
    }
  }

  private setBrands(slide: Brands) {
    this.type = SlideTypes.Brands;
    this.content = slide;
  }
  private setCocktail(slide: Cocktail) {
    this.type = SlideTypes.Cocktail;
    this.content = slide;
  }
  private setEvent(slide: Event) {
    this.type = SlideTypes.Event;
    this.content = slide;
  }
  private setHero(slide: Hero) {
    this.type = SlideTypes.Hero;
    this.content = slide;
  }
  private setNews(slide: News | ServiceWell) {
    this.type = SlideTypes.News;
    this.content = slide;
  }
  private setPodcast(slide: Podcast) {
    this.type = SlideTypes.Podcast;
    this.content = slide;
  }
  private setPodcastResource(slide: PResourcesPodcasts) {
    this.type = SlideTypes.PodcastResource;
    this.content = slide;
  }
  private setPodcastSeries(slide: PodcastSeries) {
    this.type = SlideTypes.PodcastSeries;
    this.content = slide;
  }
  private setProduct(slide: Product) {
    this.type = SlideTypes.Product;
    this.content = slide;
  }
  private setSpotlight(slide: PLmsSpotlightGridItem | PBBSpotlightGridItem) {
    this.type = SlideTypes.Spotlight;
    this.content = slide;
  }
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent
  extends ComponentBase
  implements OnInit, AfterViewInit
{
  // Input data can be PResourcesPodcasts | Product | News | Event | Hero | Brands | Cocktail | Podcast | PodcastSeries;
  @Input() data;
  @Input() slidesLg;
  @Input() slidesMd;
  @Input() pageType;
  @Input() themeColor;
  @Input() header: boolean = false;
  @Input() dots: boolean = false;
  @Input() headline;
  @Input() btnColor: BtnColors;

  @ViewChild('myCarousel', { static: true }) myCarousel: NguCarousel<any>;
  @ViewChild('leftBtn', { static: true }) leftBtn: any;
  @ViewChild('rightBtn', { static: true }) rightBtn: any;
  slides: CarouselSlide[] = [];
  carouselConfig: NguCarouselConfig;
  slideType = SlideTypes;
  isArrows: boolean = false;

  constructor(
    private breakPointObserver: BreakpointObserver,
    private render: Renderer2
  ) {
    super();
  }

  ngOnInit() {
    console.log(this.data);
    this.carouselConfig = {
      grid: {
        xs: 1,
        sm: 1,
        md: this.slidesMd ? +this.slidesMd : 2,
        lg: +this.slidesLg,
        all: 0,
      },
      slide: 1,
      point: {
        visible: this.dots ? true : false,
      },
      speed: 400,
      // interval: {
      //   timing: 4000
      // },
      load: 2,
      touch: false,
      loop: true,
      easing: 'cubic-bezier(0, 0, 0.2, 1)',
    };

    if (this.btnColor) {
      this.render.setStyle(
        this.leftBtn.nativeElement,
        'backgroundColor',
        this.btnColor
      );
      this.render.setStyle(
        this.rightBtn.nativeElement,
        'backgroundColor',
        this.btnColor
      );
    }

    if (this.data) {
      this.transformSlides();
      // this.checkBrowserWidth();
    }
  }

  ngAfterViewInit() {
    if (this.data) {
      this.checkBrowserWidth();
    }
  }

  transformSlides() {
    this.data.forEach((slide) => {
      this.slides.push(new CarouselSlide(slide));
    });
  }

  checkBrowserWidth() {
    this.unSub(
      this.breakPointObserver.observe([`(min-width: 1201px)`])
    ).subscribe((result) => {
      if (result.matches) {
        this.isArrows = this.slides.length > +this.slidesLg ? true : false;
        this.myCarousel.reset();
      }
    });

    this.unSub(
      this.breakPointObserver.observe([
        `(max-width: 1200px) and (min-width: 992px)`,
      ])
    ).subscribe((result) => {
      if (result.matches) {
        this.isArrows = this.slides.length > +this.slidesMd ? true : false;
        this.myCarousel.reset();
      }
    });

    this.unSub(
      this.breakPointObserver.observe([`(max-width: 991px)`])
    ).subscribe((result) => {
      if (result.matches) {
        this.isArrows = this.slides.length > 1 ? true : false;
        this.myCarousel.reset();
      }
    });
  }
}
