import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TaxMicroCommunity } from '@proxy/service-proxies';

export interface DialogData {
  accepted: boolean;
  isAffiliate: boolean;
  isAffiliateSuccess: boolean;
  microCommunity: TaxMicroCommunity
}
@Component({
  selector: 'app-sharing-dialog',
  templateUrl: './sharing-dialog.component.html',
  styleUrls: ['./sharing-dialog.component.scss'],
})
export class SharingDialogComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<SharingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close(true);
  }
}
