import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {
  @Input('element') element: HTMLElement;
  @ViewChild('a',{static:true}) a: ElementRef;
  @ViewChild('ar',{static:true}) ar: ElementRef;

  public routed: boolean = false;
  public href: string;
  public staticHtml: string;

  constructor() { }

  ngOnInit() {
    // Check if relative or absolute link
    const href = Array.from(this.element.attributes).find(attr => attr.nodeName.toLocaleLowerCase() === 'href');
    this.routed = href && href.value.startsWith('/');
    this.staticHtml = this.element.outerHTML;
    if(href){
      this.href = href.value;
    }
    
    
  }

  private setAttributes(element: ElementRef, attributes: NamedNodeMap): void{
    Array.from(attributes).forEach((attr: Attr) =>{
      element.nativeElement.setAttribute(attr.nodeName,attr.value);
    })
  }


}
