import { Component, Input, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@core/auth/auth.service';
import { DialogComponent } from '@core/components/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import {
  AccountServiceProxy,
  IRegisterInput,
  MicroCommunityServiceProxy,
  RegisterInput,
  SubmitCodeInput,
  TaxMicroCommunity,
  UserServiceProxy,
} from '@proxy/service-proxies';
import { GtmService } from 'bsi-toolkit';
import { TranslationExtService } from '@shared/services/translation-ext.service';
import { Country } from '@modules/join/phone-country-codes';

import * as moment from 'moment';
import { FinishedDialogComponent } from './components/finished-dialog/finished-dialog.component';
import { RoutingService } from '@routing/services/routing.service';
import { ConfigKeys, ConfigService } from '@shared/services/config.service';

import { switchMap, mergeMap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss'],
})
export class UserRegistrationComponent implements OnInit {
  @Input() dialogRef: MatDialogRef<UserRegistrationComponent>;
  public userForm: FormGroup;
  private authenticated: boolean;
  public formSubmitting: boolean = false;
  public registerError: boolean = false;
  public ageValidMesasge: string = '';
  public userCountry: Country | undefined;
  public countries: Country[];

  private ageVerified: boolean = false;
  // Boolean for if we are in the US market or not so the month and day field will be switched
  public isUS: boolean = false;
  public isAU: boolean = false;
  public isIN: boolean = false;
  public isGlobal: boolean = false;

  public cookieLink: string;
  public termLink: string;
  public privacyLink: string;
  constructor(
    private accountApi: AccountServiceProxy,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private gtm: GtmService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private translateExt: TranslationExtService,
    private routingService: RoutingService,
    public dialog: MatDialog,
    private configSvc: ConfigService,
    private microSvc: MicroCommunityServiceProxy,
    private userSvc: UserServiceProxy,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.countries = this.translateExt.getCountries();
    this.userCountry = this.getCountryFromList();

    // US Market Form Group
    this.createForm(this.userCountry);

    if (localStorage.getItem('birth_day')) {
      this.userForm.controls['dobDay'].setValue(
        +localStorage.getItem('birth_day')
      );
    }
    if (localStorage.getItem('birth_month')) {
      this.userForm.controls['dobMonth'].setValue(
        +localStorage.getItem('birth_month')
      );
    }
    if (localStorage.getItem('birth_year')) {
      this.userForm.controls['dobYear'].setValue(
        +localStorage.getItem('birth_year')
      );
    }

    this.configSvc
      .getConfigurations([
        ConfigKeys.TermsLink,
        ConfigKeys.PrivacyLink,
        ConfigKeys.DrinksmartLink,
        ConfigKeys.CookieLink,
      ])
      .subscribe((links) => {
        this.termLink = links[ConfigKeys.TermsLink];
        this.privacyLink = links[ConfigKeys.PrivacyLink];
        this.cookieLink = links[ConfigKeys.CookieLink];
      });
  }

  validateAge(control) {
    const birthday = new Date(control.value);
    const age =
      new Date(Date.now() - birthday.getTime()).getUTCFullYear() - 1970;
    return age >= 21 ? null : { min: true };
  }

  passwordMatchValidator(form: FormGroup) {
    const password = form.get('password');
    const confirmPassword = form.get('confirmPassword');

    if (password.value !== confirmPassword.value) {
      confirmPassword.setErrors({ passwordMismatch: true });
    } else {
      confirmPassword.setErrors(null);
    }
  }

  openLoginDialog(event): void {
    event.preventDefault();
    this.dialogRef.close();
    const dialogRef = this.dialog.open(DialogComponent, {
      width: 'auto',
      maxWidth: '90vw',
      data: { params: this.data },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.authenticated = true;
      } else if (result === false) {
        this.authenticated = false;
      }
      // this.authenticated = result;
    });
  }

  private getCountryFromList(): Country {
    this.countries = this.translateExt.getCountries();
    let storedCountry = this.countries.find((country) => {
      return country.alpha2 === localStorage.getItem('blendCountry');
    });

    if (storedCountry) {
      return storedCountry;
    } else {
      return undefined;
    }
  }

  private createForm(country: Country) {
    if (!country) {
      this.isGlobal = true;
      this.isIN = false;
      this.isUS = false;
      this.isAU = false;
      this.userForm = this.formBuilder.group(
        {
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          password: [
            '',
            [
              Validators.required,
              Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{7,30})'),
            ],
          ],
          confirmPassword: ['', Validators.required],
          dobMonth: [
            localStorage.getItem('birth_month')
              ? +localStorage.getItem('birth_month')
              : null,
            [Validators.required, Validators.min(1), Validators.max(12)],
          ],
          dobDay: [
            localStorage.getItem('birth_day')
              ? +localStorage.getItem('birth_day')
              : null,
            [Validators.required, Validators.min(1), Validators.max(31)],
          ],
          dobYear: [
            localStorage.getItem('birth_year')
              ? +localStorage.getItem('birth_year')
              : null,
            [Validators.required, Validators.min(1900)],
          ],
          city: ['', Validators.required],
          country: [
            this.userCountry ? this.userCountry : undefined,
            Validators.required,
          ],
          community: [false, Validators.requiredTrue],
          communication: [false],
          legal: [false, Validators.requiredTrue],
          affiliate: [''],
        },
        {
          validators: this.passwordMatchValidator,
        }
      );
    } else {
      if (country && country.alpha2.toLowerCase() === 'us') {
        this.isUS = true;
        this.isAU = false;
        this.isIN = false;
        this.isGlobal = false;
        this.userForm = this.formBuilder.group(
          {
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: [
              '',
              [
                Validators.required,
                Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{7,30})'),
              ],
            ],
            confirmPassword: ['', Validators.required],
            dobMonth: [
              localStorage.getItem('birth_month')
                ? +localStorage.getItem('birth_month')
                : null,
              [Validators.required, Validators.min(1), Validators.max(12)],
            ],
            dobDay: [
              localStorage.getItem('birth_day')
                ? +localStorage.getItem('birth_day')
                : null,
              [Validators.required, Validators.min(1), Validators.max(31)],
            ],
            dobYear: [
              localStorage.getItem('birth_year')
                ? +localStorage.getItem('birth_year')
                : null,
              [Validators.required, Validators.min(1900)],
            ],
            state: ['', Validators.required],
            zipcode: ['', Validators.required],
            country: [
              this.userCountry ? this.userCountry : undefined,
              Validators.required,
            ],
            community: [false, Validators.requiredTrue],
            communication: [false],
            legal: [false, Validators.requiredTrue],
            affiliate: [''],
          },
          {
            validators: this.passwordMatchValidator,
          }
        );
        // Non Us Market Form Gruop
      } else if (country && country.alpha2.toLowerCase() == 'au') {
        this.isAU = true;
        this.isUS = false;
        this.isIN = false;
        this.isGlobal = false;
        this.userForm = this.formBuilder.group(
          {
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: [
              '',
              [
                Validators.required,
                Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{7,30})'),
              ],
            ],
            confirmPassword: ['', Validators.required],
            dobMonth: [
              localStorage.getItem('birth_month')
                ? +localStorage.getItem('birth_month')
                : null,
              [Validators.required, Validators.min(1), Validators.max(12)],
            ],
            dobDay: [
              localStorage.getItem('birth_day')
                ? +localStorage.getItem('birth_day')
                : null,
              [Validators.required, Validators.min(1), Validators.max(31)],
            ],
            dobYear: [
              localStorage.getItem('birth_year')
                ? +localStorage.getItem('birth_year')
                : null,
              [Validators.required, Validators.min(1900)],
            ],
            addressOne: ['', Validators.required],
            addressTwo: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zipcode: ['', Validators.required],
            country: [
              this.userCountry ? this.userCountry : undefined,
              Validators.required,
            ],
            community: [false, Validators.requiredTrue],
            communication: [false],
            legal: [null, Validators.requiredTrue],
            affiliate: [''],
          },
          {
            validators: this.passwordMatchValidator,
          }
        );
      } else if (country && country.alpha2.toLowerCase() == 'in') {
        this.isIN = true;
        this.isUS = false;
        this.isAU = false;
        this.isGlobal = false;

        this.userForm = this.formBuilder.group(
          {
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: [
              '',
              [
                Validators.required,
                Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{7,30})'),
              ],
            ],
            confirmPassword: ['', Validators.required],
            dobMonth: [
              localStorage.getItem('birth_month')
                ? +localStorage.getItem('birth_month')
                : null,
              [Validators.required, Validators.min(1), Validators.max(12)],
            ],
            dobDay: [
              localStorage.getItem('birth_day')
                ? +localStorage.getItem('birth_day')
                : null,
              [Validators.required, Validators.min(1), Validators.max(31)],
            ],
            dobYear: [
              localStorage.getItem('birth_year')
                ? +localStorage.getItem('birth_year')
                : null,
              [Validators.required, Validators.min(1900)],
            ],
            state: ['', Validators.required],
            country: [
              this.userCountry ? this.userCountry : undefined,
              Validators.required,
            ],
            community: [false, Validators.requiredTrue],
            communication: [false],
            legal: [false, Validators.requiredTrue],
            affiliate: [''],
          },
          {
            validators: this.passwordMatchValidator,
          }
        );
      } else {
        this.isGlobal = true;
        this.isIN = false;
        this.isUS = false;
        this.isAU = false;
        this.userForm = this.formBuilder.group(
          {
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: [
              '',
              [
                Validators.required,
                Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{7,30})'),
              ],
            ],
            confirmPassword: ['', Validators.required],
            dobMonth: [
              localStorage.getItem('birth_month')
                ? +localStorage.getItem('birth_month')
                : null,
              [Validators.required, Validators.min(1), Validators.max(12)],
            ],
            dobDay: [
              localStorage.getItem('birth_day')
                ? +localStorage.getItem('birth_day')
                : null,
              [Validators.required, Validators.min(1), Validators.max(31)],
            ],
            dobYear: [
              localStorage.getItem('birth_year')
                ? +localStorage.getItem('birth_year')
                : null,
              [Validators.required, Validators.min(1900)],
            ],
            city: ['', Validators.required],
            country: [
              this.userCountry ? this.userCountry : undefined,
              Validators.required,
            ],
            community: [false, Validators.requiredTrue],
            communication: [false],
            legal: [false, Validators.requiredTrue],
            affiliate: [''],
          },
          {
            validators: this.passwordMatchValidator,
          }
        );
      }
    }
  }

  onSubmit(): void {
    let DOB = `${this.userForm.get('dobYear').value} - ${
      this.userForm.get('dobMonth').value
    } - ${this.userForm.get('dobDay').value}`;
    this.formSubmitting = true;
    let country = this.userForm.get('country').value.alpha2;

    this.ageValidation(this.userCountry.ageLimit);

    if (this.ageVerified) {
      const formData: IRegisterInput = {
        name: this.userForm.get('firstName').value,
        surname: this.userForm.get('lastName').value,
        userName: this.userForm.get('email').value,
        emailAddress: this.userForm.get('email').value,
        password: this.userForm.get('password').value,
        captchaResponse: 'captcha test',
        addressLine1: this.userForm.controls.hasOwnProperty('addressOne')
          ? this.userForm.get('addressOne').value
          : undefined,
        addressLine2: this.userForm.controls.hasOwnProperty('addressTwo')
          ? this.userForm.get('addressTwo').value
          : undefined,
        streetNumber: undefined,
        streetName: undefined,
        city: this.userForm.controls.hasOwnProperty('city')
          ? this.userForm.get('city').value
          : undefined,
        stateProv: this.userForm.controls.hasOwnProperty('state')
          ? this.userForm.get('state').value
          : undefined,
        postalCode: this.userForm.controls.hasOwnProperty('zipcode')
          ? this.userForm.get('zipcode').value
          : undefined,
        country: country,
        unitNumber: undefined,
        yearsInIndustry: undefined,
        hearAbout: 'n/a',
        venueName: undefined,
        venueAddress1: undefined,
        venueAddress2: undefined,
        venueNumber: undefined,
        venueStreetName: undefined,
        venueCity: undefined,
        venueStateProv: undefined,
        venuePostalCode: undefined,
        venueCountry: undefined,
        yourRole: '	',
        birthday: moment(DOB, 'YYYY-M-D'),
        gender: '',
        phoneNumber: undefined,
        phoneCountryCode: undefined,
        emailOptIn: this.userForm.get('communication').value,
        smsOptIn: false,
        languageCode: undefined,
      };

      const registerForm = new RegisterInput(formData);

      this.accountApi.register(registerForm).subscribe(
        (data) => {
          this.formSubmitting = false;
          if (data.canLogin) {
            this.gtm.pushEvent({ event: 'e_accountRegister' });

            if (this.userForm.get('affiliate').value) {
              this.affiliateSubmission(registerForm);
            } else {
              // Todo integrate rerouting after successful registration
              this.accountRedirect(false, false);
              this.auth
                .signIn(
                  this.userForm.value.email,
                  this.userForm.value.password,
                  'registration'
                )
                .subscribe((response) => {
                  if (response.success) {
                    this.auth
                      .authSSO(registerForm.password)
                      .subscribe((set) => {});
                  }
                });
              localStorage.removeItem('birth_day');
              localStorage.removeItem('birth_month');
              localStorage.removeItem('birth_year');
              localStorage.removeItem('blendCountry');
            }
          } else {
            this.registerError = true;
          }
        },
        (error) => {
          const msg = JSON.parse(error.response);
          // check for specific errors
          if (
            msg.error.message ===
            'An account with the given email already exists.'
          ) {
            this._snackBar.open(
              this.translate.instant('Account.Form.EmailInUse'),
              '',
              {
                duration: 5000,
                panelClass: 'tb-snackbar-error',
              }
            );
          } else {
            this._snackBar.open(
              this.translate.instant('Account.Form.RegistrationErrorBirthday'),
              '',
              {
                duration: 5000,
                panelClass: 'tb-snackbar-error',
              }
            );
          }
          this.formSubmitting = false;
          this.registerError = true;
          // this.errorMessage = 'There was an unexpected error while processing your registration.  Please try again shortly.';
        }
      );
    }
  }

  onCountryChange(changedCountry: Country) {
    this.userCountry = this.countries.find((country) => {
      return country.alpha2 === changedCountry.alpha2;
    });
    this.createForm(changedCountry);
    this.userForm.controls['country'].setValue(changedCountry);
  }

  accountRedirect(
    isAffiliate: boolean,
    isAffiliateSuccess: boolean,
    microCommunity?: TaxMicroCommunity
  ) {
    this.gtmConfirmation();
    const dialogRef = this.dialog.open(FinishedDialogComponent, {
      width: 'auto',
      maxWidth: '450px',
      data: {
        market: this.userForm.get('country').value,
        queryParams: this.data.queryParams,
        isAffiliate: isAffiliate,
        isAffiliateSuccess: isAffiliateSuccess,
        microCommunity: microCommunity,
      },
      panelClass: 'user-dialog',
    });
  }

  gtmConfirmation() {
    this.gtm.pushEvent({
      event: 'pageview',
      component: 'blend_signup_process',
      vpv_title: 'sign_up_confirmation',
      vpv_path: '/vpv/join/sign_up_confirmation',
    });
  }

  ageValidation(minAge) {
    const birthdate = new Date(
      this.userForm.get('dobYear').value,
      this.userForm.get('dobMonth').value,
      this.userForm.get('dobDay').value
    );

    const today = new Date();
    let age = today.getFullYear() - birthdate.getFullYear();
    // Adjust age if the birthday hasn't occurred yet this year
    const monthDiff = today.getMonth() - birthdate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthdate.getDate())
    ) {
      age--;
    }

    if (age >= minAge) {
      this.ageVerified = true;
      this.ageValidMesasge = '';
    } else {
      this.ageVerified = false;
      this.ageValidMesasge = this.translate.instant(
        'Account.Join.AccessDenied'
      );
    }
  }

  affiliateSubmission(registrationForm: RegisterInput) {
    this.auth
      .signIn(
        this.userForm.value.email,
        this.userForm.value.password,
        'registration'
      )
      .pipe(
        switchMap((response) => {
          if (response.success) {
            return this.auth.authSSO(registrationForm.password).pipe(
              mergeMap(() => {
                return this.microSvc
                  .submitCode(
                    new SubmitCodeInput({
                      code: this.userForm.get('affiliate').value,
                      languageCode: 'en',
                    })
                  )
                  .pipe(
                    catchError((error) => {
                      // Handle the error here
                      this.accountRedirect(true, false);
                      // You can either return an empty observable or rethrow the error
                      return throwError(error);
                    })
                  );
              })
            );
          }
        })
      )
      .subscribe((microSvcResponse) => {
        const microSvcValue = microSvcResponse;
        // Handle the final completion or any other logic you need.
        this.accountRedirect(true, true, microSvcResponse.items[0]);
      });
  }
}
