import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { get } from 'lodash';
import { Router } from '@angular/router';
import { Brands, Product } from '@proxy/service-proxies';
@Component({
  selector: 'app-product-carousel-card',
  templateUrl: './product-card-carousel.component.html',
  styleUrls: ['./product-card-carousel.component.scss']
})
export class ProductCardCarouselComponent implements OnInit {
  @Input() data: Brands | Product;
  link: string;
  img: string;
  category: string;
  name: string;


  brandsPage: boolean;
  productsPage: boolean;


  stageUrlPrepend = environment.stageUrl;
  constructor(private imgSafePipe: ImgSafePathPipe, private router: Router) { }

  ngOnInit() {
    if (this.data instanceof Brands) {
      this.img = this.imgSafePipe.transform(this.data, `['brandImage'][0].images[0].link.card_2_3`);
      this.name = get(this.data, `['brandSubHeading']`);
      this.link = get(this.data, `['slug']`);
    } else if (this.data instanceof Product) {
      this.link = get(this.data, `[slug]`);
      this.img = this.imgSafePipe.transform(this.data, `['productImage'][0].images[0].link.card_2_3`);
      this.category = get(this.data, `['productCategory'][0].categoryHeadline`);
      this.name = get(this.data, `[productSubHeading]`);
    }
  }

}
