import { Component, OnInit, Input } from '@angular/core';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { get } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { PageType } from '@shared/interfaces/interfaces';
import {
  BlendCategory,
  PEducationSection,
  Brands,
  Product,
  TPB,
} from '@proxy/service-proxies';
import { RoutingService } from '@routing/services/routing.service';
@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss'],
})
export class CategoryCardComponent implements OnInit {
  @Input() data: BlendCategory | any;
  @Input() inverted: string;
  @Input() page: PageType;
  @Input() header: boolean = true;
  invertedBoolean: boolean;
  learnMore = true;
  blueArrow = true;
  // set to true if on /learn/categories
  categoryPage: boolean;

  perfectBlend: boolean;

  heading: string;
  img: string;
  text: string;
  link: string | undefined;
  linkMain: string | undefined;
  title: string;
  label: string;
  sectionRoute;
  constructor(
    private imgSafePipe: ImgSafePathPipe,
    private translate: TranslateService,
    private routeSvc: RoutingService
  ) {}

  ngOnInit() {
    // Refactor
    if (this.inverted) {
      this.invertedBoolean = true;
    }

    // this.categoryPage = this.page === PageType.Category;
    if (this.data instanceof BlendCategory) {
      this.categoryPage = true;
      this.title = get(this.data, `categorySubHeadline`);
      this.text = get(this.data, `body['value']`);
      // this.link = get(this.data, `slug`);
      this.linkMain = get(this.data, `slug`);
      this.img = this.imgSafePipe.transform(
        this.data,
        `featuredImage[0].images[0].link.default`
      );
      this.sectionRoute = this.routeSvc.getRouteFromContentType(
        get(this.data, `featuredItems[0]._discriminator`)
      );
      this.label = '';
    } else if (this.data instanceof PEducationSection) {
      this.heading = get(this.data, `heading`);
      this.text = get(this.data, `['itemDescription'].value`);
      this.link =
        get(this.data, `['featuredItems'][0]?.slug?.toLowerCase()`) ??
        undefined;

      this.linkMain = (get(this.data, ['heading']) || '').toLowerCase();

      this.label = this.translate.instant('Shared.CategoryCard.Featured');

      const featured: BlendCategory | Brands | Product = get(this.data, `['featuredItems'][0]`);
      if (featured instanceof BlendCategory) {
        this.img = this.imgSafePipe.transform(
          this.data,
          `['featuredItems'][0]['featuredImage'][0].images[0].link.default`
        );
        this.title = get(this.data, `['featuredItems'][0].categoryHeadline`);
      } else if (featured instanceof Brands) {
        this.img = this.imgSafePipe.transform(
          this.data,
          `['featuredItems'][0]['brandHeaderBannerImage'][0].images[0].link.default`
        );
        this.title = get(
          this.data,
          `['featuredItems'][0].brandRelatedHeadline`
        );
      } else if (featured instanceof Product) {
        this.img = this.imgSafePipe.transform(
          this.data,
          `['featuredItems'][0]['featuredImage'][0].images[0].link.default`
        );
        this.title = get(this.data, `['featuredItems'][0].productSubHeading`);
      }
    } else if (this.data instanceof TPB) {
      this.perfectBlend = true;
      this.link = '/perfect-blend/' + get(this.data, `slug`);
      this.linkMain = undefined;
      this.title = get(this.data, `heading.value`);
      if (this.data.featuredImage) {
        this.img = this.imgSafePipe.transform(
          this.data,
          `featuredImage[0].images[0].link.default`
        );
      }

      this.learnMore = false;
      this.blueArrow = false;
    }

    // End Refactor
  }
}
