import { Component, ViewChild, OnInit, ElementRef, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LocationService } from '@modules/new-join/services/location.service';
import { HttpClient } from '@angular/common/http';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-auto-complete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, AfterViewInit, OnChanges {
  public searchControl: FormControl;
  @Input() placeholder: string;
  @Input() formControlInput: string;
  @Input() form;
  @Input() tabIndex;
  @Input() userCountryInput;
  @Input('placeSearch-attribute') placeSearchAttr: boolean;
  @Output() autoCompleteValue = new EventEmitter();
  @Output() manualInput = new EventEmitter();
  @ViewChild('search', { static: false }) search: ElementRef;
  @ViewChild('placeSearch', { static: false }) placeSearch: ElementRef;
  @ViewChild('msg', { static: false }) msg: ElementRef;
  // this is set by user input
  userCountry;

  public placeSelected: boolean;
  public selectedLocation;


  public isStreet: boolean;
  public isManualInput = false;

  public showToggle: boolean = true;

  // variable for dropdown autocpmlete results so on selection of locaiton we can change styles
  private completeDropdown: Element;

  cantFindAddressPlaceholder: string;
  cantFindVenuePlaceholder: string;

  cantFind: string;

  autoCompleteOptions;
  location;
  userLocation;


  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.autoCompleteOptions = [];
    }
  }
  constructor(
    private locationService: LocationService,
    private http: HttpClient,
    private translate: TranslateService,
    private eRef: ElementRef
  ) { }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.userCountryInput) {
      this.userCountryInput = changes.userCountryInput.currentValue;
    }
    if (changes) {
      this.cantFind = this.placeSearchAttr ? this.translate.instant('Account.Join.CantFindVenue') : this.translate.instant('Account.Join.CantFindAddress');
    }
    if (changes.placeholder) {
      this.placeholder = this.placeholder;
    }

  }
  ngOnInit() {
    this.placeSearchAttr = this.placeSearchAttr !== undefined;

    this.cantFind = this.placeSearchAttr ? this.translate.instant('Account.Join.CantFindVenue') : this.translate.instant('Account.Join.CantFindAddress');

    // this.cantFindAddressPlaceholder = this.translate.instant('Account.Join.CantFindAddress');
    // this.cantFindVenuePlaceholder = this.translate.instant('Account.Join.CantFindVenue');
  }


  ngAfterViewInit() {
    if (!this.placeSearchAttr) {
      fromEvent(this.search.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            if (this.search.nativeElement.value.length > 3) {
              this.http.get(`https://search.hereapi.com/v1/autocomplete?in=countryCode:${this.userCountryInput.alpha3}&limit=10&lang=en&q=${this.search.nativeElement.value}&apiKey=rPuHGGQ9MQIM_P_ElAzyrBJEtuvmTHek8hX4tGiQnZ4`).subscribe(data => {
                this.autoCompleteOptions = data['items'];

                if (this.autoCompleteOptions['length'] === 0) {
                  this.msg.nativeElement.classList.add('visible');
                  this.msg.nativeElement.innerHTML = this.cantFind;
                } else {
                  this.msg.nativeElement.classList.remove('visible');
                }
              });
            } else {
              this.autoCompleteOptions = [];
              this.msg.nativeElement.classList.remove('visible');
            }
          })
        )
        .subscribe();
    }
    if (this.placeSearchAttr && !this.isManualInput) {
      this.locationService.userCountryRecieved.subscribe(result => {
        if (result) {
          this.userCountry = this.locationService.getUserCountry();
        }
      });

      this.locationService.userLocationRecieved.subscribe(result => {
        if (result) {
          this.userLocation = this.locationService.setUserLocation();
        }
      });

      fromEvent(this.placeSearch.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            if (this.placeSearch.nativeElement.value.length > 3) {
              this.http.get(`https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey=rPuHGGQ9MQIM_P_ElAzyrBJEtuvmTHek8hX4tGiQnZ4&q=${this.placeSearch.nativeElement.value}&in=countryCode:${this.userCountry}&limit=10&at=${this.userLocation.position.lat},${this.userLocation.position.lng}&resultType=place`).subscribe(data => {
                // const placeType = ['place', 'houseNumber', 'street'];
                this.autoCompleteOptions = data['items'].filter(result => {
                  // return placeType.some(result.resultType);
                  return result.resultType === 'place' || result.resultType === 'houseNumber' || result.resultType === 'street';
                });

                if (this.autoCompleteOptions['length'] === 0) {
                  this.msg.nativeElement.classList.add('visible');
                  this.msg.nativeElement.innerHTML = this.cantFind;
                } else {
                  this.msg.nativeElement.classList.remove('visible');
                }
              });
            } else {
              this.autoCompleteOptions = [];
              this.msg.nativeElement.classList.remove('visible');
            }
          })
        )
        .subscribe();
    }

  }


  toggleManualInput() {
    this.isManualInput = true;
    if (this.placeSearchAttr) {
      this.placeSearch.nativeElement.value = '';
    } else {
      this.search.nativeElement.value = '';
    }
    this.manualInput.emit(this.isManualInput);
    this.msg.nativeElement.classList.remove('visible');
  }

  enableManualInput() {
    this.isManualInput = true;
    this.msg.nativeElement.classList.remove('visible');
  }

  selectLocation(location) {
    this.location = location;
    if (this.placeSearchAttr) {
      this.placeSearch.nativeElement.value = location.address.label;
    } else {
      this.search.nativeElement.value = location.address.label;
      this.locationService.setUserCountry(this.location);
    }
    this.autoCompleteValue.emit(location);
    // const address = this.location.address;
    this.autoCompleteOptions = [];



    // this.http.get(`https://geocode.search.hereapi.com/v1/geocode?q=${address.label}&apikey=rPuHGGQ9MQIM_P_ElAzyrBJEtuvmTHek8hX4tGiQnZ4&in=countryCode:USA`).subscribe(result => {
    //   this.locationService.getUserLocation(result['items'][0]);
    // });

    this.isManualInput = true;
  }

  closeAutoComplete() {
    setTimeout(() => {
      this.autoCompleteOptions = [];
    }, 100);

  }


}



