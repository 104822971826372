// import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
// import {
//   CanActivate,
//   ActivatedRouteSnapshot,
//   RouterStateSnapshot,
//   UrlTree,
//   Router,
//   Params,
// } from '@angular/router';
// import { Observable, of } from 'rxjs';
// import { AuthService } from './auth.service';
// import { map, take } from 'rxjs/operators';
// import { isPlatformBrowser } from '@angular/common';
// import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
// import { MarketService } from '@shared/services/market.service';

// @Injectable({
//   providedIn: 'root',
// })
// export class AuthGuard implements CanActivate {
//   authenticated: boolean;
//   currentMarket;
//   constructor(
//     private auth: AuthService,
//     private localize: LocalizeRouterService,
//     private router: Router,
//     private marketSvc: MarketService,
//     @Inject(PLATFORM_ID) private platform: Object
//   ) {}
//   canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): Observable<boolean> | boolean {
//     // current iteration
//     if (isPlatformBrowser(this.platform)) {
//       return this.auth.isAuthenticated().pipe(
//         map((x) => {
//           if (x === true) {
//             return true;
//           } else {
//             const redirectUrl = next.pathFromRoot
//               .filter((v) => v.url.length > 0)
//               .map((v) => v.url.map((segment) => segment.toString()).join('/'))
//               .join('/');
//             // this.router.navigate([this.localize.translateRoute('/access-page'), {'return': redirectUrl}]);
//             const params: Params = { return: redirectUrl };
//             this.router.navigate(
//               [this.localize.translateRoute('/access-page')],
//               { queryParams: params }
//             );
//             return false;
//           }
//         })
//       );
//     } else {
//       return true;
//     }

//     // if (isPlatformBrowser(this.platform)) {
//     //   // return this.check(next);
//     //   this.getCountries1();
//     //   if (this.getCountries1()) {
//     //   }
//     //   this.getCountries().then((market) => {
//     //     if (market.country === 'US') {
//     //       return this.check(next);
//     //     } else {
//     //       return of(true);
//     //     }
//     //   });
//     // } else {
//     //   return true;
//     // }
//   }

//   // check(next) {
//   //   console.log(next);
//   //   console.log(this.auth);
//   //   return this.auth.isAuthenticated().pipe(
//   //     map((x) => {
//   //       console.log(x);
//   //       if (x === true) {
//   //         return true;
//   //       } else {
//   //         const redirectUrl = next.pathFromRoot
//   //           .filter((v) => v.url.length > 0)
//   //           .map((v) => v.url.map((segment) => segment.toString()).join('/'))
//   //           .join('/');
//   //         // this.router.navigate([this.localize.translateRoute('/access-page'), {'return': redirectUrl}]);
//   //         const params: Params = { return: redirectUrl };
//   //         this.router.navigate([this.localize.translateRoute('/access-page')], {
//   //           queryParams: params,
//   //         });
//   //         return false;
//   //       }
//   //     })
//   //   );
//   // }

//   // getCountries() {
//   //   return this.marketSvc.getCurrentMarket().toPromise();
//   // }

//   // getCountries1() {
//   //   return this.marketSvc.getCurrentMarket().pipe(take(1)).toPromise();
//   // }
// }

// Chat GPT Code

import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map, take } from 'rxjs/operators';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private localize: LocalizeRouterService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.auth.isAuthenticated().pipe(
      take(1),
      map((authenticated) => {
        if (authenticated) {
          return true;
        } else {
          const redirectUrl = next.pathFromRoot
            .filter((v) => v.url.length > 0)
            .map((v) => v.url.map((segment) => segment.toString()).join('/'))
            .join('/');
          const params: { return: string } = { return: redirectUrl };
          this.router.navigate([this.localize.translateRoute('/access-page')], {
            queryParams: params,
          });
          return false;
        }
      })
    );
  }
}
