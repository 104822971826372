<ng-container *ngFor="let el of elements;">
  
  <!-- Plain element with no children -->
  <div *ngIf="!el.dynamic && el.html.children.length === 0" [outerHTML]="el.html.outerHTML | sanitize"></div>

  <!-- Handle elements with children -->
  <ng-container *ngIf="el.html.children.length > 0">
    <app-outer-wrapper #wrapperBasic [element]="el"></app-outer-wrapper>
    <ng-content *ngTemplateOutlet='wrapperBasic.childComponentTemplate'></ng-content>
  </ng-container>
  
  <!-- Dynamic with no children -->
  

  <!-- <ng-container *ngIf="!el.dynamic && el.html.children.length > 0">
    <app-outer-wrapper #wrapperBasic [element]="el"></app-outer-wrapper>
    <ng-content *ngTemplateOutlet='wrapperBasic.childComponentTemplate'></ng-content>
  </ng-container>
  
  <ng-container *ngIf="el.dynamic">
    <app-dynamic-wrapper #wrapper [element]="el.html"></app-dynamic-wrapper>
    <ng-content *ngTemplateOutlet='wrapper.childComponentTemplate'></ng-content>
  </ng-container> -->
</ng-container>
<div [outerHTML]="ssrHtml"></div>