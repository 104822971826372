import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@core/auth/auth.service';
import { SignInComponent } from '@core/auth/components/sign-in/sign-in.component';
// import { SignInComponent }

export interface DialogData {
  authenticated: boolean;
}
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @ViewChild('siginin', { static: true }) signin: SignInComponent;

  loginForm: FormGroup;
  resetForm: FormGroup;
  authenticated: boolean;
  errorMsg: string;
  loginError = false;
  forgotPassword = false;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.auth.subjectAuthenticated.subscribe((authenticated) => {
      this.authenticated = authenticated;
    });
  }

  ngOnInit() {}
}
