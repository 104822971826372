import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { SurfaceColors, TwoUpPageTypes, TwoUpPageColors } from '@core/enum/enum';
import { PageBase } from '@core/interfaces/page-base';
@Component({
    template: ``
})
export class ComponentBase implements OnDestroy {
    public ngUnsubscribe = new Subject();
    public SurfaceColors = SurfaceColors;
    public TwoUpPageTypes = TwoUpPageTypes;
    public TwoUpPageColors = TwoUpPageColors;
    constructor() {

    }

    public unSub(call: Observable<any>): Observable<any> {
        return call.pipe(takeUntil(this.ngUnsubscribe));
    }


    ngOnDestroy() {
        this.ngUnsubscribe.next({});
        this.ngUnsubscribe.complete();
    }
}
