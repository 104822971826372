import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import { IBreadcrumbs } from '@shared/services/link.service';
import { PageService } from '@shared/services/page.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() breadcrumb: string;
  @Input() pageData;
  @Input() paddingTop: boolean;
  @Input() darkBackground: boolean;
  @Input() grayBackground: boolean;
  breadcrumbs: IBreadcrumbs[] = [];

  constructor(
    private router: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    protected pageSvc: PageService
  ) {}

  ngOnInit() {
    this.breadcrumbsService.clean();
    this.breadcrumbs = this.breadcrumbsService.breadcrumbs;

    if (this.breadcrumb) {
      this.breadcrumbsService.breadcrumbLink({}, this.breadcrumb);
    } else {
      this.breadcrumbsService.getBreadcrumbs(
        this.pageData,
        this.router.snapshot.params
      );
    }
  }
}
