import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { get } from 'lodash';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';

import { environment } from '@env/environment';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { PageType } from '@shared/interfaces/interfaces';
import { SocialShareService } from '@shared/services/social-share.service';

@Component({
  selector: 'app-cocktail-detail-card',
  templateUrl: './cocktail-detail-card.component.html',
  styleUrls: ['./cocktail-detail-card.component.scss'],
})
export class CocktailDetailCardComponent implements OnInit, AfterViewInit {
  @Input() data;
  @Input() type: PageType;
  @Input() locale;
  @Input() header: boolean;
  @Input() isDarkMode: boolean;
  @ViewChild('pinterestElement') pinterestElement: ElementRef;
  @ViewChild('emailElement') emailElement: ElementRef;
  img: string;
  heading: string;
  description: string;
  glassware: string;
  garnish: string;
  ingredients: string;
  method: string;
  brand: string;
  brandSlug: string;
  category: string;
  categorySlug: string;
  winner: boolean;

  visible_recipe_mail_body: Array<string>;

  visible_recipe_mail_body_1: string;
  visible_recipe_mail_body_2: string;
  visible_recipe_mail_body_3: string;
  visible_recipe_mail_body_link: string;

  baseUrl = environment.baseUrl;

  perfectBlendLocation: boolean;
  constructor(
    private imgSafePipe: ImgSafePathPipe,
    private meta: Meta,
    private socialShareSvc: SocialShareService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const cocktail = this.data;
    if (this.type === PageType.PerfectBlendFinalist) {
      this.perfectBlendLocation = true;
    } else {
      this.perfectBlendLocation = false;
    }

    this.img = this.imgSafePipe.transform(cocktail, `image[0].images[0].link.default`);
    this.heading = get(cocktail, `heading.value`);
    this.description = get(cocktail, `body.value`);
    this.glassware = get(cocktail, `glassware`);
    this.garnish = get(cocktail, `garnish`);
    this.method = get(cocktail, `methodDescription`);
    this.ingredients = get(cocktail, `ingredients`);
    this.category = get(cocktail, `brand[0]category[0].categorySubHeadline`);
    this.categorySlug = get(cocktail, `brand[0]category[0].slug`);
    this.brand = get(cocktail, `brand[0]brandSubHeading`);
    this.brandSlug = get(cocktail, `brand[0].slug`);
    this.winner = get(cocktail, `cocktailWinner`);

    this.visible_recipe_mail_body_1 = this.translate.instant(
      'Cocktail.Email.Line1',
      { heading: this.heading }
    );
    this.visible_recipe_mail_body_2 = this.translate.instant(
      'Cocktail.Email.Line2'
    );
    this.visible_recipe_mail_body_3 = this.translate.instant(
      'Cocktail.Email.Line3'
    );

    this.visible_recipe_mail_body = [
      this.visible_recipe_mail_body_1,
      this.visible_recipe_mail_body_2,
      this.visible_recipe_mail_body_3,
    ];
    this.visible_recipe_mail_body = this.visible_recipe_mail_body.map(
      (line) => {
        return line.replace(/&/i, '%26');
      }
    );

    this.visible_recipe_mail_body_link = `${environment.baseUrl}/cocktails/${this.data.slug}`;
  }

  ngAfterViewInit(): void {
    this.socialShareSvc.pinterestLinkSetup(
      this.pinterestElement.nativeElement,
      this.data,
      this.heading,
      this.img
    );

    this.socialShareSvc.emailLinkSetup(
      this.emailElement.nativeElement,
      this.data,
      this.heading,
      this.visible_recipe_mail_body
    );
  }
  twitterShare() {
    this.socialShareSvc.twitter(this.data, this.heading);
  }

  emailShare(event) {
    event.preventDefault();
    this.socialShareSvc.emailShare(this.emailElement);
  }

  facebookShare() {
    this.socialShareSvc.facebook(this.data);
  }

  pinterestShare(event) {
    event.preventDefault();
    this.socialShareSvc.pinterestShare(this.pinterestElement);
  }

  updateTitle(title: string) {
    this.meta.updateTag({
      property: 'og:title',
      content: `${this.translate.instant('Cocktail.BrowserTitle')} | ${
        this.heading
      }`,
    });
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ property: 'og:description', content: desc });
  }

  updateImage(img) {
    this.meta.updateTag({ property: 'og:image', content: img });
  }
}
