import { Component, OnInit, Input } from '@angular/core';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { LinkItem, LinkService } from '@shared/services/link.service';
@Component({
  selector: 'app-ambassador-card',
  templateUrl: './ambassador-card.component.html',
  styleUrls: ['./ambassador-card.component.scss'],
})
export class AmbassadorCardComponent implements OnInit {
  @Input() data;
  public heading: string;
  public image: string;
  public bgImg: string;
  public link: LinkItem;
  constructor(
    private imgSafePipe: ImgSafePathPipe,
    private linkSvc: LinkService
  ) {}

  ngOnInit(): void {
    this.heading = this.data.heading;
    this.image = this.imgSafePipe.transform(
      this.data,
      'profileImage.images[0].link.card_3_4'
    );
    this.bgImg = this.imgSafePipe.transform(
      this.data,
      ' style[0].featuredStyleItem[0].styleImage.images[0].link.card_3_4'
    );
    this.link = this.linkSvc.getLink(this.data);
  }
}
