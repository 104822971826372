import { Component, OnInit, Input } from '@angular/core';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { get } from 'lodash';
import {
  PBotwSection,
  PPDFSection,
  PBrandParagraph,
  PCategoryParagraph,
  PTPBSection,
  PCommunitySection,
  PLmsPingPong,
  PNewsPingPong,
  PSWPingPong,
  PSpreadsheetsSection,
  PBBPingPong,
} from '@proxy/service-proxies';

enum MediaTypes {
  Image = 'img',
  Video = 'video',
}

class Media {
  public fileType: MediaTypes;
  public img?: string;
  public video?: string;

  private Types = MediaTypes;

  constructor(
    data:
      | PBotwSection
      | PBBPingPong
      | PPDFSection
      | PBrandParagraph
      | PCategoryParagraph
      | PTPBSection
      | PCommunitySection
      | PLmsPingPong
      | PNewsPingPong
      | PSpreadsheetsSection,
    private imgSafePipe: ImgSafePathPipe
  ) {
    if (data instanceof PBotwSection) {
      this.setBotw(data);
    } else if (
      data instanceof PPDFSection ||
      data instanceof PSpreadsheetsSection
    ) {
      this.setPDF(data);
    } else if (data instanceof PBrandParagraph) {
      this.setBrand(data);
    } else if (data instanceof PCategoryParagraph) {
      this.setCategory(data);
    } else if (data instanceof PTPBSection) {
      this.setPerfectBlend(data);
    } else if (data instanceof PCommunitySection) {
      this.setCommunity(data);
    } else if (data instanceof PLmsPingPong) {
      this.setLms(data);
    } else if (data instanceof PSWPingPong) {
      this.setNewsPingPong(data);
    } else if (data instanceof PNewsPingPong || data instanceof PBBPingPong) {
      this.setNewsPingPong(data);
    } else {
      this.setDefault(data);
    }
  }

  private setBotw(data) {
    this.fileType =
      data.image._discriminator === 'Image'
        ? this.Types.Image
        : this.Types.Video;
    if (this.fileType === this.Types.Image) {
      this.img = this.imgSafePipe.transform(data, `image.images[0]link.default`);
    } else if (this.fileType === this.Types.Video) {
      this.video = data.image.url;
    }
  }

  private setPDF(data) {
    if (data.image) {
      this.fileType =
        data.image._discriminator === 'Image'
          ? this.Types.Image
          : this.Types.Video;
    }
    if (this.fileType === this.Types.Image) {
      this.img = this.imgSafePipe.transform(data, `image.images[0]link.default`);
    } else if (this.fileType === this.Types.Video) {
      this.video = data.image.url;
    }
  }

  private setBrand(data) {
    this.fileType =
      data.itemImage[0]._discriminator === 'Image'
        ? this.Types.Image
        : this.Types.Video;
    if (this.fileType === this.Types.Image) {
      this.img = this.imgSafePipe.transform(data, `itemImage[0].images[0].link.default`);
    } else if (this.fileType === this.Types.Video) {
      this.video = data.image.url;
    }
  }

  private setCategory(data) {
    this.fileType =
      data.itemImage[0]._discriminator === 'Image'
        ? this.Types.Image
        : this.Types.Video;
    if (this.fileType === this.Types.Image) {
      this.img = this.imgSafePipe.transform(data, `itemImage[0].images[0].link.default`);
    } else if (this.fileType === this.Types.Video) {
      this.video = data.image.url;
    }
  }

  private setPerfectBlend(data) {
    if (data.image) {
      this.fileType =
        data.image._discriminator === 'Image'
          ? this.Types.Image
          : this.Types.Video;
      if (this.fileType === this.Types.Image) {
        this.img = this.imgSafePipe.transform(data, `image.images[0]link.default`);
      } else if (this.fileType === this.Types.Video) {
        this.video = data.image.url;
      }
    }
  }

  private setNewsPingPong(data) {
    if (data.image) {
      this.fileType =
        data.image._discriminator === 'Image'
          ? this.Types.Image
          : this.Types.Video;
      if (this.fileType === this.Types.Image) {
        this.img = this.imgSafePipe.transform(data, `image.images[0]link.default`);
      } else if (this.fileType === this.Types.Video) {
        this.video = data.image.url;
      }
    }
  }

  private setCommunity(data) {
    this.fileType =
      data.image._discriminator === 'Image'
        ? this.Types.Image
        : this.Types.Video;
    if (this.fileType === this.Types.Image) {
      this.img = this.imgSafePipe.transform(data, `image.images[0]link.default`);
    } else if (this.fileType === this.Types.Video) {
      this.video = data.image.url;
    }
  }

  private setLms(data) {
    this.fileType =
      get(data, `image._discriminator`) === 'Image'
        ? this.Types.Image
        : this.Types.Video;
    if (this.fileType === this.Types.Image) {
      this.img = this.imgSafePipe.transform(data, `image.images[0]link.default`);
    } else if (this.fileType === this.Types.Video) {
      this.video = data.image.url;
    }
  }

  private setDefault(data) {
    this.fileType = data.imgSrc ? this.Types.Image : this.Types.Video;
    if (data.imgSrc.video) {
      this.fileType = this.Types.Video;
      this.video = data.imgSrc.src;
    } else {
      if (this.fileType === this.Types.Image) {
        this.img = this.imgSafePipe.transform(data, `imgSrc`);
      } else if (this.fileType === this.Types.Video) {
        this.video = data.image.url;
      }
    }
  }
}

@Component({
  selector: 'app-two-up-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class TwoUpMediaComponent implements OnInit {
  @Input() media;
  @Input() page;
  @Input() goldBorder;

  displayedMedia: Media;

  isImg: boolean;
  isVideo: boolean;
  image: string;
  videoSrc: string;

  constructor(public imgSafePipe: ImgSafePathPipe) {}

  ngOnInit() {
    if (this.media) {
      this.displayedMedia = new Media(this.media, this.imgSafePipe);
    }
  }
}
