import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from '@core/classes/component-base';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { LinkService, LinkItem } from '@shared/services/link.service';

@Component({
  selector: 'app-featured-content',
  templateUrl: './featured-content.component.html',
  styleUrls: ['./featured-content.component.scss'],
})
export class FeaturedContentComponent extends ComponentBase implements OnInit {
  @Input() data;
  @Input() authenticated = false;
  public link;
  public showLink = false;
  public linkItem: LinkItem;
  featuredContent;


  constructor(
    public imgSafePipe: ImgSafePathPipe,
    private linkSvc: LinkService
  ) {
    super();
  }

  ngOnInit() {
    this.featuredContent = this.data;



    // use link service

    if (this.featuredContent.pageLink) {
      this.linkItem = this.linkSvc.getLink(this.featuredContent.pageLink);
      this.link = this.linkItem.link;

      this.showLink = this.linkItem.privateLink ? this.authenticated : true;
      // switch (this.featuredContent.pageLink._discriminator) {
      //   case 'Home':
      //     this.link = '/';
      //     break;
      // }
    }
  }
}
