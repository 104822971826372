import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { environment } from '../../environments/environment';
import { ApiInterceptor } from '../../app/core/auth/api.interceptor';

export function getRemoteServiceBaseUrl(): string {
  return environment.apiBaseUrl;
}

@NgModule({
  providers: [
    {
      provide: ApiServiceProxies.API_BASE_URL,
      useFactory: getRemoteServiceBaseUrl,
    },
    ApiServiceProxies.AuditLogServiceProxy,
    ApiServiceProxies.CachingServiceProxy,
    ApiServiceProxies.ChatServiceProxy,
    ApiServiceProxies.CommonLookupServiceProxy,
    ApiServiceProxies.EditionServiceProxy,
    ApiServiceProxies.FriendshipServiceProxy,
    ApiServiceProxies.HostSettingsServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.LanguageServiceProxy,
    ApiServiceProxies.NotificationServiceProxy,
    ApiServiceProxies.OrganizationUnitServiceProxy,
    ApiServiceProxies.PermissionServiceProxy,
    ApiServiceProxies.ProfileServiceProxy,
    ApiServiceProxies.RoleServiceProxy,
    ApiServiceProxies.SessionServiceProxy,
    ApiServiceProxies.TenantServiceProxy,
    ApiServiceProxies.TenantDashboardServiceProxy,
    ApiServiceProxies.TenantSettingsServiceProxy,
    ApiServiceProxies.TimingServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.UserLinkServiceProxy,
    ApiServiceProxies.UserLoginServiceProxy,
    ApiServiceProxies.WebLogServiceProxy,
    ApiServiceProxies.AccountServiceProxy,
    ApiServiceProxies.TokenAuthServiceProxy,
    ApiServiceProxies.TenantRegistrationServiceProxy,
    ApiServiceProxies.HostDashboardServiceProxy,
    ApiServiceProxies.PaymentServiceProxy,
    ApiServiceProxies.DemoUiComponentsServiceProxy,
    ApiServiceProxies.InvoiceServiceProxy,
    ApiServiceProxies.SubscriptionServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.UiCustomizationSettingsServiceProxy,
    ApiServiceProxies.PayPalPaymentServiceProxy,
    ApiServiceProxies.StripePaymentServiceProxy,
    ApiServiceProxies.NewsServiceProxy,
    ApiServiceProxies.BrandsServiceProxy,
    ApiServiceProxies.PageServiceProxy,
    ApiServiceProxies.CategoryServiceProxy,
    ApiServiceProxies.GeekOutServiceProxy,
    ApiServiceProxies.ProductServiceProxy,
    ApiServiceProxies.BarFlyServiceProxy,
    ApiServiceProxies.FilterServiceProxy,
    ApiServiceProxies.CocktailServiceProxy,
    ApiServiceProxies.MenuServiceProxy,
    ApiServiceProxies.EventServiceProxy,
    ApiServiceProxies.AuthServiceProxy,
    ApiServiceProxies.BotwServiceProxy,
    ApiServiceProxies.TPBServiceProxy,
    ApiServiceProxies.UtilServiceProxy,
    ApiServiceProxies.PodcastServiceProxy,
    ApiServiceProxies.ServiceDirectoryServiceProxy,
    ApiServiceProxies.BookServiceProxy,
    ApiServiceProxies.HiveMindServiceProxy,
    ApiServiceProxies.ServiceWellServiceProxy,
    ApiServiceProxies.TPBCompServiceProxy,
    ApiServiceProxies.RootsServiceProxy,
    ApiServiceProxies.BartenderBasicsServiceProxy,
    ApiServiceProxies.TPBRegionsServiceProxy,
    ApiServiceProxies.TPBPrelimServiceProxy,
    ApiServiceProxies.TPBVoteServiceServiceProxy,
    ApiServiceProxies.TPBJuryServiceServiceProxy,
    ApiServiceProxies.MicroCommunityServiceProxy,
    ApiServiceProxies.BrandAmbassadorsServiceProxy,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  ],
})
export class ServiceProxyModule {}
