import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationCountriesService } from './translation-countries.service';
import { Country } from '@modules/join/phone-country-codes';
import { CountryTranslation } from 'bsi-toolkit-agegate';

@Injectable({
  providedIn: 'root'
})
export class TranslationExtService {
  constructor(
    private translate: TranslateService,
    private translateCountry: TranslationCountriesService) {
    // Array of User Roles
    this.translate.instant([
      'Account.Form.Roles.Owner',
      'Account.Form.Roles.DirectorofOperations',
      'Account.Form.Roles.Manager',
      'Account.Form.Roles.BeverageDirector',
      'Account.Form.Roles.BarManager',
      'Account.Form.Roles.Bartender',
      'Account.Form.Roles.ServerWaitStaff',
      'Account.Form.Roles.Barback',
      'Account.Form.Roles.Host',
      'Account.Form.Roles.BackofHouse'
    ]);
    this.translate.instant(['Account.Form.Genders.Male', 'Account.Form.Genders.Female', 'Account.Form.Genders.PreferNotToSay']);
    this.translate.instant([
      'Account.Form.Pronouns.HeHim',
      'Account.Form.Pronouns.SheHer',
      'Account.Form.Pronouns.TheyThem',
      'Account.Form.Pronouns.ZeZirHir',
      'Account.Form.Pronouns.NotListed']);
    this.translate.instant(['Contact.Reasons.1', 'Contact.Reasons.2', 'Contact.Reasons.3', 'Contact.Reasons.4']);
  }

  public getUserRoles() {
    return this.getArrayFromKey('Account.Form.Roles');
  }
  public getRoles() {
    const roles = {
      'BackofHouse': this.translate.instant('Account.Form.Roles.BackofHouse'),
      'Barback': this.translate.instant('Account.Form.Roles.Barback'),
      'BarManager': this.translate.instant('Account.Form.Roles.BarManager'),
      'Bartender': this.translate.instant('Account.Form.Roles.Bartender'),
      'BeverageDirector': this.translate.instant('Account.Form.Roles.BeverageDirector'),
      'BSEmployee': this.translate.instant('Account.Form.Roles.BSEmployee'),
      'DirectorofOperations': this.translate.instant('Account.Form.Roles.DirectorofOperations'),
      'Host': this.translate.instant('Account.Form.Roles.Host'),
      'Manager': this.translate.instant('Account.Form.Roles.Manager'),
      'Owner': this.translate.instant('Account.Form.Roles.Owner'),
      'ServerWaitStaff': this.translate.instant('Account.Form.Roles.ServerWaitStaff')
    };
    return roles;
  }

  public getUserGenders(): string[] {
    return this.getArrayFromKey('Account.Form.Genders');
  }

  public getCountries(): Country[] {
    return this.translateCountry.getCountries();
  }

  public getCountriesAgeGate(): CountryTranslation[] {
    const countries: CountryTranslation[] = [];
    this.translateCountry.getCountries().forEach(country => {
      countries.push({
        name: country.name,
        code: country.alpha2
      });
    });
    return countries;
  }

  public getContactReasons(): string[] {
    return this.getArrayFromKey('Contact.Reasons');
  }

  private getArrayFromKey(key: string): string[] {
    return Object.values<string>(this.translate.instant(key)).filter(a => a !== '');
  }

  public getPronouns() {
    const pronouns = {
      'HeHim': this.translate.instant('Account.Form.Pronouns.HeHim'),
      'SheHer': this.translate.instant('Account.Form.Pronouns.SheHer'),
      'TheyThem': this.translate.instant('Account.Form.Pronouns.TheyThem'),
      'ZeZirHir': this.translate.instant('Account.Form.Pronouns.ZeZirHir'),
      'NotListed': this.translate.instant('Account.Form.Pronouns.NotListed'),
    };

    return pronouns;
    // return this.getArrayFromKey('Account.Form.Pronouns');
  }
}
