import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';
import { get } from 'lodash';
/**
 * Returns a full URL path for an image
 */
@Pipe({
  name: 'img'
})
export class ImgPipe implements PipeTransform {

  constructor() { }
  transform(value: string, args?: any): any {
    return value && value.startsWith(environment.assetUrl) ? value : `${environment.assetUrl}${value}`;
  }
}

/**
 * Returns first image in an array
 */
@Pipe({
  name: 'imgFirst'
})
export class ImgSafePathPipe implements PipeTransform {

  constructor(private imgPipe: ImgPipe) { }
  transform(item: any, path: any, absolute: boolean = true): any {
    return absolute ? this.imgPipe.transform(get(item, path)) : get(item, path);
  }
}