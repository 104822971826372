<!-- BVI -->
<div
  class="tb-card tb-card__cocktail tb-card__detail tb-card--full text-center"
  [ngClass]="{ darkmode: isDarkMode }"
>
  <div class="row">
    <div class="col-md-4">
      <div class="media__figure thumb-large" title="thumb image">
        <img appLazyLoad [src]="img" alt=""width="522" height="696"/>
      </div>
    </div>
    <div class="col-md-8">
      <div class="icon" *ngIf="winner">
        <img src="/assets/images/cards/trophy-icon.svg" alt="" width="18" height="18"/>
      </div>
      <div class="tb-card__label label text-uppercase fw-bold">
        <span>
          <a
            class="tb-card__link"
            [ngClass]="{ goldLink: perfectBlendLocation }"
            [routerLink]="['/learn/categories' | localize, categorySlug]"
            >{{ category }}</a
          >
          /
          <a
            class="tb-card__link"
            [ngClass]="{ goldLink: perfectBlendLocation }"
            [routerLink]="['/learn/brands' | localize, brandSlug]"
            >{{ brand }}</a
          ></span
        >
      </div>
      <h3 class="tb-card__title h1 text-uppercase" [innerHTML]="heading"></h3>
      <div class="media__figure thumb-mobile" title="thumb image">
        <img [src]="img" alt="" />
      </div>
      <div class="tb-card__description-text" [innerHTML]="description"></div>
      <div class="row tb-card__accordion text-left">
        <div class="col-md-6 align-items">
          <li class="item" *ngIf="ingredients">
            <div class="content-wrapper">
              <h5 class="button-type item-title">
                {{ 'Cocktail.Card.Ingredients' | translate }}
              </h5>
              <div class="content">
                <app-cocktail-ingredients
                  [data]="ingredients"
                  [locale]="locale"
                  [type]="type"
                >
                </app-cocktail-ingredients>
              </div>
            </div>
          </li>

          <div class="item" *ngIf="!isDarkMode">
            <div class="content-wrapper">
              <h5 class="button-type item-title">Share Now</h5>
              <div class="content">
                <ul class="social">
                  <li (click)="facebookShare()">
                    <img appLazyLoad src="/assets/images/cards/facebook-icon.webp" alt="" />
                  </li>
                  <li (click)="twitterShare()">
                    <img appLazyLoad src="/assets/images/cards/Twitter-icon.webp" alt="" />
                  </li>
                  <li (click)="pinterestShare($event)">
                    <a #pinterestElement href="" target="_blank">
                      <img appLazyLoad src="/assets/images/cards/Pinterest-icon.webp" alt=""
                    /></a>
                  </li>
                  <li (click)="emailShare($event)">
                    <a #emailElement>
                      <img appLazyLoad src="/assets/images/cards/Share-icon.webp" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 align-items">
          <div class="item" *ngIf="garnish">
            <div class="content-wrapper">
              <h5 class="button-type item-title">
                {{ 'Cocktail.Card.Garnish' | translate }}
              </h5>
              <p class="content" [innerHTML]="garnish"></p>
            </div>
          </div>
          <div class="item" *ngIf="method">
            <div class="content-wrapper">
              <h5 class="button-type item-title">
                {{ 'Cocktail.Card.Method' | translate }}
              </h5>
              <p class="content" [innerHTML]="method"></p>
            </div>
          </div>

          <div class="item" *ngIf="glassware">
            <div class="content-wrapper">
              <h5 class="button-type item-title">
                {{ 'Cocktail.Card.Glassware' | translate }}
              </h5>
              <p class="content" [innerHTML]="glassware"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->

<!-- <article class="tb-card tb-card__cocktail tb-card__detail tb-card--full text-center">
  <div class="row">
    <div class="col-md-4">
      <div class="media__figure thumb-large" title="thumb image">
        <img src="/assets/images/cards/cocktail-thumb-large.png" alt="">
      </div>
    </div>
    <div class="col-md-8">
      <div class="icon" *ngIf="winner">
        <img src="/assets/images/cards/trophy-icon.svg" alt="">
      </div>
      <div class="tb-card__label label text-uppercase fw-bold">
        <span> <a class="tb-card__link" [ngClass]="{goldLink: perfectBlendLocation}"
            [routerLink]="['/learn/categories' | localize, categorySlug]">{{category}}</a> / <a class="tb-card__link"
            [ngClass]="{goldLink: perfectBlendLocation}"
            [routerLink]="['/learn/brands' | localize, brandSlug]">{{brand}}</a></span>
      </div>
      <h3 class="tb-card__title h1  text-uppercase" [innerHTML]="heading">
      </h3>
      <div class="media__figure thumb-mobile" title="thumb image">
        <img src="/assets/images/cards/cocktail-thumb-large.png" alt="">
      </div>
      <div class="tb-card__description-text" [innerHTML]="description">

      </div>
      <ul class="tb-card__accordion text-left">
        <li class="item">
        </li>
        <li class="item">
          <div class="content-wrapper">
            <h5 class="button-type item-title">
              {{ 'Cocktail.Card.Method' | translate }}
            </h5>
            <div class="content" [innerHTML]="method">
            </div>
          </div>
        </li>
        <li class="item" *ngIf="ingredients">
          <div class="content-wrapper">
            <h5 class="button-type item-title">
              {{ 'Cocktail.Card.Ingredients' | translate }}
            </h5>
            <div class="content">
              <app-cocktail-ingredients [data]='ingredients' [locale]="locale" [type]="type">
              </app-cocktail-ingredients>
            </div>
          </div>
        </li>

        <li class="item">
          <div class="content-wrapper">
            <h5 class="button-type item-title">
              {{ 'Cocktail.Card.Glassware' | translate }}
            </h5>
            <div class="content" [innerHTML]="glassware">
            </div>
          </div>
        </li>
        <li class="item">
          <div class="content-wrapper">
            <h5 class="button-type item-title">
              Share Now
            </h5>
            <div class="content">
              <ul class="social">
                <li (click)="facebookShare()"><a href="#">
                    <img src="/assets/images/cards/facebook-icon.png" alt="">
                  </a></li>
                <li (click)="twitterShare()"><a href="#">
                    <img src="/assets/images/cards/Twitter-icon.png" alt="">
                  </a></li>
                <li><a
                    [href]="'https://www.pinterest.com/pin/create/button/?url=' + pinterestUrl + data.slug + '&description=' + heading + '&media=' + img"
                    target="_blank">
                    <img src="/assets/images/cards/Pinterest-icon.png" alt="">
                  </a></li>
                <li><a appEmailShare [body_1]="visible_recipe_mail_body" [email_link]="visible_recipe_mail_body_link"
                    [heading]="heading">
                    <img src="/assets/images/cards/Share-icon.png" alt="">
                  </a></li>
              </ul>

            </div>
          </div>
        </li>
        <li class="item">
          <div class="content-wrapper">
            <h5 class="button-type item-title">
              {{ 'Cocktail.Card.Garnish' | translate }}
            </h5>
            <div class="content" [innerHTML]="garnish">
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</article> -->
<!-- End Bvi -->
