import { Component, OnInit, Input } from '@angular/core';
import { SurfaceColors } from '@core/enum/enum';

@Component({
  selector: 'app-surface-section',
  templateUrl: './surface-section.component.html',
  styleUrls: ['./surface-section.component.scss'],
})
export class SurfaceSectionComponent implements OnInit {
  @Input() color;
  @Input() direction;
  @Input() goldBorder;
  @Input() paddingVal;

  surfaceB: string;

  colorClass: string;
  public colors = SurfaceColors;

  constructor() {}

  ngOnInit() {
    switch (this.color) {
      case SurfaceColors.Gray200:
        this.colorClass = `tb_surface--light-gray`;
        break;
      case SurfaceColors.Gray400:
        this.colorClass = `tb_surface--gray`;
        break;
      case SurfaceColors.Gray700:
        this.colorClass = `tb_surface--dark-gray`;
        break;
      case SurfaceColors.Gray800:
        this.colorClass = `tb_surface--black`;
        break;
      default:
        this.colorClass = 'tb_surface';
        break;
    }
  }
}
