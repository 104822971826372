import {
	Component,
	OnInit,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { Botw } from '@proxy/service-proxies';
import { LinkService, LinkItem } from '@shared/services/link.service';
// import { IconCardInterface } from '@shared/interfaces/interfaces';

export interface IconCardInterface {
	heading: string;
	text: string;
	icon: string;
	featuredItem?: Botw;
}
@Component({
	selector: 'app-icon-card',
	templateUrl: './icon-card.component.html',
	styleUrls: ['./icon-card.component.scss'],
})
export class IconCardComponent implements OnInit, OnChanges {
	@Input() data: IconCardInterface;
	@Input() authenticated: boolean = false;
	@Input() header: boolean = false;
	@Input() reverse: boolean = false;
	icon: string;
	public link;
	public showLink: boolean = false;
	public linkItem: LinkItem;

	public serviceWellItem: boolean;
	constructor(private linkSvc: LinkService) {}

	ngOnInit() {
		if (
			this.data.featuredItem &&
			(this.data.featuredItem['_discriminator'] === 'ServiceWell' ||
				this.data.featuredItem['_discriminator'] === 'ServiceWellPage')
		) {
			this.serviceWellItem = true;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.data) {
			this.icon = changes.data.currentValue['icon'];
			this.linkItem = this.linkSvc.getLink(
				changes.data.currentValue.featuredItem
			);
		}
		if (changes.authenticated) {
			this.authenticated = changes.authenticated.currentValue;
		}

		if (this.linkItem) {
			this.link = this.linkItem.link;

			this.showLink = this.linkItem.privateLink ? this.authenticated : true;
		}
	}
}
