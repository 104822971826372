import { Component, OnInit, Input } from '@angular/core';
import { get } from 'lodash';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';

@Component({
  selector: 'app-carousel-card',
  templateUrl: './carousel-card.component.html',
  styleUrls: ['./carousel-card.component.scss']
})
export class CarouselCardComponent implements OnInit {
  @Input() data;
  title: string;
  route: string;
  link: string;
  img: string;
  constructor(private imgSafePipe: ImgSafePathPipe) { }

  ngOnInit() {
    // this.link = get(this.data, `slug`);
    switch (this.data.type) {
      case ('node--categories'):
        this.img = this.imgSafePipe.transform(this.data, `['featuredImage'][0].images[0].link.card_16_9`);
        this.route = '/learn/categories';
        this.title = get(this.data, `categorySubHeadline`);
        this.link = get(this.data, 'slug');
        break;
      case ('node--brands'):
        this.img = this.imgSafePipe.transform(this.data, `['brandHeaderBannerImage'][0].images[0].link.card_16_9`);
        this.route = '/learn/brands';
        this.title = get(this.data, `brandSubHeading`);
        this.link = get(this.data, 'slug');
        break;
    }
  }

}
