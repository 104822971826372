<div class="gallery">
  <div class="row" style="
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 0rem;
      flex-direction: column;
    " *ngIf="heading">
    <h6 class="h6" style="text-align:center" *ngIf="heading" [innerHTML]="heading">
    </h6>
    <div *ngIf="copyValue">
      <p class="text-center" [innerHTML]="copyValue"></p>
    </div>
  </div>
  <div class="gallery-container">
    <img appLazyLoad class="slide" #divs [src]="item" alt="" *ngFor="let item of galleryItems" (swipe)="onSwipe($event)" width="668" height="445"/>
    <div class="gallery-controls">
      <button class="leftRs tb_carousel-card__prev" (click)="goPrevious()"></button>
      <button class="rightRs tb_carousel-card__next" (click)="goNext()"></button>
    </div>
  </div>
  <div class="row copy">
    <div style="width: 100%" *ngFor="let item of galleryCaptions; let i = index">
      <p class="text-center" [innerHTML]="item" *ngIf="i === captionShown && item !== null"></p>
    </div>
  </div>
</div>

<!-- <button (click)="goPrevious()">Previous</button>
<button (click)="goNext()">next</button> -->