import { Component, OnInit, Inject, PLATFORM_ID, ChangeDetectorRef, Optional } from '@angular/core';
import { LoadingService } from '@core/services/loading.service';
import { isPlatformBrowser } from '@angular/common';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from 'bsi-toolkit';
import { RoutingService } from '@routing/services/routing.service';
import { AuthService } from '@core/auth/auth.service';

declare var $: any;


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    loadingBoolean = false;
    public loadGtm: boolean = false;
    public isBrowser = false;
    public isIe: boolean = false;
    constructor(
        private loading: LoadingService,
        @Inject(PLATFORM_ID) private platform: Object,
        private ref: ChangeDetectorRef,
        private localize: LocalizeRouterService,
        private seoSvc: SeoService,
        private routeSvc: RoutingService,
        private authSvc: AuthService,
        
    ) { }

    ngOnInit() {
        // Handle html lang value
        this.seoSvc.setLang(this.routeSvc.parseHtmlLang(this.localize.parser.currentLang));
        this.localize.routerEvents.subscribe((language: string) => {
            this.seoSvc.setLang(this.routeSvc.parseHtmlLang(language));
        });

        if (isPlatformBrowser(this.platform)) {
            this.isBrowser = true;
            // Only load GTM on browser side as server side will cause double load
            this.loadGtm = true;

            // turns loading bar on and off
            this.loading.loadingSubject.subscribe(data => {
                this.loadingBoolean = data;
                setTimeout(() => {
                    this.loadingBoolean = false;
                }, 3500);
                this.ref.detectChanges();

            });


            let menu = 0;
            $('.cd_menu-button').click(function () {
                if (menu === 0) {
                    $('.cd_menu-button').addClass('active');
                    $('.cd_template__component-menu').addClass('active');
                    menu = 1;
                } else {
                    $('.cd_menu-button').removeClass('active');
                    $('.cd_template__component-menu').removeClass('active');
                    menu = 0;
                }

            });

            $(window).scroll(function () {
                const scroll = $(window).scrollTop();

                if (scroll >= 1) {
                    $('.tb_navigation').addClass('scrolled');
                } else {
                    $('.tb_navigation__menuparent').removeClass('submenu-active');
                    $('.tb_navigation').removeClass('scrolled');
                    $('.tb_navigation').removeClass('expose-menu');
                    $('.tb_hamburger__bars').removeClass('crossed');
                }
            });

            // request current user if authenticated so we can maintain the last active status if use is logged in
            this.authSvc.isAuthenticated().subscribe(authenticated => {
                if (authenticated) {
                    this.authSvc.getUser().subscribe(user => { });
                }
            });
        }


        if (isPlatformBrowser(this.platform)) {
            this.isIe = window.navigator.userAgent.toLowerCase().indexOf('trident') > -1;
        }
    }
}
