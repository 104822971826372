import { Component, OnInit, Input } from '@angular/core';
import { SpotlightCardInterface } from '@shared/interfaces/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  IPSpotlight,
  IGeekOut,
  TextFormatted,
  PFeaturedContent,
  PNewsSpotlightGridItem,
  PSWSpotlightGridItem,
} from '@proxy/service-proxies';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { get } from 'lodash';
import { RoutingService } from '@routing/services/routing.service';
import { LinkService, LinkItem } from '@shared/services/link.service';
@Component({
  selector: 'app-spotlight-card',
  templateUrl: './spotlight-card.component.html',
  styleUrls: ['./spotlight-card.component.scss'],
})
export class SpotlightCardComponent implements OnInit {
  @Input() data:
    | IPSpotlight
    | IGeekOut
    | PFeaturedContent
    | PNewsSpotlightGridItem;
  @Input() reverse;
  @Input() catLabel;
  @Input() authenticated: boolean = false;
  brandsPage = true;
  // boolean whether geekout data or not
  geekOut: boolean = false;

  community: boolean = false;
  img: string;
  heading: string | TextFormatted;
  body: string;
  category: string;
  categorySlug: string;
  brand: string;
  brandSlug: string;
  link: string | LinkItem | any;
  showLink: boolean;
  linkText: string;
  linkIsExternal: boolean = false;
  linkIsInternal: boolean = false;

  newsSpotLight: boolean = false;
  public linkItem: LinkItem;
  public sectionRoute: string;

  constructor(
    private imgSafePipe: ImgSafePathPipe,
    private routeSvc: RoutingService,
    private linkSvc: LinkService
  ) {}

  ngOnInit() {
    this.geekOut = this.data['_discriminator'] === 'GeekOut' ? true : false;
    if (
      this.data['_discriminator'] === 'PNewsSpotlightGridItem' ||
      this.data['_discriminator'] === 'PSWSpotlightGridItem' ||
      this.data['_discriminator'] === 'PBBSpotlightGridItem'
    ) {
      this.newsSpotLight = true;
      this.img = this.imgSafePipe.transform(this.data, `image.images[0].link.card_16_9`);
      this.heading = this.data.heading;
      this.body = get(this.data, `description.value`);
      if (this.data['subPage']) {
        this.showLink = true;
        this.linkIsInternal = true;
        this.link = this.linkSvc.getLink(this.data['subPage']).link;
        this.linkText = get(this.data, `subPageLinkName`);
      } else if (this.data['link'].uri) {
        this.showLink = true;
        this.linkIsExternal = true;
        this.link = get(this.data, `link.uri`);
        this.linkText = get(this.data, `link.title`);
      } else {
        this.showLink = false;
      }
    }

    if (
      this.data['_discriminator'] === 'PCommunitySpotlightGridItem' ||
      this.data['_discriminator'] === 'PLmsSpotlightGridItem'
    ) {
      this.community = true;
    } else {
      this.community = false;
    }

    if (this.geekOut) {
      this.img = this.imgSafePipe.transform(
        this.data,
        `[featuredImage][0][images][0].link.card_16_9`
      );
      this.heading = get(this.data, `heading.value`);
      this.body = get(this.data, `['body'].summary`);
      this.category = get(this.data, `['brandCategory'][0].categoryHeadline`);
      this.categorySlug = get(this.data, `['brandCategory'][0].slug`);
      this.brand = get(this.data, `['brand'][0].brandRelatedHeadline`);
      this.brandSlug = get(this.data, `['brand'][0].slug`);
      this.link = get(this.data, `slug`);
    } else if (this.community) {
      this.img = this.imgSafePipe.transform(this.data, `image.images[0].link.card_16_9`);
      this.heading = get(this.data, `heading`);
      this.body = get(this.data, `description.value`);
      if (this.data['pageLink']) {
        this.linkItem = this.linkSvc.getLink(get(this.data, `pageLink`));
      }

      if (this.linkItem) {
        this.link = this.linkItem.link;
        this.showLink = this.linkItem.privateLink ? this.authenticated : true;
        // this.link = get(this.data, `slug`);
        this.linkText = get(this.data, `pageLinkName`);
      }
    } else if (!this.geekOut && !this.newsSpotLight) {
      this.img = this.imgSafePipe.transform(
        this.data,
        `['media'][0].images[0].link.card_16_9`,
        true
      );
      this.heading = get(this.data, `heading`);
      this.body = get(this.data, `['sectionDescription'].value`);
      this.sectionRoute = this.routeSvc.getRouteFromContentType(
        get(this.data, `featuredItems[0]._discriminator`)
      );

      this.linkItem = this.linkSvc.getLink(get(this.data, `featuredItems[0]`));
      if (this.linkItem) {
        this.showLink = this.linkItem.privateLink ? this.authenticated : true;
        this.link = this.linkItem.link;
      }
    }

    if (this.catLabel) {
      this.brandsPage = false;
    }
  }
}
