import { Directive, HostBinding, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appEmailShare]'
})
export class EmailShareDirective implements AfterViewInit {
  @HostBinding('attr.href') href = null;
  @Input() heading: string;
  @Input() body_1: Array<string>;
  @Input() email_link: string;
  emailBody: string;
  constructor() { }


  ngAfterViewInit() {
    this.emailBody = this.body_1.length > 1 ? this.body_1.join('%0D%0A') : this.body_1[0];

    this.href = `mailto:?subject=The%20Blend%20-%20${this.heading}&body=${this.emailBody} %0D%0A %0D%0A %0D%0A %0D%0A ${this.email_link}`;
  }
}
