<div class="tb-card tb-card__selection">
    <div class="media__figure" title="thumb image">
        <a [routerLink]="link">
            <img appLazyLoad [src]="img" alt="" width="470" height="264">
        </a>
        <div class="tb-card__selection-label text-uppercase fw-bold">
            <span><a [routerLink]="['/learn/categories' | localize, categoryLink]">{{category}}</a>
                <ng-container *ngIf="label"><span class="label-separator">/</span></ng-container> <a
                    [routerLink]="['/learn/brands' | localize, labelLink]">{{label}}</a>
            </span>
        </div>
    </div>
    <h3 class="tb-card__title text-center text-uppercase">
        <a [routerLink]="['/learn/geek-out' | localize, link]" class="tb-card__link" title="Card Title">
            {{headline}}
        </a>
    </h3>
</div>