<!-- <img appLazyLoad class="tb_image" [src]="img" alt="">
<div class="tb_card__copy-mark">
  <div class="tb_card__category" [innerHTML]="category"></div>
  <p class="tb_card__brand" [innerHTML]="brand"></p>
  <div class="tb_card__mark" *ngIf="winner">
    <h3 *ngIf="header">
      <img class="tb_card__award-icon" src="../../../assets/images/icons/tpb-winners--red.svg">
      {{cocktailName}}
    </h3>
    <div *ngIf="!header">
      <img class="tb_card__award-icon" src="../../../assets/images/icons/tpb-winners--red.svg">
      {{cocktailName}}
    </div>
  </div>
  <h3 class="tb_card__mark" *ngIf="!winner && header">
    {{cocktailName}}</h3>
  <div class="tb_card__mark" *ngIf="!winner && !header">
    {{cocktailName}}</div>
</div> -->


<div class="tb-card tb-card__cocktail text-center">
  <div class="tb-card__label label text-uppercase fw-bold">
    <span>{{category}}
      <!-- / Label -->
    </span>
  </div>
  <div class="icon" *ngIf="winner">
    <img src="/assets/images/cards/trophy-icon.svg" alt="" width="18" height="18">
  </div>
  <div class="media__figure" title="thumb image">
    <img appLazyLoad [src]="img" alt="" width="225" height="300" >
  </div>
  <p class="tb-card__label inputs text-center text-uppercase">
    {{brand}}
  </p>
  <h3 class="tb-card__title  text-uppercase">
    <a href="#" class="tb-card__link" title="Card Title">
      {{cocktailName}}
    </a>
  </h3>
</div>