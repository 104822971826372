import { Injectable, OnInit } from '@angular/core';
import { UtilServiceProxy, BaseInput, Config, PConfig } from '@proxy/service-proxies';
import { RoutingLocale } from '@routing/interfaces/routing';
import { RoutingService } from '@routing/services/routing.service';
import { switchMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private locale: RoutingLocale;
  private config: Config;

  constructor(
    private utilService: UtilServiceProxy,
    private routingSvc: RoutingService
  ) { }

  // getConfig(language, market) {
  //   return this.utilService.config(new BaseInput({
  //     languageCode: language,
  //     market: market,
  //     publishedStatus: '1'
  //   }));
  // }

  /**
   * Returns a config value given a key
   */
  public getConfiguration(key: ConfigKeys): Observable<string> {
    return this.refresh().pipe(
      switchMap(success => {
        if (success) {
          const value: PConfig = this.config.keyValues.find((item: PConfig) => item.key === key) as PConfig;
          if (value) {
            return of(value.value);
          } else {
            return of(null);
          }
        }
      })
    );
  }

  /**
   * Returns a record with multiple keys
   * @param key 
   */
  public getConfigurations(keys: ConfigKeys[]): Observable<Record<string, string>> {
    return this.refresh().pipe(
      switchMap(success => {
        if (success) {
          const values: PConfig[] = this.config.keyValues.filter((item: PConfig) => keys.some(key => key === item.key)) as PConfig[];
          let configs: Record<string, string> = {};
          for (let value of values) {
            configs[value.key] = value.value;
          }
          return of(configs);
          // if (records) {
          //   return of(value.value);
          // } else {
          //   return of(null);
          // }
        }
      })
    );
  }

  /**
   * This methods is called to make sure the config does not need to be refreshed
   */
  private refresh(): Observable<boolean> {
    const currentLocale = this.routingSvc.getLocale();
    if (this.locale && this.config) {
      if (this.routingSvc.compareLocales(this.locale, currentLocale)) {
        // current locale is the same so return true
        return of(true);
      } else {
        // current locale is different so refresh config
        return this.setConfig(currentLocale).pipe(
          switchMap(success => {
            return of(success);
          })
        );
      }
    } else {
      // We need to get config and set locale
      return this.setConfig(currentLocale).pipe(
        switchMap(success => {
          return of(success);
        })
      );
    }
  }

  private setConfig(locale: RoutingLocale): Observable<boolean> {
    return this.utilService.config(new BaseInput({
      languageCode: locale.language,
      market: locale.market,
      publishedStatus: '1'
    })).pipe(
      switchMap(config => {
        if (config && config.items.length > 0) {
          // We succesffully got a configuration so update config
          this.config = config.items[0];
          this.locale = locale;
          return of(true);
        } else {
          return of(false);
        }
      })
    );
  }
}

export enum ConfigKeys {
  TPBNewsTopic = 'tpb_news_category',
  BOTWIcon = 'BOTW_ICON',
  BOTWVIcon = 'BOTVW_ICON',
  CookieLink = 'COOKIE_LINK',
  DrinksmartLink = 'DRINKSMART_LINK',
  LMSKey = 'LMS_LINK',
  TermsLink = 'TERMS_LINK',
  PrivacyLink = 'PRIVACY_LINK',
  MetaTitle = 'META_TITLE',
  ContactEmail = 'CONTACT_EMAIL'
}
