import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  // maintains the number in the sequence that the ping pong components appear
  pingPongTrackingNumber: number = 0;

  constructor() {}

  /**
   * increasePingPongTrack
   * *whenever a ping pong component is added this will increase the counter by one
   */
  increasePingPongTrack(): void {
    this.pingPongTrackingNumber++;
  }

  /**
   * getPingPongTrackingNumber
   * *returns the tracking number so the component knows which place its in
   */
  getPingPongTrackingNumber(): number {
    return this.pingPongTrackingNumber;
  }

  resetTrackingNumber(): void {
    this.pingPongTrackingNumber = 0;
  }
}
