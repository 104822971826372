import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-template-section",
  templateUrl: "./template-section.component.html",
  styleUrls: ["./template-section.component.scss"],
})
export class TemplateSectionComponent implements OnInit {
  @Input() paddingVal;
  @Input() center;
  constructor() { }

  ngOnInit() {
  }
}
