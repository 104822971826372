// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular.json`.

export const environment = {
  production: false,
  preview: false,
  // Dev
  baseUrl: 'https://stage.theblend.world',
  oauthDomain: '.theblend.world',
  apiBaseUrl: 'https://stageapi.theblend.world',
  apiCdnUrl: 'https://stageapi.theblend.world',
  apiFileUrl: 'https://stageapi.theblend.world/files',
  // Stage
  // baseUrl: 'https://stage.theblend.world',
  // oauthDomain: '.theblend.world',
  // apiBaseUrl: 'https://stageapi.theblend.world',
  // apiCdnUrl: 'https://stageapi.theblend.world',
  // apiFileUrl: 'https://stageapi.theblend.world/files',
  // Prod
  // baseUrl: 'https://www.theblend.world',
  // oauthDomain: '.theblend.world',
  // apiBaseUrl: 'https://api.theblend.world',
  // apiCdnUrl: 'https://api.theblend.world',
  // apiFileUrl: 'https://api.theblend.world/files',
  aws: {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_BRUXDDQYv',
      userPoolWebClientId: '2jv4m62mptfnnk2a0ekfvsah8i',
    },
    // Prod Auth
    // Auth: {
    //   region: 'us-east-2',
    //   userPoolId: 'us-east-2_zpzoXka8O',
    //   userPoolWebClientId: '3m9jo61p0avi0ahjoogbum9nn',
    // },
    oauth: {
      domain: 'stageauth.theblend.world',
      scope: [
        'phone',
        'email',
        'profile',
        'aws.cognito.signin.user.admin',
        'openid',
      ],
      redirectSignIn: 'https://stage.theblend.world',
      redirectSignOut: 'https://stage.theblend.world',
      responseType: 'code',
      options: {
        AdvancedSecurityDataCollectionFlag: false,
      },
    },
    Analytics: {
      disabled: true,
    },
  },
  stageUrl: 'https://stagecontent.theblend.world',
  assetUrl: 'https://stageassets.theblend.world',
  // Prod Asset Url
  // assetUrl: 'https://assets.theblend.world',
  oneTrustID: '48e3d170-0cd6-4ffd-a7af-e8856264ce1e-test',
  markets: [
    'en-00', // English Default market
    'en-de',
    'en-in',
    'en-us',
    'en-au',
    'en-at',
    'en-es',
    'es-es',
  ],
  apiAuthPaths: [
    '/api/services/app/Auth',
    '/api/services/app/User',
    '/api/services/app/Profile',
    '/api/services/app/HiveMind/Like',
    '/api/services/app/HiveMind/ListingLikes',
    '/api/services/app/TPBComp',
    '/api/services/app/TPBJuryService',
    '/api/services/app/TPBPrelim',
    '/api/services/app/TPBRegions',
    '/api/services/app/TPBVoteService',
    '/api/services/app/MicroCommunity',
  ],
  apiForceCdnPaths: ['/api/services/app/Util/CountryCode'],
};
