<div *ngFor="let content of shownContent; let i = index">
  <app-featured-content *ngIf="content.type === types.FeaturedContent && content.display" [data]="content.content"
    [authenticated]="authenticated">
  </app-featured-content>

  <div class="container" *ngIf="i % 2 !== 0 && content.type === types.Gallery && content.display">
    <div class="row" #wrapperGallery>
      <div class="col-12">
        <app-img-carousel style="width: 100%" [slides]="content.content.gallery" [heading]="content.content.heading"
          [copy]="content.content.description" *visibleWith="wrapperGallery"></app-img-carousel>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="i % 2 === 0 && content.type === types.Gallery && content.display">
    <div class="row">
      <div class="col-12">
        <app-img-carousel style="width: 100%" [slides]="content.content.gallery" [heading]="content.content.heading"
          [copy]="content.content.description"></app-img-carousel>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="content.type === types.Finalist && content.display">
    <div class="row col-12">
      <app-finalists [data]="content.content" *ngIf="content.type === types.Finalist" style="width: 100%">
      </app-finalists>
    </div>
  </div>

  <ng-container *ngIf="content.type === types.Podcasts && content.display">
    <div class="tb_type-group tb_type-group--centered">
      <h2 class="tb_type-group__subheading" [innerHTML]="content.content.heading" style="text-transform: capitalize">
      </h2>
    </div>
    <div class="row justify-content-center" *ngFor="let podcast of content.content.featuredContent">
      <app-podcast [data]="podcast"></app-podcast>
    </div>
    <div class="row justify-content-center">
      <button class="tb_button tb_button--outline" [innerHTML]="content.content.linkText"></button>
    </div>
  </ng-container>
  <ng-container *ngIf="(content.type === types.PNewsSpotlight || content.type === types.Spotlight) && content.display">
    <div class="container">
      <div class="row" style="flex-direction: column; align-items: flex-start;">
        <div class="col-12">
          <h2 class="h2" [innerHTML]="content.content.heading"></h2>
          <p class="tb_type-group__copy" style="padding: 0 0.75rem" [innerHTML]="content.content.description.value"
            *ngIf="content.content.description" style="text-align: center"></p>
        </div>
      </div>
      <div class="row justify-center">
        <div class="col-lg-4 col-md-12 spotlight-card" *ngFor="let spotlight of content.content.content">
          <app-spotlight-card [data]="spotlight"></app-spotlight-card>
        </div>
      </div>
      <!-- <div class="row" *ngIf="content.content && content.content.content.length > 3">
        <app-carousel [slidesLg]="3" [slidesMd]="2" [data]="content.content.content">
        </app-carousel>
      </div> -->
    </div>
  </ng-container>
  <div class="tb_surface tb_surface--dark-gray" *ngIf="
  i % 2 !== 0 &&
  (content.type === types.PDF ||
    content.type === types.PtpbSection ||
    content.type === types.TPBSubPage ||
    content.type === types.PBOTW ||
    content.type === types.Community ||
    content.type === types.LMS ||
    content.type === types.PnewsPingPong) &&
  content.display
">
    <div class="container">
      <div class="row">
        <app-two-up *ngIf="
        content.type === types.PDF ||
        content.type === types.PtpbSection ||
        content.type === types.PBOTW ||
        content.type === types.TPBSubPage ||
        content.type === types.Community ||
        content.type === types.LMS ||
        content.type === types.PnewsPingPong
      " [data]="content.content" [page]="pageType" [goldBorder]="goldBorder" [linkColor]="linkColor" [index]="i"
          [trackingNumber]="content.pingPongTrackingNumber" style="width: 100%">
        </app-two-up>
      </div>
    </div>
  </div>
  <div class="tb_surface tb_surface--black" *ngIf="
  i % 2 === 0 &&
  (content.type === types.PDF ||
    content.type === types.PtpbSection ||
    content.type === types.PBOTW ||
    content.type === types.TPBSubPage ||
    content.type === types.Community ||
    content.type === types.LMS ||
    content.type === types.PnewsPingPong) &&
  content.display
">
    <div class="container">
      <div class="row">
        <app-two-up *ngIf="
  content.type === types.PDF ||
  content.type === types.PtpbSection ||
  content.type === types.TPBSubPage ||
  content.type === types.PBOTW ||
  content.type === types.Community ||
  content.type === types.LMS ||
  content.type === types.PnewsPingPong
" [data]="content.content" [page]="pageType" [goldBorder]="goldBorder" [linkColor]="linkColor" [index]="i"
          [trackingNumber]="content.pingPongTrackingNumber" style="width: 100%">
        </app-two-up>
      </div>
    </div>
  </div>
  <ng-container *ngIf="content.type === types.PText && content.display">
    <div class="container padding-y">
      <div class="row">
        <div class="col-12">
          <p class="tb_type-group__copy" [innerHTML]="content.content['textLong']"></p>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="container">
    <div *ngFor="let media of content.content['media']">
      <ng-container *ngIf="media.type === 'media--image'">
        <div class="row justify-content-center align-items-center image-row" *ngFor="let image of media.images">
          <img class="tb_image" style="max-width: 100%; height: auto" appLazyLoad
            [src]="this.imgSafePipe.transform(image, 'link.default')" alt="" />
        </div>
      </ng-container>
      <ng-container *ngIf="media.type === 'media--external_video'">
        <div class="tb_bordered-image video-section">
          <div class="video-container">
            <iframe class="video-frame" [src]="media.url | safe: 'resourceUrl'" frameborder="0" allowfullscreen>
            </iframe>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="content.type === types.Heading2 || content.type === types.Heading3">
    <div class="container">
      <div class="row" *ngIf="content.type === types.Heading2 && content.display">
        <h2 class="h2" [innerHTML]="content.content['text']"></h2>
      </div>
      <ng-container *ngIf="content.type === types.Heading3 && content.display">
        <div class="row">
          <h3 class="h3" [innerHTML]="content.content['text']"></h3>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="content.type === types.JoinBtn && content.display">
    <div class="container">
      <div class="row">
        <div class="col">
          <button class="button-type" [innerHTML]="content.button" (click)="userJoin()"
            [innerHTML]="content.content['button']"></button>
        </div>
      </div>
    </div>

  </ng-container>
</div>