import { Routes } from '@angular/router';
import { Gtm } from 'bsi-toolkit';
import { AuthGuard } from '@core/auth/auth.guard';
import { DialogOpenerComponent } from '@shared/dialog-opener.component';
import { BAGuard } from './guards/ba-guard';

// @todo:translate
export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../modules/new-home/new-home.module').then(
        (m) => m.NewHomeModule
      ),
    data: {
      GTM: {
        pageType: Gtm.pageType.home,
      },
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  // fake router to show member view
  // {
  //   path: 'member',
  //   loadChildren: () =>
  //     import('../modules/home/new-home.module').then((m) => m.HomeModule),
  //   data: {
  //     GTM: {
  //       pageType: Gtm.pageType.home,
  //     },
  //     SEO: {
  //       title: '',
  //       description: '',
  //     },
  //   },
  // },
  {
    path: 'sso',
    loadChildren: () =>
      import('../modules/sso/sso.module').then((m) => m.SsoModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AgeGateDisabled: true,
    },
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('../modules/terms/terms.module').then((m) => m.TermsModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AgeGateDisabled: true,
    },
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('../modules/privacy/privacy.module').then((m) => m.PrivacyModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AgeGateDisabled: true,
    },
  },
  {
    path: 'cookie-policy',
    loadChildren: () =>
      import('../modules/cookie-policy/cookie-policy.module').then(
        (m) => m.CookiePolicyModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AgeGateDisabled: true,
    },
  },
  {
    path: 'login',
    loadChildren: () =>
      import('../modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('../modules/logout/logout.module').then((m) => m.LogoutModule),
  },
  // {
  //     path: 'join',
  //     loadChildren: () => import('../modules/join/join.module').then(m => m.JoinModule),
  //     data: {
  //         SEO: {
  //             title: '',
  //             description: ''
  //         }
  //     }//,
  //     //resolve: { markets: MarketService}
  // },
  // {
  //   path: 'join',
  //   loadChildren: () =>
  //     import('../modules/new-join/new-join.module').then(
  //       (m) => m.NewJoinModule
  //     ),
  //   data: {
  //     SEO: {
  //       title: '',
  //       description: '',
  //     },
  //   },
  // },

  {
    path: 'join-blend/:name',
    component: DialogOpenerComponent,
    canActivate: [BAGuard]
  },

  {
    path: 'account-edit',
    loadChildren: () =>
      import('../modules/account-edit/account-edit.module').then(
        (m) => m.AccountEditModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    }, //,
    //resolve: { markets: MarketService}
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('../modules/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AgeGateDisabled: true,
    },
  },
  {
    path: 'learn',
    loadChildren: () =>
      import('../modules/learn/learn.module').then((m) => m.LearnModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'learn/brands',
    loadChildren: () =>
      import('../modules/brands/brands.module').then((m) => m.BrandsModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'learn/brands/:id',
    loadChildren: () =>
      import('../modules/brand/brand.module').then((m) => m.BrandModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'learn/brands/:brand/:id',
    loadChildren: () =>
      import('../modules/brand-sub-page/brand-sub-page.module').then(
        (m) => m.BrandSubPageModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  { path: 'geek-out', redirectTo: '/learn/geek-out', pathMatch: 'full' },
  {
    path: 'geek-out/:id',
    redirectTo: '/learn/geek-out/:id',
    pathMatch: 'full',
  },
  {
    path: 'learn/geek-out',
    loadChildren: () =>
      import('../modules/geek-out/geek-out.module').then(
        (m) => m.GeekOutModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'learn/geek-out/:id',
    loadChildren: () =>
      import('../modules/geek-out-detail/geek-out-module').then(
        (m) => m.GeekOutDetailModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'learn/products',
    loadChildren: () =>
      import('../modules/products/products.module').then(
        (m) => m.ProductsModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'learn/products/:id',
    loadChildren: () =>
      import('../modules/product-page/product-page.module').then(
        (m) => m.ProductPageModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'learn/categories',
    loadChildren: () =>
      import('../modules/categories/categories.module').then(
        (m) => m.CategoriesModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'learn/categories/:id',
    loadChildren: () =>
      import('../modules/category/category.module').then(
        (m) => m.CategoryModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'learn/categories/:brand/:id',
    loadChildren: () =>
      import('../modules/category-sub-page/category-sub-page.module').then(
        (m) => m.CategorySubPageModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'learn/still-learning',
    canActivate: [],
    loadChildren: () =>
      import('../modules/lms/lms.module').then((m) => m.LmsModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },

  { path: 'barfly', redirectTo: '/news/barfly', pathMatch: 'full' },
  {
    path: 'news/barfly',
    loadChildren: () =>
      import('../modules/barfly/barfly.module').then((m) => m.BarflyModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'news/barfly/:id',
    loadChildren: () =>
      import('../modules/barfly/barfly.module').then((m) => m.BarflyModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  { path: 'roots', redirectTo: '/news/roots', pathMatch: 'full' },
  {
    path: 'news/roots',
    loadChildren: () =>
      import('../modules/roots/roots.module').then((m) => m.RootsModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'news/roots/:id',
    loadChildren: () =>
      import('../modules/roots/roots.module').then((m) => m.RootsModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'news',
    loadChildren: () =>
      import('../modules/news/news.module').then((m) => m.NewsModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'news/service-well',
    redirectTo: '/news/articles',
    pathMatch: 'full',
  },
  {
    path: 'news/service-well/:id',
    redirectTo: '/news/articles/:id',
    pathMatch: 'full',
  },
  {
    path: 'news/articles',
    canActivate: [],
    loadChildren: () =>
      import('../modules/service-well/service-well.module').then(
        (m) => m.ServiceWellModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'news/articles/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/service-well-detail/service-well-detail.module').then(
        (m) => m.ServiceWellDetailModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'news/:id',
    loadChildren: () =>
      import('../modules/news-article/news-article.module').then(
        (m) => m.NewsArticleModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'cocktails',
    loadChildren: () =>
      import('../modules/cocktails/cocktails.module').then(
        (m) => m.CocktailsModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'community/bar-essentials',
    loadChildren: () =>
      import('../modules/bartending-basics/bartending-basics.module').then(
        (m) => m.BartendingBasicsModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'community/bar-essentials/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../modules/bartending-basics-detail/bartending-basics-detail.module'
      ).then((m) => m.BartendingBasicsDetailModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'cocktails/:id',
    loadChildren: () =>
      import('../modules/cocktail/cocktail.module').then(
        (m) => m.CocktailModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'whats-on',
    loadChildren: () =>
      import('../modules/events/events.module').then((m) => m.EventsModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'whats-on/:id',
    loadChildren: () =>
      import('../modules/event/event.module').then((m) => m.EventModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'forgot-password/:id',
    loadChildren: () =>
      import('../modules/password-reset/password-reset.module').then(
        (m) => m.PasswordResetModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AgeGateDisabled: true,
    },
  },
  {
    path: 'recover-password',
    loadChildren: () =>
      import('../modules/password-recover/password-recover.module').then(
        (m) => m.PasswordRecoverModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AgeGateDisabled: true,
    },
  },
  {
    path: 'botw',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/botw/botw.module').then((m) => m.BotwModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'botw/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/botw-campaign/botw-campaign.module').then(
        (m) => m.BotwCampaignModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'botw/:route/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/botw-subpage/botw-subpage.module').then(
        (m) => m.BotwSubpageModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'botw/:campaign/cocktails/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/cocktail/cocktail.module').then(
        (m) => m.CocktailModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'botw/:campaign/news/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/news-article/news-article.module').then(
        (m) => m.NewsArticleModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'perfect-blend',
    canActivate: [],
    loadChildren: () =>
      import('../modules/perfect-blend/perfect-blend.module').then(
        (m) => m.PerfectBlendModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'perfect-blend/active',
    canActivate: [],
    loadChildren: () =>
      import(
        '../modules/perfect-blend-active/perfect-blend-active.module'
      ).then((m) => m.PerfectBlendActiveModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'perfect-blend/registration',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../modules/perfect-blend-registration/perfect-blend-registration.module'
      ).then((m) => m.PerfectBlendRegistrationModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'perfect-blend/jury',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/perfect-blend-jury/perfect-blend-jury.module').then(
        (m) => m.PerfectBlendJuryModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  // Need to update link to use id
  {
    // path: 'perfect-blend/jury/listing',
    path: 'perfect-blend/jury/listing/:regionId/:categoryId/:configId',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../modules/perfect-blend-jury-listing/perfect-blend-jury-listing.module'
      ).then((m) => m.PerfectBlendJuryListingModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'perfect-blend/jury/cocktail/:submissionId/:regionId/:categoryId/:configId',
    // path: 'perfect-blend/jury/cocktail/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../modules/perfect-blend-jury-cocktail/perfect-blend-jury-cocktail.module'
      ).then((m) => m.PerfectBlendJuryCocktailModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'perfect-blend/jury/recycle/:regionId/:categoryId/:configId',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../modules/perfect-blend-recycle/perfect-blend-recycle.module'
      ).then((m) => m.PerfectBlendJuryListingModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'perfect-blend/:id',
    canActivate: [],
    loadChildren: () =>
      import(
        '../modules/perfect-blend-archive/perfect-blend-archive.module'
      ).then((m) => m.PerfectBlendArchiveModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'perfect-blend/:archive/:id',
    canActivate: [],
    loadChildren: () =>
      import(
        '../modules/perfect-blend-archive-subpages/perfect-blend-archive-subpages.module'
      ).then((m) => m.PerfectBlendArchiveSubpagesModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'perfect-blend/:archive/:id/:finalist_id',
    canActivate: [],
    loadChildren: () =>
      import(
        '../modules/perfect-blend-finalist/perfect-blend-finalist.module'
      ).then((m) => m.PerfectBlendFinalistModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'community',
    canActivate: [],
    loadChildren: () =>
      import('../modules/community/community.module').then(
        (m) => m.CommunityModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'community/hivemind',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/hive-mind/hive-mind.module').then(
        (m) => m.HiveMindModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
  {
    path: 'community/podcasts',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/podcasts/podcasts.module').then(
        (m) => m.PodcastsModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'community/podcasts/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/podcast-series/podcast-series.module').then(
        (m) => m.PodcastSeriesModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'community/service-directory',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/service-directory/service-directory.module').then(
        (m) => m.ServiceDirectoryModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'community/service-directory/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../modules/service-directory-page/service-directory-page.module'
      ).then((m) => m.ServiceDirectoryPageModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'community/books-library',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/books/books.module').then((m) => m.BooksModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'brand-ambassadors',
    canActivate: [],
    loadChildren: () =>
      import('../modules/brand-ambassador/brand-ambassador.module').then(
        (m) => m.BrandAmbassadorModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'brand-ambassadors/:id',
    canActivate: [],
    loadChildren: () =>
      import(
        '../modules/brand-ambassador-sub/brand-ambassador-sub.module'
      ).then((m) => m.BrandAmbassadorSubModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'brand-ambassadors-profile/:id',
    canActivate: [],
    loadChildren: () =>
      import(
        '../modules/brand-ambassador-profile/brand-ambassador-profile.module'
      ).then((m) => m.BrandAmbassadorProfileModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AuthGuard: true,
    },
  },
  {
    path: 'access-page',
    canActivate: [],
    loadChildren: () =>
      import('../modules/access-page/access-page.module').then(
        (m) => m.AccessPageModule
      ),
    data: {
      SEO: {
        title: '',
        description: '',
      },
      AgeGateDisabled: true,
    },
  },
  {
    path: 'sitemap',
    canActivate: [],
    loadChildren: () =>
      import('../modules/sitemap/sitemap.module').then((m) => m.SitemapModule),
    data: {
      SEO: {
        title: '',
        description: '',
      },
    },
  },
];
