import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
@Component({
  selector: 'app-cta-section-perfect-blend',
  templateUrl: './cta-section-perfect-blend.component.html',
  styleUrls: ['./cta-section-perfect-blend.component.scss']
})
export class CtaSectionPerfectBlendComponent implements OnInit {
  @Input() data;

  authenticated = false;
  constructor(private auth: AuthService) {
    // this.auth.checkAuthStatus();
    // this.auth.subjectAuthenticated.subscribe(authenticated => {
    //   this.authenticated = authenticated;
    // });
  }

  ngOnInit() {

  }

}
