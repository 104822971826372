<div class="feature-carousel">
  <div class="carousel-heading">
    <p class="h1" [innerHTML]="headline?.heading">Title Lorem Ipsum Dolor</p>
    <p [innerHTML]="headline?.copy">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
      tempor
      invidunt ut labore et
      dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
      clita kasd gubergren no sea. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
      tempor invidunt ut labore. At vero eos et accusam et justo duo dolores et ea rebum.</p>
  </div>

  <div class="container">
    <div class="row">
      <div class="col">
        <ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="slides">
          <div *nguCarouselDef="let item" class="item">
            <div class="tile">
              <app-podcast [data]="item.content.featuredContent[0]" *ngIf="item.type === slideType.PodcastResource">
              </app-podcast>
              <app-podcast [data]="item.content" *ngIf="item.type === slideType.Podcast"></app-podcast>
              <app-product-carousel-card [data]="item.content" *ngIf="
                    item.type === slideType.Product && pageType !== 'exploreProducts'
                  ">
              </app-product-carousel-card>
              <app-explore-products-card *ngIf="
                    pageType === 'exploreProducts' && item.type === slideType.Product
                  " [data]="item.content"></app-explore-products-card>
              <app-news-card [data]="item.content" [type]="pageType" [iconColor]="themeColor" [header]="header" *ngIf="
                    item.type === slideType.Event ||
                    item.type === slideType.Hero ||
                    item.type === slideType.News
                  ">
              </app-news-card>
              <app-product-carousel-card [data]="item.content" *ngIf="item.type === slideType.Brands">
              </app-product-carousel-card>
              <a [routerLink]="['cocktails' | localize, item.slug]"
                *ngIf="pageType === 'botw' && item.type === slideType.Cocktail">
                <app-cocktail-card [data]="item.content"></app-cocktail-card>
              </a>
              <app-podcast-series [data]="item.content" *ngIf="item.type === slideType.PodcastSeries">
              </app-podcast-series>
              <app-spotlight-card [data]="item.content" *ngIf="item.type === slideType.Spotlight"></app-spotlight-card>
            </div>
          </div>
          <ng-container *ngIf="dots">
            <ul class="feature-carousel__dots-row myPoint row justify-content-center" NguCarouselPoint>
              <li class="feature-carousel__dots" *ngFor="let a of myCarousel?.pointNumbers; let j = index"
                [class.active]="j == myCarousel.activePoint" (click)="myCarousel.moveTo(j)"></li>
            </ul>
          </ng-container>
          <button *ngIf="isArrows" #leftBtn NguCarouselPrev class="leftRs feature-carousel__control"
            [style.opacity]="myCarousel.isFirst ? 0.5:1">&lt;</button>
          <button *ngIf="isArrows" #rightBtn NguCarouselNext class="rightRs feature-carousel__control"
            [style.opacity]="myCarousel.isLast ? 0.5:1">&gt;</button>
        </ngu-carousel>
      </div>
    </div>
  </div>

</div>

<!-- <div class="carousel">
  <ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="slides">
    <div *nguCarouselDef="let item" class="item col-sm-12">
      <div class="tile">
        <app-podcast [data]="item.content.featuredContent[0]" *ngIf="item.type === slideType.PodcastResource">
        </app-podcast>
        <app-podcast [data]="item.content" *ngIf="item.type === slideType.Podcast"></app-podcast>
        <app-product-carousel-card [data]="item.content" *ngIf="
            item.type === slideType.Product && pageType !== 'exploreProducts'
          ">
        </app-product-carousel-card>
        <app-explore-products-card *ngIf="
            pageType === 'exploreProducts' && item.type === slideType.Product
          " [data]="item.content"></app-explore-products-card>
        <app-news-card [data]="item.content" [type]="pageType" [iconColor]="themeColor" [header]="header" *ngIf="
            item.type === slideType.Event ||
            item.type === slideType.Hero ||
            item.type === slideType.News
          ">
        </app-news-card>
        <app-product-carousel-card [data]="item.content" *ngIf="item.type === slideType.Brands">
        </app-product-carousel-card>
        <a [routerLink]="['cocktails' | localize, item.slug]"
          *ngIf="pageType === 'botw' && item.type === slideType.Cocktail">
          <app-cocktail-card [data]="item.content"></app-cocktail-card>
        </a>
        <app-podcast-series [data]="item.content" *ngIf="item.type === slideType.PodcastSeries"></app-podcast-series>
        <app-spotlight-card [data]="item.content" *ngIf="item.type === slideType.Spotlight"></app-spotlight-card>
      </div>
    </div>
    <button NguCarouselPrev class="leftRs tb_carousel-card__prev" [style.opacity]="myCarousel.isFirst ? 0.5 : 1"
      *ngIf="isArrows"></button>
    <button NguCarouselNext class="rightRs tb_carousel-card__next" [style.opacity]="myCarousel.isLast ? 0.5 : 1"
      *ngIf="isArrows"></button>
  </ngu-carousel>
</div> -->