<div class="container-fluid">
  <header class="" #header>
    <!-- Logo -->

    <div class="tb_header__logo">
      <div class="hamburger">
        <input type="checkbox" [checked]="isChecked" id="menu_checkbox" (click)="toggleMenu()" />
        <label for="menu_checkbox">
          <div></div>
          <div></div>
          <div></div>
        </label>
      </div>
      <div class="d-none d-sm-flex">
        <a [routerLink]="['/' | localize]">
          <img width="200px" height="82px" src="/assets/images/the-blend-black.webp" alt="" />
        </a>
      </div>
      <div class="d-sm-none">
        <a [routerLink]="['/' | localize]">
          <img width="37px" height="50px" src="/assets/images/icons/blend-swirld-black.svg" alt="" />
        </a>
      </div>
    </div>
    <!-- End Logo -->
    <!-- Links -->
    <nav class="tb_header__links">
      <ul class="tb_nav__menu">
        <li #submenu class="tb_nav__item tb_nav__item--submenu" [ngClass]="{
            hasChild: item.children.length > 0 && !item.allPrivateLinks
          }" *ngFor="let item of navItems">
          <a class="tb_nav__link" [routerLink]="[item.parent.path | localize]"
            *ngIf="(item.private && authenticated) || !item.private" tabindex="-1"
            (click)="closeMenu()">{{ item.parent.name }}</a>
          <ng-container *ngIf="item.children.length > 0 && !item.allPrivateLinks">
            <div class="submenu_arrow" (click)="submenu.classList.toggle('expanded')">
              <img src="/assets/images/down_arrow_gold.webp" class="img-fluid" />
            </div>
          </ng-container>
          <div class="tb_nav__submenu" [ngClass]="{ privateKids: item.allPrivateLinks }"
            *ngIf="item.children.length > 0 && !item.allPrivateLinks">
            <div class="submenu">
              <div class="link-container">
                <ng-container *ngFor="let child of item.children">
                  <a class="tb_nav__submenu-link" [ngClass]="{ hide: child.private && !authenticated }"
                    [routerLink]="[child.path | localize]">
                    {{ child.name }}
                  </a>
                </ng-container>
              </div>
              <!-- <div class="content-container">
                <h4 class="h4">Discover More</h4>
                <div class="row">
                  <a href="">
                    <div class="col-4">
                      <img class="img-fluid" src="https://picsum.photos/300/200" alt="" />
                    </div>
                    <div class="col-8">
                      <h6 class="h6">This is a mock title</h6>
                      <p>
                        Here is some mock content about absolutely nothing. O
                        hey look another sentence. How long does this go on for?
                      </p>
                      <a href="#" class="tb_link"> Learn More</a>
                    </div>
                  </a>
                </div>
              </div> -->
            </div>
          </div>
        </li>
      </ul>
    </nav>
    <!-- End Links -->
    <!-- Memeber -->
    <div class="tb_header__member">
      <!-- <div class="tb_input">
        <input class="tb_header__search--input" placeholder="Search" type="text">
      </div>

      <div class="tb_header__search" (click)="toggleSearch()">
        <img class="img-fluid" src="/assets/images/icons/search--gold.svg" alt="Search" />
      </div> -->
      <div id="list1" (click)="toggleModal($event)" class="tb_icon-select">
        <div class="tb_icon-select__options" (click)="stopPropagation($event)"
          [ngClass]="{ hasLanguages: isMultipleLanguages }" #marketSelector>
          <div class="marketSelector" *ngIf="currentMarket">
            <label for="market-select">Change Market</label>
            <select name="" id="market-select" (ngModelChange)="changeActiveMarket($event)" [(ngModel)]="currentMarket">
              <!-- <option value="" disabled selected>{{currentMarket.name}}</option> -->
              <option *ngFor="let market of activeMarkets" [ngValue]="market"
                [selected]="market.id === currentMarket.id" [hidden]="market.id === currentMarket.id">
                {{ market.name }}
              </option>
            </select>
          </div>
          <div class="languageSelector" *ngIf="isMultipleLanguages && activeLanguage">
            <label for="lanuge-select">{{
              'Header.ChooseLanguage' | translate
            }}</label>
            <select name="" id="language-select" (ngModelChange)="changeActiveLanguage($event)"
              [(ngModel)]="activeLanguage">
              <option [ngValue]="language" [selected]="language.id === activeLanguage.id"
                *ngFor="let language of languages">
                {{ language.languageDisplay }}
              </option>
            </select>
          </div>
        </div>

        <!-- <div class="tb_icon-select__options" #marketSelector>
          <a class="tb_icon-select__option" href="/{{
							activeMarket.country.toLowerCase() === '00'
								? ''
								: activeMarket.defaultLanguage.languageCode +
								  '-' +
								  activeMarket.country.toLowerCase()
						}}" *ngFor="let activeMarket of activeMarkets" [attr.data-market]="activeMarket.country"
            (click)="changeActiveMarket(activeMarket, $event)"
            [ngClass]="{ hide: currentMarket.name === activeMarket.name }">
            <img [src]="imgSafePipe.transform(activeMarket, 'icon.images[0].url')" *ngIf="activeMarket.icon" />
            <span class="noFlag" *ngIf="!activeMarket.icon"></span>
            <span class="tb_icon-select__option-text">{{
							activeMarket.name
						}}</span>
          </a>
        </div> -->
        <div class="tb_icon-select__selected" id="arg" *ngIf="currentMarket">
          <img width="25px" height="25px" [src]="imgSafePipe.transform(currentMarket, 'icon.images[0].url')" *ngIf="currentMarket.icon" />
        </div>
      </div>
      <div class="tb_header__user">
        <div class="tb_navigation__account" *ngIf="(!showLanguageSwitcher && isMobile) || !isMobile"
          [ngClass]="{ public: !authenticated, member: authenticated }">
          <a class="text-link">
            <span class="tb_navigation__account-text" (click)="openDialog()"
              *ngIf="!authenticated">{{ 'Header.SignIn' | translate }}
            </span>
          </a>
          <span class="tb_navigation__account-break"></span>
          <div class="tb_navigation__account-logout" *ngIf="authenticated">
            <a class="text-link" (click)="routeAccountSettings()">{{
              'Header.AccountSettings' | translate
            }}</a>
            <a class="text-link" (click)="logOut($event)">{{
              'Header.LogOut' | translate
            }}</a>
          </div>
        </div>
        <div class="tb_navigation__join" *ngIf="!authenticated">
          <button class="button-type" (click)="openRegistration()">
            {{ 'Header.Join' | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- End Member -->
  </header>
</div>

<!-- <div class="container-fluid">
    <header>
        <div class="tb_header row" #header>
            <div class="col-4 tb_header__user">
                <div class="tb_navigation__join" *ngIf="!authenticated">
                    <button class="button-type" (click)="routePath('/join')">{{'Header.Join' | translate}}</button>
                </div>
                <div class="tb_navigation__account" *ngIf="(!showLanguageSwitcher && isMobile) || !isMobile"
                    [ngClass]="{'public': !authenticated,member: authenticated}">
                    <a class="text-link">
                        <span class="tb_navigation__account-text" (click)="openDialog()"
                            *ngIf="!authenticated">{{'Header.SignIn' | translate}}
                        </span>
                    </a>
                    <span class="tb_navigation__account-break"></span>
                    <div class="tb_navigation__account-logout" *ngIf="authenticated">
                        <a class="text-link"
                            (click)="routeAccountSettings()">{{'Header.AccountSettings' | translate}}</a>
                        <a class="text-link" (click)="logOut()">{{'Header.LogOut' | translate}}</a>
                    </div>

                </div>
            </div>
            <div class="col-4 tb_header__logo d-none d-sm-flex">
                <a (click)="routePath('/')">
                    <img class="img-fluid" src="/assets/images/the-blend-white.png" alt="">
                </a>
            </div>
            <div class="col-4 tb_header__logo d-sm-none">
                <a (click)="routePath('/')">
                    <img class="img-fluid " src="/assets/images/blend-swirl.png" alt="">
                </a>
            </div>
            <div class="col-4 tb_header__links">
                <div id="list1" (click)="showList($event)" class="tb_icon-select">
                    <div class="tb_icon-select__options" #marketSelector>
                        <a class="tb_icon-select__option"
                            href="/{{activeMarket.country.toLowerCase() === '00' ? '' : (activeMarket.defaultLanguage.languageCode + '-'+activeMarket.country.toLowerCase())}}"
                            *ngFor="let activeMarket of activeMarkets" [attr.data-market]="activeMarket.country"
                            (click)="changeActiveMarket(activeMarket, $event)"
                            [ngClass]="{hide: currentMarket.name === activeMarket.name}">
                            <img [src]="imgSafePipe.transform(activeMarket, 'icon.images[0].url')"
                                *ngIf="activeMarket.icon">
                            <span class="noFlag" *ngIf="!activeMarket.icon"></span>
                            <span class="tb_icon-select__option-text">{{activeMarket.name}}</span>
                        </a>
                    </div>
                    <div class="tb_icon-select__selected" id="arg" *ngIf="currentMarket">
                        <img [src]="imgSafePipe.transform(currentMarket, 'icon.images[0].url')"
                            *ngIf="currentMarket.icon">

                    </div>
                </div>


                <div class="hamburger">
                    <input type="checkbox" [checked]="isChecked" id="menu_checkbox" (click)="toggleMenu()">
                    <label for="menu_checkbox">
                        <div></div>
                        <div></div>
                        <div></div>
                    </label>
                </div>
            </div>
        </div>
        <div class="tb_nav" #nav *ngIf="!showLanguageSwitcher">
            <div class="tb_navigation__account--mobile" [ngClass]="{'public': !authenticated,member: authenticated}">
                <div class="tb_navigation__join" *ngIf="!authenticated">
                    <a class="text-link" (click)="routePath('/join')">{{'Header.Join' | translate}}</a>
                </div>
                <a class="text-link">
                    <span class="tb_navigation__account-text" (click)="openDialog()"
                        *ngIf="!authenticated">{{'Header.SignIn' | translate}}
                    </span>
                </a>
                <span class="tb_navigation__account-break"></span>
                <div class="tb_navigation__account-logout" *ngIf="authenticated">
                    <a class="text-link" (click)="routeAccountSettings()">{{'Header.AccountSettings' | translate}}</a>
                    <a class="text-link" (click)="logOut()">{{'Header.LogOut' | translate}}</a>
                </div>

            </div>
            <ul class="tb_nav__menu">
                <li class="tb_nav__item tb_nav__item--submenu" *ngFor="let item of navItems"
                    [ngClass]="{noChild: item.children.length === 0 || (!visibleChildren(item.children) && !authenticated), hide: item.parent.private && !authenticated}"
                    #listItem>
                    <span class="tb_nav__submenu-expand"></span>
                    <a class="tb_nav__link" [routerLink]="[item.parent.path | localize]"
                        *ngIf="item.private && authenticated || !item.private" #listLink (click)="toggleMenu()"
                        tabindex="-1">{{item.parent.name}}</a>
                    <ul class="tb_nav__submenu">
                        <ng-container *ngFor="let child of item.children">
                            <li class="tb_nav__submenu-item" [ngClass]="{hide: child.private && !authenticated}">
                                <a class="tb_nav__submenu-link" [routerLink]="[child.path | localize]"
                                    (click)="toggleMenu()">{{child.name}}</a>
                            </li>
                        </ng-container>
                    </ul>
                </li>
            </ul>
            <div class="tb_navigation__language" *ngIf="isMultipleLanguages">
                <a class="tb_navigation__language-link" (click)="toggleLanguageOverlay()">
                    <span class="tb_navigation__language-icon">
                        <img src="../assets/images/icons/globe--blue.svg">
                    </span>
                    <span class="text-link">{{'Header.Language' | translate}}</span></a>
            </div>
        </div>
        <div class="tb_navigation__lmenu" #languageOverlay *ngIf="isMultipleLanguages">
            <div class="h2">{{'Header.ChooseLanguage' | translate}}</div>
            <p class="h3">{{'Header.ChooseDefaultLang' | translate}}</p>
            <ul class="tb_navigation__lmenu-list">
                <li class="tb_navigation__lmenu-item" *ngFor="let language of languages"
                    (click)="changeActiveLanguage(language)"
                    [ngClass]="{'current-language': activeLanguage.id === language.id}">
                    <a class="text-link" [innerHTML]="language.languageDisplay"></a>
                </li>
            </ul>
        </div>
    </header>
</div> -->