import { Component, OnInit, Input } from '@angular/core';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { Router, ActivatedRoute } from '@angular/router';
import { Brands } from '@proxy/service-proxies';
@Component({
  selector: 'app-brand-card',
  templateUrl: './brand-card.component.html',
  styleUrls: ['./brand-card.component.scss'],
})
export class BrandCardComponent implements OnInit {
  @Input() data;
  @Input() header: boolean = false;
  img: string;
  title: string;
  constructor(
    public imgSafePipe: ImgSafePathPipe,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.data instanceof Brands) {
      this.img = this.imgSafePipe.transform(
        this.data,
        `[brandImage][0].images[0].link.default`
      );
      this.title = this.data.title;
    } else {
      this.img = this.imgSafePipe.transform(this.data, `img`);
      this.title = this.data.name;
    }

    // if (this.router.url.includes('/learn/brands') && this.route.snapshot.params.id) {
    //   this.img = this.imgSafePipe.transform(this.data, `[brandImage][0].images[0].url`);
    // } else if (this.router.url.includes('/learn/brands')) {
    //   this.img = this.imgSafePipe.transform(this.data, `img`);
    // } else {
    //   this.img = this.imgSafePipe.transform(this.data, `[brandImage][0].images[0].url`);
    // }
  }
}
