import { Injectable } from '@angular/core';
import { RoutingLocale } from '@routing/interfaces/routing';
import {
	Market,
	FilterServiceProxy,
	FilterTaxMarketInput,
	TaxMarket,
} from '@proxy/service-proxies';
import { RoutingService } from '@routing/services/routing.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class MarketService {
	private locale: RoutingLocale;
	private markets: TaxMarket[];

	constructor(
		private routingSvc: RoutingService,
		private filterService: FilterServiceProxy
	) {}

	/**
	 * returns the global market given a list of markets
	 * @param markets
	 */
	public getGlobalMarket(markets: TaxMarket[]): TaxMarket {
		return markets.find((market) => market.country === '00');
	}

	/**
	 * Returns the global market syncronously (this does not refresh the market list so be cautious using.)
	 */
	public getGlobalMarketCurrent(): TaxMarket {
		return this.markets
			? this.markets.find((market) => market.country === '00')
			: null;
	}

	/**
	 * Returns the global market syncronously (this does not refresh the market list so be cautious using.)
	 */
	public getCurrentMarketCurrent(): TaxMarket {
		return this.markets
			? this.markets.find(
					(market) =>
						market.country.toLowerCase() === this.locale.market.toLowerCase()
			  )
			: null;
	}

	public getMarketByCodeCurrent(code: string): TaxMarket {
		return this.markets && code
			? this.markets.find(
					(market) => market.country.toLowerCase() === code.toLowerCase()
			  )
			: null;
	}

	/**
	 * Returns only the active markets in the array of markets input
	 * @param markets
	 */
	public parseActiveMarkets(markets: TaxMarket[]) {
		return markets.filter((market) => market.active);
	}

	/**
	 *
	 * @param code
	 */
	public parseMarketByCode(code: string, markets: TaxMarket[]) {
		return markets.find(
			(market) => market.country.toLowerCase() === code.toLowerCase()
		);
	}

	/**
	 * Returns the correct market to use for langauges (if not found or languages don't exists it uses global market)
	 * @param code
	 */
	public getMarketForLanguage(market: TaxMarket): TaxMarket {
		// If market doesn't exist for country use global market
		const foundMarket = market ? market : this.getGlobalMarketCurrent();

		// Set language options
		if (foundMarket.languages && foundMarket.defaultLanguage) {
			return foundMarket;
		} else {
			console.warn(
				'Market does not have language or default language set',
				foundMarket
			);
			// If no language options then we can't create select and default form to english so we will use the global market
			return this.getGlobalMarketCurrent();
		}
	}

	/**
	 * Returns the a market by code
	 */
	public getMarketByCode(code: string): Observable<TaxMarket> {
		return this.refresh().pipe(
			switchMap((success) => {
				if (success) {
					return of(
						this.markets.find(
							(market) => market.country.toLowerCase() === code.toLowerCase()
						)
					);
				}
			})
		);
	}

	/**
	 * Returns the current market
	 */
	public getCurrentMarket(): Observable<TaxMarket> {
		return this.refresh().pipe(
			switchMap((success) => {
				if (success) {
					return of(
						this.markets.find(
							(market) =>
								market.country.toLowerCase() ===
								this.locale.market.toLowerCase()
						)
					);
				}
			})
		);
	}

	/**
	 * Returns markets
	 */
	public getMarkets(activeOnly: boolean): Observable<TaxMarket[]> {
		return this.refresh().pipe(
			switchMap((success) => {
				if (success) {
					if (activeOnly) {
						const markets: TaxMarket[] = this.markets.filter(
							(market) => market.active === activeOnly
						);
						return of(markets);
					} else {
						return of(this.markets);
					}
				}
			})
		);
	}

	/**
	 * This methods is called to make sure the config does not need to be refreshed
	 */
	private refresh(): Observable<boolean> {
		const currentLocale = this.routingSvc.getLocale();
		if (this.locale && this.markets) {
			if (this.locale.language === currentLocale.language) {
				if (this.locale.market !== currentLocale.market) {
					this.locale = currentLocale;
				}
				// current locale is the same so return true
				return of(true);
			} else {
				// current locale is different so refresh config
				return this.setMarkets(currentLocale).pipe(
					switchMap((success) => {
						return of(success);
					})
				);
			}
		} else {
			// We need to get config and set locale
			return this.setMarkets(currentLocale).pipe(
				switchMap((success) => {
					return of(success);
				})
			);
		}
	}

	/**
	 *
	 * @param locale
	 */
	private setMarkets(locale: RoutingLocale): Observable<boolean> {
		return this.filterService
			.market(
				new FilterTaxMarketInput({
					active: false,
					languageCode: locale.language,
				})
			)
			.pipe(
				switchMap((markets) => {
					if (markets && markets.items.length > 0) {
						// We succesffully got a market list
						this.markets = markets.items;
						this.locale = locale;
						return of(true);
					} else {
						return of(false);
					}
				})
			);
	}
}
