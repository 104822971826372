<a [routerLink]="link.link">
  <div class="ambassador-card">
    <div class="ambassador-card__top">
      <img [src]="image" alt="" class="main img-fluid" />
      <img
        src="https://picsum.photos/400"
        alt=""
        class="background img-fluid"
      />
    </div>
    <div class="ambassador-card__bottom">
      <p class="ambassador-card__bottom--name" [innerHTML]="heading"></p>
      <p class="ambassador-card__bottom--title" [innerHTML]="data.name"></p>
    </div>
  </div>
</a>
