import { Injectable, Inject, PLATFORM_ID, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { environment } from '@env/environment';
import { LinkService } from './link.service';
import { Cocktail, ServiceWell } from '@proxy/service-proxies';

import { MatDialog } from '@angular/material/dialog';
import { SharingDialogComponent } from '@modules/sharing-dialog/sharing-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class SocialShareService {
  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    private linkSvc: LinkService,
    public dialog: MatDialog
  ) {}

  /**
   * Targets an a tag and set the href attribute to use email sharing ability
   * * Must be attached to a ViewChild nativeElement on ngAfterViewInit
   *
   * @param element - The a tag that the href needs to be set on
   * @param pageData the page that the email link should be referencing
   *
   * @param heading the heading for the email
   * @param emailBody the message that the email should be sending. Should be in an array and each index will be a separate line
   */
  public emailLinkSetup(
    element: HTMLLinkElement,
    pageData: Cocktail,
    heading: string,
    emailBody: Array<string>
  ) {
    let link = this.linkSvc.getLink(pageData);
    let emailMessage =
      emailBody.length > 1 ? emailBody.join('%0D%0A') : emailBody[0];

    element.href = `mailto:?subject=The%20Blend%20-%20${heading}&body=${emailMessage} %0D%0A %0D%0A %0D%0A %0D%0A ${environment.baseUrl}${link.link}`;
  }

  /**
   * Uses the href set by the emailLinkSetup to open a email window
   * * Designed to be used as a click handler
   * * Must be used in combination with emailLinkSetup
   * * Must use event.preventDefault to prevent routing from occuring before logic
   * @param element - the a tag that the href attribute it on
   */
  public emailShare(element: ElementRef) {
    let link = element.nativeElement.href;
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: 'auto',
      maxWidth: '90vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        if (isPlatformBrowser(this.platform)) {
          window.open(link);
          return false;
        }
      }
    });
  }

  /**
   * Uses the page data and specified heading to create a facebook window for sharing
   * * Designed to be used as a click handler
   * @param pageData - the page that the facebook link should be referencing
   */
  public facebook(pageData: Cocktail | ServiceWell): void {
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: 'auto',
      maxWidth: '90vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        if (isPlatformBrowser(this.platform)) {
          let link = this.linkSvc.getLink(pageData);
          window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' +
              encodeURIComponent(`${environment.baseUrl}/${link.link}`),
            'facebook-share-dialog',
            'width=626,height=436'
          );
          return false;
        }
      }
    });
  }

  /**
   * Uses the page data and specified heading to create a linkedIn window for sharing
   * * Designed to be used as a click handler
   * @param pageData - the page that the linkedIn link should be referencing
   */
  linkedIn(pageData: ServiceWell) {
    if (isPlatformBrowser(this.platform)) {
      const dialogRef = this.dialog.open(SharingDialogComponent, {
        width: 'auto',
        maxWidth: '90vw',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          if (isPlatformBrowser(this.platform)) {
            let link = this.linkSvc.getLink(pageData);
            window.open(
              `http://www.linkedin.com/shareArticle?mini=true&url=` +
                encodeURIComponent(`${environment.baseUrl}${link.link}`),
              '_blank',
              'width=626,height=436'
            );
            return false;
          }
        }
      });
    }
  }

  /**
   * Targets an a tag and set the href attribute to use pinterest sharing ability
   * * Must be attached to a ViewChild nativeElement on ngAfterViewInit
   * * Must be used with pinterestShare
   * @param element - The a tag that the href needs to be set on
   * @param pageData the page that the pinterest link should be referencing
   * @param heading the heading for the pinterest pin
   * @param image the image url for the pinterst pin
   */
  public pinterestLinkSetup(
    element: HTMLLinkElement,
    pageData: Cocktail,
    heading: string,
    image: string
  ): void {
    let link = this.linkSvc.getLink(pageData);
    element.href = `https://www.pinterest.com/pin/create/button/?url=${environment.baseUrl}${link.link}&description=${heading}&media=${image}`;
  }

  /**
   * Uses the href set by the pinterestLinkSetup to open a pinterest share window
   * * Designed to be used as a click handler
   * * Must be used in combination with pinterestLinkSetup
   * @param element - the a tag that the href attribute it on
   */
  public pinterestShare(element: ElementRef) {
    let link = element.nativeElement.href;
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: 'auto',
      maxWidth: '90vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        if (isPlatformBrowser(this.platform)) {
          window.open(link, '_blank', 'width=626,height=436');
          return false;
        }
      }
    });
  }

  /**
   * Uses the page data and specified heading to create a twitter window for sharing
   * * Designed to be used as a click handler
   * @param pageData - the page that the twitter link should be referencing
   * @param heading - the title you want shown before the twitter link
   */
  public twitter(pageData: Cocktail, heading: string) {
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: 'auto',
      maxWidth: '90vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        if (isPlatformBrowser(this.platform)) {
          let link = this.linkSvc.getLink(pageData);
          window.open(
            'https://twitter.com/intent/tweet?text=' +
              encodeURIComponent(
                `${heading} ${environment.baseUrl}${link.link}`
              ),
            'ShareOnTwitter',
            'width=626,height=436'
          );
          return false;
        }
      }
    });
  }

  public messengerDesktopLinkShare(element: ElementRef) {
    let link = element.nativeElement.href;
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: 'auto',
      maxWidth: '90vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        if (isPlatformBrowser(this.platform)) {
          window.open(link, '_blank', 'width=626,height=436');
        }
      }
    });
  }

  public messengerDesktopSetup(
    element: HTMLLinkElement,
    pageData: Cocktail
  ): void {
    let link = this.linkSvc.getLink(pageData);
    element.href =
      'http://www.facebook.com/dialog/send?app_id=2451888541760945&link=' +
      encodeURIComponent(`${environment.baseUrl}${link.link}`) +
      '&redirect_uri=' +
      encodeURIComponent(`${environment.baseUrl}${link.link}`);
  }

  public messengerMobile(pageData) {
    let link = this.linkSvc.getLink(pageData);
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: 'auto',
      maxWidth: '90vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (isPlatformBrowser(this.platform)) {
        window.open(
          'fb-messenger://share?link=' +
            encodeURIComponent(`${environment.baseUrl}${link.link}`) +
            '&app_id=' +
            encodeURIComponent(2451888541760945)
        );
        return false;
      }
    });
  }

  whatsAppShare(element: ElementRef) {
    let link = element.nativeElement.href;
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: 'auto',
      maxWidth: '90vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        window.open(link, '_blank', 'width=626,height=436');
        return false;
      }
    });
  }

  whatsAppSetUp(element: HTMLLinkElement, pageData: any) {
    let link = this.linkSvc.getLink(pageData);
    element.href = `whatsapp://send?text=${environment.baseUrl}${link.link}`;
  }
  // facebook messenger potential
  // <a href="fb-messenger://share/?link= https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fsharing%2Freference%2Fsend-dialog&app_id=123456789">Send In Messenger</a>

  // Whats app potential
  //<a href="https://web.whatsapp.com/send?text=www.google.com" data-action="share/whatsapp/share">Share via Whatsapp web</a>
}
