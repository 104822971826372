import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { IProduct } from '@proxy/service-proxies';
import { get } from 'lodash';
@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() data: IProduct;
  stageUrlPrepend = environment.stageUrl;
  public category: string;
  constructor(public imgSafePipe: ImgSafePathPipe) {}

  ngOnInit() {
    this.category = get(this.data, `productCategory[0].categorySubHeadline`);
  }
}
