<div class="banner tb_type-group tb_type-group--centered tb_type-group--reverse">
  <p class="tb_type-group__subtext">
    <span style="margin-right:5px;">
      <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 32 32" viewBox="0 0 32 32" x="0px"
        y="0px" width="32px" height="32px" xmlns:xml="http://www.w3.org/XML/1998/namespace" xml:space="preserve"
        version="1.1">
        <title>warning</title>
        <g>
          <path fill="#ffffff"
            d="M 31.561 28.617 L 17.9 1.395 C 17.379 0.465 16.688 0 16 0 c -0.689 0 -1.378 0.465 -1.902 1.395 L 0.438 28.617 C -0.607 30.477 0.283 32 2.417 32 h 27.166 C 31.717 32 32.607 30.479 31.561 28.617 Z M 16 28 c -1.105 0 -2 -0.895 -2 -2 s 0.895 -2 2 -2 s 2 0.895 2 2 S 17.105 28 16 28 Z M 18 20 c 0 1.105 -0.895 2 -2 2 s -2 -0.895 -2 -2 v -6 c 0 -1.105 0.895 -2 2 -2 s 2 0.895 2 2 V 20 Z" />
          <polygon fill="#ffffff" points="15.999,0 16,0 16,0" />
        </g>
      </svg>
    </span>
    {{ 'Misc.OutOfDate' | translate }}

  </p>
  <p class="tb_type-group__copy">

    {{ 'Misc.UpdateBrowser' | translate }}
  </p>
  <a [href]="outdateLink" target="_blank">
    <button class="tb_button">
      {{ 'Misc.UpdateBrowserBtn' | translate }}
    </button>
  </a>
</div>