<div class="tb_media-card tb_media-card--image">
  <a [routerLink]="link">
    <div class="tb_media-card__content-top">
      <!-- <img class="tb_media-card__image" [src]="img" alt=""> -->
      <div class="tb_media-card__bg-overlay"></div>
      <div class="tb_media-card__bottom">
        <a class="tb_icon-link tb_media-card__cta tb_media-card__cta--external-link text-link"
          [routerLink]="">{{ 'ServiceDirectory.ViewService' | translate }}</a>
        <span class="tb_media-card__meta"></span>
      </div>
      <div class="tb_media-card__content">
        <h2 class="tb_media-card__title" [innerHTML]="title"></h2>
        <div class="tb_media-card__subtitle" [innerHTML]="content"></div>
      </div>
    </div>
  </a>
</div>