import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { TPBFinalist } from '@proxy/service-proxies';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash';
@Component({
  selector: 'app-finalists',
  templateUrl: './finalists.component.html',
  styleUrls: ['./finalists.component.scss'],
})
export class FinalistsComponent implements OnInit {
  @Input() data;
  @ViewChild('expansionPanel') expansionPanel;
  expanded: boolean;
  showBtnText: string;
  finalists: TPBFinalist[] = [];
  initialFinalists: TPBFinalist[] = [];
  moreThanFourFinalists: boolean;
  link: string;
  constructor(
    public imgSafePipe: ImgSafePathPipe,
    private translate: TranslateService,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    this.expanded = false;
    this.showBtnText = 'Show More';

    if (this.data.finalists) {
      this.finalists = [...this.data.finalists];
    }

    this.finalistsSplice();

    // if (this.router.snapshot.params.id && this.router.snapshot.params.archive) {
    //   this.link = '',
    // } else {
    //   this.link = get(this.data, `slug`);
    // }
  }

  finalistsSplice() {
    if (this.finalists.length > 4) {
      this.initialFinalists = this.finalists.splice(0, 4);
      this.moreThanFourFinalists = true;
    } else {
      this.initialFinalists = this.finalists;
      this.moreThanFourFinalists = false;
    }
  }

  expandPanel() {
    this.expansionPanel.nativeElement.classList.add('expanded');
    this.expanded = true;
  }
  shrinkPanel() {
    this.expansionPanel.nativeElement.classList.remove('expanded');
    this.expanded = false;
  }

  togglePanel() {
    this.expansionPanel.nativeElement.classList.toggle('expanded');
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.showBtnText = this.translate.instant(
        'PerfectBlend.Finalists.ShowLess'
      );
    } else {
      this.showBtnText = this.translate.instant(
        'PerfectBlend.Finalists.ShowMore'
      );
    }
  }
}
