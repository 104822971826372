import { Component, OnInit, Input } from '@angular/core';
import { get } from 'lodash';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
@Component({
  selector: 'app-cocktail-card',
  templateUrl: './cocktail-card.component.html',
  styleUrls: ['./cocktail-card.component.scss']
})
export class CocktailCardComponent implements OnInit {
  @Input() data;
  @Input() header: boolean = false;
  img: string;
  category: string;
  brand: string;
  cocktailName: string;
  winner: boolean;
  constructor(private imgSafePipe: ImgSafePathPipe) { }

  ngOnInit() {
    this.img = this.imgSafePipe.transform(this.data, `image[0].images[0].link.default`);
    this.category = get(this.data, `brand[0].category[0].categorySubHeadline`);
    this.brand = get(this.data, `brand[0].brandSubHeading`);
    this.cocktailName = get(this.data, `heading.value`);
    this.winner = get(this.data, `cocktailWinner`);
  }

}
