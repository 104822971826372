<div class="tb_surface tb_surface--light-gray">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="tb_bvi_footer">
          <div class="tb_bvi_footer__section">
            <div class="tb_bvi_footer__section--top-container">
              <div class="tb_bvi_footer__logo">
                <a href="/" class="tb_bvi_footer__logo-link">
                  <img width="200px" height="82px" src="/assets/images/the-blend-black.webp" title="The Blend">
                </a>
              </div>
              <!-- <div class="tb_bvi_footer__subs">
                <ul class="tb_bvi_footer__subs-menu">
                  <li class="tb_bvi_footer__subs-item">
                    <a class="tb_bvi_footer__subs-link" href="">Subnavigation</a>
                  </li>
                  <li class="tb_bvi_footer__subs-item">
                    <a class="tb_bvi_footer__subs-link" href="">Subnavigation</a>
                  </li>
                  <li class="tb_bvi_footer__subs-item">
                    <a class="tb_bvi_footer__subs-link" href="">Subnavigation</a>
                  </li>
                  <li class="tb_bvi_footer__subs-item">
                    <a class="tb_bvi_footer__subs-link"
                      [routerLink]="['/contact-us' | localize]">{{ 'Footer.Contact' | translate }}</a>
                  </li>
                </ul>
              </div> -->
            </div>
            <div class="tb_bvi_footer__section--middle-container">
              <div class="tb_bvi_footer__section--right-container">
                <div class="tb_bvi_footer__social-container">
                  <div class="tb_bvi_footer--headline">
                    {{ 'Footer.FollowUs' | translate }}

                  </div>
                  <ul class="tb_bvi_footer__social-menu">
                    <li *ngIf="fbLink != ''" class="tb_bvi_footer__social-item">
                      <a class="tb_bvi_footer__social-link" target="_blank" [href]="fbLink">
                        <span class="tb_bvi_footer__social-icon"><img width="32px" height="32px"
                            src="/assets/images/bvi_icons/facebook-color.svg" /></span></a>
                    </li>
                    <!-- <li *ngIf="twitterLink != ''" class="tb_bvi_footer__social-item">
                      <a class="tb_bvi_footer__social-link" target="_blank" [href]="twitterLink">
                        <span class="tb_bvi_footer__social-icon"><img width="32px" height="32px"
                            src="/assets/images/bvi_icons/twitter-color.svg" /></span></a>
                    </li> -->
                    <li *ngIf="instagramLink != ''" class="tb_bvi_footer__social-item">
                      <a class="tb_bvi_footer__social-link" target="_blank" [href]="instagramLink">
                        <span class="tb_bvi_footer__social-icon"><img width="32px" height="32px"
                            src="/assets/images/bvi_icons/instagram-color.svg" /></span></a>
                    </li>
                    <li *ngIf="youtubeLink != ''" class="tb_bvi_footer__social-item">
                      <a class="tb_bvi_footer__social-link" target="_blank" [href]="youtubeLink"><span
                          class="tb_bvi_footer__social-icon"><img width="32px" height="32px"
                            src="/assets/images/bvi_icons/youtube-color.svg" /></span></a>
                    </li>
                  </ul>
                </div>
                <div class="tb_bvi_footer__market-container">
                  <div class="tb_bvi_footer--headline"> {{
                    "PerfectBlend.Registration.PreliminaryPage.ChooseRegion" | translate
                  }}</div>
                  <div class="tb_bvi_footer__market--select__container" *ngIf="activeMarkets">
                    <div (click)="showList($event)" class="tb_bvi_footer__market--select">
                      <div class="tb_bvi_footer__market--options">
                        <a class="tb_bvi_footer__market"
                          href="/{{activeMarket.country.toLowerCase() === '00' ? '' : (activeMarket.defaultLanguage.languageCode + '-'+activeMarket.country.toLowerCase())}}"
                          *ngFor="let activeMarket of activeMarkets" [attr.data-market]="activeMarket.country"
                          (click)="changeActiveMarket(activeMarket, $event)"
                          [ngClass]="{hide: currentMarket.name === activeMarket.name}">
                          <span class="tb_bvi_footer__market--select__option-text">
                            {{activeMarket.name}}</span>
                        </a>
                      </div>
                      <div class="tb_bvi_footer__market--selected" *ngIf="currentMarket">
                        <span class="tb_bvi_footer__market--selected-text">
                          {{currentMarket.name}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tb_bvi_footer__menu-container">
                <div class="tb_bvi_footer__menu">
                  <ul class="tb_bvi_footer__menu-list">
                    <li class="tb_bvi_footer__menu-item">
                      <a class="tb_bvi_footer__menu-link" *ngIf="termLink" target="_blank"
                        href="{{termLink}}">{{ 'Footer.Terms' | translate }}</a>
                    </li>
                    <li class="tb_bvi_footer__menu-item">
                      <a class="tb_bvi_footer__menu-link" *ngIf="privacyLink" target="_blank"
                        href="{{privacyLink}}">{{ 'Footer.Privacy' | translate }}</a>
                    </li>

                    <li class="tb_bvi_footer__menu-item">
                      <!-- OneTrust Cookies Settings button start ot-sdk-show-settings-->
                      <!-- <a id="ot-sdk-btn" class=" tb_bvi_footer__menu-link">{{ 'Footer.DoNotSell' | translate }}</a>  -->

                      <a class="ot-sdk-show-settings tb_bvi_footer__menu-link"
                        href="javascript:void(0);">{{ 'Footer.DoNotSell' | translate }}</a>
                      <!-- OneTrust Cookies Settings button end -->
                      <!-- <a href="" class="optanon-toggle-display" target="_self">Do Not Sell My Personal Information</a> -->
                    </li>

                    <li class="tb_bvi_footer__menu-item">
                      <a class="tb_bvi_footer__menu-link" target="_blank"
                        href="{{cookieLink}}">{{ 'Footer.Cookie' | translate }}</a>
                    </li>
                    <li class="tb_bvi_footer__menu-item">
                      <a class="tb_bvi_footer__menu-link" target="_blank"
                        href="{{drinksmartLink}}">{{ 'Footer.DrinkResponsibly' | translate }}</a>
                    </li>
                    <li class="tb_bvi_footer__menu-item">
                      <a class="tb_bvi_footer__menu-link"
                        [routerLink]="['/sitemap' | localize]">{{ 'Footer.Sitemap' | translate }}</a>
                    </li>
                    <li class="tb_bvi_footer__menu-item">
                      <a class="tb_bvi_footer__menu-link"
                        [routerLink]="['/contact-us' | localize]">{{ 'Footer.Contact' | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="tb_surface--gray">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="tb_bvi_footer__section--bottom-container">
          <div class="tb_bvi_footer__copyright-container">
            <p class="tb_footer__address-content">{{ 'Footer.Copyright' | translate:{year:year} }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="tb_surface--gray800 tb_surface--b-right">
    <div class="tb_surface__section">
      <div class="tb_footer">
        <div class="tb_footer__content">
          <ul class="tb_footer__social-menu">
            <li *ngIf="instagramLink != ''" class="tb_footer__social-item">
              <a class="tb_foter__social-link" target="_blank" [href]="instagramLink"><span
                  class="tb_footer__social-icon"><img src="../assets/images/icons/instagram.svg" /></span></a>
            </li>
            <li *ngIf="fbLink != ''" class="tb_footer__social-item">
              <a class="tb_foter__social-link" target="_blank" [href]="fbLink"><span class="tb_footer__social-icon"><img
                    src="../assets/images/icons/facebook.svg" /></span></a>
            </li>
          </ul>
          <ul class="tb_footer__footer-menu">
            <li class="tb_footer__footer-item">
           
              <a class="tb_footer__footer-link" *ngIf="termLink" target="_blank"
                href="{{termLink}}">{{ 'Footer.Terms' | translate }}</a>
            </li>
            <li class="tb_footer__footer-item">
              
              <a class="tb_footer__footer-link" *ngIf="privacyLink" target="_blank"
                href="{{privacyLink}}">{{ 'Footer.Privacy' | translate }}</a>
            </li>

            <li class="tb_footer__footer-item">
    
              <a class="optanon-toggle-display tb_footer__footer-link">{{ 'Footer.DoNotSell' | translate }}</a>
             
            </li>

            <li class="tb_footer__footer-item">
              <a class="tb_footer__footer-link"
                [routerLink]="['/cookie-policy' | localize]">{{ 'Footer.Cookie' | translate }}</a>
            </li>
            <li class="tb_footer__footer-item">
              <a class="tb_footer__footer-link" target="_blank"
                href="{{drinksmartLink}}">{{ 'Footer.DrinkResponsibly' | translate }}</a>
            </li>
            <li class="tb_footer__footer-item">
              <a class="tb_footer__footer-link"
                [routerLink]="['/sitemap' | localize]">{{ 'Footer.Sitemap' | translate }}</a>
            </li>
            <li class="tb_footer__footer-item">
              <a class="tb_footer__footer-link"
                [routerLink]="['/contact-us' | localize]">{{ 'Footer.Contact' | translate }}</a>
            </li>
          </ul>
          <div class="row justify-content-center" style="padding-bottom: 1rem; margin-right:0; margin-left:0;"
            *ngIf="activeMarkets">
            <div id="list1" (click)="showList($event)" class="tb_icon-select">
              <div class="tb_icon-select__options">
                <a class="tb_icon-select__option"
                  href="/{{activeMarket.country.toLowerCase() === '00' ? '' : (activeMarket.defaultLanguage.languageCode + '-'+activeMarket.country.toLowerCase())}}"
                  *ngFor="let activeMarket of activeMarkets" [attr.data-market]="activeMarket.country"
                  (click)="changeActiveMarket(activeMarket, $event)"
                  [ngClass]="{hide: currentMarket.name === activeMarket.name}">
                  <img [src]="imgSafePipe.transform(activeMarket, 'icon.images[0].url')" *ngIf="activeMarket.icon">
                  <span class="noFlag" *ngIf="!activeMarket.icon"></span>
                  <span class="tb_icon-select__option-text">{{activeMarket.name}}</span>
                </a>
              </div>
              <div class="tb_icon-select__selected" id="arg" *ngIf="currentMarket">
                <img [src]="imgSafePipe.transform(currentMarket, 'icon.images[0].url')" *ngIf="currentMarket.icon">
                <span class="tb_icon-select__option-text">{{currentMarket.name}}</span>
              </div>
            </div>
          </div>
          <div class="tb_footer__address">
            <p class="tb_footer__address-content">{{ 'Footer.Copyright' | translate:{year:year} }}</p>
          </div>
        </div>
      </div>
    </div>
  </div> -->