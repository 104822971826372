import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
  HttpEventType,
} from '@angular/common/http';
// import { Auth } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import { from, of, Subject, Observable } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { API_BASE_URL } from '@proxy/service-proxies';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LoadingService } from '@core/services/loading.service';
import { environment } from '@env/environment';
import { makeStateKey, TransferState } from '@angular/platform-browser';

export interface SSRKeyCache {
  url: string;
  params: any;
  type: HttpTypes;
}

export enum HttpTypes {
  GET = 'GET',
  POST = 'POST',
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private loading: LoadingService,
    @Inject(PLATFORM_ID) private platformId,
    private transferState: TransferState,
    @Inject(API_BASE_URL) private baseUrl?: string
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // If the request isn't going to the API then don't add header
    if (!request.url.startsWith(this.baseUrl)) {
      return next.handle(request.clone());
    }

    // Attempt to set published all on preview site
    if (environment.preview) {
      request = request.clone({
        url: request.url.replace('PublishedStatus=1', 'PublishedStatus=all'),
        body:
          request.body !== null
            ? request.body.replace(
                `"publishedStatus":"1"`,
                `"publishedStatus":"all"`
              )
            : request.body,
      });
    }

    // Attempt to change URL to force CDN non prod environment
    if (!environment.production) {
      if (
        environment.apiForceCdnPaths.find((path) =>
          request.url.startsWith(`${this.baseUrl}${path}`)
        )
      ) {
        request = request.clone({
          url: request.url.replace(
            environment.apiBaseUrl,
            environment.apiCdnUrl
          ),
        });
      }
    }

    this.loading.showLoader();
    // if the endpoint is registered then acquire and inject token
    return from(Auth.currentSession()).pipe(
      catchError((error) => {
        return of(false);
      }),
      mergeMap((session: CognitoUserSession) => {
        let headers = request.headers || new HttpHeaders();
        // Add Auth header if there is an AWS session & endpoint needs auth
        if (
          session &&
          environment.apiAuthPaths.find((path) =>
            request.url.startsWith(`${this.baseUrl}${path}`)
          )
        ) {
          headers = headers.append(
            'Authorization',
            'Bearer ' + session.getIdToken().getJwtToken()
          );
        }

        const interceptObservable = new Subject<HttpEvent<any>>();

        // Try to retrieve request from cache on browser side only
        if (isPlatformBrowser(this.platformId)) {
          const key = this.generateKey(request);
          const storedResponse = this.transferState.get<any>(key, null);
          // check to see if this works/makes sense
          // how can I test this
          if (storedResponse !== null) {
            setTimeout(() => {
              this.handleSuccessSSRCachedResponse(
                storedResponse,
                interceptObservable
              );
            }, 0);
            return interceptObservable;
          }
        }
        // End cache retrieval

        next.handle(request.clone({ headers: headers })).subscribe(
          (event: HttpEvent<any>) => {
            // Set request cache items
            if (isPlatformServer(this.platformId)) {
                if (event instanceof HttpResponse) {
                    const key = this.generateKey(request);
                    this.transferState.set<any>(key, event);
                }
            }
            // End set cache items

            this.handleSuccessResponse(event, interceptObservable);
            setTimeout(() => {
              this.loading.hideLoader();
            }, 2500);
          },
          (error: any) => {
            this.loading.hideLoader();
            return this.handleErrorResponse(error, interceptObservable);
          }
        );

        return interceptObservable;
      })
    );
  }

  /**
   * This method handles the case where a response is cached server side and transferred client side.
   * @param event
   * @param interceptObservable
   */
  protected handleSuccessSSRCachedResponse(
    event: HttpEvent<any>,
    interceptObservable: Subject<HttpEvent<any>>
  ): void {
    const body = event['body'];

    // Create a new object so we can have this work from server side to client side
    const r = new HttpResponse({
      body: body === 'null' ? {} : JSON.parse(body),
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      status: event['status'],
      url: event['url'],
    });

    // console.log('is htmnl event', r);

    const modifiedResponse = this.handleResponse(r);

    interceptObservable.next(
      r.clone({
        body: new Blob([JSON.stringify(modifiedResponse.body)], {
          type: 'application/json',
        }),
      })
    );
  }

  protected handleSuccessResponse(
    event: HttpEvent<any>,
    interceptObservable: Subject<HttpEvent<any>>
  ): void {
    const self = this;
    if (event instanceof HttpResponse) {
      // console.log('event', event);
      // handle event if on server or browser condition
      if (
        isPlatformServer(this.platformId) ||
        (event.body instanceof Blob &&
          event.body.type &&
          event.body.type.indexOf('application/json') >= 0)
      ) {
        const clonedResponse = event.clone();

        this.blobToText(event.body).subscribe((json) => {
          const responseBody = json === 'null' ? {} : JSON.parse(json);
          const modifiedResponse = this.handleResponse(
            event.clone({
              body: responseBody,
            })
          );

          interceptObservable.next(
            modifiedResponse.clone({
              // body: new Blob([JSON.stringify(modifiedResponse.body)], { type: 'application/json' })
              body: isPlatformServer(this.platformId)
                ? JSON.stringify(modifiedResponse.body)
                : new Blob([JSON.stringify(modifiedResponse.body)], {
                    type: 'application/json',
                  }),
            })
          );

          interceptObservable.complete();
        });
      } else {
        interceptObservable.next(event);
        interceptObservable.complete();
      }
    } else {
      interceptObservable.next(event);
    }
  }

  /**
   * Generates a cache key to store http requests locally. Mainly used for SSR loads, but can handle client side if needed.
   * @param req
   */
  private generateKey(req: HttpRequest<any>) {
    let key: SSRKeyCache;
    switch (req.method) {
      case HttpTypes.GET:
        key = {
          url: req.url,
          params: {},
          type: HttpTypes.GET,
        };
        break;

      case HttpTypes.POST:
        key = {
          url: req.url,
          params: req.body,
          type: HttpTypes.POST,
        };
        break;
    }

    return makeStateKey(JSON.stringify(key));
  }

  protected handleErrorResponse(
    error: any,
    interceptObservable: Subject<HttpEvent<any>>
  ): Observable<any> {
    const errorObservable = new Subject<any>();

    if (isPlatformServer(this.platformId) || !(error.error instanceof Blob)) {
      interceptObservable.error(error);
      interceptObservable.complete();
      return of({});
    }

    this.blobToText(error.error).subscribe((json) => {
      const errorBody = json === '' || json === 'null' ? {} : JSON.parse(json);
      const errorResponse = new HttpResponse({
        headers: error.headers,
        status: error.status,
        body: errorBody,
      });

      const ajaxResponse = this.getAbpAjaxResponseOrNull(errorResponse);

      if (ajaxResponse != null) {
        this.handleAbpResponse(errorResponse, ajaxResponse);
      } else {
        this.handleNonAbpErrorResponse(errorResponse);
      }

      errorObservable.complete();

      interceptObservable.error(error);
      interceptObservable.complete();
    });

    return errorObservable;
  }

  handleResponse(response: HttpResponse<any>): HttpResponse<any> {
    const ajaxResponse = this.getAbpAjaxResponseOrNull(response);
    if (ajaxResponse == null) {
      return response;
    }

    return this.handleAbpResponse(response, ajaxResponse);
  }

  handleAbpResponse(
    response: HttpResponse<any>,
    ajaxResponse: IAjaxResponse
  ): HttpResponse<any> {
    let newResponse: HttpResponse<any>;

    if (ajaxResponse.success) {
      newResponse = response.clone({
        body: ajaxResponse.result,
      });

      if (ajaxResponse.targetUrl) {
        this.handleTargetUrl(ajaxResponse.targetUrl);
      }
    } else {
      newResponse = response.clone({
        body: ajaxResponse.result,
      });

      if (!ajaxResponse.error) {
        // ajaxResponse.error = this.defaultError;
      }

      // this.logError(ajaxResponse.error);
      // this.showError(ajaxResponse.error);

      if (response.status === 401) {
        // this.handleUnAuthorizedRequest(null, ajaxResponse.targetUrl);
      }
    }

    return newResponse;
  }

  // TODO: add a messageservice for generic error handling at some point... perhaps snackbar
  showError(error: IErrorInfo): any {
    if (error.details) {
      return 'Error that is temporary';
      // return this._messageService.error(error.details, error.message || this.defaultError.message);
    } else {
      return 'Error that is temporary';
      // return this._messageService.error(error.message || this.defaultError.message);
    }
  }

  handleNonAbpErrorResponse(response: HttpResponse<any>) {
    const self = this;

    // switch (response.status) {
    //     case 401:
    //         self.handleUnAuthorizedRequest(
    //             self.showError(self.defaultError401),
    //             '/'
    //         );
    //         break;
    //     case 403:
    //         self.showError(self.defaultError403);
    //         break;
    //     case 404:
    //         self.showError(self.defaultError404);
    //         break;
    //     default:
    //         self.showError(self.defaultError);
    //         break;
    // }
  }

  handleTargetUrl(targetUrl: string): void {
    if (!targetUrl) {
      location.href = '/';
    } else {
      location.href = targetUrl;
    }
  }

  getAbpAjaxResponseOrNull(response: HttpResponse<any>): IAjaxResponse | null {
    if (!response || !response.headers) {
      return null;
    }

    const contentType = response.headers.get('Content-Type');
    if (!contentType) {
      return null;
    }

    if (contentType.indexOf('application/json') < 0) {
      return null;
    }

    const responseObj = JSON.parse(JSON.stringify(response.body));
    if (!responseObj.__abp) {
      return null;
    }

    return responseObj as IAjaxResponse;
  }

  ab2str(buf): string {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
  }

  isHttpEvent(obj: any): obj is HttpEvent<any> {
    return obj.type !== undefined && obj.type === HttpEventType.Sent;
  }

  protected blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next('');
        observer.complete();
      } else {
        if (isPlatformBrowser(this.platformId)) {
          const reader = new FileReader();
          reader.onload = function () {
            observer.next(this.result);
            observer.complete();
          };
          reader.readAsText(blob);
        } else {
          observer.next(blob);
          observer.complete();
        }
      }
    });
  }
}

export interface IAjaxResponse {
  success: boolean;
  result?: any;
  targetUrl?: string;
  error?: IErrorInfo;
  unAuthorizedRequest: boolean;
  __abp: boolean;
}

export interface IErrorInfo {
  code: number;
  message: string;
  details: string;
  validationErrors: IValidationErrorInfo[];
}

export interface IValidationErrorInfo {
  message: string;
  members: string[];
}
