import { Component, OnInit, Input } from "@angular/core";
import { TwoUpPageTypes } from "@core/enum/enum";

// create class like the hero component to transform data

@Component({
  selector: "app-two-up-content",
  templateUrl: "./two-up-content.component.html",
  styleUrls: ["./two-up-content.component.scss"],
})
export class TwoUpContentComponent implements OnInit {
  @Input() contentData;
  @Input() page;
  title: string;
  copy: string;
  constructor() {}

  ngOnInit() {
    switch (this.page) {
      case TwoUpPageTypes.Botw:
        this.title = this.contentData.heading;
        this.copy = this.contentData.description.value;
        break;
      case TwoUpPageTypes.BotwSub:
        this.title = this.contentData.heading;
        this.copy = this.contentData.description.value;
        break;
      case TwoUpPageTypes.PerfectBlend:
        this.title = this.contentData.heading;
        this.copy = this.contentData.description.value;
        break;
      case TwoUpPageTypes.PerfectBlendSub:
        this.title = this.contentData.heading;
        this.copy = this.contentData.description.value;
        break;
      case TwoUpPageTypes.Brand:
        this.title = this.contentData.itemHeading;
        this.copy = this.contentData.itemDescription.value;
        break;
      case TwoUpPageTypes.Category:
        this.title = this.contentData.itemHeading;
        this.copy = this.contentData.itemDescription.value;
        break;
      case TwoUpPageTypes.Community:
        this.title = this.contentData.heading;
        this.copy = this.contentData.description.value;
        break;
      case TwoUpPageTypes.LMS:
        this.title = this.contentData.heading;
        this.copy = this.contentData.description.value;
        break;
      case TwoUpPageTypes.News:
        this.title = this.contentData.heading;
        this.copy = this.contentData.description.value;
        break;
      case TwoUpPageTypes.ServiceWell:
        this.title = this.contentData.heading;
        this.copy = this.contentData.description.value;
        break;
      default:
        this.title = this.contentData.copy.heading;
        this.copy = this.contentData.copy.text;
        break;
    }

    // if (this.page === TwoUpPageTypes.Botw || this.page === TwoUpPageTypes.BotwSub) {
    // } else if (this.page === TwoUpPageTypes.Brand) {
    //   this.title = this.contentData.itemHeading;
    //   this.copy = this.contentData.itemDescription.value;
    // } else if (this.page === TwoUpPageTypes.Category) {
    //   this.title = this.contentData.itemHeading;
    //   this.copy = this.contentData.itemDescription.value;
    // } else {
    //   this.title = this.contentData.copy.heading;
    //   this.copy = this.contentData.copy.text;
    // }
  }
}
