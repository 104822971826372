import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NguCarousel, NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { get } from 'lodash';
import { ComponentBase } from '@core/classes/component-base';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-media-carousel',
  templateUrl: './media-carousel.component.html',
  styleUrls: ['./media-carousel.component.scss']
})
export class MediaCarouselComponent extends ComponentBase implements OnInit {
  @Input() data;
  @Input() heading;
  @Input() copy;
  @Input() border;
  carouselItems = [];
  highlightedItem;
  isArrows: boolean;
  @ViewChild('myCarousel', { static: true }) myCarousel: NguCarousel<any>;
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 4, all: 0 },
    slide: 1,
    point: {
      visible: true
    },
    load: 4,
    touch: false,
    loop: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };
  constructor(private imgPipe: ImgSafePathPipe, private breakPointObserver: BreakpointObserver) { super(); }

  ngOnInit() {
    this.data.forEach(element => {
      if (element._discriminator === 'Image') {
        this.carouselItems.push({ src: this.imgPipe.transform(element, `images[0].url`), type: 'img' });
      } else if (element._discriminator === 'Video') {
        this.carouselItems.push({ src: element.url, type: 'video' });
      }
    });
    this.highlightedItem = this.carouselItems[0];

    this.checkBrowserWidth();
  }

  changeHighlightedSlide(slide) {
    this.highlightedItem = slide;
  }

  checkBrowserWidth() {
    if (this.carouselItems) {
      if (this.carouselItems.length > 1) {
        this.unSub(this.breakPointObserver.observe([
          `(min-width: 1201px)`
        ])).subscribe(result => {
          if (result.matches) {
            this.isArrows = this.carouselItems.length > 4 ? true : false;
            if (this.myCarousel) {
              this.myCarousel.reset();
            }
          }
        });

        this.unSub(this.breakPointObserver.observe([
          `(max-width: 1200px) and (min-width: 992px)`
        ])).subscribe(result => {
          if (result.matches) {
            this.isArrows = this.carouselItems.length > 2 ? true : false;
            if (this.myCarousel) {
              this.myCarousel.reset();
            }
          }
        });

        this.unSub(this.breakPointObserver.observe([
          `(max-width: 991px)`
        ])).subscribe(result => {
          if (result.matches) {
            this.isArrows = this.carouselItems.length > 1 ? true : false;
            if (this.myCarousel) {
              this.myCarousel.reset();
            }
          }
        });
      }
    }
  }

}


