import { Pipe, PipeTransform } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Pipe({
  name: 'localizeRoute'
})
export class LocalizeRoutePipe implements PipeTransform {
  constructor(private localize: LocalizeRouterService) { }
  transform(value: any, args?: any): any {
    return this.localize.translateRoute(value);
  }

}
