<div *ngIf="show && !routeDisabled">
  <div id="age-gate-wrapper">
    <div id="age-gate-content" style="text-align: center">
      <div>
        <section class="splash">
          <mat-tab-group>
            <mat-tab [label]="verifyAgeTabTitle">
              <ng-template matTabContent>
                <bsi-agegate #ageGate>
                  <div class="age-gate">
                    <div class="row justify-content-center">
                      <img class="age-gate__logo" src="../../../../../assets/images/blend-swirl.webp" alt="" />
                    </div>
                    <div class="row justify-content-center">
                      <p class="age-gate__headline">{{'AgeGate.Verify' | translate}}</p>
                    </div>
                    <div class="row justify-content-center">
                      <p class="mini">{{ 'AgeGate.Disclaimer' | translate }}</p>
                    </div>
                    <div class="row justify-content-center">
                      <bsi-countries [preselected]="['US', 'AT', 'AU', 'DE', 'IN', 'NZ']">
                      </bsi-countries>
                    </div>
                    <div class="row age-gate__date-row">
                      <bsi-agegate-inputs [international]="international">
                      </bsi-agegate-inputs>
                    </div>
                    <div class="row justify-content-center">
                      <bsi-remember>
                        <p class="tb_type-group__copy" style="margin-bottom: 0">
                          {{ 'AgeGate.RememberMe' | translate }}
                        </p>
                      </bsi-remember>
                    </div>
                    <div class="row justify-content-center">
                      <p class="label age-gate__terms">
                        {{ 'AgeGate.AgreeOne' | translate }}

                        <!-- House of Suntory encourages responsible drinking. Alcohol should be consumed in moderation. By entering this website, you are agreeing to our -->
                        <a *ngIf="termLink" target="_blank" href="{{ termLink }}">{{ 'Footer.Terms' | translate }}</a>,
                        <a *ngIf="privacyLink" target="_blank"
                          href="{{ privacyLink }}">{{ 'AgeGate.Privacy' | translate }}</a>,
                        {{ 'AgeGate.AgreeTwo' | translate }}
                        <!-- And -->
                        <a *ngIf="cookieLink" target="_blank"
                          href="{{ cookieLink }}">{{ 'AgeGate.Cookie' | translate }}</a>
                      </p>

                    </div>
                    <div class="row justify-content-center">
                      <bsi-submit>{{ 'AgeGate.Continue' | translate }}
                      </bsi-submit>
                    </div>
                    <select *ngIf="selectedLangauge" [(ngModel)]="selectedLangauge" (change)="changeLanguage($event)"
                      id="language" class="age-gate__language">
                      <option [value]="language.languageCode" *ngFor="let language of languages">
                        {{ language.languageDisplay }}
                      </option>
                    </select>
                  </div>
                </bsi-agegate>
              </ng-template>
            </mat-tab>
            <mat-tab [label]="logInTabTitle">
              <ng-template matTabContent>
                <app-sign-in loginLocation="age gate" style="width: 100%">
                </app-sign-in>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </section>
      </div>
    </div>
  </div>
</div>