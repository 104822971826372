<!-- <div class="tb_media-card tb_media-card--image tb_media-card--podcast" #card *ngIf="podcastContent">
  <div class="tb_media-card__tooltip">
    <p class="tb_media-card__tooltip-text" [innerHTML]="podcastContent.description.value"></p>
  </div>
  <div class="tb_media-card__content-top">
    <img appLazyLoad class="tb_media-card__image" [src]="podcastImg" alt="">
    <div class="tb_media-card__bg-overlay"></div>
    <div class="tb_media-card__bottom">
      <a class="tb_icon-link tb_media-card__cta tb_media-card__cta--play" [routerLink]="" (click)="open(content)"
        *ngIf="isPaused"></a>
      <span class="tb_media-card__meta"></span>
    </div>
    <div class="tb_media-card__content" (click)="open(content)">
      <h3 class="tb_media-card__title" [innerHTML]="podcastContent.episodeTitle.value"></h3>
    </div>
    <div class="tb_media-card__top">
      <span class="tb_media-card__category" [innerHTML]="formatDate()"></span>
      <span class="tb_media-card__top-icon tb_media-card__top-icon--info"
        (click)="card.classList.toggle('tooltip')"></span>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">
      <svg class="icon icon-cross">
        <use xlink:href="#icon-cross">
          <symbol id="icon-cross" viewBox="0 0 32 32">
            <title>cross</title>
            <path
              d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z">
            </path>
          </symbol>
        </use>
      </svg></span>
  </button>
  <div class="modal-body">
    <iframe width="560" height="349" [src]="podcastContent.embedUrl | safe: 'resourceUrl'" frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</ng-template> -->

<div class="tb_podcast-card" #card *ngIf="podcastContent">
  <div class="tb_media-card__tooltip">
    <p class="tb_media-card__tooltip-text" [innerHTML]="podcastContent.description.value"></p>
  </div>
  <div class="tb_podcast-card__top">
    <!-- <div class="tb_podcast-card__label label">
          Category / Label
      </div> -->
    <div class="tb_podcast-card__icon">
      <div class="icon icon-play2" (click)="open(content)">
        <svg>
          <use xlink:href="#icon-play2">
            <symbol id="icon-play2" viewBox="0 0 32 32">
              <path
                d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM12 9l12 7-12 7z">
              </path>
            </symbol>
          </use>
        </svg>
      </div>

      <p class="seriesHeadline" *ngIf="podcastContent.series">
        {{podcastContent.series['heading'].value}}
      </p>
    </div>
  </div>
  <div class="tb_podcast-card__content">
    <p class="h4" [innerHTML]="podcastContent.episodeTitle.value"></p>
  </div>
  <div class="tb_podcast-card__bottom">
    <div class="tb_podcast-card__bottom-left">
      <svg class="icon icon-notification" (click)="card.classList.toggle('tooltip')">
        <use xlink:href="#icon-notification">
          <symbol id="icon-notification" viewBox="0 0 32 32">
            <path
              d="M16 3c-3.472 0-6.737 1.352-9.192 3.808s-3.808 5.72-3.808 9.192c0 3.472 1.352 6.737 3.808 9.192s5.72 3.808 9.192 3.808c3.472 0 6.737-1.352 9.192-3.808s3.808-5.72 3.808-9.192c0-3.472-1.352-6.737-3.808-9.192s-5.72-3.808-9.192-3.808zM16 0v0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16s7.163-16 16-16zM14 22h4v4h-4zM14 6h4v12h-4z">
            </path>
          </symbol>
        </use>
      </svg>
      <p [innerHTML]="formatDate()"></p>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <span aria-hidden="true" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <svg class="icon icon-cross">
      <use xlink:href="#icon-cross">
        <symbol id="icon-cross" viewBox="0 0 32 32">
          <title>cross</title>
          <path
            d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z">
          </path>
        </symbol>
      </use>
    </svg>
  </span>

  <div class="modal-body">
    <iframe width="560" height="349" [src]="podcastContent.embedUrl | safe: 'resourceUrl'" frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</ng-template>