import { Component, OnInit, Input, ComponentFactoryResolver, ViewContainerRef, ViewChild, TemplateRef, PLATFORM_ID, Inject } from '@angular/core';
import { LinkComponent } from '../components/link/link.component';
import { HostDirective } from '@shared/directives/host.directive';
import { BasicPageService, DynamicElement } from '../services/basic-page.service';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'


@Component({
  selector: 'app-basic-page',
  templateUrl: './basic-page.component.html',
  styleUrls: ['./basic-page.component.scss']
})
export class BasicPageComponent implements OnInit {
  @Input('data') data: string;

  public elements: DynamicElement[] = [];
  public ssrHtml: SafeHtml;

  constructor(private basicPageSvc: BasicPageService,
    @Inject(PLATFORM_ID) private platform: Object,
    private sanitized: DomSanitizer) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      this.elements = this.basicPageSvc.parseChildren(this.basicPageSvc.parseDocument(this.data).body);
    } else {
      this.ssrHtml = this.sanitized.bypassSecurityTrustHtml(this.data);
    }
  }






}
