import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RegistrationDialogComponent } from '@shared/registration-dialog/registration-dialog.component';

@Component({
  selector: 'app-dialog-opener',
  template: '',
})
export class DialogOpenerComponent implements OnInit {

  constructor(private dialog: MatDialog, private router: Router) {}

  ngOnInit(): void {
    const dialogRef = this.dialog.open(RegistrationDialogComponent, {
      width: '420px',
      maxWidth: '90vw',
      panelClass: 'registration-dialog',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.router.navigateByUrl('/'); // Redirect to home after the dialog is closed
    });
  }
}
