<div class="tb_mark-description__description-group-copy">
  <p class="p-ingredient" *ngFor="let ingredient of ingredients">
    <ng-container *ngIf="isUsMarket">{{ingredient.usIngredientAmount}}</ng-container>
    <ng-container *ngIf="!isUsMarket">{{ingredient.globalIngredientAmount}}</ng-container>
    <ng-container *ngIf="!ingredient.ingredientProduct">
      {{ingredient.ingredientName}}
    </ng-container>
    <ng-container *ngIf="ingredient.ingredientProduct">
      <a class="tb_link" [ngClass]="{goldLink: perfectBlendLocation}"
        [routerLink]="['/learn/products' | localize, ingredient.ingredientProduct.slug]">
        {{ingredient.ingredientName}}
      </a>
    </ng-container>
  </p>
</div>