<div class="wrapper-icon-card" [ngClass]="{'reverse' : reverse}">
  <!-- Handle card image -->
  <a class="" [routerLink]="link" *ngIf="showLink">
    <ng-container *ngTemplateOutlet="cardImg"></ng-container>
  </a>
  <ng-container *ngIf="!showLink">
    <ng-container *ngTemplateOutlet="cardImg"></ng-container>
  </ng-container>

  <!-- Handle Copy -->
  <div class="tb_card__copy-default tb_card__copy-default--reverse">
    <h3 *ngIf="header" class="h4" [innerHTML]="data.heading"></h3>
    <div *ngIf="!header" class="h4" [innerHTML]="data.heading"></div>
    <p class="tb_card__text" [innerHTML]="data.text"></p>
  </div>

  <!-- Link -->
  <a class="tb_icon-link link-text" [routerLink]="link" style="display: block" *ngIf="showLink && !serviceWellItem">
    {{ "Home.View" | translate: { title: data.heading } }}
    <span class="tb_icon-link__icon tb_icon-link__icon--right">
      <img src="../../../assets/images/icons/arrow-right-circle.svg" />
    </span>
  </a>
  <a class="tb_icon-link link-text" [routerLink]="link" style="display: block"
    *ngIf="showLink && serviceWellItem && authenticated">
    {{ "Home.ViewArticle" | translate: { title: data.heading } }}
    <span class="tb_icon-link__icon tb_icon-link__icon--right">
      <img src="../../../assets/images/icons/arrow-right-circle.svg" />
    </span>
  </a>
</div>

<ng-template #cardImg>
  <img appLazyLoad class="img-fluid" [src]="icon" alt="" />
</ng-template>

<!-- <ng-template #card>
  <div class="wrapper-icon-card">
    <img class="img-fluid" [src]="icon" alt="">
    <div class="tb_card__copy-default tb_card__copy-default--reverse">
      <h3 class="tb_card__heading" [innerHTML]="data.heading"></h3>
      <p class="tb_card__text" [innerHTML]="data.text"></p>
    </div>
  </div>
</ng-template> -->