import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { LocalizeRouterService, LocalizeParser } from '@gilsdav/ngx-translate-router';
import { RoutingLocale } from '@routing/interfaces/routing';
import {
  ListResultDtoOfTaxMarket,
  FilterServiceProxy,
  FilterTaxMarketInput,
  TaxMarket,
  Base
} from '@proxy/service-proxies';
import { Subject } from 'rxjs';

import { BehaviorSubject, from } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Route, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ProfileService } from '@core/services/profile.service';
import { MarketService } from '@shared/services/market.service';

const contentTypes: ContentTypeRoute[] = [
  { route: '/news/barfly', discriminator: 'BarflyPage' },
  { route: '/botw', discriminator: 'BotwPage' },
  { route: '/brands', discriminator: 'BrandsPage' },
  { route: '/categories', discriminator: 'CategoryPage' },
  { route: '/cocktails', discriminator: 'CocktailPage' },
  { route: '/learn', discriminator: 'EducationPage' },
  { route: '/whats-on', discriminator: 'EventPage' },
  { route: '/learn/geek-out', discriminator: 'GeekOutPage' },
  { route: '/', discriminator: 'Home' },
  { route: '/news', discriminator: 'NewsPage' },
  { route: '/products', discriminator: 'ProductPage' }
];

/**
 *  An object that maps lang file exceptions for language-market
 *  For example es-us uses mexican spanish es-mx
 */
export const LANG_EXCEPTIONS: Object = {
  'es-us': 'es-mx',
};

/**
 * An object that maps lang file exceptions for language for ROUTES ONLY. If translations file AND route are specific to a market do not use this.
 */
export const ROUTE_EXCEPTIONS: Object = {
  'en-us': 'en-us'
};

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  // private _onMarketChanged = new BehaviorSubject<RoutingLocale | null>(null);
  // marketChanged$ = this._onMarketChanged.asObservable();
  // private _currentLocale: RoutingLocale | null;
  allMarketsRecieved: Subject<any> = new Subject<any>();
  activeMarketsRecieved: Subject<any> = new Subject<any>();
  marketChanged: Subject<TaxMarket> = new Subject<TaxMarket>();

  constructor(private localizeService: LocalizeRouterService,
    private localizeParser: LocalizeParser,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platform: Object,
    private profileService: ProfileService,
    private filterService: FilterServiceProxy,
    private router: Router
  ) { }

  public static parseLanguage(language: string): RoutingLocale {
    const lang = language.split('-');

    // Check for exceptions
    if (LANG_EXCEPTIONS.hasOwnProperty(language)) {
      const locale: RoutingLocale = {
        language: LANG_EXCEPTIONS[language],
        market: lang[1] !== null ? lang[1] : '00'
      } as RoutingLocale;
      return locale;
    }
    else {
      const locale: RoutingLocale = {
        language: lang[0] !== null ? lang[0] : 'en',
        market: lang[1] !== null ? lang[1] : '00'
      } as RoutingLocale;
      return locale;
    }

  }
  public getLocale() {
    return RoutingService.parseLanguage(this.localizeService.parser.currentLang);
  }

  /**
   * Compares two routing locales return
   * @param locale1
   * @param locale2
   * @returns true if locales are the same
   */
  public compareLocales(locale1: RoutingLocale, locale2: RoutingLocale): boolean {
    return (locale1.language === locale2.language) && (locale1.market === locale2.market);
  }


  /**
   * Converts an language to the <html lang> value
   * @param lang
   */
  public parseHtmlLang(lang: string) {
    const l = RoutingService.parseLanguage(lang);
    return this.isMarketGlobal(l.market) ? l.language : lang;
  }

  public setLanguageFromAgeGate(countryCode: string, language: string, markets: ListResultDtoOfTaxMarket) {
    if (countryCode !== null && markets !== null) {
      // Check for active market
      const active = markets.items.find(market => market.country.toLowerCase() === countryCode.toLowerCase());
      if (active) {
        // notify subscribers of market change
        // this._currentLocale = {
        //   language: 'en',
        //   market: countryCode.toLowerCase()
        // } as RoutingLocale;
        // this._onMarketChanged.next(this._currentLocale);
        this.localizeService.changeLanguage(`${active.defaultLanguage['languageCode']}-${countryCode.toLowerCase()}`);
      }
    }
  }

  /**
   * Handles logic for potentially changing market for using on sign in
   * @param country - country code
   */
  public handleSignInMarket(country: string, markets: TaxMarket[]): void {
    // if markets don't match
    if (country.toLowerCase() !== this.getLocale().market.toLowerCase()) {
      const market = markets.find((market: TaxMarket) => market.country.toLowerCase() === country.toLowerCase());
      // if markets don't match and they are on active market
      if (market) {
        this.changeMarket(market);
        // if markets don't match and user profiles market is not active
      } else {
        // if markets match
        this.router.navigate(['/']);

      }
    } else {
      this.router.navigate([this.localizeService.translateRoute('/')]);
    }
  }

  /**
   * Handles logic of redirect for using on access page
   * @param redirect - url
   */
  public handleLogInRedirect(redirect: string | any[]): void {
    this.router.navigate([redirect]);
  }

  /**
   * Removes a market from a url if not global market
   * @param url 
   * @param locale 
   */
  private removeMarketFromUrl(url: string, locale: RoutingLocale): string {
    if (!this.isMarketGlobal(locale.market)) {
      // String market and leading slash
      url = url.replace(/^\/?[a-zA-Z]{2}-[a-zA-Z]{2}\//gm, '');
    }
    return url;
  }

  /**
   * Given a URL path and a locale, this method changes the URL to the appropriate lang/market and localizes it
   * @param url - The URL to change
   * @param locale - The locale to use
   */
  public changeUrlMarketChange(url: string, locale: RoutingLocale): string {
    // // If not global then replace the market
    url = this.removeMarketFromUrl(url, locale);

    // Add market and return
    const transUrl = this.localizeService.translateRoute(url);
    if (!this.isMarketGlobal(locale.market)) {
      return `${this.getUrlMarketWithLanguage(locale)}/${transUrl}`;
    }
    else {
      return transUrl.toString();
    }
  }

  public cleanReturnUrl(url: string, locale: RoutingLocale) {
    const originalUrl = url;

    url = this.removeMarketFromUrl(url, locale);

    // Make url lowercase
    url = url.toLowerCase();

    // console.log(url, this.localizeParser.routes);
    // Match a url to a route and get base for translation
    const urlSplit = url.split('/');
    const match = this.localizeParser.routes.find((route: Route) => {
      // Check if url matches route
      if (route.path === url) {
        return true;
      }
      else {
        const routeSplit = route.path.split('/');
        // If split lengths are the same we have to check otherwise we know they are the same
        if (urlSplit.length === routeSplit.length) {
          // Every item in route split should match url split
          const match = routeSplit.every((ritem, index) => {
            // If ritem starts with :
            if (ritem.startsWith(':')) {
              return true;
            }
            // If the items match then we are good
            else if (ritem === urlSplit[index]) {
              return true;
            }

            // No match so they are not equal
            return false;
          });

          return match;
        }
        else {
          // lengths don't match then there can't be a match
          return false;
        }

      }

      // Not found so return false
      return false;
    });

    // If we have a match then replace url variables
    if (match) {
      // console.log(match);
      const routeSplit = match.data.localizeRouter.path.split('/');
      let cleanUrl = '';
      routeSplit.forEach((ritem, index) => {
        if (ritem.startsWith(':')) {
          cleanUrl += urlSplit[index];
        } else {
          cleanUrl += ritem;
        }

        // Add trailing slash when necessary
        if (index !== routeSplit.length - 1) {
          cleanUrl += '/';
        }
      });

      // Add market and return
      if (!this.isMarketGlobal(locale.market)) {
        return `${this.getUrlMarketWithLanguage(locale)}/${cleanUrl}`;
      }
      else {
        return cleanUrl;
      }

    } else {
      // return false;
      return originalUrl;
    }

  }

  /**
   * Given a locale, return the url path for market/language in format en-us
   * @param locale 
   */
  private getUrlMarketWithLanguage(locale: RoutingLocale) {
    if (locale.language.length > 2) {
      const lang = locale.language.split('-')[0];
      return `${lang}-${locale.market}`;
    }
    else {
      return `${locale.language}-${locale.market}`;
    }
  }

  public changeLanguage(lang) {
    const locale = this.getLocale();
    if (lang.length > 2) {
      lang = lang.split('-')[0];
      this.translate.use(`${lang}-${locale.market}`);
      this.localizeService.changeLanguage(`${lang}-${locale.market}`);
      this.localizeParser.translateRoutes(`${lang}-${locale.market}`);
    } else {
      this.translate.use(`${lang}-${locale.market}`);
      this.localizeService.changeLanguage(`${lang}-${locale.market}`);
      this.localizeParser.translateRoutes(`${lang}-${locale.market}`);
    }
  }


  /**
   * 
   * @param market - TaxMarket
   */
  public changeMarket(market) {
    this.changeMarketWithRedirect(market, '/')
  }

  /**
   * 
   * @param market - TaxMarket
   * @param redirect - Url
   */
  public changeMarketWithRedirect(market, redirect) {
    this.router.navigate([redirect]);
    setTimeout(() => {
      // notify subscribers of market change
      // this._currentLocale = {
      //   language: 'en',
      //   market: market.toLowerCase()
      // } as RoutingLocale;
      // this._onMarketChanged.next(this._currentLocale);
      // this.localizeService.changeLanguage(`en-${market.toLowerCase()}`);
      const defaultLanguage = market.defaultLanguage.languageCode;
      this.translate.use(`${defaultLanguage}-${market.country.toLowerCase()}`);
      this.localizeService.changeLanguage(`${defaultLanguage}-${market.country.toLowerCase()}`, undefined, false);
      this.marketChanged.next(market);
    });
  }


  public getActiveMarkets() {
    this.filterService.market(new FilterTaxMarketInput({ active: true, languageCode: this.getLocale().language })).subscribe(data => {
      const activeMarkets = data;
      activeMarkets.items.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      this.activeMarketsRecieved.next(activeMarkets);
    });
  }

  public getAllMarkets() {
    this.filterService.market(new FilterTaxMarketInput({ active: false, languageCode: this.getLocale().language })).subscribe(data => {
      const markets = data;
      markets.items.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      this.allMarketsRecieved.next(markets);
    });
  }
  /**
   * Redirects to home page, gets the user's profile and redirects to their market if active
   */
  public redirectUserMarket() {
    // set country based on profile
    const locale = this.getLocale();
    if (isPlatformBrowser(this.platform)) {
      from(this.router.navigateByUrl('/')).subscribe(status => {
        this.filterService.getMarket(true, locale.language).subscribe(markets => {
          this.profileService.getProfile().subscribe(profile => {
            this.setLanguageFromAgeGate(profile.country.toLowerCase(), locale.language, markets);
          });
        });
      });
    }
  }

  /**
   * Checks if a market is considered global
   * @param market
   */
  public isMarketGlobal(market: string) {
    return market === '00';
  }

  isGlobal() {
    const locale = this.getLocale();
    return locale.market === '00';
  }

  /**
   * Returns the route for the content type based on the discriminator
   * @param contentType The discriminator of the content type
   */
  public getRouteFromContentType(discriminator: string) {
    const type = contentTypes.find(type => type.discriminator === discriminator);
    return type ? type.route : '/';
  }


}

interface ContentTypeRoute {
  route: string;
  discriminator: string;
}
