<div class="tb_2up-cta-section">
  <div class="tb_2up-cta-section__left col-md-offset-2">
    <div class="tb_2up-cta-section__text tb_type-group tb_type-group--reverse">
      <div class="tb_type-group__subheading" [innerHTML]="data.heading"></div>
      <p class="tb_type-group__copy" [innerHTML]="data.copy"></p>
    </div>
  </div>
  <div class="tb_2up-cta-section__right">
    <a class="tb_2up-cta-section__button tb_button tb_button--gold"
      [routerLink]="['/perfect-blend' | localize, 'active']">Find Out More</a>
  </div>
</div>