<div class="password">
  <div class="row text-center">
    <p>
      {{ 'Shared.Password.EnterPassword' | translate }}
    </p>
  </div>
  <form (ngSubmit)="submit()" (keyup.enter)="submit()">
    <mat-form-field>
      <input name="password" class="tb_input" matInput type="password" placeholder="{{ 'Auth.SignIn.Password' | translate }}" [(ngModel)]="password">
    </mat-form-field>
    <div class="row justify-content-center">
      <button class="tb_button" type="submit">
        {{ 'Shared.Password.Submit' | translate }}
      </button>
    </div>
  </form>
  <div class="row justify-content-center" style="margin: 1em 0">
    <p class="tb_type-group__subtext">{{errorMessage}}</p>
  </div>

</div>