import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  @Input() cards;
  @ViewChild('myCarousel', { static: false }) myCarousel: NguCarousel<any>;
  carouselConfig: NguCarouselConfig;
  items;
  constructor() {}

  ngOnInit() {
    this.carouselConfig = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      point: {
        visible: true,
      },
      speed: 400,
      load: 2,
      touch: true,
      loop: true,
      easing: 'cubic-bezier(0, 0, 0.2, 1)',
    };
  }
}
