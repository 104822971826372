import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@routing/services/routing.service';
import { Config, TaxLanguage, TaxMarket } from '@proxy/service-proxies';
import { RoutingLocale } from '@routing/interfaces/routing';
import { MarketService } from '@shared/services/market.service';
import { PageService } from '@shared/services/page.service';
import { PageComponentBase } from '@core/classes/page-component-base';
import { ImgSafePathPipe } from '@core/pipes/img.pipe';
import { PageBase, PageStatus, PageLoadType } from '@core/interfaces/page-base';
import { ConfigService, ConfigKeys } from '@shared/services/config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends PageComponentBase implements PageBase {
  public instagramLink = '';
  public fbLink = '';
  // public twitterLink = '';
  public youtubeLink = '';
  public year: number = new Date().getFullYear();

  currentMarket: TaxMarket;
  activeMarkets: TaxMarket[];
  activeLanguage: any;
  public cookieLink: string;
  public termLink: string;
  public privacyLink: string;
  public drinksmartLink: string = 'https://www.drinksmart.com';

  constructor(
    private routingService: RoutingService,
    protected pageSvc: PageService,
    public imgSafePipe: ImgSafePathPipe,
    private configSvc: ConfigService,
    private marketSvc: MarketService
  ) {
    super(pageSvc);
  }

  onPageLoad(event: PageStatus) {
    const locale = this.routingService.getLocale();

    if (
      locale.market.toLowerCase() === 'de' ||
      locale.market.toLowerCase() === 'at'
    ) {
      this.fbLink = 'https://www.facebook.com/pg/TheBlendGermany/about/';
      this.instagramLink = 'https://www.instagram.com/theblendgermany/?hl=de';
      // this.twitterLink = 'https://twitter.com';
      this.youtubeLink = 'https://youtube.com/@theblendworld1988';
    } else if (
      locale.market.toLowerCase() === 'au' ||
      locale.market.toLowerCase() === 'nz'
    ) {
      this.instagramLink = 'https://www.instagram.com/theblend_au/';
      this.fbLink = 'https://www.facebook.com/TheBlendAU';
      // this.twitterLink = 'https://twitter.com';
      this.youtubeLink = 'https://youtube.com/@TheBlendAU';
    } else if (locale.market.toLocaleLowerCase() === 'us') {
      this.instagramLink = 'https://www.instagram.com/the.blend.us/';
      this.fbLink = 'https://www.facebook.com/the.blend.us';
      // this.twitterLink = 'https://twitter.com';
      this.youtubeLink = 'https://youtube.com/@theblendworld1988';
    } else {
      this.instagramLink = 'https://www.instagram.com/theblendworld/';
      this.fbLink = 'https://www.facebook.com/theblendworld';
      // this.twitterLink = 'https://twitter.com';
      this.youtubeLink = 'https://youtube.com/@theblendworld1988';
    }

    // Handle terms & privacy links
    if (
      event.type === PageLoadType.Language ||
      event.type === PageLoadType.Initial
    ) {
      this.configSvc
        .getConfigurations([
          ConfigKeys.TermsLink,
          ConfigKeys.PrivacyLink,
          ConfigKeys.DrinksmartLink,
          ConfigKeys.CookieLink,
        ])
        .subscribe((links) => {
          this.termLink = links[ConfigKeys.TermsLink];
          this.privacyLink = links[ConfigKeys.PrivacyLink];
          this.cookieLink = links[ConfigKeys.CookieLink];

          if (links[ConfigKeys.DrinksmartLink]) {
            this.drinksmartLink = links[ConfigKeys.DrinksmartLink];
          }
        });
    }

    // Get Active Markets
    this.activeMarkets = this.marketSvc.parseActiveMarkets(event.markets);
    this.activeMarkets.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    this.updateMarketInfo(locale);
  }

  showList(event) {
    event.currentTarget.children[0].classList.toggle('showOptions');
  }

  updateMarketInfo(locale: RoutingLocale) {
    let global = this.marketSvc.getGlobalMarket(this.activeMarkets);
    global.name = 'Global';

    this.currentMarket = this.activeMarkets.find((market) => {
      return market.country.toLowerCase() === locale.market;
    });
    // Set current market or global
    this.currentMarket = this.currentMarket ? this.currentMarket : global;

    this.activeLanguage = this.currentMarket.languages.find(
      (language: TaxLanguage) =>
        language.languageCode.toLowerCase() === locale.language.toLowerCase()
    );
  }

  changeActiveMarket(selectedMarket, event) {
    event.preventDefault();
    this.routingService.changeMarket(selectedMarket);
    this.currentMarket = this.activeMarkets.find((market) => {
      return market.country === event.currentTarget.dataset.market;
    });
  }
}
