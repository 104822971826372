import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText || searchText === '') {
      return items;
    }

    if (searchText.length >= 3) {
      searchText = searchText.toLowerCase();
      return items.filter((it) => {
        if (it.type === 'node--product') {
          return it.productSubHeading.toLowerCase().includes(searchText);
        } else if (it.type === 'node--categories') {
          return it.categorySubHeading.toLowerCase().includes(searchText);
        }
      });
    } else {
      return items;
    }
  }
}
