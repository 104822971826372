<div *ngIf="show">
    <div id="single-age-gate-wrapper">
        <div id="single-age-gate-content">
            <div>
                <section class="fullscreen">
                    <div   class="grid-container full">
                        <div class="grid-x">
                            <div class="cell small-12">
                                <div class="content">
                                    <header class="site-header">
                                        <div class="row">
                                            <div class="site-branding">
                                                <a href="/" class="site-logo">
                                                    <img
                                                        src="../../../../assets/images/the-blend-logo.svg"
                                                        title="The Blend">
                                                </a>
                                            </div>
                                        </div>
                                    </header>
                    
                                    <div class="tb_single-age-gate">
                                        <div class="tb_single-age-gate__content">
                                            <div class="tb_single-age-gate__forms">
                                                <div id="tb_single-age-gate-form" class="tb_single-age-gate__form active">
                                                    <p class="tb_single-age-gate__header">
                                                        <a id="close-single-age-gate" (click)="close()"><span class="close"></span></a>
                                                        {{'SingleAgeGate.Welcome' | translate}}
                                                    </p>
                                                    <p class="tb_single-age-gate__copy">
                                                        ({{'SingleAgeGate.Copy' | translate}})
                                                    </p>
                                                    <div class="tb_single-age-gate__form">
                                                        <bsi-agegate>
                                                            <div class="row single-age-gate">
                                                                <div class="tb_single-age-gate__select">
                                                                    <bsi-countries
                                                                        [preselected]="preselected">
                                                                    </bsi-countries>
                                                                </div>
                                                                <div class="tb_single-age-gate__inputs">
                                                                    <bsi-agegate-inputs [international]="international">
                                                                    </bsi-agegate-inputs>
                                                                </div>
                                                                <bsi-remember>
                                                                    <p class="tb_type-group__copy">
                                                                        {{ 'SingleAgeGate.RememberMe' | translate }}
                                                                    </p>
                                                                </bsi-remember>

                                                                <div class="tb_single-age-gate__legal">
                                                                    {{ 'SingleAgeGate.AgreeOne' | translate }}
                                                                        <a class="tb_link" target="_blank"
                                                                        href="{{termLink}}">{{'SingleAgeGate.Terms' | translate}}</a>
                                                                        
                                                                    {{'SingleAgeGate.AgreeTwo' | translate}}
                                                                        <a class="tb_link" target="_blank"
                                                                        href="{{privacyLink}}">{{'SingleAgeGate.Privacy' |translate}}</a>
                                                                </div>

                                                                <bsi-submit>{{ 'SingleAgeGate.Continue' | translate }}
                                                                </bsi-submit>
                                                            </div>
                                                        </bsi-agegate>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>