<div class="tb_mark-description">
  <div class="text-center">
    <h1 class="h1" [innerHTML]="title">
      <span class="tb_mark-description__rule"></span>
    </h1>
  </div>
  <div class="row">
    <div class="col-12 col-md-4 offset-md-1">
      <img appLazyLoad class="img-fluid" [src]="img" alt="" width="529" height="793">
    </div>
    <div class="col-12 col-md-7">
      <div class="tb_mark-description__meta">
        <div class="tb_catlabel">
          <a class="text-link" [routerLink]="['/learn/categories' | localize, categoryLink]">{{category}}</a> // <a
            class="text-link" [routerLink]="['/learn/brands' | localize, brandLink]">{{brand}}</a>
        </div>
      </div>
      <div class="tb_mark-description__description-group">
        <p class="tb_mark-description__description-group-copy" [innerHTML]="description">
        </p>
      </div>
      <div class="tb_mark-description__description-group">
        <h2 class="tb_mark-description__description-group-title h2">{{ 'Shared.ProductDetail.Aroma' | translate }}</h2>
        <p class="tb_mark-description__description-group-copy" [innerHTML]="aroma">

        </p>
      </div>
      <div class="tb_mark-description__description-group">
        <h2 class="tb_mark-description__description-group-title h2">{{ 'Shared.ProductDetail.Taste' | translate }}</h2>
        <p class="tb_mark-description__description-group-copy" [innerHTML]="taste">
        </p>
      </div>
      <div class="tb_mark-description__description-group">
        <h2 class="tb_mark-description__description-group-title h2">{{ 'Shared.ProductDetail.Finish' | translate }}</h2>
        <p class="tb_mark-description__description-group-copy" [innerHTML]="finish">
        </p>
      </div>
    </div>
  </div>
</div>