<app-surface-section [color]="SurfaceColors.Gray700" *ngIf="featuredContent">
  <app-template-section [center]="true" [paddingVal]="'1em'">
    <div class="tb_type-group tb_type-group--centered tb_type-group--reverse" style="width: 100%">
      <div class="row featuredContentRow--media">
        <div class="videoContainer" style="width: 100%" *ngIf="featuredContent.media.type === 'media--external_video'">
          <!-- <iframe [src]="featuredContent.media.url | safe: 'resourceUrl'" frameborder="0" allowfullscreen></iframe> -->
          <div class="row" style="display:flex; justify-content: center;">
            <div class="tb_template__section tb_bordered-image"
              style="position:relative;padding-top:50.25%; width:90%; height: 90%">
              <iframe [src]="featuredContent.media.url | safe: 'resourceUrl'" frameborder="0" allowfullscreen
                style="position:absolute;top:5%;left:5%;width:90%;height:90%;"></iframe>
            </div>
          </div>
        </div>
        <img appLazyLoad class="img-fluid" [src]="imgSafePipe.transform(featuredContent, 'media.images[0].url')" alt="" width="1600" height="900"
          *ngIf="featuredContent.media.type === 'media--image'">
      </div>
      <div class="row featuredContentRow--copy text-center">
        <h2 class="h2" [innerHTML]="featuredContent.heading"></h2>
        <p class="featuredCopy" [innerHTML]="featuredContent.description.value"></p>
      </div>
    </div>
  </app-template-section>
  <app-template-section [center]="true" [paddingVal]="0" *ngIf="link">
    <a [routerLink]="[link]" *ngIf="showLink">
      <button class="tb_button tb_button--outline" [innerHTML]="featuredContent.pageLinkName"
        style="color: white !important"></button>
    </a>
  </app-template-section>
</app-surface-section>