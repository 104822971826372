import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PageType } from '@shared/interfaces/interfaces';

@Component({
  selector: 'app-cocktail-ingredients',
  templateUrl: './cocktail-ingredients.component.html',
  styleUrls: ['./cocktail-ingredients.component.scss']
})
export class CocktailIngredientsComponent implements OnInit {
  @Input() data;
  @Input() type: PageType;
  @Input() locale;
  isUsMarket: boolean;
  ingredients;
  perfectBlendLocation: boolean;
  constructor(private router: Router) { }

  ngOnInit() {
    this.ingredients = this.data;

    this.perfectBlendLocation = this.type === PageType.PerfectBlendFinalist;

    this.isUsMarket = this.locale.market === 'us' ? true : false;

    // if (this.router.url.includes('perfect-blend')) {
    //   this.perfectBlendLocation = true;
    // } else {
    //   this.perfectBlendLocation = false;
    // }
  }

}
